<div class="pr-content-container">
  <div class="pr-content-header" *ngIf="!isMobile">
    <div class="list-title text-h4 font-bold">
      {{ "patient_prescription.benefit" | translate }}
    </div>
  </div>
  <div class="pr-content-body">
    <div class="cards-container">
      <div class="cards-inner-container flex flex-col gap-4 md:flex-row">
        <div class="flex flex-col gap-4">
          <div class="section-header">
            {{ "patient_prescription.details" | translate }}
          </div>

          <div class="pr-card flex flex-col justify-center">
            <div class="card-row flex items-center justify-between md:gap-4">
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.starting_treatment" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription?.start_date || "---" }}
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.type" | translate }}
                </div>
                <div class="card-item-content">
                  <div class="fancy-name" [matTooltip]="patientPrescription?.content?.name">
                    {{ patientPrescription?.content?.name || "---" }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.ending_treatment" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription?.end_date || "---" }}
                </div>
              </div>
            </div>
            <div class="card-row flex gap-4 items-center">
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.attending_physician" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription?.physician?.full_name || "---" }}
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.date" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription?.date }}
                </div>
              </div>
              <div
                class="tag ml-auto"
                [ngClass]="(patientPrescription?.status && patientPrescription?.status=='closed') ? 'tag-done' : 'tag-not-done'"
                [ngStyle]="handleStatusColor(patientPrescription)"
              >
                {{
                patientPrescription?.status
                  ? (("shared." + patientPrescription.status)| translate)
                  : ""
                }}
              </div>
            </div>
          </div>

          <div class="section-header">
            {{'file_manager.toolbar_title'| translate}}
          </div>

          <div class="pr-card flex gap-4 p-4">
            <div class="ai-proof">
              <div class="keen-slider-container mb-8">
                <div class="keen-slider gap-4" #sliderRef [ngBusy]="subscription">
                  <ng-container
                    *ngIf="!prescriptionFiles||prescriptionFiles.length==0"
                  >
                    <div class="nothing-to-display">
                      {{"shared.no_element_to_show"| translate}}
                    </div>
                  </ng-container>
                  <div
                    class="keen-slider__slide"
                    *ngFor="
                        let currentFile of prescriptionFiles;
                        trackBy: trackByFn;
                        let idx = index
                      "
                  >
                    <img [src]="getFileIcon(currentFile)" alt=""/>

                    <div class="keen-slider__slide__actions">
                      <button
                        mat-icon-button
                        class="file-icon-btn"
                        (click)="previewFile(currentFile?.id, currentFile?.type, currentFile?.url_shortcut)"
                        [matTooltip]="
                            'appointment.passage_proof.preview' | translate
                          "
                      >
                        <mat-icon
                          fontSet="mdi"
                          fontIcon="mdi-arrow-expand"
                        ></mat-icon>
                      </button>
                      <div class="keen-slider-file-name">{{currentFile.name}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="section-header">
            {{ "patient_prescription.tutorials" | translate }}
          </div>

          <div class="pr-card flex flex-col  gap-4 p-4">
            <div class="nothing-to-display" *ngIf="tutorials.length==0">
              {{"shared.no_element_to_show" | translate}}
            </div>
            <ng-container *ngFor="let t of tutorials;let idx = index">
              <div class="card-row flex items-center gap-4 clickable-div">
                <div class="tutor-head w-10 font-bold">{{fomatNumber(idx + 1)}}</div>
                <div class="clickable-div-link">
                  <a (click)="openLink(t.url_shortcut)">{{t.name}}</a>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="section-header">
            {{ "patient_prescription.satisfaction_form" | translate }}
          </div>
          <div class="pr-card flex gap-4 p-4" *ngIf="satisfactionForms.length==0">
            <div class="nothing-to-display">
              {{
              "appointment.no_satisfaction_form_to_display"
                | translate
              }}
            </div>
          </div>
          <ng-container *ngFor="let f of satisfactionForms">
            <div class="pr-card flex gap-4 p-4">
              <div class="rating-num text-subtitle1 font-bold">{{(f['satisfaction_form_rating'] / 20).toFixed(1)}}</div>
              <div class="rating-shape-container flex gap-4">
                <div class="rating-shape">
                  <mat-icon
                    [ngClass]="{'star-fill':f['satisfaction_form_rating'] / 20>=1}"
                    fontSet="mdi"
                    fontIcon="mdi-star"
                  ></mat-icon>
                  <mat-icon [ngClass]="{'star-fill':f['satisfaction_form_rating'] / 20>=2}"
                            fontSet="mdi"
                            fontIcon="mdi-star"
                  ></mat-icon>
                  <mat-icon [ngClass]="{'star-fill':f['satisfaction_form_rating'] / 20>=3}"
                            fontSet="mdi"
                            fontIcon="mdi-star"
                  ></mat-icon>
                  <mat-icon [ngClass]="{'star-fill':f['satisfaction_form_rating'] / 20>=4}"
                            fontSet="mdi"
                            fontIcon="mdi-star"
                  ></mat-icon>
                  <mat-icon [ngClass]="{'star-fill':f['satisfaction_form_rating'] / 20==5}"
                            fontSet="mdi" fontIcon="mdi-star"></mat-icon>
                </div>
                <div class="rating-text">#{{f['ticket_external_id'] || f['ticket_internal_id']}}
                  - {{f['ticket_reason']}}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
