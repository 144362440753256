import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrescriptionComponent} from './components/prescription/prescription.component';
import {PrescriptionRoutingModule} from './prescription-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgBusyModule} from 'ng-busy';
import {SharedModule} from '../shared/shared.module';
import {BaseComponent} from './components/base/base.component';
import {PrescriptionService} from './services/prescription.service';
import {ngfModule} from 'angular-file';
import {PrescriptionGridComponent} from './components/prescription-grid/prescription-grid.component';
import {PrescriptionMainComponent} from './components/prescription-main/prescription-main.component';
import {ExternalFilesDialogComponent} from './dialogs/external-files/external-files-dialog.component';
import {FtTableModule} from '@ft/table';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {FtFormModule} from '@ft/form';
import {FileManagerModule} from '@ft/file-manager';
import {CancellationDialogComponent} from './dialogs/cancellation/cancellation.component';
import {ClosureDialogComponent} from './dialogs/closure/closure.component';
import {PrescriptionContentComponent} from './components/prescription-content/prescription-content.component';
import {RenewDialogComponent} from './dialogs/renew/renew.component';
import {SuspendDialogComponent} from './dialogs/suspend/suspend.component';
import {PrescriptionTemplateComponent} from './components/prescription-template/prescription-template.component';
import {PrescriptionBlockComponent} from './components/prescription-block/prescription-block.component';
import {ForceClosureDialogComponent} from './dialogs/force-closure/force-closure.component';
import {ForceConformDialogComponent} from './dialogs/force-conform/force-conform.component';


@NgModule({
    declarations: [
        PrescriptionComponent,
        BaseComponent,
        PrescriptionGridComponent,
        PrescriptionMainComponent,
        ExternalFilesDialogComponent,
        CancellationDialogComponent,
        ClosureDialogComponent,
        PrescriptionContentComponent,
        ClosureDialogComponent,
        RenewDialogComponent,
        SuspendDialogComponent,
        PrescriptionTemplateComponent,
        PrescriptionBlockComponent,
        ForceClosureDialogComponent,
        ForceConformDialogComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        NgBusyModule,
        SharedModule,
        PrescriptionRoutingModule,
        FtTableModule,
        ngfModule,
        FtCoreModule,
        FtFormModule,
        FileManagerModule
    ],
    exports: [
        PrescriptionTemplateComponent
    ],
    providers: [
        PrescriptionService
    ]
})
export class PrescriptionModule {
}
