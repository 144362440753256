<div class="pr-content-container">
  <div class="pr-content-header" *ngIf="!isMobile">
    <div class="list-title text-h4 font-bold">{{ "patient_request.requests" | translate }}</div>
  </div>
  <div class="pr-content-body" infiniteScroll #innerTable
       [infiniteScrollDistance]="5"
       [infiniteScrollThrottle]="100"
       (scrolled)="onScroll()"
       [fromRoot]="false"
       [scrollWindow]="false">
    <pr-search (searchChange)="handleSearch($event)"></pr-search>
    <div class="flex items-center justify-center ">
      <div *ngIf="!requests || requests.length==0"
           class="nothing-to-display">{{ "shared.no_element_to_show" | translate }}</div>
    </div>

    <div class="cards-container">
      <div class="cards-inner-container flex flex-col gap-4">
        <ng-container *ngFor="let request of requests">
          <div *ngIf="isMobile" class="pr-card">
            <div class="more-container flex" *ngIf="!request.request_treated?.is_treated">
              <mat-icon
                class="cursor-pointer"
                fontSet="mdi"
                fontIcon="mdi-dots-vertical"
                [matMenuTriggerFor]="cardMenu"
              ></mat-icon>
            </div>
            <mat-menu #cardMenu>
              <button mat-menu-item (click)="isTreatedRequestAction(request.id)">
                <span>{{ "patient_request.treated" | translate }}</span>
              </button>
            </mat-menu>
            <div class="title card-item mb-4" *ngIf="request.appointment?.ticket.ticket_content_histories.at(-1)
              .reason">
              <div class="text-subtitle2">
                <span class="card-item-title">{{ "patient_request.appointment" | translate }}:</span>
                {{
                request.appointment?.ticket.ticket_content_histories.at(-1)
                  .reason
                }}
              </div>
            </div>
            <div class="content card-item mb-4" *ngIf="request.content">
              <div class="text-subtitle2">
                <span class="card-item-title">{{ "patient_request.request" | translate }}: </span>
                {{ request.content }}
              </div>
            </div>
            <div class="flex gap-4 flex-wrap">
              <div>
                <div class="tag tag-standard">{{ "settings." + request.type | translate }}</div>
              </div>
              <div>
                <div class="tag"
                     [ngClass]="request.request_treated?.is_treated ? 'tag-done' : 'tag-not-done'">                {{
                  (request.request_treated?.is_treated ? "shared.treated" : "shared.not_treated")
                    | translate
                  }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- <div class="search-container" >
      </div> -->
    <div class="table-header grid grid-flow-row-dense grid-cols-12 gap-x-4 px-4" *ngIf="!isMobile">
      <div class="table-header-cell col-span-3">
        <div>{{ "patient_request.type" | translate }}</div>
      </div>
      <div class="table-header-cell col-span-3">
        <div>{{ "patient_request.title" | translate }}</div>
      </div>
      <div class="table-header-cell col-span-3">
        <div>{{ "patient_request.content" | translate }}</div>
      </div>
      <div class="table-header-cell col-span-3">
        <div>{{ "patient_request.status" | translate }}</div>
      </div>
    </div>
    <div class="table-body flex gap-4 flex-col" *ngIf="!isMobile">
      <div
        class="table-b-row min-h-20 grid grid-flow-row-dense grid-cols-12 gap-y-1 p-4 gap-x-4"
        *ngFor="let request of requests"
      >
        <div class="table-b-cell col-span-3 flex items-center">
          <div
            class="tag tag-standard sm:rounded-md sm:whitespace-normal sm:px-4 lg:whitespace-nowrap lg:px-3 lg:rounded-full"
          >
            {{ "settings." + request.type | translate }}
          </div>
        </div>
        <div class="col-span-3 flex items-center">
          <div class="ellipsis" [class.ellipsis]="!request.isEllipsis">
            {{
            request.appointment?.ticket.ticket_content_histories.at(-1)
              .reason || "-"
            }}
          </div>
        </div>
        <div class="col-span-3 flex items-center">
          <div class="ellipsis" [class.ellipsis]="!request.isEllipsis">{{ request.content || "-" }}</div>
        </div>
        <div class="col-span-3 flex">
          <div class="flex-grow flex items-center">
            <div>
              <div
                class="tag tag-done sm:rounded-md sm:whitespace-normal lg:whitespace-nowrap lg:rounded-full"
                [ngClass]="request.request_treated?.is_treated ? 'tag-done' : 'tag-not-done'"
              >
                {{
                (request.request_treated?.is_treated ? "shared.treated" : "shared.not_treated")
                  | translate
                }}
              </div>
            </div>
          </div>
          <div class="flex items-center w-fit">
            <div class="flex mr-2" *ngIf="!request.request_treated?.is_treated">
              <mat-icon
                class="cursor-pointer"
                fontSet="mdi"
                fontIcon="mdi-dots-vertical"
                [matMenuTriggerFor]="menu"
              ></mat-icon>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="isTreatedRequestAction(request.id)">
                <span>{{ "patient_request.treated" | translate }}</span>
              </button>
            </mat-menu>
            <div class="relative w-6 h-6 cursor-pointer" (click)="request.isEllipsis = !request.isEllipsis">
              <div class="arrow" [class.active]="!!request.isEllipsis"></div>
              <!-- <mat-icon
                class="cursor-pointer"
                fontSet="mdi"
                fontIcon="mdi-chevron-down"
              ></mat-icon> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
