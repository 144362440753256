<div class="pr-content-container">
  <div class="pr-content-header" *ngIf="!isMobile">
    <div class="list-title text-h4 font-bold">
      {{ "settings.tfu-models" | translate }}
    </div>
    <button mat-button color="primary" (click)="addTfuModel($event)">{{"shared.add" | translate}}</button>
  </div>
  <div class="pr-content-body">
    <pr-search></pr-search>
    <div class="w-full grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-4">
      <!-- [routerLink]="'/settings/tfu-model/'+tfuModel.id" -->
      <div
      (click)="navigateToTfuModel(tfuModel.id)"
        class="setting-card-item lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12"
        [class.selected]="selectedId === tfuModel.id"
        *ngFor="let tfuModel of tfuModels"
      >
      <div class="flex flex-col flex-grow">
        <div class="header">
          {{ "settings.title" | translate }}
        </div>
        <div class="content">
          {{ tfuModel.title || "---" }}
        </div>
      </div>
      <div class="flex flex-col flex-grow">
        <div class="header">
          {{ "settings.steps_number" | translate }}
        </div>
        <div class="content">
          <div class="fancy-name">
            {{ tfuModel.items?.length }}
          </div>
        </div>
      </div>
      <div class="delete-btn-container">
        <button mat-icon-button (click)="removeTfuModel($event, tfuModel.id)">
          <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon> 
        </button>
      </div>
      </div>
    </div>
  </div>
</div>
