import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  // With this BehaviorSubject you can save the sidenav state and consumed later into other pages.
  public sideNavState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}
}
