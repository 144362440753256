import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { assignIn } from 'lodash';
import * as moment from 'moment';
import { AppConfigsService, AuthService } from '@ft/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-force-conform',
  templateUrl: './force-conform.component.html',
  styleUrls: ['./force-conform.component.scss'],
})
export class ForceConformDialogComponent implements OnInit {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  subscription: Subscription;
  public breakpoints = Breakpoints;

  model = { force_date: moment().format('YYYY-MM-DD'), reason: null };

  constructor(
    public breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ForceConformDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public appConfigService: AppConfigsService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 640px)'])
      .subscribe((result) => {
        if (result.breakpoints['(max-width: 640px)']) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  handleSave() {
    this.dialogRef.close(
      assignIn(
        this.model,
        !this.authService.isRoot()
          ? { force_by: this.authService.getStaff() }
          : {}
      )
    );
  }
}
