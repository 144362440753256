import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TfuBaseComponent } from '../tfu-base/tfu-base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TfuModelService } from '../../../services/tfu-model.service';
import { MatDialog } from '@angular/material/dialog';
import { TFUModelType, TelephoneFollowUpPlanType } from '../../../types/telephone-follow-up.type';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { projectName } from '../../../../shared/utils/consts';
import { convertToNumber } from '../../../../shared/utils/functions';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'pr-tfu-model',
  templateUrl: './tfu-model.component.html',
  styleUrls: ['./tfu-model.component.scss'],
  providers: [TfuModelService],
})
export class TfuModelComponent extends TfuBaseComponent {
  public docId: number | null = null;
  constructor(
    public route: ActivatedRoute,
    public tfuService: TfuModelService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private _titleService: Title,
    private _router: Router
  ) {
    super(route, tfuService, fb, dialog);
    this.isModel = true;
  }

  ngOnInit(): void {
    this._titleService.setTitle(
      projectName +
        ' - ' +
        this.translateService.instant('settings.telephone-follow-up-plan-model')
    );
    this.init();
    this.subs.add(
      this.route.params.subscribe({
        next: (params) => {
          this.docId = convertToNumber(params['id']);
          if (this.docId) {
            this.subs.add(
              this.tfuService.fetch({ id: this.docId }).subscribe({
                next: (tfuModel: TFUModelType): void => {
                  this.tfuObj = tfuModel;
                  this.reactiveForm.get('id')?.setValue(this.tfuObj.id ?? '');
                  this.reactiveForm
                    .get('title')
                    ?.setValue(this.tfuObj.title ?? '');
                },
                error: (error) => {
                  this.docId = null;
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
    super.ngOnInit();
  }

/**
 * Initializes the component by setting up form validation and reactive logic for title changes.
 */
public init(): void {
  // Get the title form control
  const title = this.reactiveForm.get('title');

  // Set up validation for the title
  if (title) {
    title.setValidators([Validators.required]);

    // Listen for title changes, debounced and distinct
    title.valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((val) => {
        this.handleTitleChange(val);
      });
  }
}

/**
 * Handles changes to the title, creating or updating the TFU model as needed.
 *
 * @param val - The new title value
 */
private handleTitleChange(val: string): void {
  this.addIfNone(() => {
    // Check if the title has actually changed
    if (val !== this.tfuObj.title) {
      // Unsubscribe from any previous update subscription
      this.tempSub?.unsubscribe();

      // Update the TFU model with the new title
      this.tempSub = this.tfuService.update({
        id: this.tfuObj.id,
        title: val,
      }).subscribe({
        next: (tfuPlan: TelephoneFollowUpPlanType) => {
          this.tfuObj = tfuPlan;
        },
      });
    }
  });
}

/**
 * Conditionally creates a new TFU model or calls a provided callback function.
 *
 * @param fb - An optional callback function to be executed if a new model is not created.
 */
public addIfNone(fb?: Function): void {
  if (!this.docId && this.reactiveForm.valid) {
    // Create a new TFU model if a docId is not present and the form is valid
    this.subs.add(
      this.tfuService.create(this.reactiveForm.value).subscribe({
        next: (tfuModel: TFUModelType): void => {
          // Update component state with the newly created model
          this.tfuObj = tfuModel;
          this.docId = tfuModel.id;

          // Navigate to the TFU model details page
          this._router.navigate(['/settings/tfu-model/', this.docId], { replaceUrl: true });

          // Optionally call the provided callback function
          if (fb) {
            fb();
          }
        },
      })
    );
  } else if (this.reactiveForm.valid) {
    // Call the provided callback function if the form is valid, but a model already exists
    if (fb) {
      fb();
    }
  } else {
    // Mark all form controls as touched to visually indicate validation errors
    this.reactiveForm.markAllAsTouched();
  }
}

  public isFormControlsValid() {
    return this.reactiveForm.valid;
  }
}
