import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    Optional,
    Self,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatSelect} from '@angular/material/select';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

import {debounce, filter, fromEvent, Observable, of, Subject, Subscription} from 'rxjs';
import {
    cloneDeep,
    get,
    isEmpty,
    isFunction,
    isNaN,
    isNumber,
    map,
    noop,
    uniqueId,
    filter as lodashFilter,
    findIndex,
    has
} from 'lodash';

import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {DialogOpenerDirective, GeneralPurposeService, pushOrUpdate, SelectConfig} from "@ft/core";
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

const CONTROL_LABEL = 'pr-select-search';

@Component({
    selector: 'pr-select-search',
    templateUrl: './select-search.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: MatFormFieldControl, useExisting: SelectSearchComponent}
    ],
    styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnDestroy, MatFormFieldControl<any>, ControlValueAccessor, AfterViewInit {
    private _value;
    private _required = false;
    private _disabled = false;
    private _placeholder: string;
    private _isTouched = false;
    private _sourceSubscription: Subscription;

    private _onTouched = noop;
    private _onValueChange = noop;

    public key = '';
    public valueKey = '';
    public compareKey = 'id';
    public compareWith: (first: any, second: any) => boolean = null;
    public optionContent: (item: any) => string = null;
    public disabledOption: (item: any) => boolean = null;
    public config: SelectConfig;

    public items: any[] = [];
    public initItems: any[] = [];
    public controlType = CONTROL_LABEL;
    public stateChanges: Subject<void> = new Subject<void>();
    public searchKey = null;
    @ViewChild('select', {static: true}) public selectContainer: MatSelect;
    @ViewChild('searchInput') searchInput: ElementRef;

    @Input()
    public get placeholder() {
        return this._placeholder;
    }

    public set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    public get required() {
        return this._required;
    }

    public set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }

    @Input() public multiple: boolean;

    @Input()
    public set disabled(attr) {
        this._disabled = coerceBooleanProperty(attr) || this.items.length === 0;
        this.stateChanges.next();
    }

    public get disabled() {
        return this._disabled;
    }

    @Input()
    public set value(item: any | null) {
        if (this.multiple || !this.compareFunc(this._value, item)) this.writeValue(item);
    }

    public get value(): any | null {
        return this._value;
    }

    @Input('config')
    public set handleConfig(config: SelectConfig) {
        this.config = config;
        this.handleData();
    }

    @Input('dialogOpener')
    public set handleDialogOpener(directive: DialogOpenerDirective) {
        directive.emitter.subscribe(value => {
            this.value = value;
            this.items = pushOrUpdate(this.items, value);
            this.valueChanged();
        });
    }

    @HostBinding()
    public id = uniqueId(`${CONTROL_LABEL}-`);

    @HostBinding('attr.aria-describedby')
    public describedBy = '';

    @HostBinding('class.floating')
    public get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    constructor(
        private _sanitizer: DomSanitizer,
        private _service: GeneralPurposeService,
        private _changeDetectorRef: ChangeDetectorRef,
        @Optional() @Self() public ngControl: NgControl) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngAfterViewInit() {
        // server-side search
        this.clearSearch();
        fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                filter(Boolean),
                debounceTime(100),
                distinctUntilChanged(),
                tap((text) => {
                    if (this.config.event) {
                        this.handleLocalSearch(this.searchInput.nativeElement.value);
                    } else {
                        this.handleSearch(this.searchInput.nativeElement.value);
                    }

                })
            )
            .subscribe();
    }

    public ngOnDestroy() {
        this.stateChanges.complete();
        if (this._sourceSubscription) this._sourceSubscription.unsubscribe();
        this.clearSearch();
    }

    public valueChanged() {
        this._onValueChange(this.value);
        this.stateChanges.next();
    }

    public getContent(item): SafeHtml {
        let content: string;

        if (isFunction(this.optionContent)) {
            content = this.optionContent(item);
        } else {
            content = get(item, this.key);
        }

        return this._sanitizer.bypassSecurityTrustHtml(content);
    }

    public handleDisabledOption(item): boolean {
        if (isFunction(this.disabledOption)) {
            return this.disabledOption(item);
        } else {
            return false;
        }
    }

    // to fix this issue
    public compareFunc = (first: any, second: any) => {
        if (isFunction(this.compareWith)) {
            return this.compareWith(first, second);
        } else {
            let idx = has(second, 'id') ? findIndex(this.items, {id: second.id}) : 1;
            if (idx < 0) {
                this.items.push(second);
            }
            return get(first, this.compareKey, first) === get(second, this.compareKey, second);
        }
    }

    // form control methods
    public get empty() {
        return isEmpty(this.value) || isNumber(this.value) && isNaN(this.value);
    }

    public get focused() {
        return this.selectContainer.focused;
    }

    public get errorState() {
        return this.selectContainer.errorState;
    }

    public setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    public onContainerClick(event: MouseEvent) {
        this._onTouched();
        this._isTouched = true;
    }

    // valueAccessor Implementation
    public registerOnChange(fn: (value: any) => any): void {
        this._onValueChange = fn;
    }

    public registerOnTouched(fn: () => any): void {
        this._onTouched = fn;
    }

    public writeValue(value: any): void {
        this._value = value;
        this._changeDetectorRef.markForCheck();
    }

    public handleData() {
        this.key = get(this.config, 'key', 'value');
        this.valueKey = get(this.config, 'valueKey', null);
        this.compareKey = get(this.config, 'compareKey', 'id');
        this.compareWith = get(this.config, 'compareWith', null);
        this.optionContent = get(this.config, 'optionContent', null);
        this.disabledOption = get(this.config, 'disabledOption', null);

        let subject: Observable<any[]> = of([]);

        if (this.config.url) subject = this._service.getByHttp(this.config.url);
        else if (this.config.event) subject = this._service.getByEvent(this.config.event);
        else if (this.config.observable) subject = this.config.observable;
        else throw new Error('NO OBSERVABLE HAS BEEN PROVIDED');

        if (this._sourceSubscription) this._sourceSubscription.unsubscribe();

        this._sourceSubscription = subject.subscribe(data => {
            this.items = data;
            this.initItems = data;

            this._changeDetectorRef.markForCheck();

            // fix : autoSelect writeOver the real value, if a value is already set
            if (this.config.autoSelect && !this._value && data.length > 0) {
                Promise.resolve().then(() => {
                    if (this.multiple && !this.valueKey) this.value = data;
                    else if (this.multiple && this.valueKey) this.value = map(data, this.valueKey);
                    else if (!this.multiple && this.valueKey) this.value = get(data, `0.${this.valueKey}`);
                    else this.value = cloneDeep(data[0]);

                    this.valueChanged();
                });
            }
        });
    }

    public handleSearch(value: any) {
        if (value) {
            this._service.getByHttp(`${get(this.config, 'url')}?searchKey=${value}`).subscribe(res => {
                this.items = res;
            });
        } else {
            this.handleData();
        }
    }

    public handleLocalSearch(value: any) {
        this.items = lodashFilter(this.initItems, (e) => {
            return get(e, this.key, '').toLowerCase().includes(value.toLowerCase());
        });
    }

    public clearSearch() {
        this.searchKey = null;
        this.handleData();
    }


}

