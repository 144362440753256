import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { TfuPlanService } from '../../../services/tfu-plan.service';
import {
  TelephoneFollowUpItemType,
  TelephoneFollowUpPlanType,
} from '../../../types/telephone-follow-up.type';
import { MatDialog } from '@angular/material/dialog';
import { TfuItemComponent } from '../tfu-item/tfu-item.component';
import { ConfirmDialogComponent } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { TfuBaseComponent } from '../tfu-base/tfu-base.component';

@Component({
  selector: 'pr-tfu-plan',
  templateUrl: './tfu-plan.component.html',
  styleUrls: ['./tfu-plan.component.scss'],
  providers: [TfuPlanService],
})
export class TfuPlanComponent extends TfuBaseComponent {
  @Input() public tfuPlanId: number | null = null;
  constructor(
    public route: ActivatedRoute,
    public tfuService: TfuPlanService,
    public fb: FormBuilder,
    public dialog: MatDialog
  ) {
    super(route, tfuService, fb, dialog);
    this.isModel = false;
  }

  ngOnInit(): void {
    this.subs.add(
      this.route.params.subscribe({
        next: (params) => {
          this.subs.add(
            this.tfuService.fetch({ id: this.tfuPlanId }).subscribe({
              next: (tfuPlan: TelephoneFollowUpPlanType): void => {
                this.tfuObj = tfuPlan;
                this.reactiveForm.get('id')?.setValue(this.tfuObj.id ?? '');
                // this.reactiveForm
                //   .get('title')
                //   ?.setValue(this.tfuObj.title ?? '');
                
                
              },
            })
          );
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
  }
}
