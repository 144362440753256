import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GridComponent} from './components/grid/grid.component';
import {PatientComponent} from './components/patient/patient.component';
import {AuthGuard} from '@ft/core';
import {PatientFormComponent} from './components/patient-form/patient-form.component';
import {PatientMobileFormComponent} from './components/patient-mobile-form/patient-mobile-form.component';
import {PatientDisplayComponent} from './components/patient-display/patient-display.component';

const routes: Routes = [
  {
    path: '',
    component: PatientComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', component: GridComponent },
      // { path: 'form', component: PatientFormComponent},
      { path: 'form-edit', component: PatientFormComponent },
      { path: 'form-edit/:id', component: PatientFormComponent },
      {
        path: 'form/:id',
        component: PatientDisplayComponent,
        data: { projectName: 'PratiLab', title: 'Fiche patient' }, // TODO project name change
      },
      { path: 'mobile-form', component: PatientMobileFormComponent },
      { path: 'mobile-form/:id', component: PatientMobileFormComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientRoutingModule {}
