<!-- <div class="flex-item-1 magnify-container">
    <div
      fxLayout="row"
      class="ft-table-search-bar"
    >
      <mat-icon
        fontSet="mdi"
        fontIcon="mdi-magnify"
        class="magnify-icon"
      ></mat-icon>

      <input
        type="text"
        [(ngModel)]="key"
        fxFlex
        [placeholder]="'table.search' | translate"
      />

      <button
        type="button"
        mat-icon-button
        (click)="cancelSearch()"
        color="warn"
        *ngIf="key.length > 0"
        [@scaleAnimation]
      >
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
      </button>
    </div>
  </div> -->


  <div class="search-bar" [class.focused]="isFocused">
    <div class="search-bar-icon search-bar-item">
        <mat-icon
                fontSet="mdi"
                fontIcon="mdi-magnify"
                [class.mat-primary]="isFocused"
                class="magnify-icon"
        ></mat-icon>
    </div>
    <div class="search-bar-input search-bar-item">
        <input
                type="text"
                fxFlex
                [value]="key"
                [ftAutoFocus]="false"
                [placeholder]="'table.search' | translate"
                (focus)="isFocused = true"
                (blur)="isFocused = false"
                (input)="onSearch($event.target.value)"
        />
    </div>
</div>