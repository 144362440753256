<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-comment-text"></mat-icon>
    </button>
  </div>
  <h2 translate="shared.comments"></h2>
  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>

</mat-toolbar>
  <div mat-dialog-content fxLayout="column">
      <app-comments [docId]="model" [cls]="'prescription'"></app-comments>
  </div>


