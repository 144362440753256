import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@ft/core';
import { TicketContainerComponent } from './components/ticket-container/ticket-container.component';
import { ContentComponent } from './components/content/content.component';
import { TicketItemComponent } from './components/ticket-item/ticket-item.component';
import { SatisfactionFormComponent } from './components/satisfaction-form/satisfaction-form.component';
import { CustomBusyComponent } from '../custom-busy/custom-busy.component';

const routes: Routes = [
  {
    path: '',
    component: TicketContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', component: ContentComponent },
      { path: 'item/:id', component: TicketItemComponent },
      { path: 'custom-busy', component: CustomBusyComponent },
      {
        path: 'satisfaction-form',
        component: SatisfactionFormComponent,
      },
      {
        path: 'satisfaction-form/:id',
        component: SatisfactionFormComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TicketRoutingModule {}
