import {Component} from '@angular/core';
import {BaseLoginComponent} from '@ft/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '@ft/core';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigsService} from '@ft/core';

@Component({
    selector: 'pr-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseLoginComponent {

    constructor(router: Router, snackBar: MatSnackBar,
                authService: AuthService, translate: TranslateService,
                activeRoute: ActivatedRoute, appConfig: AppConfigsService) {
        super(router, snackBar, authService, translate, activeRoute, appConfig);
    }

}
