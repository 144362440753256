<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-account-plus"></mat-icon>
    </button>
  </div>
  <h2>{{ 'settings.user_account' | translate }}</h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm">
  <div [ngBusy]="subscription"></div>

  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <h1> {{'patient.add_user_account'|translate}}</h1> <br/>
    <div fxLayout="column" fxLayoutGap="6px" class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">
      <ng-template [ftAcl]="{resource: 'user', action: 'create'}">
        <mat-form-field fxFlex class="md:col-span-12 col-span-12">
          <mat-label>{{'settings.user' | translate}}</mat-label>
          <mat-select [(ngModel)]="model.staff" name="user" [compareWith]="compareCondition">
            <mat-option *ngFor="let s of staffs" [value]="s">
              {{s.first_name}} {{s.last_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>

    </div>
  </div>

  <div mat-dialog-actions>
    <span fxFlex></span>
    <button type="button" mat-flat-button color="primary" (click)="handleSave()" [disabled]="form.invalid">
      <span>{{'shared.save'|translate}}</span>
    </button>
    <button type="button" mat-flat-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
      <span>{{'shared.reset'|translate}}</span>
    </button>
  </div>
</form>
