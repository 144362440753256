<div fxFlex fxLayout="column" (window:resize)="onResize()">
    <mat-toolbar>
        <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
        </div>
        <ng-container *ngIf="!isMobile">
            <h2>{{"shared.prescription"|translate}}</h2>
            <div fxFlex fxLayoutAlign="center">
                <h2> {{getFullName()}} | {{model?.date | ftDate}} - {{templateName}}</h2>
            </div>
            <button *ngIf="model.id ||prescription || prescriptions?.length==0" type="button" mat-button
                    class="with-icon" [matMenuTriggerFor]="templateMenu">
                <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
                <span>{{'shared.new'|translate}}</span>
            </button>
        </ng-container>
        <ng-container *ngIf="isMobile">
            <div fxFlex class="text-truncate">
                <span> {{getFullName()}} | {{model?.date | ftDate}} - {{templateName}}</span>
            </div>
            <mat-icon [matMenuTriggerFor]="templateMenu" fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
            <ng-container *ngIf="prescription && prescription['id']">
                <ng-container *ngTemplateOutlet="mobileItemActions;context:{model: model}"></ng-container>
            </ng-container>
        </ng-container>
        <mat-menu #templateMenu="matMenu" class="ft-small-menu">
            <ng-container *ngFor="let link of navLinks">
                <button mat-menu-item [disabled]="link.isDisabled" (click)="handleLink(link)">
                    <mat-icon fontSet="mdi" fontIcon="mdi-file-document" color="accent"></mat-icon>
                    <span>{{link.label | translate}}</span>
                </button>
            </ng-container>
        </mat-menu>
    </mat-toolbar>

    <div class="ft-content no-padding" fxFlex>
        <mat-drawer-container fxFlex class='ft-content-container'>
            <mat-drawer *ngIf="!isMobile" mode='side' opened='true'>
                <div fxLayout="column" fxLayoutGap="6px" fxFlex class="sub-drawer">
                    <div class="ft-content mat-elevation-z1" fxFlex>
                        <mat-toolbar color="primary" class="sub-toolbar">
                            <div class="ft-module-icon">
                                <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
                            </div>
                            <h2 translate="shared.history"></h2>
                        </mat-toolbar>
                        <mat-action-list role="list" class="ft-content" [@heightAnimation]>
                            <div *ngIf="!prescriptions || prescriptions.length == 0" class="ft-empty-content">
                                <mat-icon fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
                                <span translate="shared.no_element_to_show"></span>
                            </div>
                            <mat-list-item role="listitem" *ngFor="let p of prescriptions">
                                <button type="button" mat-line (click)="showPrescription(p)"
                                        [disabled]="p == prescription">
                                    <mat-icon fxFlex="noshrink" fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
                                    <span fxFlex>{{p?.date | ftDate}} | {{p?.content?.name}}</span>
                                    <mat-icon fxFlex="noshrink"
                                              class="ft-success"
                                              *ngIf="prescription?.id==p.id && editedItem==prescription?.id"
                                              fontSet="mdi"
                                              fontIcon="mdi-pencil-box-outline"></mat-icon>
                                    <mat-icon fxFlex="noshrink" class="ft-accent-color"
                                              *ngIf="prescription?.id==p.id && editedItem!=prescription?.id"
                                              fontSet="mdi"
                                              fontIcon="mdi-eye"></mat-icon>
                                </button>
                                <mat-icon fontSet="mdi" [matMenuTriggerFor]="menu"
                                          fontIcon="mdi-dots-vertical"></mat-icon>
                                <mat-menu #menu="matMenu" class="ft-small-menu">
                                    <button mat-menu-item (click)="enableEdit(p)" *ngIf="editedItem!=p?.id">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline"
                                                  color="accent"></mat-icon>
                                        <span>{{'shared.edit'| translate}}</span>
                                    </button>
                                    <button mat-menu-item (click)="closePrescription(p)"
                                            [disabled]="p?.status==='closed'">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-progress-check" color="accent"></mat-icon>
                                        <span>{{'shared.closed'| translate}}</span>
                                    </button>
                                    <ng-template [ftAcl]="{resource: 'sms-message', action: 'create'}">
                                        <button mat-menu-item (click)="sendSMS(p)">
                                            <mat-icon fontSet="mdi" fontIcon="mdi-phone-message"
                                                      color="accent"></mat-icon>
                                            <span>{{'shared.sms'| translate}}</span>
                                        </button>
                                    </ng-template>
                                    <button mat-menu-item
                                            [ftPrint]="{group:p.content.name, view:'/api/pl/prescription',  model:p['id'] }"
                                            (prePrint)="printPrescription()"
                                    >
                                        <mat-icon fontSet="mdi" fontIcon="mdi-printer" color="accent"></mat-icon>
                                        <span>{{'shared.print'| translate}}</span>
                                    </button>
                                    <button mat-menu-item (click)="removePrescription(p)">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-delete" color="warn"></mat-icon>
                                        <span>{{'shared.delete'| translate}}</span>
                                    </button>
                                </mat-menu>
                            </mat-list-item>
                        </mat-action-list>
                    </div>
                </div>
            </mat-drawer>
            <mat-drawer-content fxLayout='column'>
                <div class="ft-parent-layout mat-elevation-z1" [class.ft-content-margin]="true" fxFlex
                     fxLayout="column">
                    <div fxLayout="column" fxFlex="" class="ft-busy-container" [ngBusy]="subscription">
                        <div fxFlex fxLayout="column" class="ft-content ft-padding">
                            <div class="bloc-container" fxLayoutGap="12px" [fxLayout]="isMobile ? 'column': 'row'">
                                <mat-form-field fxFlex="nogrow" [ftAutoFocus]="true">
                                    <input matInput ftDate [matDatepicker]="b_date"
                                           [placeholder]="'shared.date'|translate" required
                                           [(ngModel)]="model.date" name="b_date">
                                    <mat-datepicker-toggle matSuffix [for]="b_date">
                                        <mat-icon matDatepickerToggleIcon fontSet="mdi"
                                                  fontIcon="mdi-calendar-today"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #b_date></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field fxFlex="nogrow" [ftAutoFocus]="true">
                                    <input matInput ftDate [matDatepicker]="s_date"
                                           [placeholder]="'shared.start_date'|translate"
                                           [(ngModel)]="model.start_date" name="s_date">
                                    <mat-datepicker-toggle matSuffix [for]="s_date">
                                        <mat-icon matDatepickerToggleIcon fontSet="mdi"
                                                  fontIcon="mdi-calendar-today"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #s_date></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field fxFlex="nogrow" [ftAutoFocus]="true">
                                    <input matInput ftDate [matDatepicker]="e_date"
                                           [placeholder]="'prescription.end_date'|translate"
                                           [(ngModel)]="model.end_date" name="e_date">
                                    <mat-datepicker-toggle matSuffix [for]="e_date">
                                        <mat-icon matDatepickerToggleIcon fontSet="mdi"
                                                  fontIcon="mdi-calendar-today"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #e_date></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field *ngIf="getLastRenewDetails()" fxFlex="nogrow" [ftAutoFocus]="true">
                                    <input matInput ftDate [matDatepicker]="s_date"
                                           [placeholder]="'prescription.renew_start_date'|translate"
                                           [value]="getLastRenewDetails().start_date" name="s_date">
                                    <mat-datepicker-toggle matSuffix [for]="s_date">
                                        <mat-icon matDatepickerToggleIcon fontSet="mdi"
                                                  fontIcon="mdi-calendar-today"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #s_date></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field *ngIf="getLastRenewDetails()" fxFlex="nogrow" [ftAutoFocus]="true">
                                    <input matInput ftDate [matDatepicker]="e_date"
                                           [placeholder]="'prescription.renew_end_date'|translate"
                                           [value]="getLastRenewDetails().end_date" name="e_date">
                                    <mat-datepicker-toggle matSuffix [for]="e_date">
                                        <mat-icon matDatepickerToggleIcon fontSet="mdi"
                                                  fontIcon="mdi-calendar-today"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #e_date></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="bloc-container" fxLayoutGap="12px">
                                <mat-form-field *ngIf="!isDoctor" fxFlex>
                                    <input readonly matInput [placeholder]="'settings.physician'|translate"
                                           name="physician"
                                           [(ngModel)]="physician"/>
                                    <button matSuffix mat-icon-button type="button" color="primary"
                                            [matTooltip]="'shared.search' | translate "
                                            (click)="$event.stopImmediatePropagation(); searchPhysician();">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
                                    </button>
                                    <button matSuffix mat-icon-button type="button" color="primary"
                                            [matTooltip]="'shared.add' | translate "
                                            (click)="$event.stopImmediatePropagation(); newPhysician();">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                                    </button>
                                    <button *ngIf="patient.physician" matSuffix mat-icon-button type="button"
                                            color="warn"
                                            [matTooltip]="'shared.clean' | translate "
                                            (click)="$event.stopImmediatePropagation(); clearPhysician();">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>{{'prescription.priority' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="model.priority" name="priority"
                                                [compareWith]="compareCondition">
                                        <mat-option *ngFor="let p of ['LOW', 'NORMAL', 'HIGH', 'IMMEDIATE']"
                                                    [value]="p">
                                            {{p|translate}}
                                        </mat-option>
                                    </mat-select>
                                    <button *ngIf="model.priority" matSuffix mat-icon-button type="button" color="warn"
                                            [matTooltip]="'prescription.priority' | translate "
                                            (click)="$event.stopImmediatePropagation(); model.priority=null;">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <mat-slide-toggle [(ngModel)]="model.refundable" name="insured">
                                <span fxFlex>{{'prescription.refundable' | translate}}</span>
                            </mat-slide-toggle>
                            <div class="bloc-container" fxLayoutGap="12px">
                                <mat-form-field *ngIf="model?.refundable" fxFlex="50">
                                    <mat-label>{{'patient.insurance' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="model.insurance" name="insurance"
                                                [compareWith]="compareCondition">
                                        <mat-option *ngFor="let t of insurances" [value]="t">
                                            {{t.name}}
                                        </mat-option>
                                    </mat-select>
                                    <button *ngIf="model.insurance" matSuffix mat-icon-button type="button" color="warn"
                                            [matTooltip]="'patient.insurance' | translate "
                                            (click)="$event.stopImmediatePropagation(); model.insurance=null;">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                                    </button>
                                </mat-form-field>

                            </div>
                            <div class="bloc-container" fxLayoutGap="12px">
                                <mat-form-field fxFlex="50">
                                <textarea matInput [(ngModel)]="model.intervention_address"
                                          name="intervention_address"
                                          [placeholder]="'patient.intervention_address'|translate"></textarea>
                                </mat-form-field>
                                <!--                                TODO complete prescription upload-->
                                <!--   <button mat-button ngfSelect multiple="1" [(files)]="uploadedFiles"
                                           (filesChange)="uploadFiles($event)"
                                           class="icon-only" *ngIf="model?.id">
                                       <mat-icon fontSet="mdi" fontIcon="mdi-cloud-upload"></mat-icon>
                                   </button>-->
                            </div>
                            <div class="bloc-container" fxLayoutGap="12px">
                                <mat-form-field fxFlex>
                                    <input matInput [(ngModel)]="model.comment"
                                           [placeholder]="'shared.note'|translate"/>
                                </mat-form-field>
                            </div>
                            <div class="bloc-container" fxFlex>
                                <div class="prescription-forms-bloc">
                                    <span>{{'prescription.exam_forms'| translate}}</span>
                                    <span fxFlex=""></span>
                                    <button *ngIf="currentExamForm?.id" type="button" mat-button class="icon-only"
                                            (click)="saveExamForm()">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-content-save-check-outline"></mat-icon>
                                    </button>
                                    <button type="button" mat-button class="icon-only"
                                            [matMenuTriggerFor]="examFormsMenu">
                                        <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
                                    </button>
                                    <mat-menu #examFormsMenu="matMenu" class="ft-small-menu">
                                        <ng-container *ngFor="let eF of examFormTemplates">
                                            <button mat-menu-item (click)="handleExamForm(eF)">
                                                <mat-icon fontSet="mdi" fontIcon="mdi-file-document"
                                                          color="accent"></mat-icon>
                                                <span>{{eF.name | translate}}</span>
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                </div>
                                <div fxLayout="column" class="ft-content" style="min-height: 500px"><!-- remove style-->
                                    <mat-action-list role="list" class="ft-content" [@heightAnimation]>

                                        <mat-list-item role="listitem" *ngFor="let f of examForms">
                                            <button type="button" mat-line (click)="currentExamForm=f">
                                                <mat-icon fxFlex="noshrink" fontSet="mdi"
                                                          fontIcon="mdi-file-document"></mat-icon>
                                                <span fxFlex>  {{f?.template?.name}}</span>
                                                <span>{{f.created_at| ftDate}}</span>
                                            </button>
                                            <button type="button" mat-button
                                                    class="icon-only" (click)="deleteExamForm(f.id)">
                                                <mat-icon fontSet="mdi"
                                                          fontIcon="mdi-delete"></mat-icon>
                                            </button>
                                        </mat-list-item>

                                    </mat-action-list>

                                    <hr class="ft-sep">
                                    <ft-form fxFlex="" [(template)]="currentExamForm.template"></ft-form>
                                </div>
                            </div>
                            <div class="prescription-template" style="min-height: 500px"><!-- todo remove style -->
                                <!--                                <ft-form fxFlex="" [(template)]="prescriptionTemplate"></ft-form>-->
                                <pr-prescription-template fxFlex="" [(template)]="prescriptionTemplate"
                                                          (fieldFileChange)="uploadFieldFiles($event)"
                                                          (fieldFileDelete)="handleFieldFileDelete($event)"
                                                          (fieldFilePreview)="previewFieldFile($event)"
                                ></pr-prescription-template>

                            </div>
                            <div class="file-manager-container" fxLayout="column">
                                <ft-file-manager [actions]="actions" fxFlex *ngIf="model?.id" [root]="'prescription'"
                                                 [context]="fileManagerContext"
                                                 [contextKeys]="[ 'fileType']"
                                                 [rootId]="model.id"></ft-file-manager>
                            </div>
                            <div class="bloc-container" fxFlex>
                                <h4 class="bloc-title">{{'shared.comments'|translate}}</h4>
                                <app-comments [docId]="model?.id" [cls]="'prescription'"></app-comments>
                            </div>
                        </div>
                        <div class="ft-actions" *ngIf="editedItem==model?.id || !model.id  ">
                            <span fxFlex></span>
                            <ng-template [ftAcl]="{resource: 'prescription', action: 'cancel'}">
                                <button
                                        *ngIf="model?.id && !model?.cancelled" type="button" color="warn"
                                        mat-raised-button
                                        (click)="handleCancellation()">
                                    {{'prescription.cancellation'|translate}}
                                </button>
                            </ng-template>
                            <ng-template [ftAcl]="{resource: 'prescription', action: 'reactivate'}">
                                <button
                                        *ngIf="model?.id &&   model?.status==='closed'" type="button" color="primary"
                                        mat-raised-button
                                        (click)="handleReactivate()">
                                    {{'prescription.reactivate'|translate}}
                                </button>
                            </ng-template>
                            <ng-template [ftAcl]="{resource: 'prescription', action: 'renew'}">
                                <button *ngIf="model?.id && !model?.cancellation_details" type="button"
                                        color="primary"
                                        mat-raised-button
                                        (click)="handleRenew()">
                                    {{'prescription.renew'|translate}}
                                </button>
                            </ng-template>
                            <ng-template [ftAcl]="{resource: 'prescription', action: 'suspend'}">
                                <button *ngIf="model?.id && !model?.suspend_details" type="button"
                                        color="primary"
                                        mat-raised-button
                                        (click)="handleSuspend()">
                                    {{'prescription.suspend'|translate}}
                                </button>
                            </ng-template>

                            <ng-template [ftAcl]="{resource: 'prescription', action: 'force_conform'}">
                                <button *ngIf="model?.id && !isContentConform() &&  !model?.force_conform_details"
                                        type="button"
                                        color="primary"
                                        mat-raised-button
                                        (click)="handleForceConform()">
                                    {{'prescription.force_conform'|translate}}
                                </button>
                            </ng-template>
                            <ng-template [ftAcl]="{resource: 'prescription', action: 'force_close'}">
                                <button *ngIf="model?.id && !isCloseAllowed &&  !model?.force_close_details"
                                        type="button"
                                        color="primary"
                                        mat-raised-button
                                        (click)="handleForceClose()">
                                    {{'prescription.force_close'|translate}}
                                </button>
                            </ng-template>
                            <button type="button" mat-raised-button color="warn" (click)="exit()">
                                <span>{{'shared.reset'|translate}}</span>
                            </button>
                            <button type="button" color="primary" mat-raised-button tabindex="2"
                                    (click)="handleSave($event)"
                                    [disabled]="!model?.date">
                                {{'shared.save'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>

    <ng-template let-link="link" #mobileItemActions>
        <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical" [matMenuTriggerFor]="mobileActionMenu"></mat-icon>
        <mat-menu #mobileActionMenu="matMenu" class="ft-small-menu">
            <button mat-menu-item (click)="enableEdit(prescription)" *ngIf="editedItem!=prescription?.id">
                <mat-icon fontSet="mdi" fontIcon="mdi-pencil-box-outline" color="accent"></mat-icon>
                <span>{{'shared.edit'| translate}}</span>
            </button>
            <button mat-menu-item [ftAcl]="{resource: 'prescription', action: 'close'}"
                    (click)="closePrescription(prescription)"
                    [disabled]="prescription?.status==='closed'">
                <mat-icon fontSet="mdi" fontIcon="mdi-progress-check" color="accent"></mat-icon>
                <span>{{'shared.closed'| translate}}</span>
            </button>
            <ng-template [ftAcl]="{resource: 'sms-message', action: 'create'}">
                <button mat-menu-item (click)="sendSMS(prescription)">
                    <mat-icon fontSet="mdi" fontIcon="mdi-phone-message" color="accent"></mat-icon>
                    <span>{{'shared.sms'| translate}}</span>
                </button>
            </ng-template>
            <button mat-menu-item
                    [ftPrint]="{group:prescription.content.name, view:'/api/pl/prescription',  model:prescription['id'] }"
                    (prePrint)="printPrescription()"
            >
                <mat-icon fontSet="mdi" fontIcon="mdi-printer" color="accent"></mat-icon>
                <span>{{'shared.print'| translate}}</span>
            </button>
            <button mat-menu-item (click)="removePrescription(prescription)">
                <mat-icon fontSet="mdi" fontIcon="mdi-delete" color="warn"></mat-icon>
                <span>{{'shared.delete'| translate}}</span>
            </button>
        </mat-menu>
    </ng-template>
</div>
