import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@ft/core';
import { PatientAppointmentContainerComponent } from './components/patient-appointment-container/patient-appointment-container.component';
import { PatientAppointmentListComponent } from './components/patient-appointment-list/patient-appointment-list.component';
import { PatientAppointmentItemComponent } from './components/patient-appointment-item/patient-appointment-item.component';

const routes: Routes = [
  {
    path: '',
    component: PatientAppointmentContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', component: PatientAppointmentListComponent },
      { path: 'item/:id', component: PatientAppointmentItemComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientAppointmentRoutingModule {}
