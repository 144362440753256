import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PatientPrescriptionRoutingModule } from './patient-prescription-routing.module';
import { MaterialModule } from '@ft/core';
import { FormsModule } from '@angular/forms';
import { PatientPrescriptionContainerComponent } from './components/patient-prescription-container/patient-prescription-container.component';
import { PatientPrescriptionListComponent } from './components/patient-prescription-list/patient-prescription-list.component';
import { PatientPrescriptionItemComponent } from './components/patient-prescription-item/patient-prescription-item.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgBusyModule} from "ng-busy";
import {FileManagerModule} from "@ft/file-manager";

@NgModule({
  declarations: [
    PatientPrescriptionContainerComponent,
    PatientPrescriptionListComponent,
    PatientPrescriptionItemComponent,
  ],
    imports: [
        FormsModule,
        MaterialModule,
        SharedModule,
        PatientPrescriptionRoutingModule,
        InfiniteScrollModule,
      NgBusyModule,
              FileManagerModule

    ],
})
export class PatientPrescriptionModule {}
