import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ReminderComponent } from '../../../../shared/dialogs/reminder/reminder.component';
import { TelephoneFollowUpItemType } from '../../../types/telephone-follow-up.type';
import { Subscription } from 'rxjs';
import { assign, cloneDeep, omit, unset } from 'lodash';
import { ReminderType } from '../../../types/action-notifier.type';
import { ConfirmDialogComponent } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pr-tfu-item',
  templateUrl: './tfu-item.component.html',
  styleUrls: ['./tfu-item.component.scss'],
})
export class TfuItemComponent implements OnInit, OnDestroy {
  public isModel = false;
  public reactiveForm!: FormGroup;
  public tfuItem: TelephoneFollowUpItemType = null;
  public reminder: ReminderType = null;
  private _tempSub = new Subscription();
  private _subs = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TfuItemComponent>,
    private _fb: FormBuilder,
    private _dialog: MatDialog,
    private _translateService: TranslateService
  ) {
    
    
    
    this.reactiveForm = this._fb.group({
      // oid: [''],
      title: ['', Validators.required],
      comment: [''],
    });
  }

  ngOnDestroy(): void {
    this._tempSub?.unsubscribe();
    this._subs?.unsubscribe();
  }

  ngOnInit(): void {
    
    
    
    
    this.isModel = this.data.is_model;
    if(this.data.tfu_item) {
      this.tfuItem = cloneDeep(this.data.tfu_item);
      // this.reactiveForm = new FormGroup({
      //   title: new FormControl(),
      // });
      
      
      // this.reactiveForm.get('oid')?.setValue(this.tfuItem?.oid ?? '');
      this.reactiveForm.get('title')?.setValue(this.tfuItem?.title ?? '');
      this.reactiveForm
        .get('comment')
        ?.setValue(this.tfuItem?.comment ?? '');
      this.reminder = cloneDeep(this.tfuItem?.reminder);
      
      
      
      
    }

  }

  public onSubmit() {}

  public isFormControlsValid() {
    return this.reactiveForm.valid;
  }

  public saveTfuItem(event) {
    if (this.reactiveForm.valid) {
      if(this.reminder?.oid === 'temp') {
        // this.reminder.oid = null;
        delete this.reminder.oid;
        // unset(this.reminder, "oid");
      }
      let getTfuItemOldId = () => this.tfuItem?.oid ? { oid: this.tfuItem?.oid } : {};

      this.tfuItem = assign({}, this.reactiveForm.value, {
        reminder: cloneDeep(this.reminder),
      }, getTfuItemOldId());
      
      
      
      this.dialogRef.close(this.tfuItem);
    } else {
      event.preventDefault();
      this.reactiveForm.markAllAsTouched();
      // alert(this._translateService.instant('settings.at_least_one_reminder_is_required'));
    }
  }

  public addReminder() {
    this.addReminderByDialog('');
  }

  public editReminder(reminderOid: string) {
    this.addReminderByDialog(reminderOid);
  }

  public addReminderByDialog(reminderOid: string) {
    
    
    
    
    
    
    
    let dialogRef: any;
    if (reminderOid) {
      dialogRef = this._dialog.open(ReminderComponent, {
        panelClass: 'big-dialog',
        data: {
          reminder: cloneDeep(this.reminder),
        },
      });
    } else {
      dialogRef = this._dialog.open(ReminderComponent, {
        panelClass: 'big-dialog',
        data: { reminder: null },
      });
    }

    this._tempSub?.unsubscribe();
    this._tempSub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        data.oid = data.oid || 'temp';
        this.reminder = data;
        
        
        
        
        // this.reactiveForm.patchValue({
        //   reminder: this.data.reminder?.notification_config,
        // });
      }
    });
  }
  public removeReminder(reminderOid: string) {
    this._subs.add(
      this._dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'settings.delete_reminder_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) {
              this.reminder = null
            }
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }
}
