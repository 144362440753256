<app-pr-basic-generic-view-table fxFlex
                                 [remote]="true"
                                 [source]="source$"
                                 [hasSearch]="hasSearch"
                                 [selectable]="selectable"
                                 [hasPagination]="hasPagination"
                                 [lineClass]="lineClass"
                                 [columns]="columns | async"
                                 (lineClick)="handleClick($event)"
                                 [multiActions]="tableActions"
                                 (searchChange)="handleSearchChange($event)"
                                 [sheetToggle]="toggle$"
                                 [pageSize]="pageSize"
                                 [pageSizeOptions]="pageSizeOptions"
                                 [paginateHandler]="paginateHandler"
                                 [(selected)]="selected"
                                 [exportExcel]="exportExcel"
                                 (exportExcelHandler)="handleExcelExport($event)"
                                 [hasFooter]="hasFooter"
                                 [styleRules]="styleRules"
                                 (queryChange)="handleQueryChange($event)"
                                 [customFilters]="customFilters"

>
</app-pr-basic-generic-view-table>
