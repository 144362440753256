import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

import {assign, chain, cloneDeep, groupBy, isEmpty, keys, map, size, zipObject} from 'lodash';
import {SharedService} from '../../services/shared.service';
import {Subscription} from 'rxjs';
import {AppConfigsService} from '@ft/core';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

    content = null;
    groupedComments = [];
    comments: any = [];
    dateFormat = null;
    timeFormat = null;
    promise: Subscription;
    has_comment = false;
    cls: string = null;
    docId: string = null;


    @Input('cls') set handleCls(value) {
        this.cls = value;
    }

    @Input('docId') set handleDocId(value) {
        if (value) {
            this.docId = value;
            this.loadComments();
        }
    }

    constructor(public translateService: TranslateService, public app_configService: AppConfigsService, public sharedService: SharedService) {
        this.dateFormat = app_configService.dateFormat;
        this.timeFormat = app_configService.timeFormat;
    }

    ngOnInit(): void {
        if (this.docId) {
            this.loadComments();
        }
    }

    onSubmit() {
    }

    newCommentClick(ev) {
        ev.stopPropagation();
        if (!isEmpty(this.content)) {
            let c = assign({}, {
                title: `${this.translateService.instant('comment_note')} ${moment().format(this.dateFormat)} ${moment().format(this.timeFormat)}`,
                comment_date: moment(),
                comment_time: moment().format(this.timeFormat),
                doc_model: this.cls,
                doc_id: this.docId,
                content: cloneDeep(this.content),
            });
            this.sharedService.saveDocumentComment(c).subscribe(data => {
                this.loadComments();
            });
            this.content = null;
        }
    }

    loadComments() {
        if (this.docId) {
            this.promise = this.sharedService.getDocumentComments(this.cls, this.docId).subscribe(data => {
                this.comments = data;
                this.has_comment = isEmpty(data);
                this.handleComments();
            });
        }

    }

    handleComments() {
        let grouped = groupBy(this.comments, 'comment_date');
        this.groupedComments = map(keys(grouped), (k) => {
            return zipObject(["comment_date", "details"], [k, this.groupComments(grouped[k])])
        });
        setTimeout(() => {
            if (!isEmpty(this.groupedComments)) {
                let scroll_idx = `anc_${size(this.groupedComments) - 1}_${(chain(this.groupedComments).last() as any).get('details').size().value() - 1}_${(chain(this.groupedComments).last() as any).get('details').last().get('details').size().value() - 1}`;
                let elem = document.getElementById(scroll_idx);
                if (elem) {
                    elem.scrollIntoView(true);
                }
            }

        }, 200);

    }

    groupComments(c) {
        let grouped = groupBy(c, (e) => {
            return moment(e['comment_time'], this.timeFormat).format(this.app_configService.timeFormat);
        });
        return map(keys(grouped), (k) => {
            return zipObject(["comment_time", "details"], [k, grouped[k]]);
        });
    }

    formatDate(d) {
        return moment(d).format("ddd DD MMMYYYY") || d;
    }


    removeComment(s) {
        this.promise = this.sharedService.deleteDocumentComment(s.id).subscribe(data => {
            this.loadComments();
        });
    }
}
