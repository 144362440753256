export let patientAppointmentFr: Record<string, any> = {
    appointment_list: 'Liste des rendez-vous',
    appointment: 'Rendez-vous',
    action: {
        confirm: 'Confirmer',
        cancel: 'Annuler',
        reschedule: 'Replanifier',
    },
    confirm_appointment_question: 'Voulez-vous confirmer ce rendez-vous?',
    request_rescheduling : 'Demander une replanification',
    request_cancellation : 'Demander une annulation',
    reason : 'Motif',
    address : 'Adresse',
    comments : 'Commentaires',
};
