import {Component, EventEmitter, HostBinding, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {PatientService} from '../../services/patient.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {concat, find, get, head, noop, chain} from 'lodash';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SettingsService} from '../../../settings/services/settings.service';
import {PATIENTS_VIEW} from '../../utils/views-settings';
import {ActionItem, DefaultValuesService} from '@ft/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
    @HostBinding('class.host-class-mobile') public isMobile = false;
    public breakpoints = Breakpoints;
    subscription: Subscription;
    dataSource: Observable<any[]> = of([]);
    prescriptionStatusColors = this.defaultValuesService.getValue(
        'prescriptionStateColors'
    );
    multiActions: ActionItem[] = [{
        class: 'mat-accent',
        icon: 'mdi-plus',
        method: (item, ev) => {
            return this.handleNew();
        },
        tooltip: this.translateService.instant('patient.new'),
        acl: {resource: 'patient', action: 'create', behavior: 'remove'},
    }];
    singleActions = {
        label: '',
        key: 'actions',
        type: 'actions',
        actions: [
            {
                class: 'mat-accent',
                icon: 'mdi-eye-outline',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.handlePatient(item);
                },
                tooltip: this.translateService.instant('patient.patient_file'),
                acl: {resource: 'patient', action: 'update', behavior: 'remove'},
            },
            {
                class: 'mat-accent',
                icon: 'mdi-file-document',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.handlePrescription(item);
                },
                tooltip: this.translateService.instant('patient.new_prescription'),
                acl: {resource: 'prescription', action: 'get', behavior: 'remove'},
            },
            // {
            //     class: 'mat-warn',
            //     icon: 'mdi-account-remove',
            //     isMultipleSelection: false,
            //     method: (item, index, ev) => {
            //         return this.removePatient(get(item, 'id'));
            //     },
            //     tooltip: this.translateService.instant('shared.delete'),
            //     acl: {resource: 'patient', action: 'delete', behavior: 'remove'},
            // },
        ],
    };
    physicianColumn = [
        {
            label: 'settings.physician',
            key: 'physician.last_name',
            type: 'text',
            is_global_searchable: true,
            callBack: (item) => {
                return `${get(item, 'physician.last_name', '')} ${get(item, 'physician.first_name', '')}`;
            },
            is_ref: true,
            model: 'staff.Physician'
        }
    ];
    prescriptionsCountColumn = [
        {
            label: 'patient.prescriptions_count',
            key: 'prescriptions_count',
            type: 'badge',
            is_global_searchable: false,
            callBack: (item) => {
                return get(item, 'prescriptions_count') > 9 ? '' + get(item,
                    'prescriptions_count') : '0' + get(item, 'prescriptions_count');
            },
            style: (item) => this.handleStatusColor(item),
            tooltip: (item) => this.handleStatusToolTip(item)
        }
    ];
    reloadEvent = new EventEmitter();
    // isMobile = false;
    viewSettings = PATIENTS_VIEW;
    customFilters = [
        {
            label: 'patient.with_prescription', query: [
                {
                    value: 0,
                    operator: '$gt',
                    logical_operator: 'and',
                    key: 'prescriptions_count',
                    type: 'number'
                },
                {
                    value: true,
                    operator: '$exists',
                    logical_operator: 'and',
                    key: 'prescriptions_count',
                    type: 'number'
                }
            ]
        },
        {
            label: 'patient.without_prescription', query: [
                {
                    value: 0,
                    operator: '$eq',
                    logical_operator: 'or',
                    key: 'prescriptions_count',
                    type: 'number'
                },
                {
                    value: false,
                    operator: '$exists',
                    logical_operator: 'and',
                    key: 'prescriptions_count',
                    type: 'number'
                }
            ]
        }, {
            label: 'patient.dead', query: [
                {
                    value: true,
                    operator: '$exists',
                    logical_operator: 'and',
                    key: 'death_reason',
                    type: 'text'
                }
            ]
        }

    ];

    constructor(
        protected route: ActivatedRoute,
        public breakpointObserver: BreakpointObserver,
        public patientService: PatientService,
        public translateService: TranslateService,
        private dialog: MatDialog,
        private router: Router,
        private settingsService: SettingsService,
        protected snackBar: MatSnackBar,
        public defaultValuesService: DefaultValuesService
    ) {
        this.viewSettings.viewSettings.viewColumns = concat(
            this.viewSettings.viewSettings.viewColumns,
            (!this.settingsService.isDoctor() ? this.physicianColumn : [], this.prescriptionsCountColumn as any),
            this.singleActions
        );
        this.viewSettings.actions = this.multiActions;


    }

    ngOnInit(): void {
        this.breakpointObserver
            .observe(['(max-width: 640px)'])
            .subscribe((result) => {
                if (result.breakpoints['(max-width: 640px)']) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
    }

    handlePatient(model) {
        this.router.navigate([`patient/form/${model.id}`]).then(noop);
    }

    handleNew() {
        this.router.navigate([`patient/form-edit/`]).then(noop);
    }


    handlePrescription(obj) {
        this.router
            .navigate(['prescription/content/'], {
                queryParams: {patient: obj.id},
            })
            .then(noop);
    }


    handleStatusColor(item) {
        return {
            'background-color': (chain(this.prescriptionStatusColors).find({
                name: get(item, 'last_prescription_status')
            }) as any).get('color', 'rgb(255, 204, 153)').value()
        };
    }
    handleStatusToolTip(item) {
        return get(item, 'last_prescription_status') ? this.translateService.instant('shared.' +
            get(item, 'last_prescription_status')) : '';
    }
}
