<!-- TODO add drawer for the map in mobile mode -->
<mat-drawer-container class="appointment-item-outer-container">
  <mat-drawer class="map-outer-container" #drawer mode="over">
    <div class="map-inner-container">
      <mat-toolbar class="map-header">
        <div class="map-title">
          {{ "appointment.map" | translate }}
        </div>
        <div class="map-close-container">
          <button
            mat-icon-button
            class="icon-only map-close"
            (click)="drawer.toggle()"
          >
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <!-- <div class="appointment-item-map" *ngIf="isMobile">map</div> -->
      <pr-map class="map-content" *ngIf="isMobile"></pr-map>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <!-- Main content -->
    <div *ngIf="appointment.id" class="appointment-item-container md:flex-row">
      <div class="appointment-item-details">
        <div class="appointment-item-info-container flex flex-col">
          <div class="aii-desktop flex flex-row p-4 m-1 gap-1">
            <div class="aii flex-grow flex items-center">
              <p>
                <strong *ngIf="appointment.external_id"
                ><u>#{{ appointment.external_id }}</u> &nbsp;&nbsp;</strong
                >
                <strong>{{
                  appointment.ticket_content_histories.at(-1).reason | titlecase
                  }}</strong>
                <span
                  class="tag ml-2"
                  [ngStyle]="{
                    'background-color': ticketStatusColor().bgColor,
                    color: ticketStatusColor().txtColor
                  }"
                >
                  {{ "settings." + ticketStatusColor().name | translate }}
                </span>

                <br/>
                {{ formatScheduleDate(appointment.ticket_schedule_histories.at(-1).execution_date) }}
                &#8226;
                {{
                appointment.ticket_schedule_histories.at(-1).start_time
                  | ftTime : "hh:mm"
                }}
                -
                {{
                appointment.ticket_schedule_histories.at(-1).end_time
                  | ftTime : "hh:mm"
                }}
                <br/>
                <br/>
                <span class="font-semibold">{{ "shared.patient" | translate }}:</span>
                {{ appointment?.prescription?.patient?.full_name | titlecase }}
                <br/>
                <span class="font-semibold">{{ "settings.physician" | translate }}:</span>
                {{ appointment?.prescription?.physician?.full_name | titlecase }}
                <br/>
                <span class="font-semibold">{{ "shared.address" | translate }}:</span>
                {{
                appointment?.prescription?.intervention_address
                  ? (appointment?.prescription?.intervention_address
                    | titlecase)
                  : "###########"
                }}
                <br/>

                <span class="prescription-comment"> <span class="font-semibold">{{ "shared.note" | translate }}:</span>{{
                appointment?.prescription?.comment
                  ? (appointment?.prescription?.comment
                    | titlecase)
                  : "###########"
                  }}</span>
              </p>
            </div>
            <div class="aii-actions-container">
              <div class="aii-actions">
                <ng-template
                  [ftAcl]="{ resource: 'ticket', action: 'uncancel' }"
                >
                  <button
                    mat-icon-button
                    (click)="uncancelAppointment(appointment.id)"
                    *ngIf="
                      appointment.status_histories?.at(-1)?.status ===
                      'CANCELLED'
                    "
                    [matTooltip]="'appointment.action.undo' | translate"
                  >
                    <mat-icon
                      fontSet="mdi"
                      fontIcon="mdi-undo-variant"
                    ></mat-icon>
                  </button>
                </ng-template>
                <ng-template
                  [ftAcl]="{ resource: 'ticket', action: 'undo_completion' }"
                >
                  <button
                    mat-icon-button
                    (click)="undoCompletionAppointment(appointment.id)"
                    *ngIf="
                      appointment.status_histories?.at(-1)?.status ===
                      'COMPLETED'
                    "
                    [matTooltip]="'appointment.action.undo' | translate"
                  >
                    <mat-icon
                      fontSet="mdi"
                      fontIcon="mdi-undo-variant"
                    ></mat-icon>
                  </button>
                </ng-template>
                <ng-template [ftAcl]="{ resource: 'ticket', action: 'accept' }">
                  <button
                    mat-icon-button
                    (click)="acceptAppointment(appointment.id)"
                    *ngIf="
                      ((appointment.ticket_acceptance_histories && appointment.ticket_acceptance_histories.length==0)||
                      ['SCHEDULED','RESCHEDULED'].includes(appointment.status_histories?.at(-1)?.status) ||
                        !appointment.ticket_acceptance_histories?.at(-1)
                          ?.is_accepted) &&
                      appointment.status_histories?.at(-1)?.status !==
                        'CANCELLED' &&
                      appointment.status_histories?.at(-1)?.status !== 'MISSED'&&
                      appointment.status_histories?.at(-1)?.status !== 'FORCE_COMPLETED'
                      && this.appointment['ticket_receipt'] && this.appointment['ticket_read']
                    "
                    [matTooltip]="'appointment.action.accept' | translate"
                  >
                    <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </button>
                </ng-template>
                <ng-template [ftAcl]="{ resource: 'ticket', action: 'reject' }">
                  <button
                    mat-icon-button
                    (click)="rejectAppointment(appointment.id)"
                    *ngIf="
                      ((appointment.ticket_acceptance_histories && appointment.ticket_acceptance_histories.length==0)||
                        appointment.ticket_acceptance_histories?.at(-1)
                          ?.is_accepted) &&
                      !appointment.ticket_start?.is_started &&
                      !(
                        appointment.status_histories?.at(-1)?.status ===
                        'ACCEPTED'
                      ) &&
                      appointment.status_histories?.at(-1)?.status !==
                        'CANCELLED' &&
                      appointment.status_histories?.at(-1)?.status !== 'MISSED'&&
                      appointment.status_histories?.at(-1)?.status !== 'FORCE_COMPLETED'
                      && this.appointment['ticket_receipt'] && this.appointment['ticket_read']
                    "
                    [matTooltip]="'appointment.action.reject' | translate"
                  >
                    <mat-icon
                      fontSet="mdi"
                      fontIcon="mdi-window-close"
                    ></mat-icon>
                  </button>
                </ng-template>
                <ng-template [ftAcl]="{ resource: 'ticket', action: 'start' }">
                  <button
                    mat-icon-button
                    (click)="startAppointment(appointment.id)"
                    *ngIf="
                      appointment.ticket_acceptance_histories?.at(-1)
                        ?.is_accepted &&
                      !appointment.ticket_start?.is_started &&
                      appointment.status_histories?.at(-1)?.status !==
                        'CANCELLED' &&
                      appointment.status_histories?.at(-1)?.status !== 'MISSED'
                    "
                    [matTooltip]="'appointment.action.start' | translate"
                  >
                    <mat-icon
                      fontSet="mdi"
                      fontIcon="mdi-play-outline"
                    ></mat-icon>
                  </button>
                </ng-template>
                <ng-template [ftAcl]="{ resource: 'ticket', action: 'resume' }">
                  <button
                    mat-icon-button
                    (click)="resumeAppointment(appointment.id)"
                    *ngIf="
                      appointment.ticket_acceptance_histories?.at(-1)
                        ?.is_accepted &&
                      appointment.ticket_pause_histories &&
                      appointment.ticket_pause_histories?.at(-1)?.is_paused &&
                      appointment.status_histories?.at(-1)?.status !==
                        'CANCELLED'
                    "
                    [matTooltip]="'appointment.action.resume' | translate"
                  >
                    <mat-icon
                      fontSet="mdi"
                      fontIcon="mdi-play-circle-outline"
                    ></mat-icon>
                  </button>
                </ng-template>
                <ng-template [ftAcl]="{ resource: 'ticket', action: 'pause' }">
                  <button
                    mat-icon-button
                    (click)="pauseAppointment(appointment.id)"
                    *ngIf="
                      appointment.ticket_acceptance_histories?.at(-1)
                        ?.is_accepted &&
                      appointment.ticket_start?.is_started &&
                      appointment.ticket_pause_histories &&
                      !appointment.ticket_pause_histories?.at(-1)?.is_paused &&
                      appointment.status_histories?.at(-1)?.status !==
                        'CANCELLED' &&
                      appointment.status_histories?.at(-1)?.status !==
                        'COMPLETED'
                    "
                    [matTooltip]="'appointment.action.pause' | translate"
                  >
                    <mat-icon fontSet="mdi" fontIcon="mdi-pause"></mat-icon>
                  </button>
                </ng-template>
                <ng-template [ftAcl]="{ resource: 'ticket', action: 'force-completed' }">
                  <button
                    mat-icon-button
                    (click)="forceComplete(appointment.id)"
                    *ngIf="
                      appointment.status_histories?.at(-1)?.status ===
                        'MISSED'
                    "
                    [matTooltip]="'appointment.action.force_completed' | translate"
                  >
                    <mat-icon fontSet="mdi" fontIcon="mdi-check-underline-circle-outline"></mat-icon>
                  </button>
                </ng-template>
                <ng-template
                  [ftAcl]="{ resource: 'ticket', action: 'complete' }"
                >
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    *ngIf="
                      appointment.ticket_acceptance_histories?.at(-1)
                        ?.is_accepted &&
                      appointment.status_histories?.at(-1)?.status !==
                        'CANCELLED' &&
                      appointment.status_histories?.at(-1)?.status !==
                        'COMPLETED' &&
                      appointment.status_histories?.at(-1)?.status !== 'MISSED'
                    "
                    [matTooltip]="
                      'appointment.action.display_additional_actions'
                        | translate
                    "
                  >
                    <mat-icon
                      fontSet="mdi"
                      fontIcon="mdi-dots-vertical"
                    ></mat-icon>
                  </button>
                </ng-template>

                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    (click)="completeAppointment(appointment.id)"
                    *ngIf="
                      appointment.status_histories?.at(-1)?.status ===
                      'IN_PROGRESS'
                    "
                  >
                    <span>{{ "appointment.action.complete" | translate }}</span>
                  </button>
                  <button
                    mat-menu-item
                    (click)="cancelAppointment(appointment.id)"
                  >
                    <span>{{
                      "appointment.cancel_the_intervention" | translate
                      }}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <div
            class="aii-mobile flex items-center gap-2 px-4 pb-4"
            *ngIf="isMobile"
          >
            <button
              mat-flat-button
              color="primary"
              (click)="drawer.open(); showMapView()"
            >
              <mat-icon
                fontSet="mdi"
                fontIcon="mdi-map-marker-outline"
              ></mat-icon>
              {{ "appointment.map" | translate }}
            </button>
            <button *ngIf="appointment?.prescription?.patient?.phone_number"
                    mat-flat-button
                    color="primary" (click)="callPhoneNumber(appointment?.prescription?.patient?.phone_number)"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-phone-outline"></mat-icon>
              {{appointment?.prescription?.patient?.phone_number}}
            </button>
          </div>
        </div>
        <div class="appointment-item-details-inner-container flex-grow">
          <mat-tab-group
            class="h-full"
            mat-stretch-tabs
            (selectedTabChange)="selectedTabChange()"
          >
            <mat-tab [label]="'appointment.details' | translate">
              <div     class="ai-details">
                <!-- <pr-prescription-template
                  *ngIf="appointment.prescription"
                  fxFlex
                  [(template)]="appointment.prescription.content"
                ></pr-prescription-template> -->
                <ng-container *ngIf="appointment.prescription?.content?.blocks">
                  <pr-prescription-template
                    fxFlex
                    [(template)]="appointment.prescription.content"
                  ></pr-prescription-template>
                </ng-container>
                <ng-container
                  *ngIf="!appointment.prescription?.content?.blocks"
                >
                  <div class="nothing-to-display">Nothing to show</div>
                </ng-container>
              </div>
            </mat-tab>
            <mat-tab
              [label]="'appointment.passage_proof.proof_of_passage' | translate"
            >
              <div class="ai-proof">
                <div class="ai-add-button-container mb-4">
                  <button
                    mat-flat-button
                    ngfSelect
                    multiple="1"
                    accept="image/*"
                    [(files)]="uploadedFiles"
                    (filesChange)="uploadFiles($event)"
                    [disabled]="getDisabledValue()"
                  >
                    <mat-icon
                      class="mr-2"
                      fontSet="mdi"
                      fontIcon="mdi-plus-circle-outline"
                    ></mat-icon>
                    {{ "appointment.passage_proof.upload_images" | translate }}
                  </button>
                  <!-- <div class="ai-add-button" (click)="imgInput.click()">
                    <input
                      #imgInput
                      hidden="true"
                      type="file"
                      onclick="this.value=null"
                    />
                    <mat-icon
                      fontSet="mdi"
                      fontIcon="mdi-plus-circle-outline"
                    ></mat-icon>

                    {{ "appointment.passage_proof.upload_images" | translate }}
                  </div> -->
                </div>
                <div class="keen-slider-container mb-8">
                  <div class="keen-slider" #sliderRef [ngBusy]="subscription">
                    <ng-container
                      *ngIf="
                        !currentFiles?.length && ngBusyItems.proofPassageImages
                      "
                    >
                      <div class="nothing-to-display">
                        {{
                        "appointment.passage_proof.no_images_to_display"
                          | translate
                        }}
                      </div>
                    </ng-container>
                    <div
                      class="keen-slider__slide"
                      *ngFor="
                        let currentFile of currentFiles;
                        trackBy: trackByFn;
                        let idx = index
                      "
                    >
                      <img [src]="getFileIcon(currentFile)" alt=""/>
                      <div class="keen-slider__slide__actions">
                        <button
                          mat-icon-button
                          class="file-icon-btn"
                          (click)="deleteFile(currentFile?.id)"
                          [matTooltip]="
                            'appointment.passage_proof.delete' | translate
                          "
                          *ngIf="!getDisabledValue()"
                        >
                          <mat-icon
                            fontSet="mdi"
                            fontIcon="mdi-delete-outline"
                          ></mat-icon>
                        </button>
                        <button
                          mat-icon-button
                          class="file-icon-btn"
                          (click)="previewFile(currentFile?.id)"
                          [matTooltip]="
                            'appointment.passage_proof.preview' | translate
                          "
                        >
                          <mat-icon
                            fontSet="mdi"
                            fontIcon="mdi-arrow-expand"
                          ></mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <mat-form-field class="w-full" appearance="outline">
                    <mat-label>{{
                      "appointment.passage_proof.comment" | translate
                      }}</mat-label>
                    <textarea
                      matInput
                      #commentTextarea
                      name="commentText"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="10"
                      [readonly]="getDisabledValue()"
                    ></textarea>
                    <!-- [(ngModel)]="commentText" -->
                    <!-- (keyup)="saveComment($event)" -->
                  </mat-form-field>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div
        class="appointment-item-map"
        *ngIf="!isMobile"
        (pr-after-if)="showMapView()"
      >
        <div class="next-appointment" *ngIf="!md">
          <h2>{{ "appointment.following_interventions" | translate }}</h2>
          <ul class="flex gap-3">
            <li *ngFor="let appointmentItem of nextAppointments">
              <div
                class="next-appointment-inner-container flex flex-col items-center"
                (click)="navigateToAppointment(appointmentItem)"
                [matTooltip]="
                  appointmentItem.ticket_content_histories.at(-1).reason
                "
              >
                <div class="nxt-a-date-container">
                  <div class="nxt-a-date-day font-bold">
                    {{ appointmentItem.day }}
                  </div>
                  <div class="nxt-a-date-month uppercase">
                    {{ appointmentItem.month }}
                  </div>
                  <div class="nxt-a-date-year">{{ appointmentItem.year }}</div>
                </div>
                <div class="nxt-a-to-now-date-container flex items-center">
                  <div class="nxt-a-to-now-date">
                    {{
                    toNow(
                      appointmentItem.ticket_schedule_histories.at(-1)
                        .execution_date
                    )
                    }}
                  </div>
                </div>
              </div>
            </li>
            <div
              class="nothing-to-display"
              *ngIf="nextAppointments.length === 0"
            >
              {{ "appointment.no_appointments_to_display" | translate }}
            </div>
          </ul>
        </div>
        <div class="next-appointment" *ngIf="!md">
          <h2>{{ "patient_prescription.satisfaction_form" | translate }}</h2>

          <div *ngIf="satisfactionForm" class="pr-card flex gap-4 p-4">
            <div class="rating-num text-subtitle1 font-bold">{{(satisfactionForm['rating'] / 20).toFixed(1)}}</div>
            <div class="rating-shape-container flex gap-4">
              <div class="rating-shape">
                <mat-icon
                  [ngClass]="{'star-fill':satisfactionForm['rating'] / 20>=1}"
                  fontSet="mdi"
                  fontIcon="mdi-star"
                ></mat-icon>
                <mat-icon [ngClass]="{'star-fill':satisfactionForm['rating'] / 20>=2}"
                          fontSet="mdi"
                          fontIcon="mdi-star"
                ></mat-icon>
                <mat-icon [ngClass]="{'star-fill':satisfactionForm['rating'] / 20>=3}"
                          fontSet="mdi"
                          fontIcon="mdi-star"
                ></mat-icon>
                <mat-icon [ngClass]="{'star-fill':satisfactionForm['rating'] / 20>=4}"
                          fontSet="mdi"
                          fontIcon="mdi-star"
                ></mat-icon>
                <mat-icon [ngClass]="{'star-fill':satisfactionForm['rating'] / 20==5}"
                          fontSet="mdi" fontIcon="mdi-star"></mat-icon>

              </div>
              <div>
                <a class="text-blue-600"
                  (click)="navigateToSatisfactionForm(satisfactionForm.id)">
                  {{satisfactionForm.created_at | ftDate  }}
                </a>
              </div>
            </div>
          </div>

          <div
            class="nothing-to-display"
            *ngIf="!satisfactionForm"
          >
            {{ "appointment.no_satisfaction_form_to_display" | translate }}
          </div>
        </div>


        <div class="map-container">
          <h2 class="pl-4">{{ "appointment.map" | translate }}</h2>
          <pr-map class="map-content flex-grow"></pr-map>
        </div>


      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
