import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-patient-resume',
  templateUrl: './patient-resume.component.html',
  styleUrls: ['./patient-resume.component.scss']
})
export class PatientResumeComponent implements OnInit {
  subscription: Subscription;
  model = null;

  constructor(public dialogRef: MatDialogRef<PatientResumeComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {
    this.model = data;
  }

  ngOnInit(): void {
  }

  handleSave(reset: boolean = false) {
    this.dialogRef.close({force: true});

  }

  navigateToPatient() {
    this.dialogRef.close({force: false});

  }

}
