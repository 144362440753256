import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';


@NgModule({
    imports: [RouterModule.forChild([])],
    exports: [RouterModule]
})
export class SharedRoutingModule {
}
