type LocationType = {
  lat: string;
  lng: string;
};

type StatusHistoryType = {
  created_at?: string;
  status: string;
};

type BaseTicketHistoryType = {
  performer_id?: number;
  comment?: string;
  created_at?: string;
};

type TicketContentHistoryType = BaseTicketHistoryType & {
  reason?: string;
  location_url?: string;
  location?: LocationType;
};

export type TicketPauseHistoryType = BaseTicketHistoryType & {
  is_paused: boolean;
};

type TicketCompletionHistoryType = BaseTicketHistoryType & {
  is_completed: boolean;
};

type TicketCancellationHistoryType = BaseTicketHistoryType & {
  is_cancelled: boolean;
  cancellation_reason?: string; // Replace with an enum or specific type if needed
};

export type TicketAcceptanceHistoryType = BaseTicketHistoryType & {
  is_accepted: boolean;
};

type TicketScheduleHistoryType = BaseTicketHistoryType & {
  all_day: boolean;
  execution_date?: string;
  start_time?: string;
  end_time?: string;
};

type TicketReceiptType = BaseTicketHistoryType & {
  is_received: boolean;
};

export type TicketReadType = BaseTicketHistoryType & {
  is_read: boolean;
};

export type TicketStartType = BaseTicketHistoryType & {
  is_started: boolean;
};

type PassageProofType = {
  comment?: string;
};

export type TicketType = {
  id?: number;
  access_level?: string;
  status_histories?: StatusHistoryType[];
  created_at?: string;
  ticket_content_histories?: TicketContentHistoryType[];
  prescription?: any;
  ticket_pause_histories?: TicketPauseHistoryType[];
  ticket_completion_histories?: TicketCompletionHistoryType[];
  ticket_cancellation_histories?: TicketCancellationHistoryType[];
  ticket_acceptance_histories?: TicketAcceptanceHistoryType[];
  ticket_schedule_histories?: TicketScheduleHistoryType[];
  ticket_receipt?: TicketReceiptType;
  ticket_read?: TicketReadType;
  ticket_start?: TicketStartType;
  passage_proof?: PassageProofType;
  day?: string;
  month?: string;
  year?: string;
  external_id?: string|number;
  assignee?: any
};

export type PathParamsType = {
  id?: number;
  name?: string;
};
