import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';

export const CALENDAR_PRESETS = {
    plugins: [
        momentPlugin,
        dayGridPlugin,
        interactionPlugin,
        timeGridPlugin,
        listPlugin,
    ],
    // initialView: 'timeGridWeek',
    // initialView: 'dayGridMonth',
    initialView: 'listMonth',
    dayMaxEventRows: true,
    dayMaxEvents: true,
    dayHeaders: true,
    dayHeaderClassNames: 'day-header',
    dayHeaderFormat: {weekday: 'narrow'},
    dayCellClassNames: 'day-cell-th',
    titleFormat: 'MMMM D, YYYY', // you can now use moment format strings!
    locale: 'fr',
    allDayText: 'toute la journée',
    // FullCalendar headerToolbar
    headerToolbar: false,
    // headerToolbar: {
    //     left: 'prev next today',
    //     center: 'title',
    //     end: 'dayGridMonth timeGridWeek timeGridDay list',
    // },
    buttonText: {
        today: "Aujourd'hui",
        month: 'Mois',
        week: 'Semaine',
        day: 'Jour',
        list: 'Liste',
    },
    // FullCalendar nowIndicator
    nowIndicator: true,
    nowIndicatorClassNames: 'now-indicator',
    selectable: true,
    eventDisplay: 'block',
    // FullCalendar Drag&Drop
    // editable: true,
    // droppable: true, // this allows things to be dropped onto the calendar
    height: '100%',
    listDayFormat: { weekday: 'long', day: 'numeric' },
    listDaySideFormat: false,
    views: {
        dayGrid: {

        },
        timeGrid: {

        },
        // TODO week
        // week: {
        //     dayHeaderFormat: { weekday: 'short', day: 'numeric' }
        // },
        day: {

        }
      },
};
