import {lang as sharedFr} from './shared/i18n';
import {lang as patientFr} from './patient/i18n';
import {lang as prescriptionFr} from './prescription/i18n';
import {appointmentFr} from './ticket/fr.i18n';
import {appointmentEn} from './ticket/en.i18n';
import {patientAppointmentFr} from './patient-appointment/fr.i18n';
import {patientAppointmentEn} from './patient-appointment/en.i18n';
import {patientRequestFr} from './patient-request/fr.i18n';
import {patientRequestEn} from './patient-request/en.i18n';
import {patientPrescriptionFr} from './patient-prescription/fr.i18n';
import {patientPrescriptionEn} from './patient-prescription/en.i18n';
import {lang as settingsFr} from './settings/i18n';
import {lang as sharedEn} from './shared/i18n';
import {lang as patientEn} from './patient/i18n';
import {lang as prescriptionEn} from './prescription/i18n';
import {lang as settingsEn} from './settings/i18n';
import {tableFrLang, tableEnLang} from '@ft/table';
import {
    fileManagerFrLang,
    mediaFrLang,
    visualizerFrLang
} from '@ft/file-manager';
import {
    fileManagerEnLang as file_managerEn,
    mediaEnLang as mediaEn,
    visualizerEnLang as viewerEn
} from '@ft/file-manager';

import {
    wsFrLang,
    authFrLang,
    coreFrLang,
    commonFrLang,
    wsEnLang,
    authEnLang,
    coreEnLang,
    commonEnLang
} from '@ft/core';
import {formEnLang, formFrLang} from '@ft/form';


const fr = Object.assign(
    {},
    {ws: wsFrLang},
    {auth: authFrLang},
    {core: coreFrLang},
    {common: commonFrLang},
    {table: tableFrLang},
    {patient: patientFr},
    {shared: sharedFr},
    {prescription: prescriptionFr},
    {appointment: appointmentFr},
    {patient_appointment: patientAppointmentFr},
    {patient_request: patientRequestFr},
    {patient_prescription: patientPrescriptionFr},
    {settings: settingsFr},
    {viewer: visualizerFrLang, media: mediaFrLang, file_manager: fileManagerFrLang},
    {form: Object.assign(formFrLang, {'file': 'Fichier'})}
);

const en = Object.assign(
    {},
    {ws: wsEnLang},
    {auth: authEnLang},
    {core: coreEnLang},
    {common: commonEnLang},
    {table: tableEnLang},
    {patient: patientEn},
    {shared: sharedEn},
    {prescription: prescriptionEn},
    {appointment: appointmentEn},
    {patient_appointment: patientAppointmentEn},
    {patient_request: patientRequestEn},
    {patient_prescription: patientPrescriptionEn},
    {settings: settingsEn},
    {viewer: viewerEn, media: mediaEn, file_manager: file_managerEn},
    {form: Object.assign(formEnLang, {'file': 'File'})}
);


export const lang = {
    fr, en
};
