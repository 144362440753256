<div class="pr-content-container">
  <div class="pr-content-header">
    <div class="list-title text-h4 font-bold">
      {{ "settings.action_notifier" | translate }}
    </div>
    <button mat-icon-button [routerLink]="'/settings/action-notifier'">
      <mat-icon fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
    </button>
  </div>
  <div class="pr-content-body">
    <form
      [formGroup]="reactiveForm"
      (ngSubmit)="onSubmit()"
      class="settings flex flex-col gap-4"
    >
      <div class="settings-section">
        <div class="settings-sub-section">
          <div class="form-field-label">
            {{ "settings.select_action" | translate }}:
            <span class="text-red-500">*</span>
          </div>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="action"
              (selectionChange)="updateAction($event.value)"
              [compareWith]="objectComparisonFunction"
            >
              <ng-container *ngFor="let action of actions">
                <ng-container>
                  <!-- [value]="{label: action.translate, resource_name: action.name, action_label: crudAction.translate, name: crudAction.name}" -->
                  <mat-option
                    *ngFor="let crudAction of action.actions"
                    [value]="{
                      label: action.translate,
                      resource_name: action.name,
                      action_label: crudAction.translate,
                      name: crudAction.name
                    }"
                    [disabled]="!action.translate"
                  >
                    <span class="font-medium"
                      >{{ action.translate | translate
                      }}<span
                        class="text-red-500 text-opacity-40"
                        *ngIf="!action.translate"
                        >{{ "settings.no_translation_for" | translate }} "{{
                          action.name
                        }}"</span
                      ></span
                    >
                    /
                    <span class="font-bold">{{
                      crudAction.translate | translate
                    }}</span>
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="reactiveForm.get('action').hasError('required')">
              {{ "settings.please_select_an_action" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="settings-sub-section">
          <div class="form-field-label">
            {{ "settings.select_notification_recipients" | translate }}:
            <span class="text-red-500">*</span>
          </div>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="recipients"
              (selectionChange)="updateRecipients($event.value)"
              multiple
            >
              <mat-select-trigger>
                {{ getFirstSelectedRecipient(reactiveForm.get('recipients').value?.[0]) }}
                <ng-container
                  *ngIf="
                    (reactiveForm.get('recipients').value?.length || 0) > 1;
                    else noSelection
                  "
                >
                  <span class="example-additional-selection lowercase">
                    (+{{
                      (reactiveForm.get("recipients").value?.length || 0) - 1
                    }}
                    {{
                      (reactiveForm.get("recipients").value?.length === 2
                        ? "settings.other"
                        : "settings.others"
                      ) | translate
                    }})
                  </span>
                </ng-container>
                <ng-template #noSelection></ng-template>
              </mat-select-trigger>
              <mat-option
                *ngFor="let recipient of recipientList"
                [value]="recipient.value"
              >
                {{ recipient.viewValue | translate }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="reactiveForm.get('recipients').hasError('required')"
            >
              {{ "settings.please_select_recipients" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="settings-section-separator" *ngIf="isFormControlsValid()"> -->
      <!-- todo remove reminder from action notifier config reminder will be separated from notifier
       <div class="settings-section-separator">* * * * * *</div>
      <div
        class="settings-section flex flex-col gap-4"
        [ngClass]="{ 'opacity-50': !isFormControlsValid() }"
      >
        <div class="form-field-label">
          {{ "settings.add_reminders" | translate }}:
        </div>
        <div
          class="notif-step-container flex flex-col gap-4"
          *ngIf="actionNotifierItem?.reminders?.length"
        >
          <div
            class="notif-step"
            *ngFor="
              let reminder of actionNotifierItem?.reminders;
              trackBy: trackByFunction;
              let i = index
            "
          >
            <div class="settings-sub-section">
              <div class="notif-step-header">
                <div class="flex-grow">
                  {{ reminder.title ? reminder.title : "Rappel " + (i + 1) }}
                </div>
                <div
                  class="cursor-pointer"
                  (click)="editReminder(reminder.oid)"
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                </div>
                <div
                  class="cursor-pointer"
                  (click)="removeReminder(reminder.oid)"
                >
                  <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-delete-outline"
                  ></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="centred-add-btn"
          (click)="addReminder()"
          [disabled]="!isFormControlsValid()"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-plus-circle-outline"></mat-icon>
          <span>{{ "settings.add_reminder" | translate }}</span>
        </button>
      </div>-->
      <div class="settings-section-separator">* * * * * *</div>
      <div
        class="settings-section"
        [ngClass]="{ 'opacity-50': !isFormControlsValid() }"
      >
        <button
          type="button"
          class="settings-add-btn"
          (click)="editNotificationByDialog()"
          [disabled]="!isFormControlsValid()"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-cog-outline"></mat-icon>
          <span>{{ "settings.edit_notification" | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</div>

<!-- <button type="button" (click)="test()">click</button> -->
