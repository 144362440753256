import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-patient',
  template: `
    <div 
    fxFlex fxLayout="column" 
    [ngClass]="breakpointObserver.isMatched('(min-width: 1280px)') ? 'ft-parent-layout' : 'no-ft-parent-layout'">
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  public breakpoints = Breakpoints;
  constructor(public breakpointObserver: BreakpointObserver,) {
  }
  ngOnInit(): void {
    this.breakpointObserver
    .observe(['(max-width: 640px)'])
    .subscribe((result) => {
      if (result.breakpoints['(max-width: 640px)']) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
}

}
