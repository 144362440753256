<div class="calendar-container">
  <pr-calendar-header
    (next)="next()"
    (dayView)="dayView()"
    (weekView)="weekView()"
    (monthView)="monthView()"
    (fourDaysView)="fourDaysView()"
    (listView)="listView()"
    (mapView)="mapView()"
    (prev)="prev()"
    (today)="today()"
    (createEvent)="createEvent()"
    (goToDate)="goToDate($event)"
    [headerTitle]="calendarHeaderTitle"
    [activeView]="emitCalendarView"
    [config]="calendarHeaderConfig"
  ></pr-calendar-header>

  <section class="calendar">
    <div id="full-calendar" #calendar></div>
  </section>
</div>