import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TITLES} from '../../../shared/utils/consts';
import {Subscription} from 'rxjs';
import {PhysicianService} from '../../services/physician.service';
import {SettingsService} from '../../services/settings.service';
import {assign, get} from 'lodash';
import {SMALL_DIALOG} from "@ft/core";
import {PhysicianStaffDialogComponent} from "../physician-staff-dialog/physician-staff-dialog.component";


@Component({
  selector: 'app-physician-setup',
  templateUrl: './physician-setup.component.html',
  styleUrls: ['./physician-setup.component.scss']
})
export class PhysicianSetupComponent implements OnInit {
  physician: any = {gender: 'MALE', "contact_info": {"emails": [], "phone_numbers": []}, users: []};
  titles = TITLES;
  subscription: Subscription;
  role = null;
  specialities = [];

  constructor(public dialogRef: MatDialogRef<PhysicianSetupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public physicianService: PhysicianService,
              public settingsService: SettingsService,
              private _dialog: MatDialog) {
    if (get(data, 'model.id')) {
      this.subscription = this.physicianService.getPhysician(get(data, 'model.id')).subscribe(res => {
        this.physician = !this.physician.users ? assign(res, {users: []}) : res;
      });
    }
  }

  ngOnInit(): void {

    this.settingsService.getSpecialities().subscribe(data => {
      this.specialities = data;
    });
  }

  handleSave() {
    this.subscription = this.physicianService.savePhysician(this.physician).subscribe(data => {
      this.dialogRef.close(data);
    });
  }

  compareCondition(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  public handleStaff() {

    const dialogRef = this._dialog.open(PhysicianStaffDialogComponent, assign(SMALL_DIALOG, {
      autoFocus: false,
      data: {
        staff: !this.physician.users || this.physician.users.length == 0 ? assign(this.physician, {users: [{}]}) : this.physician,
        url: "api/pl/physician"
      }
    }));

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.physician = data;
      }
    });
  }


}
