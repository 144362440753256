import {TableColumnDefinition} from "@ft/core";

export const SPECIALITY_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'name',
    'key': 'name',
    'sortBy': 'name',
    'type': 'text',
    'is_global_searchable': true
  },
  {
    'label': 'description',
    'key': 'description',
    'sortBy': 'description',
    'type': 'text',
    'is_global_searchable': true
  }
];
