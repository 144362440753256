import {ChangeDetectorRef, Component, ElementRef, HostBinding, ViewChild} from '@angular/core';
import {PatientPrescriptionType} from '../../patient-prescription.type';
import {BreakpointObserver} from '@angular/cdk/layout';
import {PatientPrescriptionService} from '../../services/patient-prescription.service';
import {get, isEmpty, concat, chain} from "lodash";
import {debounceTime} from 'rxjs/operators';
import {ResponsiveBaseComponent} from '../../../shared/components/responsive-base/responsive-base.component';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {DefaultValuesService} from "@ft/core";

@Component({
  selector: 'pr-patient-prescription-list',
  templateUrl: './patient-prescription-list.component.html',
  styleUrls: ['./patient-prescription-list.component.scss'],
  host: {
    '[class.has-scroll]': 'hasScroll',
  }

})
export class PatientPrescriptionListComponent extends ResponsiveBaseComponent {
  @HostBinding('class.has-scroll') public hasScroll: boolean;
  @ViewChild('innerTable', {static: true}) innerTable: ElementRef;
  public patientPrescriptions: PatientPrescriptionType[] = [];
  public listItems = [
    {translationKey: 'date', objectKey: 'date'},
    {translationKey: 'type', objectKey: 'name'},
    {translationKey: 'starting_treatment', objectKey: 'start_date'},
    {translationKey: 'ending_treatment', objectKey: 'end_date'},
    {translationKey: 'attending_physician', objectKey: 'physician'},
  ]
  page = 0;
  dataLength = 0;
  limit = 50;
  pagesCount = 1;
  searchAll = null;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    private _patientPrescriptionService: PatientPrescriptionService, private ref: ChangeDetectorRef,
    private defaultValuesService: DefaultValuesService
  ) {
    super(breakpointObserver, titleService, translateService);
    this.title = "patient_prescription.patient_prescription_list";
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.handleData();
  }

  public onScroll(): void {
    if (this.page < this.pagesCount + 1) {
      this.page++;
      this.handleData();
    }
  }

  handleData() {
    this._patientPrescriptionService.getPatientPaginatedPrescriptions(this.page, this.searchAll).pipe(debounceTime(150)).subscribe(data => {
      if (data) {
        if (!isEmpty(data.list)) {
          this.patientPrescriptions = concat(this.patientPrescriptions, data.list);
        }
        if (!this.dataLength) {
          this.dataLength = get(data, 'length', 0);
          this.pagesCount = this.dataLength !== 0 ? Math.ceil(this.dataLength / this.limit) : 0;
        }
      }
    })
  }

  handleSearch(ev) {
    this.searchAll = ev;
    this.page = 0;
    this.patientPrescriptions = [];
    this.getScrollStatus();
    this.handleData();
  }

  getScrollStatus() {
    setTimeout(() => {
      this.hasScroll = this.innerTable.nativeElement.scrollHeight > this.innerTable.nativeElement.clientHeight;
      this.ref.markForCheck();
    });
  }

  handleStatusColor(item) {
    return {
      'color': (chain(this.defaultValuesService.getValue(
        'prescriptionStateColors'
      )).find({
        name: get(item, 'status')
      }) as any).get('color', 'rgb(255, 204, 153)').value()
    };
  }

}
