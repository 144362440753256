import { ReminderType } from "./action-notifier.type";

type TFUStatusType = "IN_PROGRESS" | "DONE";

export type TelephoneFollowUpItemType = {
    oid: number;
    title: string;
    is_model: boolean;
    done: boolean;
    reminder: ReminderType;
    comment: string;
}

type BaseTFUType = {
    id?: number;
    title?: string;
    items?: TelephoneFollowUpItemType[];
}

export type TFUModelType = BaseTFUType & {
    
}

export type TelephoneFollowUpPlanType = BaseTFUType & {
    status?: TFUStatusType;
    prescription?: any;
}