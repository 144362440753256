import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {PatientPrescriptionType} from '../../patient-prescription.type';
import {concatMap, forkJoin, from, map, mergeMap, Observable, of, Subscription, toArray} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DomSanitizer, SafeUrl, Title} from '@angular/platform-browser';
import {CommentService} from '../../../shared/services/comment.service';
import {ActivatedRoute} from '@angular/router';
import {PatientPrescriptionService} from '../../services/patient-prescription.service';
import {MatDialog} from '@angular/material/dialog';
import {projectName} from '../../../shared/utils/consts';
import {ResponsiveBaseComponent} from '../../../shared/components/responsive-base/responsive-base.component';
import {TranslateService} from '@ngx-translate/core';
import {first, chain, find, get} from "lodash";
import {FileManagerService, FtFile, VisualizerService} from "@ft/file-manager";
import {trackByFn} from '../../../shared/utils/functions';
import {FileInfoType} from "../../../shared/types/common.type";
import KeenSlider, {KeenSliderInstance} from "keen-slider";
import {DefaultValuesService} from "@ft/core";

@Component({
  selector: 'pr-patient-prescription-item',
  templateUrl: './patient-prescription-item.component.html',
  styleUrls: ['./patient-prescription-item.component.scss'],
})
export class PatientPrescriptionItemComponent extends ResponsiveBaseComponent {
  public patientPrescription: PatientPrescriptionType;
  public listItems = [
    {translationKey: 'date', objectKey: 'date'},
    {translationKey: 'type', objectKey: 'type'},
    {translationKey: 'starting_treatment', objectKey: 'start_date'},
    {translationKey: 'ending_treatment', objectKey: 'end_date'},
    {translationKey: 'attending_physician', objectKey: 'physician'},
  ]
  public comments: any[] = [];
  public satisfactionForms: any = [];
  public tutorials: any = [];
  public subscription: Subscription;
  public prescriptionFiles: any = [];
  public trackByFn = trackByFn('id');
  public slider: KeenSliderInstance = null;
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  public prescriptionFolder: any = null;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    private _commentService: CommentService,
    private _activatedRoute: ActivatedRoute,
    private _patientPrescriptionService: PatientPrescriptionService,
    private _managerService: FileManagerService,
    private _dialog: MatDialog,
    private _visualizerService: VisualizerService,
    private _sanitizer: DomSanitizer,
    public defaultValuesService: DefaultValuesService,
  ) {
    super(breakpointObserver, titleService, translateService);
  }

  ngOnInit(): void {
    this.title = 'patient_prescription.benefit';
    super.ngOnInit();

    this.subs.add(
      this._activatedRoute.params.subscribe({
        next: (params) => {
          this.subs.add(
            this.subscription = forkJoin([
              this._patientPrescriptionService.getPatientPrescriptionItem(params['id']),
              this._patientPrescriptionService.getPatientPrescriptionSatisfactionForms(params['id']),
              this._managerService.readFolder(
                'prescription.models.Prescription',
                params['id'],
                null,
                ['tutorialType'],
                {tutorialType: 'Link'}
              ),
              this._managerService.readFolder(
                'prescription.models.Prescription',
                params['id'],
                null,
                ['fileType'],
                {fileType: 'Report'}
              ),
            ]).subscribe(data => {
              this.patientPrescription = data[0];
              this.satisfactionForms = data[1];
              this.tutorials = chain(data[2]).get('files', []).filter({
                context: {tutorialType: 'Link'},
                type: 'link'
              }).value();
              this._generateFilesUrl(data[3]);
              this.slider = new KeenSlider(this.sliderRef.nativeElement, {
                // loop: true,
                mode: 'snap',
                slides: {
                  perView: 'auto',
                  spacing: 15,
                },
              });


            }));
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
  }

  fomatNumber(idx) {
    return String(idx).padStart(2, '0');
  }

  private _generateFilesUrl(directory) {

    if (directory.files?.length > 0) {
      this.prescriptionFolder = directory;
      this.prescriptionFiles = directory.files.filter(file => file.is_dir == false);


    } else {
      this.prescriptionFiles = [];
    }
  }

  public previewFile(fileId: string, fileType: string, url_shortcut?: string) {
    if (fileType == 'link') {
      window.open(url_shortcut);
    } else {
      const localizedFile: [number, FtFile[]] =
        this.prescriptionFolder.visualizedFiles(fileId);
      const file = find(this.prescriptionFolder.files, {id: fileId});
      this._visualizerService.open({
        data: localizedFile[1],
        index: localizedFile[0],
      });
    }
  }

  getFileIcon(file) {
    return file.has_thumbnail ? this._managerService.getFileUrl(file.id, 'thumbnail') : file.icon;
  }

  openLink(link) {
    window.open(link);
  }

  handleStatusColor(item) {
    return {
      'color': (chain(this.defaultValuesService.getValue(
        'prescriptionStateColors'
      )).find({
        name: get(item, 'status')
      }) as any).get('color', 'rgb(255, 204, 153)').value()
    };
  }
}
