import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketContainerComponent } from './components/ticket-container/ticket-container.component';
import { TicketRoutingModule } from './ticket-routing.module';
import { ContentComponent } from './components/content/content.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import {FtCoreModule, MaterialModule} from '@ft/core';
import { SharedModule } from '../shared/shared.module';
import { MapComponent } from './components/map/map.component';
import { TicketItemComponent } from './components/ticket-item/ticket-item.component';
import { AfterIfDirective } from './directives/after-if.directive';
import { SatisfactionFormComponent } from './components/satisfaction-form/satisfaction-form.component';
import { NgBusyModule } from 'ng-busy';
import { PrescriptionModule } from '../prescription/prescription.module';
import { ngfModule } from 'angular-file';
import { FtFormModule } from '@ft/form';
import { SettingsModule } from '../settings/settings.module';

@NgModule({
  declarations: [
    TicketContainerComponent,
    ContentComponent,
    MapComponent,
    TicketItemComponent,
    AfterIfDirective,
    SatisfactionFormComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ngfModule,
    PrescriptionModule,
    TicketRoutingModule,
    FullCalendarModule,
    NgBusyModule,
    FtFormModule,
    SettingsModule,
    FtCoreModule,
  ]
})
export class TicketModule { }
