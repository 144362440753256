<div class="ft-parent-layout ft-content-margin mat-elevation-z1" fxFlex fxLayout="column">

    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-stethoscope"></mat-icon>
        </div>
        <h2 translate="settings.speciality_list"></h2>
    </mat-toolbar>
    <div fxFlex fxLayout="column" class="ft-content">
        <app-pr-generic-view-table [namespace]="viewSettings?.viewNamespace"
                               [actions]="viewSettings?.actions"
                               [viewSettings]="viewSettings?.viewSettings"
                               [hasPagination]="viewSettings?.hasPagination"
                               [selectable]="viewSettings?.selectable"
                               [query]="viewSettings?.query"
                               [reloadEvent]="reloadEvent"
                               [exportExcel]="true"
        >
        </app-pr-generic-view-table>
    </div>
</div>
