import {Component, OnInit} from '@angular/core';
import {DrawerLink} from "@ft/core";

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    public links: DrawerLink[] = [
        {
            label: 'settings.generals',
            items: [
                {
                    icon: 'mdi-wrench',
                    label: 'settings.base_settings',
                    path: 'base-settings',
                },
                {
                    icon: 'mdi-printer-settings',
                    label: 'settings.printing_setup',
                    path: 'printing-setup',
                }
            ]
        },
        {
            label: 'settings.access_control',
            items: [
                {
                    icon: 'mdi-account-switch',
                    label: 'settings.profiles',
                    path: 'profile-setup',
                    acl: {resource: 'profile', action: 'create', behavior: 'remove'}

                },
                {
                    icon: 'mdi-account',
                    label: 'settings.users',
                    path: 'staff-setup',
                    acl: {resource: 'user', action: 'create', behavior: 'remove'}
                }
            ]
        },
        {
            label: 'settings.application_config',
            items: [
                {
                    icon: 'mdi-contacts',
                    label: 'settings.physician_list',
                    path: 'physician-list',
                    acl: {resource: 'physician', action: 'create', behavior: 'remove'}
                },
                {
                    icon: 'mdi-stethoscope',
                    label: 'settings.speciality_list',
                    path: 'speciality-list',
                    acl: {resource: 'speciality', action: 'get', behavior: 'remove'}
                },
                {
                    icon: 'mdi-view-dashboard',
                    label: 'settings.prescription_forms_setup',
                    path: 'prescription-forms',
                    acl: {resource: 'prescription-forms', action: 'get', behavior: 'remove'}
                },
                {
                    icon: 'mdi-phone-message',
                    label: 'settings.sms_setup',
                    path: 'sms-setup',
                    acl: {resource: 'sms-history', action: 'get', behavior: 'remove'}
                },
                // {
                //     icon: 'mdi-bell-cog',
                //     label: 'settings.notifications',
                //     path: 'notification',
                //     acl: {resource: 'notification', action: 'get', behavior: 'remove'}
                // },
                // {
                //     icon: 'mdi-reminder',
                //     label: 'settings.reminders',
                //     path: 'reminder',
                //     acl: {resource: 'reminder', action: 'get', behavior: 'remove'}
                // },
                {
                    icon: 'mdi-bell-badge',
                    label: 'settings.action_notifiers',
                    path: 'action-notifier',
                    acl: {resource: 'action-notifier', action: 'get', behavior: 'remove'}
                },
                {
                    icon: 'mdi-reminder',
                    label: 'settings.tfu-models',
                    path: 'tfu-model',
                    acl: {resource: 'tfu-model', action: 'get', behavior: 'remove'}
                },
            ]
        },
    ];

    constructor() {
    }

    ngOnInit(): void {
        
    }

}
