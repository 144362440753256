import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { projectName } from '../../utils/consts';

@Component({
  selector: 'pr-responsive-base',
  templateUrl: './responsive-base.component.html',
  styleUrls: ['./responsive-base.component.scss'],
})
export class ResponsiveBaseComponent implements OnInit, OnDestroy {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  public md: boolean = false;
  public lg: boolean = false;
  public title = 'shared.temp_title';
  public subs = new Subscription();
  constructor(
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService
  ) {}
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  ngOnInit(): void {
    this.titleService.setTitle(
      projectName + ' - ' + this.translateService.instant(this.title)
    );
    this.subs.add(
      this.breakpointObserver
        .observe([
          '(max-width: 640px)',
          '(max-width: 767px)',
          '(max-width: 940px)',
        ])
        .subscribe((result) => {
          this.isMobile = result.breakpoints['(max-width: 640px)']
            ? true
            : false;
          this.md = result.breakpoints['(max-width: 767px)'] ? true : false;
          this.lg = result.breakpoints['(max-width: 940px)'] ? true : false;
        })
    );
  }
}
