import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PatientRoutingModule} from './patient-routing.module';
import {GridComponent} from './components/grid/grid.component';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgBusyModule} from 'ng-busy';
import {PatientComponent} from './components/patient/patient.component';
import {SharedModule} from '../shared/shared.module';
import {PatientService} from './services/patient.service';
import {PatientBaseComponent} from './components/patient-form/patient-base.component';
import {FtTableModule} from '@ft/table';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {PatientFormComponent} from './components/patient-form/patient-form.component';
import {PatientMobileFormComponent} from './components/patient-mobile-form/patient-mobile-form.component';
import {PatientDeathDialogComponent} from './dialogs/patient-death/patient-death.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {PatientDisplayComponent} from './components/patient-display/patient-display.component';
import {FileManagerModule} from '@ft/file-manager';

import {ngfModule} from 'angular-file';
import {PatientAccountSetupComponent} from './dialogs/patient-account-setup/patient-account-setup.component';
import {PatientResumeComponent} from './dialogs/patient-resume/patient-resume.component';
import {AgHandlerDirective} from './directives/custom-age-handler.directive';


@NgModule({
    declarations: [
        GridComponent,
        PatientComponent,
        PatientBaseComponent,
        PatientFormComponent,
        PatientMobileFormComponent,
        PatientDeathDialogComponent,
        PatientDisplayComponent,
        PatientAccountSetupComponent,
        PatientResumeComponent,
      AgHandlerDirective
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        NgBusyModule,
        FtTableModule,
        SharedModule,
        PatientRoutingModule,
        FtCoreModule,
        InfiniteScrollModule,
        FileManagerModule,
        ngfModule
    ],
    providers: [
        PatientService
    ]
})
export class PatientModule {
}
