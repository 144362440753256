import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {PrescriptionService} from '../../../prescription/services/prescription.service';
import {get, pick, join, isEmpty} from 'lodash';
import {SmsService} from '../../services/sms.service';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from '../../../settings/services/settings.service';


@Component({
    selector: 'send-sms-dialog',
    templateUrl: './send-sms-dialog.component.html',
    styleUrls: ['./send-sms-dialog.component.scss'],
})
export class SendSmsDialogComponent implements OnInit {
    subscription: Subscription;
    patient = null;
    physician = null;
    content = null;
    focusContent = true;
    send_to = 'all';
    errorMessage = [];
    @ViewChild('inp') inp: any;
    templates = [];
    public template=null;

    constructor(public dialogRef: MatDialogRef<SendSmsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private prescriptionService: PrescriptionService,
                public translateService: TranslateService,
                private _adapter: MomentDateAdapter,
                private settingsService: SettingsService,
                private smsService: SmsService) {
        this.patient = get(data, 'patient');
        this.physician = get(data, 'physician');
    }

    ngOnInit(): void {
        this.subscription = this.settingsService.getSmsTemplates().subscribe(data => {
            this.templates = data;
        });
    }

    handleSend(ev) {
        this.subscription = this.smsService.sendSms({
            patient: pick(this.patient, ['_d', 'phone_number']),
            physician: pick(this.physician, ['id', 'phone_number']),
            content: this.content,
            send_to: this.send_to
        }).subscribe(data => {
            if (get(data, 'patient_response.error')) {
                this.errorMessage.push(this.translateService.instant('shared.patient_sms_error_label') +
                    this.translateService.instant('shared.' + get(data, 'patient_response.message')) + '::' +
                    get(data, 'patient_response.origin.to', ''));
            }
            if (get(data, 'physician_response.error')) {
                this.errorMessage.push(this.translateService.instant('shared.physician_sms_error_label') +
                    this.translateService.instant('shared.' + get(data, 'physician_response.message')) + '::' +
                    get(data, 'physician_response.origin.to', ''));
            }
            if (isEmpty(this.errorMessage)) {
                this.dialogRef.close(data);
            }

        });
    }

    updateContentPatient(ev) {
        this.content += `${get(this.patient, 'first_name', '')} ${get(this.patient, 'last_name', '')}`;
        this.focusContent = true;
        this.inp.nativeElement.focus();
    }

    updateContentPhysician(ev) {
        this.content += `${get(this.physician, 'first_name', '')} ${get(this.physician, 'last_name', '')}`;
        this.focusContent = true;
        this.inp.nativeElement.focus();
    }


    handleSmsTemplate(t) {
        this.content = t.content.replace('{{patient.full_name}}', `${get(this.patient, 'first_name', '')} ${get(this.patient, 'last_name', '')}`)
            .replace('{{physician.full_name}}', `${get(this.physician, 'first_name', '')} ${get(this.physician, 'last_name', '')}`);
        this.inp.nativeElement.focus();

    }
}
