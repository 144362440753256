import { Component} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { projectName } from '../../../shared/utils/consts';
import { fromNow } from '../../../shared/utils/functions';
import { CommentService } from '../../../shared/services/comment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { AppointmentType } from '../../patient-appointment.type';
import { assign, cloneDeep, find, has, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ComposeDialogComponent } from '../../../shared/dialogs/compose-dialog/compose-dialog.component';
import { ConfirmDialogComponent } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PatientRequestService } from '../../../patient-request/services/patient-request.service';
import { PatientRequestType } from '../../../patient-request/patient-request.type';
import { CommentType } from '../../../shared/types/common.type';
import { DateTime } from 'luxon';
import { AppConfigsService } from '@ft/core';
import { TranslateService } from '@ngx-translate/core';
import { ResponsiveBaseComponent } from '../../../shared/components/responsive-base/responsive-base.component';
import * as moment from "moment";

const appointmentStatuses = [
  { name: 'SCHEDULED', bgColor: '#b3eeff', txtColor: '#003366' },
  { name: 'CONFIRMED', bgColor: '#d7f0e5', txtColor: '#00704b' },
  {
    name: 'CANCEL_REQUESTED',
    bgColor: 'rgba(255, 230, 204, 1)',
    txtColor: '#8b3e00',
  },
  { name: 'RESCHEDULE_REQUESTED', bgColor: '#ffdf80', txtColor: '#8b5200' },
];

@Component({
  selector: 'pr-patient-appointment-item',
  templateUrl: './patient-appointment-item.component.html',
  styleUrls: ['./patient-appointment-item.component.scss'],
})
export class PatientAppointmentItemComponent extends ResponsiveBaseComponent {
  public appointment = {} as AppointmentType;
  public dateFormat: string;
  public timeFormat: string;
  public comments: any[] = [];
  public commentContent = '';
  private _cls = 'patient_appointment';
  public doc_id = null;
  constructor(
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    private _commentService: CommentService,
    private _activatedRoute: ActivatedRoute,
    private _patientAppointmentService: PatientAppointmentService,
    private _patientRequestService: PatientRequestService,
    private _dialog: MatDialog,
    private _app_configService: AppConfigsService,
    private _translateService: TranslateService,
    private _router: Router
  ) {
    super(breakpointObserver, titleService, translateService);
  }

  ngOnInit(): void {
    this.title = 'patient_appointment.appointment';
    this.dateFormat = this._app_configService.dateFormat;
    this.timeFormat = this._app_configService.timeFormat;
    super.ngOnInit();

    this.subs.add(
      this._activatedRoute.params.subscribe({
        next: (params) => {
          this.subs.add(
            this._patientAppointmentService
              .fetch({ id: params['id'] })
              .subscribe({
                next: (item: AppointmentType): void => {
                  this.doc_id = params['id'];
                  this.appointment = item;
                  this.loadComments();
                  this.title = this.appointment.ticket?.ticket_content_histories?.at(-1)?.reason;
                  this._patientAppointmentService
                    .ChangeStatusToReceived([item])
                    .subscribe({
                      next: (done: boolean) => {
                        if (!this.appointment['appointment_read'])
                          this.subs.add(
                            this._patientAppointmentService
                              .update({
                                id: params['id'],
                                dataAPIAction: 'read',
                              })
                              .subscribe({
                                next: (item: AppointmentType) => {
                                  this.appointment = item;
                                },
                              })
                          );
                      },
                    });
                },
                error: (error) => {
                  this._router.navigate(['/patient-appointment/list']);
                }
              })
          );
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public fromNow(dateVar: any) {
    return fromNow(dateVar);
  }

  public getCommentById(id: string) {
    return this.comments.find((comment) => comment.id === id);
  }

  public getFirstLetter(name: string) {
    return name?.charAt(0) || 'A';
  }

  public loadComments() {
    this.subs.add(
      this._commentService
        .fetchAll(null, `doc_model=${this._cls}&doc_id=${this.doc_id}`)
        .subscribe({
          next: (comments: CommentType[]) => {
            this.comments = comments;
          },
        })
    );
  }

  public replyToComment(commentId: string) {
    const dialogRef = this._dialog.open(ComposeDialogComponent, {
      data: { question: 'shared.write_reply', objId: commentId },
    });
    this.subs.add(
      dialogRef.afterClosed().subscribe((data) => {
        if (data?.content) {
          let comment = this._prepareComment(data.content, data.objId);
          this.saveComment(comment).subscribe((data) => {
            this.loadComments();
          });
        }
      })
    );
  }

  private _prepareComment(content, commentId = null) {
    const conditionalProperties = commentId
  ? { reply_to: commentId}
  : {  };
    return assign(
      {},
      {
        title: `${this._translateService.instant(
          'comment_note'
        )} ${DateTime.now().toFormat('yyyy-MM-dd')} ${DateTime.now().toFormat(
          'HH:mm'
        )}`,
        comment_date: DateTime.now().toFormat('yyyy-MM-dd'),
        comment_time: DateTime.now().toFormat('HH:mm'),
        doc_model: this._cls,
        doc_id: this.doc_id,
        content: cloneDeep(content),
      },
      conditionalProperties
    );
  }

  public addNewComment(event) {
    event.stopPropagation();
    if (!isEmpty(this.commentContent)) {
      let comment = this._prepareComment(this.commentContent);
      // this.sharedService.saveDocumentComment(c).subscribe(data => {
      //     this.loadComments();
      // });
      this.saveComment(comment).subscribe((data) => {
        this.loadComments();
      });
      this.commentContent = null;
    }
  }

  public saveComment(comment) {
    if (has(comment, 'id')) {
      // return this._httpClient.put(`api/pl/comment/${get(c, 'id')}/`, c);
      return this._commentService.update(comment);
    } else {
      return this._commentService.create(comment);
    }
  }

  public deleteComment(commentId: number) {
    // return this._httpClient.delete(`api/pl/comment/${commentId}/`);
    // return this._commentService.deleteById(commentId);
    this.subs.add(
      this._commentService.deleteById(commentId).subscribe({
        next: () => {
          this.loadComments();
        },
      })
    );
  }

  public appointmentStatus() {
    return find(appointmentStatuses, {
      name: this.appointment.status_histories?.at(-1).status,
    });
  }

  public isAppointmentActionStatus() {
    const statusList: string[] = [
      'RESCHEDULE_REQUESTED',
      'CANCEL_REQUESTED',
      'CONFIRMED',
    ];
    return this.appointment.status_histories?.at(-1).status
      ? !statusList.includes(this.appointmentStatus()?.name)
      : false;
  }

  public confirmAppointment() {
    this.subs.add(
      this._patientAppointmentService
        .update({ id: this.appointment.id, dataAPIAction: 'confirm' })
        .subscribe({
          next: (appointment: AppointmentType) => {
            this.appointment = appointment;
          },
        })
    );
  }

  public requestRescheduling() {
    const dialogRef = this._dialog.open(ComposeDialogComponent, {
      data: { question: 'patient_appointment.request_rescheduling' },
    });
    this.subs.add(
      dialogRef.afterClosed().subscribe((data) => {

        if (data?.content) {
          this.subs.add(
            this._patientAppointmentService
              // .requestRescheduling(this.appointment.id)
              .update({ id: this.appointment.id, dataAPIAction: 'reschedule' })
              .subscribe({
                next: (appointment: AppointmentType) => {
                  this.appointment = appointment;
                },
              })
          );
          this.subs.add(
            this._patientRequestService
              // .requestRescheduling(this.appointment.id)
              .create({
                content: data?.content,
                type: 'RESCHEDULE_REQUESTED',
                appointment: { id: this.appointment.id },
              })
              .subscribe({
                next: (patientRequest: PatientRequestType) => {},
              })
          );
        }
      })
    );
  }

  public requestCancellation() {
    const dialogRef = this._dialog.open(ComposeDialogComponent, {
      data: { question: 'patient_appointment.request_cancellation' },
    });
    this.subs.add(
      dialogRef.afterClosed().subscribe((data) => {
        if (data?.content) {
          this.subs.add(
            this._patientAppointmentService
              // .requestRescheduling(this.appointment.id)
              .update({ id: this.appointment.id, dataAPIAction: 'cancel' })
              .subscribe({
                next: (appointment: AppointmentType) => {
                  this.appointment = appointment;
                },
              })
          );
          this.subs.add(
            this._patientRequestService
              // .requestRescheduling(this.appointment.id)
              .create({
                content: data?.content,
                type: 'CANCEL_REQUESTED',
                appointment: { id: this.appointment.id },
              })
              .subscribe({
                next: (patientRequest: PatientRequestType) => {},
              })
          );
        }
      })
    );
  }

  public confirmAction() {
    this.subs.add(
      this._dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'patient_appointment.confirm_appointment_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) this.confirmAppointment();
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }
}
