import { Subject } from 'rxjs';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Component, Inject, Injectable } from '@angular/core';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UIService {
  public config: any;
  private _loadingStateChange$: Subject<boolean> = new Subject<boolean>();

  constructor(private _snackBar: MatSnackBar, private _translateService: TranslateService) {}

  public setLoadingState$(isLoadingState: boolean) {
    this._loadingStateChange$.next(isLoadingState);
  }

  public getLoadingState$() {
    return this._loadingStateChange$;
  }

  public showSnackBar(
    message: string,
    action: string | undefined,
    duration: number
  ) {
    this._snackBar.open(message, action, {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['custom-snackbar'],
    });
  }

  public openSnackBar(message: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.data = {
      preClose: () => {
        this._snackBar.dismiss();
      },
      message: this._translateService.instant(message),
    }
    config.panelClass = ['custom-snackbar'];
    const snackBar = this._snackBar.openFromComponent(SnackBarComponent, config);
  }

  public log(error: Error) {
    if (!environment.production) console.error(error);
    else this.openSnackBar(error.message);
  }
}
