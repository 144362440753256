import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TfuPlanService } from '../../../services/tfu-plan.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  TFUModelType,
  TelephoneFollowUpItemType,
  TelephoneFollowUpPlanType,
} from '../../../types/telephone-follow-up.type';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { TfuItemComponent } from '../tfu-item/tfu-item.component';
import { assign } from 'lodash';

@Component({
  selector: 'pr-tfu-base',
  templateUrl: './tfu-base.component.html',
  styleUrls: ['./tfu-base.component.scss'],
})
export class TfuBaseComponent implements OnInit, OnDestroy {
  public isModel = false;
  public reactiveForm!: FormGroup;
  public tfuObj: TelephoneFollowUpPlanType = null;
  public subs = new Subscription();
  public tempSub = new Subscription();
  constructor(
    public route: ActivatedRoute,
    public tfuService: TfuPlanService,
    public fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.reactiveForm = this.fb.group({
      id: [''],
      title: [''],
    });
  }

  ngOnDestroy(): void {
    this.tempSub?.unsubscribe();
    this.subs?.unsubscribe();
  }

  ngOnInit(): void {

  }

  public onSubmit() {}

  public addTfuItem() {
    this.addTfuItemByDialog(null);
  }

  public editTfuItem(reminderOid: number) {
    this.addTfuItemByDialog(reminderOid);
  }

  public updateTfuPlanControls() {
    this.reactiveForm.get('title')?.setValue(this.tfuObj.title ?? '');
  }

  public addTfuItemByDialog(tfuItemOid: number) {

    let dialogRef: any;
    if (tfuItemOid) {
      dialogRef = this.dialog.open(TfuItemComponent, {
        panelClass: 'big-dialog',
        data: {
          tfu_item: this.tfuObj.items?.find(
            (tfuItem) => tfuItem.oid === tfuItemOid
          ),
          is_model: this.isModel 
        },
      });
    } else {
      dialogRef = this.dialog.open(TfuItemComponent, {
        panelClass: 'big-dialog',
        data: { tfu_item: null, is_model: this.isModel },
      });
    }

    this.tempSub?.unsubscribe();
    this.tempSub = dialogRef.afterClosed().subscribe((data) => {

      if (data) {
        let newTfuItems = [...this.tfuObj.items];
        
        if (data.oid) {
          let foundTfuItem = newTfuItems.find(
            (tfuItem) => tfuItem.oid === data.oid
          );
          if (foundTfuItem) {
            if(this.isModel) {
              data.is_model = true;
            }
            newTfuItems = newTfuItems.map((obj) =>
              data.oid === obj.oid ? data : obj
            );
          }
        } else {
          newTfuItems.push(data);
        }
        
        this._checkTfuPlanItemStatus(newTfuItems);
        this.updateTfuPlanBackend(newTfuItems);
      }
    });
  }

  public updateTfuPlanBackend(newTfuItems: TelephoneFollowUpItemType[]) {
    this.tempSub?.unsubscribe();
    let withStatusObj = (obj) => {
      if (this.tfuObj.status) {
        return {
          status: this.tfuObj.status,
        };
      } else {
        return {};
      }
    };
    let updateObj = assign(
      {},
      {},
      {
        id: this.tfuObj.id,
        title: this.tfuObj.title,
        status: this.tfuObj.status,
        items: newTfuItems,
      },
      withStatusObj(this.tfuObj)
    );
    this.tempSub = this.tfuService.update(updateObj).subscribe({
      next: (tfuPlan) => {
        this.tfuObj = tfuPlan;
        
        

        this.updateTfuPlanControls();
      },
    });
  }

  public removeTfuItem(tfuItemOid: number) {
    this.subs.add(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'settings.delete_follow_up_step_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) {
              let newTfuItems = this.tfuObj.items.filter(
                (obj) => obj.oid !== tfuItemOid
              );
              
              
              this._checkTfuPlanItemStatus(newTfuItems);
              this.updateTfuPlanBackend(newTfuItems);
            }
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }

  public checkTfuItem(tfuItem: TelephoneFollowUpItemType) {
    this.subs.add(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'settings.complete_follow_up_step_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) {
              tfuItem.done = true;
              this._checkTfuPlanItemStatus(this.tfuObj.items);
              this.updateTfuPlanBackend(this.tfuObj.items);
            }
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }

  private _checkTfuPlanItemStatus(tfuItems: TelephoneFollowUpItemType[]) {
    // Check if all is_done properties are true
    const allDone = tfuItems.every((item) => item.done);

    // If allDone is true, set the status to "done"
    if (allDone) {
      this.tfuObj.status = 'DONE';
    } else {
      // If allDone is false, set the status to "in_progress"
      this.tfuObj.status = 'IN_PROGRESS';
    }
  }
}
