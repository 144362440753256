import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PatientRequestContainerComponent } from './components/patient-request-container/patient-request-container.component';
import { PatientRequestListComponent } from './components/patient-request-list/patient-request-list.component';
import { PatientRequestRoutingModule } from './patient-request-routing.module';
import {FtCoreModule, MaterialModule} from '@ft/core';
import { FormsModule } from '@angular/forms';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgBusyModule} from "ng-busy";

@NgModule({
  declarations: [
    PatientRequestContainerComponent,
    PatientRequestListComponent,
  ],
    imports: [
        FormsModule,
        MaterialModule,
        SharedModule,
        PatientRequestRoutingModule,
        InfiniteScrollModule,
       NgBusyModule,
          FtCoreModule
    ],
})
export class PatientRequestModule {}
