import {AfterViewInit, Directive, Input, NgZone, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {NgControl} from '@angular/forms';
// import {NumberDirective} from './number.directive';
import {MatInput} from '@angular/material/input';

import {isNil, isNaN} from 'lodash';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {TranslateService} from '@ngx-translate/core';
import {NumberDirective} from '@ft/core';

@Directive({
  selector: 'input[ftAgHandler][ftNumber]'
})
export class AgHandlerDirective implements AfterViewInit, OnInit {
  private _isMonth: boolean;
  private _dateControl: NgControl;
  private _ageLabel: HTMLElement;
  private readonly _monthLabel: string;
  private readonly _yearsLabel: string;

  @Input('ftAgHandler')
  public set handleDateControl(control: NgControl) {
    this._dateControl = control;
    this._dateControl.valueChanges.subscribe(date => this._birthDateChanged(date));
  }

  @Input('ftAgLabel')
  public set handleLabelControl(control: HTMLElement) {
    this._ageLabel = control;
  }

  constructor(
    private _adapter: MomentDateAdapter,
    _translateService: TranslateService,
    @Optional() @Self() private _matInput: MatInput,
    @Optional() @Self() private _ngControl: NgControl,
    @Optional() @Self() private _numberControl: NumberDirective, private _zone: NgZone) {
    this._monthLabel = _translateService.instant('core.controls.months');
    this._yearsLabel = _translateService.instant('shared.controls.years');
  }

  public ngAfterViewInit() {
    if (this._numberControl) {
      this._numberControl.min = 0;
      this._numberControl.toFixed = 0;
    }
  }

  public ngOnInit() {
    if (this._ngControl) {
      this._ngControl.valueChanges.subscribe(age => this._ageChanged(age));
    }
  }

  // private API
  private _birthDateChanged(date) {
    let age = isNil(date) ? null : this._adapter.today().diff(date, 'years');

    if (age === 0) {
      this._isMonth = true;
      age = this._adapter.today().diff(date, 'months');
    } else {
      this._isMonth = false;
    }

    if (this._ngControl) {
      this._ngControl.valueAccessor.writeValue(age);
    }
    if (this._matInput) {
      this._handlePlaceholder();
    }
  }

  private _ageChanged(value) {
    if (isNil(value) || isNaN(value)) {
      return;
    }

    // this._isMonth = false;
    let birthDate;
    if (this._isMonth) {
      birthDate = this._adapter.today().subtract(value, 'months').date(1);
    } else {
      birthDate = this._adapter.today().subtract(value, 'years').date(1).month(0);
    }

    if (this._dateControl) {
      this._dateControl.reset(birthDate);
    }
    if (this._matInput) {
      this._handlePlaceholder();
    }
  }

  private _handlePlaceholder() {
    const placeholder = this._matInput.placeholder;

    if (this._isMonth && !placeholder.includes(this._monthLabel)) {
      this._matInput.placeholder = `${placeholder.replace(this._yearsLabel, '').replace(this._monthLabel, '')}${this._monthLabel}`;
    }
    if (!this._isMonth) {
       this._matInput.placeholder = `${placeholder.replace(this._yearsLabel, '').replace(this._monthLabel, '')}${this._yearsLabel}`;
    }
    if (this._ageLabel && this._matInput.value) { this._ageLabel.innerHTML = this._matInput.placeholder; }

  }
}
