<!-- <app-toolbar></app-toolbar> -->
<div class="satisfaction-form-outer-container">
  <div [ngBusy]="subscription"></div>
  <div class="satisfaction-form-container" [ngStyle]=" readOnly ? {'pointer-events': 'none'} :{}">
    <ng-container *ngIf="satisfactionForm  && !showMissedTicketWarning && !showExistWarning && !showSaveSuccess">
      <pr-prescription-template [(template)]="satisfactionForm.template"></pr-prescription-template>
      <ng-container *ngIf="!readOnly">
        <p>{{'appointment.satisfaction_form_rating'|translate}}</p>
        <!--        <mat-form-field fxFlex="15">-->
        <!--          <input matInput name="rating" [ftNumber]="'float'"-->
        <!--                 placeholder="0" max="100" min="0"-->
        <!--                 [(ngModel)]="satisfactionForm.rating"/>-->
        <!--        </mat-form-field>-->
        <div class="star-rating">
          <span *ngFor="let star of stars; let i = index"
                (click)="rate(i + 1)"
                (mouseover)="hover(i + 1)"
                (mouseleave)="resetHover()"
                class="star"
                [class.filled]="i < rating"
                [class.hovered]="i < hoverState">
            &#9733; <!-- Unicode for a filled star -->
          </span>
        </div>


        <button class="ml-4"
                type="button" mat-flat-button
                color="primary"
                (click)="save($event)">
          {{"shared.save"|translate}}
        </button>
      </ng-container>

    </ng-container>

    <ng-container *ngIf="showExistWarning">
      <div
        class="h-screen flex items-center justify-center font-bold"> {{'appointment.exist_satisfaction_form_warning' | translate}} </div>
    </ng-container>
    <ng-container *ngIf="showSaveSuccess">
      <div
        class="h-screen flex items-center justify-center font-bold"> {{'appointment.save_satisfaction_form_success' | translate}} </div>
    </ng-container>
    <ng-container *ngIf="showMissedTicketWarning">
      <div
        class="h-screen flex items-center justify-center font-bold"> {{'appointment.form_missed_ticket_warning' | translate}} </div>
    </ng-container>

  </div>
</div>






