import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {assignIn} from 'lodash';
import * as moment from 'moment';
import {AppConfigsService, AuthService} from '@ft/core';

@Component({
    selector: 'app-closure',
    templateUrl: './closure.component.html',
    styleUrls: ['./closure.component.scss']
})
export class ClosureDialogComponent implements OnInit {
    subscription: Subscription;

    model = {close_date: moment().format('YYYY-MM-DD'), reason: null};

    constructor(public dialogRef: MatDialogRef<ClosureDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any, public appConfigService: AppConfigsService, public authService: AuthService) {
    }

    ngOnInit(): void {
    }

    handleSave() {
        this.dialogRef.close(assignIn(this.model, {closed: true},
            !this.authService.isRoot() ? {closed_by: this.authService.getStaff()} : {}));
    }
}
