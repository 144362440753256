<form #modelForm="ngForm"
          fxLayout="column"
          fxFlex
          class="ft-padding"
    >
        <ng-container *ngIf="model?.blocks">
            <div *ngFor="let b of model.blocks; trackBy: trackByFn; let blockIdx = index">
                <pr-prescription-block *ngIf="showBlock(b)" [block]="b" (blockChange)="handleChange(b, $event)"
                                       (blockValid)="handleBlockValid(b.name, $event)"
                                       (fieldFileChange)="handleFieldFile($event,blockIdx)"
                                       (fieldFileDelete)="handleFieldFileDelete($event,blockIdx)"
                                       (fieldFilePreview)="previewFile($event)"
                                       [template]="model"></pr-prescription-block>
            </div>
        </ng-container>
    </form>
