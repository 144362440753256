import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgBusyModule} from 'ng-busy';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {SharedRoutingModule} from './shared-routing.module';
import {ProgressDialogComponent} from './dialogs/progress/progress.dialog';
import {DataApiService} from './services/data-api.service';
import {SearchDialogComponent} from './dialogs/search-dialog/search-dialog.component';
import {CommentsComponent} from './components/comments/comments.component';
import {CommentsDialogComponent} from './dialogs/comments-dialog/comments-dialog.component';
import {SmsService} from './services/sms.service';
import {SendSmsDialogComponent} from './dialogs/send-sms/send-sms-dialog.component';
import {FtTableModule} from '@ft/table';
import {FtCoreModule, MaterialModule} from '@ft/core';
import { PrGenericViewTableComponent } from './components/pr-generic-view-table/pr-generic-view-table.component';
import { PrBasicGenericViewTableComponent } from './components/pr-basic-generic-view-table/pr-basic-generic-view-table.component';
import { CardsViewComponent } from './components/cards-view/cards-view.component';
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { LoginComponent } from './components/login/login.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';

import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { LuxonModule } from 'luxon-angular';
import { ComposeDialogComponent } from './dialogs/compose-dialog/compose-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { SearchComponent } from './components/search/search.component';
import { ReminderComponent } from './dialogs/reminder/reminder.component';
import { NotificationComponent } from './dialogs/notification/notification.component';
import { TfuItemComponent } from '../settings/components/tfu/tfu-item/tfu-item.component';
import { TfuPlanComponent } from '../settings/components/tfu/tfu-plan/tfu-plan.component';
import { ResponsiveBaseComponent } from './components/responsive-base/responsive-base.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';



@NgModule({
    declarations: [
        ToolbarComponent,
        ProgressDialogComponent,
        SearchDialogComponent,
        CommentsComponent,
        CommentsDialogComponent,
        SendSmsDialogComponent,
        PrGenericViewTableComponent,
        PrBasicGenericViewTableComponent,
        CardsViewComponent,
        LoginComponent,
        SelectSearchComponent,
        CalendarHeaderComponent,
        SearchComponent,
        ComposeDialogComponent,
        ConfirmDialogComponent,
        ReminderComponent,
        NotificationComponent,
        TfuItemComponent,
        TfuPlanComponent,
        ResponsiveBaseComponent,
        SnackBarComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        NgBusyModule,
        FtTableModule,
        SharedRoutingModule,
        FtCoreModule,
        InfiniteScrollModule,
        LuxonModule,
    ],
    exports: [
        TranslateModule,
        ToolbarComponent,
        ProgressDialogComponent,
        SearchDialogComponent,
        CommentsComponent,
        SendSmsDialogComponent,
        PrGenericViewTableComponent,
        CardsViewComponent,
        LoginComponent,
        SelectSearchComponent,
        CalendarHeaderComponent,
        SearchComponent,
        LuxonModule,
        ReminderComponent,
        NotificationComponent,
        TfuItemComponent,
        TfuPlanComponent,
        FormsModule
    ],
    providers: [
        DataApiService,
        SmsService
    ]
})
export class SharedModule {
}
