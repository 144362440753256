import { AfterContentInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({selector: '[pr-after-if]'})
export class AfterIfDirective implements AfterContentInit {
    @Output('pr-after-if')
    public after: EventEmitter<void> = new EventEmitter<void>();

    public ngAfterContentInit(): void {
       // timeout helps prevent unexpected change errors
       setTimeout(()=> this.after.next(),0);
    //    Promise.resolve().then(() => {this.after.next()})
    }
}
