import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Component({
    selector: 'pr-progress',
    template: `
        <div mat-dialog-content fxLayout="column">
            <mat-progress-bar [mode]="progress ? 'determinate' : 'buffer'" [value]="progress"></mat-progress-bar>
        </div>
    `,
    styles: []
})
export class ProgressDialogComponent implements OnInit {
    public progress: number = null;
    private _observable: Observable<any>;

    constructor(
        private _dialogRef: MatDialogRef<ProgressDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._observable = data.observable;
    }

    ngOnInit() {
        this._observable
            .subscribe({
                next: event => this.progress = Math.round(100 * event.loaded / event.total),
                complete: () => this._dialogRef.close()
            });
    }

}
