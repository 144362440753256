<div class="pr-content-container">
  <div class="pr-content-header" *ngIf="!isMobile">
    <div class="list-title text-h4 font-bold">
      {{ "patient_prescription.benefits" | translate }}
    </div>
  </div>
  <div class="pr-content-body" infiniteScroll #innerTable
       [infiniteScrollDistance]="5"
       [infiniteScrollThrottle]="100"
       (scrolled)="onScroll()"
       [fromRoot]="false"
       [scrollWindow]="false">
    <pr-search (searchChange)="handleSearch($event)"></pr-search>

    <div class="cards-container" *ngIf="isMobile">
      <div class="cards-inner-container flex flex-col gap-4">
        <ng-container *ngFor="let patientPrescription of patientPrescriptions">
          <div
            class="pr-card flex flex-col gap-4 cursor-pointer"
          >
            <div class="flex gap-4 flex-wrap items-center"
                 [routerLink]="'/patient-prescription/item/'+patientPrescription.id">
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.date" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription.date || "---" }}
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.type" | translate }}
                </div>
                <div class="card-item-content">
                  <div class="fancy-name">
                    {{ patientPrescription.content?.name || "---" }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.starting_treatment" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription.start_date || "---" }}
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.ending_treatment" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription.end_date || "---" }}
                </div>
              </div>
              <div class="flex flex-col gap-1">
                <div class="card-item-title">
                  {{ "patient_prescription.attending_physician" | translate }}
                </div>
                <div class="card-item-content">
                  {{ patientPrescription.physician?.full_name || "---" }}
                </div>
              </div>
              <div
                class="tag ml-auto"
                 [ngClass]="(patientPrescription.status && patientPrescription.status=='closed') ? 'tag-done' : 'tag-not-done'"
              [ngStyle]="handleStatusColor(patientPrescription)"
            >
              {{
              patientPrescription.status
                ? (("shared." + patientPrescription.status)| translate)
                : ""

              }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div
      class="table-header grid grid-flow-row-dense grid-cols-12 gap-x-4 px-4"
      *ngIf="!isMobile"
    >
      <div
        class="table-header-cell col-span-2"
        *ngFor="let listItem of listItems"
      >
        <div class="ellipsis">
          {{ "patient_prescription." + listItem.translationKey | translate }}
        </div>
      </div>
      <div class="table-header-cell col-span-2">
        <div class="ellipsis">
          {{ "patient_prescription.status" | translate }}
        </div>
      </div>
    </div>
    <div class="table-body flex gap-4 flex-col" *ngIf="!isMobile">
      <div
        class="table-b-row min-h-20 grid grid-flow-row-dense grid-cols-12 gap-y-1 p-4 gap-x-4 cursor-pointer"
        [routerLink]="'/patient-prescription/item/'+patientPrescription.id"
        *ngFor="let patientPrescription of patientPrescriptions"
      >
        <div class="table-b-cell col-span-2 flex items-center">
          <div>
            {{ patientPrescription.date || "---" }}
          </div>
        </div>
        <div class="col-span-2 flex items-center">
          <div class="fancy-name" [matTooltip]="patientPrescription.content.name">
            {{ patientPrescription.content.name }}
          </div>
        </div>
        <div class="col-span-2 flex items-center">
          <div>{{ patientPrescription.start_date || "---" }}</div>
        </div>
        <div class="col-span-2 flex items-center">
          <div>{{ patientPrescription.end_date || "---" }}</div>
        </div>
        <div class="col-span-2 flex items-center">
          <div>{{ patientPrescription.physician?.full_name || "---" }}</div>
        </div>
        <div class="col-span-2 flex items-center">
          <div>
            <div
              class="tag tag-done sm:rounded-md sm:whitespace-normal lg:whitespace-nowrap lg:rounded-full"
              [ngClass]="(patientPrescription.status && patientPrescription.status=='closed') ? 'tag-done' : 'tag-not-done'"
              [ngStyle]="handleStatusColor(patientPrescription)"
            >
              {{
              patientPrescription.status
                ? (("shared." + patientPrescription.status)| translate)
                : ""

              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
