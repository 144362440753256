import { Observable, of } from 'rxjs';
import { PatientPrescriptionType } from '../../patient-prescription/patient-prescription.type';

export let patientPrescriptionSample = (): Observable<
  any[]
> => {
  return of([
    {
      id: 1,
      date: '2021-03-31',
      start_date: '2021-04-01',
      end_date: '2021-04-02',
      type: 'PSG_PG',
      physician: 'Sabri Yassir',
      is_treated: false,
    },
    {
      id: 2,
      date: '2021-03-31',
      start_date: '2021-04-01',
      end_date: '2021-04-02',
      type: 'VINI',
      physician: 'Sabri Yassir',
      is_treated: true
    },
    {
      id: 10,
      date: '2022-01-15',
      start_date: '2022-01-16',
      end_date: '2022-01-17',
      type: 'VINI',
      physician: 'Dr. Smith',
      is_treated: true
    },
    {
      id: 11,
      date: '2022-02-20',
      start_date: '2022-02-21',
      end_date: '2022-02-22',
      type: 'VINI',
      physician: 'Dr. Johnson',
      is_treated: false
    },
    {
      id: 12,
      date: '2022-03-25',
      start_date: '2022-03-26',
      end_date: '2022-03-27',
      type: 'VINI',
      physician: 'Dr. Anderson',
      is_treated: true
    },
    {
      id: 4,
      date: '2022-04-10',
      start_date: '2022-04-11',
      end_date: '2022-04-12',
      type: 'VINI',
      physician: 'Dr. Davis',
      is_treated: true
    },
    {
      id: 5,
      date: '2022-05-05',
      start_date: '2022-05-06',
      end_date: '2022-05-07',
      type: 'VINI',
      physician: 'Dr. Taylor',
      is_treated: false
    },
    {
      id: 6,
      date: '2022-06-15',
      start_date: '2022-06-16',
      end_date: '2022-06-17',
      type: 'VINI',
      physician: 'Dr. Martinez',
      is_treated: true
    },
    {
      id: 7,
      date: '2022-07-20',
      start_date: '2022-07-21',
      end_date: '2022-07-22',
      type: 'VINI',
      physician: 'Dr. Garcia',
      is_treated: false
    }
  ]);
};
