import {PATIENT_COLUMNS} from './patient-columns';
import {SYSTEM_COLUMNS} from '../../shared/utils/common-system-column';

export const PATIENTS_VIEW = {
    trackByKey: 'id',
    hasSearch: true,
    selectable: false,
    hasPagination: true,
    viewNamespace: 'patientList',
    query: [],
    viewSettings: {
        'model': 'patient.Patient',
        'viewColumns': PATIENT_COLUMNS,
        'systemColumns': SYSTEM_COLUMNS,
        'defaultName': 'Liste des patients'
    },
    actions: [],
    resource: 'patient',
    formRoute: '/patient/form-edit'
};
