export let appointmentEn: Record<string, any> = {
  tickets_list: 'List of tickets',
  calendar: {
    today: "Today",
    day: "Day",
    week: "Week",
    month: "Month",
    four_days: "4 Days",
    list: "List",
    months_of_year: {
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
    },
  },
  map: "Map",
  following_interventions: "following interventions",
  no_appointments_to_display: "Unfortunately, there are no appointments available to display.",
  no_map_locations_available: "Unfortunately, there are no map locations available for display.",
  details: "Details",
  complete: "Complete",
  cancel_the_intervention: "Cancel the intervention",
  satisfaction_form: "Satisfaction form",
  action: {
    cancel: "Cancel",
    complete: "Complete",
    undo: "Undo",
    pause: "Pause",
    resume: "Resume",
    start: "Start",
    reject: "Reject",
    accept: "Accept",
  },
  passage_proof: {
    delete: "Delete",
    preview: "Preview",
    proof_of_passage: "Proof of passage",
    upload_images: "Upload images",
    comment: "Comment",
    no_images_to_display: "No images to display",
  },

  exist_satisfaction_form_warning: 'The satisfaction form has already been filled in',
  save_satisfaction_form_success: 'Your review has been successfully registered',
  form_missed_ticket_warning: 'No satisfaction form available, please contact support'

};
