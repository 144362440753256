<div fxLayout="column"  class="comments-container">
  <!--  <form fxFlex="80%" name="comment-form" #commentForm="ngForm" fxLayout="column" class="ft-padding">-->
  <div fxLayout="column" class="ft-content comments-list" [ngBusy]="promise">
    <ng-container *ngFor="let c of groupedComments; let idxGp=index; ">
      <div class="date-separator">
        <hr class="separator-hr">
        <div class="separator-text">
          <span>  {{formatDate(c.comment_date)}}</span>
        </div>
      </div>

      <ng-container        *ngFor="let d of c.details; let idx= index; ">
        <div id="anc_{{idxGp}}_{{idx}}" fxFlex fxLayout="column" class="details">
          <div fxLayout="row">
            <mat-icon fontSet="mdi" fontIcon="mdi-comment-text"></mat-icon>
            <span fxFlex class="time">{{d.comment_time}}</span>
          </div>
          <div fxFlex fxLayout="column" class="content">
            <ng-container *ngFor="let s of d.details; let idxD= index; ">
              <div id="anc_{{idxGp}}_{{idx}}_{{idxD}}" fxLayout="row" class="details content-item">
                <div fxFlex fxLayoutAlign="center center">
                  <span fxFlex="">{{s.content}}</span>
                </div>
                <div class="flex-nogrow opts">
                  <ng-template [ftAcl]="{resource: 'comments', action: 'update'}" >
                    <button color="warn" mat-icon-button (click)="removeComment(s)">
                      <mat-icon fontSet="mdi" [fontIcon]="'mdi-close'"></mat-icon>
                    </button>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <!--  </form>-->
  <div class="comment-text" fxLayoutAlign="end end">
    <mat-form-field fxFlex>
      <input [ftAutoFocus]="docId ? true :false" matInput [placeholder]="'shared.write_comment' | translate"
             name="username"
             [(ngModel)]="content" autocomplete="off" (keypress)="$event.keyCode == 13 ? newCommentClick($event) :null">
      <button matSuffix mat-icon-button type="button" color="primary" [disabled]="!content"
              (click)="newCommentClick($event)" tabindex="-1">
        <mat-icon fontSet="mdi" [fontIcon]="'mdi-send'"></mat-icon>
      </button>
    </mat-form-field>
  </div>

</div>



