import { Injectable } from '@angular/core';
import { DataApiService } from '../../shared/services/data-api.service';
import { HttpClient } from '@angular/common/http';
import { TelephoneFollowUpPlanType } from '../types/telephone-follow-up.type';
import { UIService } from '../../shared/services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class TfuPlanService extends DataApiService<TelephoneFollowUpPlanType> {
  constructor(public httpClient: HttpClient, public uiService: UIService) {
    super(httpClient, uiService);
    this.setRootUrl('/api/pl/telephone-follow-up-plan');
  }
}
