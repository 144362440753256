<mat-toolbar *ngIf="!isMobile">
    <ol class="pr-breadcrumb">
        <li routerLink="/" class="hidden md:flex">
            <button type="button" mat-icon-button>
                <mat-icon fontSet="mdi" fontIcon="mdi-home"></mat-icon>
            </button>
        </li>
        <li routerLink="/patient/list" class="hidden md:flex">
            {{'patient.patient_list'|translate}}
        </li>
        <li>{{'patient.patient_file'|translate}}</li>
    </ol>

    <div class="ft-actions">
        <button
                class="ml-2"
                type="button"
                mat-mini-fab
                color="primary"
                (click)="handleEdit()"
        >
            <mat-icon fontSet="mdi" fontIcon="mdi-account-edit"></mat-icon>
        </button>
        <button
                class="ml-2"
                type="button"
                mat-mini-fab
                color="primary"
                [matMenuTriggerFor]="menu"
        >
            <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
        </button>

    </div>
</mat-toolbar>
<div class="h-full sm:border-t sm:border-b sm:overflow-auto sm:mb-4">
    <div class="flex min-h-full">
        <div
                class="patient-info flex flex-col items-center gap-8 relative"
                [ngClass]="{ 'patient-info-for-mobile': !isDesktop }"
        >
            <button *ngIf="isMobile" mat-mini-fab class="absolute top-2.5 right-4" color="primary" [matMenuTriggerFor]="menu">
                <mat-icon fontSet="mdi" fontIcon="mdi-dots-vertical"></mat-icon>
            </button>
            <div class="img-profile mt-8 flex flex-col items-center gap-2">
                <img
                        _ngcontent-oms-c483=""
                        [src]="patient?.picture? patient?.picture :'/assets/icons/default-avatar.svg'"
                        alt="avatar"
                        class="rounded-full"
                />
                <h2>{{patient.full_name}}</h2>
                <div *ngIf="!patient?.death_date" class="active">{{'patient.actif'|translate}}</div>
                <div *ngIf="patient?.death_date" class="idle">{{'patient.idle'|translate}}</div>
            </div>

            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ 'shared.general_infos' | translate }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="attributes-container">
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon
                                        fontSet="mdi"
                                        fontIcon="mdi-gender-male-female"
                                ></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{'patient.gender'|translate}}</div>
                                <div class="attribute-value">{{ patient?.gender ? ("patient." + patient?.gender|translate) : '#'}}</div>
                            </div>
                        </div>
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon
                                        fontSet="mdi"
                                        fontIcon="mdi-card-account-details"
                                ></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{'patient.national_id'|translate}}</div>
                                <div class="attribute-value">
                                    {{ patient?.national_id || '###'}}
                                </div>
                            </div>
                        </div>
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon fontSet="mdi" fontIcon="mdi-doctor"></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{ "settings.physician" | translate }}</div>
                                <div class="attribute-value">{{patient?.physician ? ('Dr. ' + patient?.physician?.full_name) : '###'}}</div>
                            </div>
                        </div>
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon
                                        fontSet="mdi"
                                        fontIcon="mdi-calendar-range"
                                ></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{ "patient.birth_date" | translate }}</div>
                                <div class="attribute-value">
                                    {{ patient.birth_date || '###' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="contact-info">
                <mat-card-header class="flex justify-center items-center">
                    <mat-card-title>{{ 'patient.contact_infos' | translate }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="attributes-container">
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon fontSet="mdi" fontIcon="mdi-at"></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{'patient.email'|translate}}</div>
                                <div class="attribute-value">{{ patient.email || '###' }}</div>
                            </div>
                        </div>
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon fontSet="mdi" fontIcon="mdi-phone"></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{ 'patient.phone_number' | translate }}</div>
                                <div class="attribute-value">{{ patient.phone_number || '###' }}</div>
                            </div>
                        </div>
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon fontSet="mdi" fontIcon="mdi-city-variant"></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{ "shared.city" | translate }}</div>
                                <div class="attribute-value">{{ patient?.city ? patient?.city?.short_name : '###' }}</div>
                            </div>
                        </div>
                        <div class="attribute-item">
                            <div class="attribute-icon">
                                <mat-icon fontSet="mdi" fontIcon="mdi-map-marker"></mat-icon>
                            </div>
                            <div>
                                <div class="attribute-key">{{ "shared.address" | translate }}</div>
                                <div class="attribute-value">
                                    {{patient.address || '###'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div
                    class="toolbar-spacer-for-mobile"
                    *ngIf="isMobile"
            ></div>
        </div>
        <div class="grow patient-prescription border-l" *ngIf="isDesktop">
            <section class="pr-section">
                <h1>{{'shared.prescriptions'|translate}}</h1>
                <div class="ks-container flex justify-center items-center">
                    <div class="ks-nav-btn">
                        <button mat-icon-button (click)="slider.prev()" *ngIf="isNavigable">
                            <mat-icon fontSet="mdi" fontIcon="mdi-chevron-left"></mat-icon>
                        </button>
                    </div>
                    <div class="ks-inner-container grid">
                        <div class="keen-slider" #desktopSliderRef>
                            <div class="no-prescription" *ngIf="prescriptions.length==0 && showSliders">
                                {{'prescription.no_element_found'| translate}}
                            </div>
                            <div *ngIf="!showSliders">
                                loading..
                            </div>
                            <div
                                    *ngFor="let p of prescriptions"
                                    class="keen-slider__slide number-slide"
                                    [ngClass]="showSliders ? 'opacity-100' : 'opacity-0'"
                            >
                                <mat-card class="slider-card">
                                    <mat-card-header>
                                        <mat-card-title>
                                            <span> {{p.template_name}}</span>
                                        </mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <div class="attributes-container">
                                            <div class="attribute-item">
                                                <div class="attribute-icon">
                                                    <mat-icon
                                                            fontSet="mdi"
                                                            fontIcon="mdi-doctor"
                                                    ></mat-icon>
                                                </div>
                                                <div>
                                                    <div class="attribute-key">{{ "patient.prescribing_physician" | translate }}</div>
                                                    <div class="attribute-value">{{p?.physician ? 'Dr. ' + p?.physician?.full_name : '###'}}</div>
                                                </div>
                                            </div>
                                            <div class="attribute-item">
                                                <div class="attribute-icon">
                                                    <mat-icon
                                                            fontSet="mdi"
                                                            fontIcon="mdi-calendar-range"
                                                    ></mat-icon>
                                                </div>
                                                <div>
                                                    <div class="attribute-key">{{ "prescription.create_date" | translate }}</div>
                                                    <div class="attribute-value">
                                                        {{ (p.date |ftDate) || '###'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="attribute-item">
                                                <div class="attribute-icon">
                                                    <mat-icon
                                                            fontSet="mdi"
                                                            fontIcon="mdi-play"
                                                    ></mat-icon>
                                                </div>
                                                <div>
                                                    <div class="attribute-key">{{ "shared.start_date" | translate }}</div>
                                                    <div class="attribute-value">
                                                        {{ p.start_date ? (p.start_date   |ftDate) : '###'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="attribute-item">
                                                <div class="attribute-icon">
                                                    <mat-icon
                                                            fontSet="mdi"
                                                            fontIcon="mdi-pause"
                                                    ></mat-icon>
                                                </div>
                                                <div>
                                                    <div class="attribute-key">{{ "prescription.end_date" | translate }}</div>
                                                    <div class="attribute-value">
                                                        {{ p.end_date ? (p.end_date   |ftDate) : '###'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="attribute-item">
                                                <div class="attribute-icon">
                                                    <mat-icon
                                                            fontSet="mdi"
                                                            fontIcon="mdi-map-marker"
                                                    ></mat-icon>
                                                </div>
                                                <div>
                                                    <div class="attribute-key">{{ "patient.intervention_address" | translate }}</div>
                                                    <div class="attribute-value">
                                                        {{ p?.intervention_address || '###'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <div class="ks-nav-btn">
                        <button mat-icon-button (click)="slider.next()" *ngIf="isNavigable">
                            <mat-icon fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
                        </button>
                    </div>
                </div>
            </section>
            <div class="file-manager-container flex flex-col min-h-[300px]">
                <ft-file-manager   *ngIf="patient?.id"
                                 class="grow w-auto m-[16px]"
                                 [root]="'patient'"
                                 [rootId]="patient?.id"
                ></ft-file-manager>
            </div>
        </div>
    </div>
</div>
<button
        mat-mini-fab
        class="action-btn"
        color="primary"
        (click)="handleEdit()"
        *ngIf="isMobile"
>
    <mat-icon fontSet="mdi" fontIcon="mdi-account-edit"></mat-icon>
</button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="handlePrescription()">
                <mat-icon fontSet="mdi" fontIcon="mdi-file-document" color="primary"></mat-icon>
                <span>{{'patient.create_prescription'| translate}}</span>
            </button>
            <button mat-menu-item (click)="handleDeathDetail()">
                <mat-icon fontSet="mdi" fontIcon="mdi-emoticon-dead-outline" color="primary"></mat-icon>
                <span>{{'patient.declare_death'|translate}}</span>
            </button>
            <button mat-menu-item (click)="removePatient()">
                <mat-icon fontSet="mdi" fontIcon="mdi-delete" color="warn"></mat-icon>
                <span>{{'shared.delete'| translate}}</span>
            </button>
        </mat-menu>
