import {TableColumnDefinition} from '@ft/core';

export const PATIENT_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'patient.last_name',
    'key': 'last_name',
    'sortBy': 'last_name',
    'type': 'text',
    'is_global_searchable': true,
    'required': true,
  },
  {
    'label': 'patient.first_name',
    'key': 'first_name',
    'sortBy': 'first_name',
    'type': 'text',
    'is_global_searchable': true,
    'required': true,
  },
  {
    'label': 'patient.birth_date',
    'key': 'birth_date',
    'sortBy': 'birth_date',
    'type': 'date',
    'is_global_searchable': true
  },
  {
    'label': 'patient.national_id',
    'key': 'national_id',
    'sortBy': 'national_id',
    'type': 'text',
    'is_global_searchable': true
  },
  {
    'label': 'patient.phone_number',
    'key': 'phone_number',
    'sortBy': 'phone_number',
    'type': 'text',
    'is_global_searchable': true
  }
];
