import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { MainComponent } from './main.component';
import { AuthGuard, LoggedGuard } from '@ft/core';
import { LoginComponent } from './shared/components/login/login.component';
import { CustomBusyComponent } from './custom-busy/custom-busy.component';

const routes: Routes = [

  {
    path: '',
    canActivate: [AuthGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/patient/list',
      },
      {
        path: 'patient',
        data: { isMobileNavBarHasPlusButton: true },
        loadChildren: () =>
          import('./patient/patient-routing.module').then(
            (m) => m.PatientRoutingModule
          ),
      },
      {
        path: 'prescription',
        data: { isMobileNavBarHasPlusButton: true },
        loadChildren: () =>
          import('./prescription/prescription-routing.module').then(
            (m) => m.PrescriptionRoutingModule
          ),
      },
      {
        path: 'ticket',
        loadChildren: () =>
          import('./ticket/ticket-routing.module').then(
            (m) => m.TicketRoutingModule
          ),
      },
      {
        path: 'patient-appointment',
        loadChildren: () =>
          import('./patient-appointment/patient-appointment-routing.module').then(
            (m) => m.PatientAppointmentRoutingModule
          ),
      },
      {
        path: 'patient-request',
        loadChildren: () =>
          import('./patient-request/patient-request-routing.module').then(
            (m) => m.PatientRequestRoutingModule
          ),
      },
      {
        path: 'patient-prescription',
        loadChildren: () =>
          import('./patient-prescription/patient-prescription-routing.module').then(
            (m) => m.PatientPrescriptionRoutingModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings-routing.module').then(
            (m) => m.SettingsRoutingModule
          ),
      },
    ],
  },
  // {path: 'login', component: LoginComponent, canActivate: [LoggedGuard], data: {logo: '/assets/logo.png'}}
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedGuard],
    data: { logo: '/assets/logo.png' },
  },
  {
    path: 'custom-busy',
    component: CustomBusyComponent,
  },
];

// const routerOptions: ExtraOptions = {
//   // useHash: false,
//   scrollPositionRestoration: 'enabled',
//   anchorScrolling: 'enabled',
//   // ...any other options you'd like to use
// };

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
