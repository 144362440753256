<div class="pr-content-container">
  <div class="pr-content-header">
    <div class="list-title text-h4 font-bold">
      {{ "settings.notification" | translate }}
    </div>
    <button mat-button color="accent" [mat-dialog-close]="false">
      {{ "shared.cancel" | translate }}
    </button>
    <button mat-button color="primary" (click)="saveNotification($event)">
      {{ "shared.save" | translate }}
    </button>
  </div>
  <div class="pr-content-body" [formGroup]="reactiveForm">
    <div class="settings flex flex-col gap-4">
      <div class="settings-section">
        <div class="settings-section-title">
          {{ "shared.select_recipient" | translate }}:
        </div>
        <div class="form-field-label">{{ "shared.profiles" | translate }}</div>
        <mat-form-field appearance="outline">
          <!-- [compareWith]="objectComparisonFunction" -->
          <mat-select
            formControlName="profiles"
            (selectionChange)="selectProfile($event)"
            multiple
          >
            <mat-select-trigger>
              {{ getFirstSelectedProfile(reactiveForm.get('profiles').value?.[0]) }}
              <ng-container
                *ngIf="
                  (reactiveForm.get('profiles').value?.length || 0) > 1;
                  else noSelection
                "
              >
                <span class="lowercase">
                  (+{{ (reactiveForm.get("profiles").value?.length || 0) - 1 }}
                  {{
                    (reactiveForm.get("profiles").value?.length === 2
                      ? "settings.other"
                      : "settings.others"
                    ) | translate
                  }})
                </span>
              </ng-container>
              <ng-template #noSelection></ng-template>
            </mat-select-trigger>
            <mat-option *ngFor="let profile of profiles" [value]="profile.id">
              {{ profile.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reactiveForm.get('profiles').hasError('required')">
            {{ "settings.please_select_profiles" | translate }}
          </mat-error>
        </mat-form-field>
        <div
          class="form-field-label"
          [ngClass]="{ 'opacity-50': users.length === 0 }"
        >
          {{ "shared.users" | translate }}
        </div>
        <mat-form-field
          appearance="outline"
          class="unique-placeholder"
          [ngClass]="{ 'opacity-50': users.length === 0 }"
        >
          <mat-select
            formControlName="users"
            multiple
            [disabled]="users.length === 0"
            [placeholder]="
              users.length === 0 &&
              reactiveForm.get('profiles').value.length > 0
                ? ('shared.there_are_no_users_in_the_above_profiles' | translate)
                : ''
            "
          >
            <mat-select-trigger>
              {{ getFirstSelectedUser(reactiveForm.get('users').value?.[0]) }}
              <ng-container
                *ngIf="
                  (reactiveForm.get('users').value?.length || 0) > 1;
                  else noSelection
                "
              >
                <span class="example-additional-selection lowercase">
                  (+{{ (reactiveForm.get("users").value?.length || 0) - 1 }}
                  {{
                    (reactiveForm.get("users").value?.length === 2
                      ? "settings.other"
                      : "settings.others"
                    ) | translate
                  }})
                </span>
              </ng-container>
              <ng-template #noSelection></ng-template>
            </mat-select-trigger>
            <mat-option *ngFor="let user of users" [value]="user.id">
              {{ user.full_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reactiveForm.get('users').hasError('required')">
            {{ "settings.please_select_users" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="settings-section-separator">* * * * * *</div>
      <div class="settings-section">
        <div class="settings-section-title">
          {{ "shared.select_channels" | translate }}
        </div>
        <div class="pr-card">
          <div class="flex flex-col gap-2 w-fit">
            <mat-slide-toggle formControlName="to_app">{{
              "shared.to_app" | translate
            }}</mat-slide-toggle>
            <mat-slide-toggle formControlName="to_sms">{{
              "shared.sms" | translate
            }}</mat-slide-toggle>
          <!--  <mat-slide-toggle formControlName="to_email">{{
              "shared.email" | translate
            }}</mat-slide-toggle>-->
          </div>
        </div>
      </div>
      <div class="settings-section-separator">* * * * * *</div>
      <div class="settings-section">
        <div class="settings-section-title">
          {{ "settings.acl.sms_template" | translate }}:
        </div>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="sms_template"
              [compareWith]="objectComparisonFunction"
            >
                  <!-- [value]="{label: action.translate, resource_name: action.name, action_label: crudAction.translate, name: crudAction.name}" -->
                  <mat-option
                    *ngFor="let t of smsTemplates"
                    [value]="t"
                  >
                    <span >{{t.title}}</span>
                  </mat-option>
            </mat-select>
            <mat-error *ngIf="reactiveForm.get('sms_template').hasError('required')">
              {{ "settings.please_select_an_sms_template" | translate }}
            </mat-error>
          </mat-form-field>
      </div>
      <div class="settings-section">
        <div class="settings-section-title">
          {{ "shared.app_notification_template" | translate }}:
        </div>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="message"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
          ></textarea>
        </mat-form-field>
      </div>


      <div class="settings-section">
        <div class="settings-section-title">
          {{ "shared.link" | translate }}
        </div>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="link"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
