import {Component} from '@angular/core';
import {AppConfigsService} from '@ft/core';
import {TranslateService} from '@ngx-translate/core';

import {DateAdapter} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Moment} from 'moment';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
    `,
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'prati-cloud-front';

    constructor(private translateService: TranslateService, private appConfigService: AppConfigsService,
                adapter: DateAdapter<Moment>, momentAdapter: MomentDateAdapter, private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer
    ) {
        this.translateService.use(appConfigService.appLang);
        this.translateService.setDefaultLang(appConfigService.appLang);

        adapter.setLocale(appConfigService.appLang);
        momentAdapter.setLocale(appConfigService.appLang);


        this.matIconRegistry.addSvgIcon(
            'filter-options',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/filter-options.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'death',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/death.svg')
        );
    }
}
