import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get} from 'lodash';

@Component({
  selector: 'app-comments-dialog',
  templateUrl: './comments-dialog.component.html',
  styleUrls: ['./comments-dialog.component.scss']
})
export class CommentsDialogComponent implements OnInit {
  model: any = null;

  constructor(public dialogRef: MatDialogRef<CommentsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = get(data, 'pk');
  }

  ngOnInit(): void {
  }

}
