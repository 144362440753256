import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ViewValueType} from '../../../settings/types/shared.type';
import {forkJoin, Subscription} from 'rxjs';
import {ProfileService} from '../../../settings/services/profile.service';
import {UserService} from '../../../settings/services/user.service';
import {DOCUMENT} from '@angular/common';
import {SettingsService} from "../../../settings/services/settings.service";

@Component({
  selector: 'pr-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  // public profileList: string[] = ['Techicien', 'Médecin', 'Patient'];
  // public profileList: ViewValueType[] = [
  //   { value: 'PATIENT', viewValue: 'Patient' },
  //   { value: 'PHYSICIAN', viewValue: 'Médecin' },
  //   { value: 'TECHNICIAN', viewValue: 'Technicien' },
  //   { value: 'ADMINISTRATOR', viewValue: 'Administrateur' },
  // ];
  public profiles: any = [];
  public profileList: ViewValueType[] = [
    {value: 3, viewValue: 'Patient'},
    {value: 4, viewValue: 'Médecin'},
    {value: 5, viewValue: 'Technicien'},
    {value: 6, viewValue: 'Administrateur'},
  ];
  public users: any = [];
  public userList: any = [];
  public reactiveForm!: FormGroup;
  private _subs = new Subscription();
  public smsTemplates:any=[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NotificationComponent>,
    private _fb: FormBuilder,
    private _profileService: ProfileService,
    private _userService: UserService,
    private _settingsService: SettingsService,
  ) {
    this.reactiveForm = this._fb.group({
      profiles: [[]],
      users: [[]    ],
      to_app: [false],
      to_email: [{value: false, disabled: true}],
      to_sms: [false],
      message: [''],
      link: null,
      sms_template: null,
    });
  }

  ngOnDestroy(): void {
    this._subs?.unsubscribe();
  }

  ngOnInit(): void {

     this._subs.add(
    forkJoin([
      this._profileService.fetchAll(),
      this._userService.fetchAll(),
      this._settingsService.getSmsTemplates(),
    ]).subscribe({
        next: (data: any): void => {
          this.profiles = data[0];
          this.userList = data[1];
          this._filterUsers();
          this.smsTemplates=data[2];
        },
      }));

    // this._subs.add(
    //   this._profileService.fetchAll().subscribe({
    //     next: (profiles: any[]): void => {
    //       this.profiles = profiles;
    //     },
    //   })
    // );
    // this._subs.add(
    //   this._userService.fetchAll().subscribe({
    //     next: (users: any[]): void => {
    //       this.userList = users;
    //       // this.users = users;
    //       this._filterUsers()
    //
    //
    //     },
    //   })
    // );


    if (this.data) {
      this.reactiveForm.get('to_app')?.setValue(this.data.to_app);
      this.reactiveForm.get('to_email')?.setValue(this.data.to_email);
      this.reactiveForm.get('to_sms')?.setValue(this.data.to_sms);
      this.reactiveForm.get('message')?.setValue(this.data.message);
      this.reactiveForm.get('link')?.setValue(this.data.link);
      this.reactiveForm.get('sms_template')?.setValue(this.data.sms_template);
      this.data.profiles.forEach((profile) => {
        this.reactiveForm
          .get('profiles')
          ?.setValue([
            ...this.reactiveForm.get('profiles').value,
            parseInt(profile.id, 10),
          ]);
      });

      this.data.users.forEach((user) => {
        this.reactiveForm
          .get('users')
          ?.setValue([
            ...this.reactiveForm.get('users').value,
            parseInt(user?.id, 10),
          ]);
      });


    }
  }

  public selectProfile(event) {
    this._filterUsers()
  }

  /**
   * Filters the user list based on selected profile IDs from the form.
   */
  private _filterUsers(): void {
    // Get the selected profile IDs from the form
    const desiredProfileIds = this.reactiveForm.get('profiles').value;

    // If profile IDs are selected, perform filtering
    if (desiredProfileIds) {
      // Filter the user list to those containing at least one of the desired profile IDs
      this.users = this.userList.filter((user) => {
        // Extract profile IDs from user's users
        const profileIds = user.users.map((u) => u?.profile.id);

        // Check if any of the desired profile IDs are included in the user's profile IDs
        return desiredProfileIds.some((id) => profileIds.includes(id));
      });
    }
  }

  public saveNotification(event) {
    if (this.reactiveForm.valid) {
      this.dialogRef.close(this.reactiveForm.value);
    } else {
      event.preventDefault();
      this.reactiveForm.markAllAsTouched();
    }
  }

  public getFirstSelectedProfile(profileId: string) {
    const desiredProfile = this.profiles.find((item) => item.id === profileId);

    if (desiredProfile) {
      return desiredProfile.name;
    } else {
      return '';
    }
  }

  public getFirstSelectedUser(userId: string) {
    const desiredUser = this.users.find((item) => item.id === userId);

    if (desiredUser) {
      return desiredUser.full_name;
    } else {
      return '';
    }
  }

  public objectComparisonFunction = function (o1, o2): boolean {
    return o1 && o2 && o1.id === o2.id;
  }


}
