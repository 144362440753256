<div
        fxFlex
        fxLayout="column"
        class="ft-content"
        *ngIf="breakpointObserver.isMatched('(min-width: 1280px)')"
>
    <app-pr-generic-view-table
            [namespace]="viewSettings?.viewNamespace"
            [actions]="multiActions"
            [viewSettings]="viewSettings?.viewSettings"
            [hasPagination]="viewSettings?.hasPagination"
            [selectable]="viewSettings?.selectable"
            [query]="viewSettings?.query"
            [reloadEvent]="reloadEvent"
            [exportExcel]="true"
            [customFilters]="customFilters"
    >
    </app-pr-generic-view-table>
</div>


<pr-cards-view fxFlex fxLayout="column" *ngIf="breakpointObserver.isMatched('(max-width: 1279.98px)')"
               [viewSettings]="viewSettings"
               [customTemplate]="cardsContentTemplate"></pr-cards-view>

<ng-template let-item="item" #cardsContentTemplate>
    <div class="attributes-header">
        <div class="w-full" (click)="handlePrescriptionEdit(item.id)">
            <span class="item-badge" [ngStyle]="TemplatesColors(item)">
                <span class="global-badge">
                    {{ item.template_name }}
                </span>
            </span>
            <span class="item-title grow">{{ item?.patient?.full_name }}</span>
            <div class="item-icons">
                <!--<mat-icon color="warn" fontSet="mdi" fontIcon="mdi-bell"></mat-icon>-->
                <mat-icon [style]="handleStatusColor(item)" class="item-check ml-1" fontSet="mdi"
                          fontIcon="mdi-check-circle" [matTooltip]="item.status|translate"></mat-icon>
            </div>
        </div>
    </div>
    <div class="attributes-container">
        <div class="attribute-item">
            <div class="attribute-icon">
                <mat-icon
                        fontSet="mdi"
                        fontIcon="mdi-doctor"
                ></mat-icon>
            </div>
            <div>
                <div class="attribute-key">{{ "patient.prescribing_physician" | translate }}</div>
                <div class="attribute-value">{{item?.physician ? 'Dr. ' + item?.physician?.full_name : ''}}</div>
            </div>
        </div>
<!--        <div class="attribute-item">-->
<!--            <div class="attribute-icon">-->
<!--                <mat-icon-->
<!--                        fontSet="mdi"-->
<!--                        fontIcon="mdi-calendar-range"-->
<!--                ></mat-icon>-->
<!--            </div>-->
<!--            <div>-->
<!--                <div class="attribute-key">{{ "prescription.create_date" | translate }}</div>-->
<!--                <div class="attribute-value">-->
<!--                    {{ item.date |ftDate}}-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="attribute-item">-->
<!--            <div class="attribute-icon">-->
<!--                <mat-icon-->
<!--                        fontSet="mdi"-->
<!--                        fontIcon="mdi-play"-->
<!--                ></mat-icon>-->
<!--            </div>-->
<!--            <div>-->
<!--                <div class="attribute-key">{{ "shared.start_date" | translate }}</div>-->
<!--                <div class="attribute-value">-->
<!--                    {{ item.start_date ? (item.start_date   |ftDate) : '###'}}-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="attribute-item">-->
<!--            <div class="attribute-icon">-->
<!--                <mat-icon-->
<!--                        fontSet="mdi"-->
<!--                        fontIcon="mdi-pause"-->
<!--                ></mat-icon>-->
<!--            </div>-->
<!--            <div>-->
<!--                <div class="attribute-key">{{ "prescription.end_date" | translate }}</div>-->
<!--                <div class="attribute-value">-->
<!--                    {{ item.end_date ? (item.end_date   |ftDate) : '###'}}-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="attribute-item">
            <div class="attribute-icon">
                <mat-icon
                        fontSet="mdi"
                        fontIcon="mdi-map-marker"
                ></mat-icon>
            </div>
            <div>
                <div class="attribute-key">{{ "patient.intervention_address" | translate }}</div>
                <div class="attribute-value">
                    {{ item?.intervention_address || '###'}}
                </div>
            </div>
        </div>
    </div>
</ng-template>


<button
        mat-mini-fab
        class="action-btn"
        color="primary"
        *ngIf="isMobile"
        (click)="handleNewPrescription()"
>
    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
</button>

