<div class="pr-content-container">
    <div class="pr-content-header" *ngIf="!isMobile">
      <div class="list-title text-h4 font-bold">
        {{ "settings.the_actions_notifiers" | translate }}
      </div>
      <button mat-button color="primary" (click)="addActionNotifier($event)">{{"shared.add" | translate}}</button>
    </div>
    <div class="pr-content-body">
      <pr-search></pr-search>
      <div class="w-full grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-4">
        <div
        [routerLink]="'/settings/action-notifier/'+actionNotifier.id"
          class="setting-card-item lg:col-span-4 md:col-span-6  col-span-12"
          [class.selected]="selectedId === actionNotifier.id"
          *ngFor="let actionNotifier of actionNotifiers"
        >
        <div class="flex flex-col">
          <div class="header">
            {{ "settings.actions" | translate }}
          </div>
          <div class="content">
            {{ actionNotifier.action.label | translate }} / {{ actionNotifier.action.action_label | translate }}
          </div>
        </div>
        <div class="flex flex-col">
          <div class="header">
            {{ "settings.recipients" | translate }}
          </div>
          <div class="content">
            <div class="fancy-name" *ngFor="let recipient of actionNotifier.recipients; let last = last">
              {{ getRecipientViewValue(recipient) | translate }}
            </div>
          </div>
        </div>
        <div class="delete-btn-container">
          <button mat-icon-button (click)="remove($event, actionNotifier.id)">
            <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon> 
          </button>
        </div>
        </div>
      </div>
  
    </div>
  </div>
  