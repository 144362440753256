import { Component, HostBinding, OnInit } from '@angular/core';
import {
  onMainContentChange,
  onSideNavChange,
  animateText,
} from './shared/animations/animations';
import { SidenavService } from './shared/services/sidenav.service';
import { checkIsMobile } from './shared/utils/functions';
import { ResponsiveService } from './shared/services/responsive.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ActivatedRoute,
  NavigationEnd,
  Route,
  Router,
  Event,
} from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  // styles: [':host {height: 100%;  width: 100%; display: flex; flex-direction: column}']
  styleUrls: ['./main.component.scss'],
  animations: [onMainContentChange, onSideNavChange, animateText],
})
export class MainComponent implements OnInit {
  @HostBinding('class.host-class-mobile') public isMobile = false;
  public xs: boolean;
  public onSideNavChange: boolean;
  public isMobileNavBarHasPlusButton = false;
  public sideNavState: boolean = false;
  public linkText: boolean = false;
  // public isMobile: boolean = false;
  public breakpoints = Breakpoints;
  public links = [
    {
      path: '/patient',
      label: 'Patients',
      icon: 'mdi-account-multiple',
      acl: { resource: 'patient', action: 'create' },
    },
    {
      path: '/prescription',
      label: 'Prescriptions',
      icon: 'mdi-file-document',
      acl: {
        resource: 'prescription',
        action: 'create'
      },
    },
    {
      path: '/ticket',
      label: 'Tickets',
      icon: 'mdi-ticket-outline',
      acl: {
        resource: 'ticket',
        action: 'create'
      },
    },
    {
      path: '/patient-appointment',
      label: 'Rendez-vous',
      icon: 'mdi-ticket-account',
      acl: {
        resource: 'appointment',
        action: 'get'
      },
    },
    {
      path: '/patient-prescription',
      label: 'Prestations',
      icon: 'mdi-file-document-outline',
      acl: {
        resource: 'patient-prescription',
        action: 'get'
      },
    },
    {
      path: '/patient-request',
      label: 'Demandes',
      icon: 'mdi-hand-extended-outline',
      acl: {
        resource: 'patient-request',
        action: 'create'
      },
    },
  ];
  constructor(
    public breakpointObserver: BreakpointObserver,
    private _sidenavService: SidenavService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this._sidenavService.sideNavState$.subscribe((res) => {
      this.onSideNavChange = res;
    });
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 640px)', '(max-width: 420px)'])
      .subscribe((result) => {
        this.isMobile =  result.breakpoints['(max-width: 640px)'] ? true : false;
        this.xs =  result.breakpoints['(max-width: 420px)'] ? true : false;
      });

    this._reconfigureMobileNavBar();
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this._reconfigureMobileNavBar();
      }
    });
  }

  private _reconfigureMobileNavBar() {
    this.isMobileNavBarHasPlusButton =
      this._activatedRoute.snapshot.firstChild.data.isMobileNavBarHasPlusButton;
  }

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 2000);
    this._sidenavService.sideNavState$.next(this.sideNavState);
  }
}
