import { trigger, state, style, transition, animate } from '@angular/animations';
export let duration = '250ms';
let animationTimingFunction = ' ease-in-out';
export const onSideNavChange = trigger('onSideNavChange', [
  state('close',
    style({
      'width': '54px'
    })
  ),
  state('open',
    style({
      'width': '220px'
    })
  ),
  transition('close => open', animate(duration + animationTimingFunction)),
  transition('open => close', animate(duration + animationTimingFunction)),
]);


export const onMainContentChange = trigger('onMainContentChange', [
  state('close',
    style({
      'margin-left': '54px'
    })
  ),
  state('open',
    style({
      'margin-left': '220px'
    })
  ),
  transition('close => open', animate(duration + animationTimingFunction)),
  transition('open => close', animate(duration + animationTimingFunction)),
]);

export const animateText = trigger('animateText', [
  state('hide',
    style({
      'display': 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      'display': 'flex',
      opacity: 1,
    })
  ),
  transition('close => open', animate('350ms ease-in')),
  transition('open => close', animate('200ms ease-out')),
]);