import {Component, Output, EventEmitter} from '@angular/core';
import {FormComponent} from "@ft/form";
import {assignIn} from "lodash";

@Component({
    selector: 'pr-prescription-template',
    templateUrl: './prescription-template.component.html',
    styleUrls: ['./prescription-template.component.scss']
})
export class PrescriptionTemplateComponent extends FormComponent {
    @Output() public fieldFileChange = new EventEmitter();
    @Output() public fieldFileDelete = new EventEmitter();
    @Output() public fieldFilePreview = new EventEmitter();

    constructor() {
        super();
    }

    handleFieldFile(ev, blockIdx) {
        this.fieldFileChange.emit(assignIn(ev, {blockIdx}));
    }

    handleFieldFileDelete(ev,blockIdx) {
        this.fieldFileDelete.emit(assignIn(ev, {blockIdx}));
    }
     previewFile(ev) {
        this.fieldFilePreview.emit(ev);
    }
}
