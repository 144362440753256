<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-account-multiple"></mat-icon>
    </button>
  </div>
  <h2>{{ 'patient.patient_already_exist' | translate }}</h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm">

  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div fxLayout="column" fxLayoutGap="6px">
      <div fxLayout="row" fxLayoutGap="4px"><h4 class="font-bold">{{ "patient.last_name" | translate }}</h4>:
        <span>{{model.last_name}}</span>
        <button type="button" mat-icon-button class="patient-navigation" (click)="navigateToPatient()">
          <mat-icon fontSet="mdi" fontIcon="mdi-open-in-new"></mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxLayoutGap="4px"><h4 class="font-bold">{{ "patient.first_name" | translate }}</h4>:
        <span>{{model.first_name}}</span></div>
      <div fxLayout="row" fxLayoutGap="4px"><h4 class="font-bold">{{ "patient.birth_date" | translate }}</h4>:
        <span>{{model.birth_date |ftDate}}</span></div>
      <div fxLayout="row" fxLayoutGap="4px"><h4 class="font-bold">{{ "patient.national_id" | translate }}</h4>
        :<span>{{model.national_id}}</span></div>
    </div>
  </div>

  <div mat-dialog-actions fxLayoutAlign="center center">
    <button type="button" mat-raised-button color="warn" (click)="handleSave()"  cdkFocusInitial>
      <span>{{'shared.save_any_way'|translate}}</span>
    </button>
  </div>
</form>
