import {Component, OnInit} from '@angular/core';
import {FormSetupComponent, FormSetupService} from '@ft/form';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-prescription-forms',
    templateUrl: './prescription-forms.component.html',
    styleUrls: ['./prescription-forms.component.scss']
})
export class PrescriptionFormsComponent extends FormSetupComponent {
    public fieldTypes = [
        'checkbox',
        'radio-button',
        'text',
        'long-text',
        'number',
        'date',
        'title',
        'paragraph',
        'list',
        'image',
        'table-layout',
        'formula',
        'file'
    ];

    constructor(
        translateService: TranslateService,
        formSetupService: FormSetupService,
        _route: ActivatedRoute,
        _router: Router,
        snackBar: MatSnackBar
    ) {
        super(translateService, formSetupService, _route, _router, snackBar);
    }

    compareCondition(o1, o2) {
        return o1 && o2 && o1 === o2;
    }
}
