<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-stethoscope"></mat-icon>
    </button>
  </div>
  <h2>{{ 'settings.speciality' | translate }}</h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm">
  <div [ngBusy]="subscription"></div>

  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div fxLayout="column" fxLayoutGap="6px">

        <mat-form-field fxFlex>
          <input matInput type="text" required name="last_name" placeholder="{{'shared.name'|translate}}"
                 [ftAutoFocus]="true"
                 [(ngModel)]="model.name"/>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="text"  name="description" placeholder="{{'shared.description'|translate}}"
                 [(ngModel)]="model.description"/>
        </mat-form-field>

    </div>
  </div>

  <div mat-dialog-actions>
    <span fxFlex></span>
    <button type="button" mat-raised-button color="primary" (click)="handleSave()" [disabled]="form.invalid">
      <span>{{'shared.save'|translate}}</span>
    </button>
    <button type="button" mat-raised-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
      <span>{{'shared.reset'|translate}}</span>
    </button>
  </div>
</form>
