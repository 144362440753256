import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {fromNow, redirectTo} from '../../utils/functions';
import {AppConfigsService, AuthService, FtWsService} from '@ft/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {TicketService} from 'src/app/ticket/services/ticket.service';
import {Router} from '@angular/router';
import {get, isEmpty, isNull} from 'lodash';
import {BaseCalendarService} from '../../services/base-calendar.service';
import {SharedService} from '../../services/shared.service';
import * as moment from 'moment';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  public breakpoints = Breakpoints;
  private _subs = new Subscription();
  staffFullName = this.authService.getStaff().full_name;
  public user = {
    username: this.authService.getUser()?.username,
    staff: `${!isEmpty(this.staffFullName) ? this.staffFullName : ''}`,
    avatar: null,
  };
  public logo: string = '/assets/white-logo384.png';
  public notify = true;
  public appointments: any = [];
  links = [
    {
      path: '/patient',
      label: 'shared.patients',
      icon: 'mdi-account-multiple',
      acl: {resource: 'patient', action: 'get', class: 'mat-tab-disabled'},
    },
    {
      path: '/prescription',
      label: 'shared.prescriptions',
      icon: 'mdi-file-document',
      acl: {
        resource: 'prescription',
        action: 'get',
        class: 'mat-tab-disabled',
      },
    },
  ];
  notificationOpened = false;
  displayedAppointments = null;
  unread_notification = null;
  notification_current_page = 1;
  has_next_notification_page = false;
  has_prev_notification_page = false;
  pageInfo = null;

  // public isMobile: Boolean;

  constructor(
    public authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    private _ticketService: TicketService,
    private _baseCalendarService: BaseCalendarService,
    private _router: Router,
    private ws: FtWsService,
    private sharedService: SharedService,
    private _app_configService: AppConfigsService
  ) {
  }

  ngOnInit(): void {
    this._subs.add(
      this.ws.observe('notification.handle_refresh').subscribe((data) => {
        this._fetchAppointmentNotifications();
      })
    );
    this._fetchAppointmentNotifications();
    this._subs.add(
      this._baseCalendarService.getAppointmentNotificationChange$().subscribe({
        next: () => {
          this._fetchAppointmentNotifications();
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
    this.breakpointObserver
      .observe(['(max-width: 640px)'])
      .subscribe((result) => {
        if (result.breakpoints['(max-width: 640px)']) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  public filteredAppointmentsLength(): number {
    return this.appointments.filter((element, index) => {
      return !element.read;
    }).length;
  }

  public readNotification(notif) {
    this.sharedService.handleReadNotification(notif.id).subscribe(data => {
      this._fetchAppointmentNotifications()
    });
    redirectTo(
      this._router,
      '/ticket/custom-busy',
      notif.link
    );
  }

  public getLocalDate(dateStr) {
    return moment.utc(dateStr).local().format(this._app_configService.dateTimeFormat);
  }

  public fromNow(dateVar: any) {
    return fromNow(moment.utc(dateVar).local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
  }

  private paginateNotifications(event: any, paginate = null) {
    event.stopPropagation();
    if (!isNull(paginate)) {
      if (paginate === 1) {
        this.notification_current_page += 1;
      }
      if (paginate === -1) {
        this.notification_current_page -= 1;
      }
    }
    this.menuOpened(true);
    this._fetchAppointmentNotifications();
  }

  private _fetchAppointmentNotifications() {

    this.sharedService.getNotifications(this.notification_current_page).subscribe(res => {
      this.appointments = get(res, 'data');
           // this.displayedAppointments = this.appointments;
      this.unread_notification = get(res, 'total_count');
      this.has_next_notification_page = get(res, 'has_next');
      this.has_prev_notification_page = get(res, 'has_previous');
      this.ChangeNotifStatusToReceived(get(res, 'data'));
    });
  }


  public ChangeNotifStatusToReceived(items) {
    const ids: number[] = items
      .filter((e) => {
        return !e['receipted'];
      })
      .map((n) => {
        return n.id;
      });
    if (ids.length > 0) this.sharedService.getChangeNotificationStatus(ids).subscribe(data => {
      this._fetchAppointmentNotifications();
    });
  }

  logout() {
    return this.authService.logout();
  }

  menuOpened(opened = true) {
    this.notificationOpened = opened;
    if (opened) {
      this.displayedAppointments = this.appointments;
    } else {
      this.displayedAppointments = null;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  handlePageInfos() {
    return `${(this.notification_current_page - 1) * 5} - ${Math.min(this.notification_current_page * 5, this.unread_notification)} de ${this.unread_notification}`;
  }

}
