import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrescriptionComponent } from './components/prescription/prescription.component';
import { BaseComponent } from './components/base/base.component';
import { PrescriptionGridComponent } from './components/prescription-grid/prescription-grid.component';
import { AuthGuard } from '@ft/core';
import { PrescriptionContentComponent } from './components/prescription-content/prescription-content.component';

const routes: Routes = [
  {
    path: '',
    component: PrescriptionComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', component: PrescriptionGridComponent },
      {
        path: 'content',
        component: PrescriptionContentComponent,
      },
      {
        path: 'content/:id',
        component: PrescriptionContentComponent,
      },
      // {
      //   path: ':id',
      //   component: PrescriptionComponent,
      //   children: [
      //     // { path: '', pathMatch: 'full', redirectTo: 'content' },

      //     { path: 'content', component: BaseComponent },
      //     { path: 'content/:id', component: BaseComponent },
      //   ],
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrescriptionRoutingModule {}
