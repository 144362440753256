<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <button type="button" mat-icon-button tabindex="-2">
            <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
        </button>
    </div>
    <h2>{{ 'prescription.force_conform_title' | translate }}</h2>

    <span fxFlex></span>
    <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<form novalidate #form="ngForm">
    <div [ngBusy]="subscription"></div>
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div fxLayout="column" fxLayoutGap="6px">
            <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
          >
            <mat-label>{{'prescription.force_conform_reason'|translate}}</mat-label>
            <textarea
              matInput
              [(ngModel)]="model.reason"
              name="reason" rows="7" required
            ></textarea>
          </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions>
        <span fxFlex></span>
        <button type="button" mat-flat-button class="cancel-btn" [mat-dialog-close]="null" cdkFocusInitial>
            <span>{{'shared.reset'|translate}}</span>
        </button>
        <button type="button" mat-flat-button color="primary" (click)="handleSave()" [disabled]="form.invalid">
            <span>{{'shared.save'|translate}}</span>
        </button>
    </div>
</form>
