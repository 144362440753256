<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <button type="button" mat-icon-button tabindex="-2">
            <mat-icon fontSet="mdi" fontIcon="mdi-emoticon-dead-outline"></mat-icon>
        </button>
    </div>
    <h2>{{ 'patient.declare_death' | translate }}</h2>

    <span fxFlex></span>
    <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<form novalidate #form="ngForm">
    <div [ngBusy]="subscription"></div>

    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div fxLayout="column" fxLayoutGap="6px">
                <mat-form-field fxFlex="nogrow" [ftAutoFocus]="true">
                    <input matInput ftDate [matDatepicker]="b_date"
                           [placeholder]="'patient.death_date'|translate" required
                           [(ngModel)]="model.death_date" name="b_date">
                    <mat-datepicker-toggle matSuffix [for]="b_date">
                        <mat-icon matDatepickerToggleIcon fontSet="mdi"
                                  fontIcon="mdi-calendar-today"></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #b_date></mat-datepicker>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <input matInput type="text" required name="reason"
                           placeholder="{{'patient.death_reason'|translate}}"
                           [(ngModel)]="model.death_reason"/>
                </mat-form-field>

        </div>
    </div>

    <div mat-dialog-actions>
        <button *ngIf=" death_date && death_reason" type="button" mat-raised-button color="warn" (click)="handleSave(true)" [disabled]="form.invalid">
            <span>{{'patient.reset_death_info'|translate}}</span>
        </button>
        <span fxFlex></span>
        <button type="button" mat-raised-button color="primary" (click)="handleSave()" [disabled]="form.invalid">
            <span>{{'shared.save'|translate}}</span>
        </button>
        <button type="button" mat-raised-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
            <span>{{'shared.reset'|translate}}</span>
        </button>
    </div>
</form>
