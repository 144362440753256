<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <mat-icon fontSet="mdi" fontIcon="mdi-account-settings"></mat-icon>
  </div>
  <h2 mat-dialog-title translate="auth.account.label"></h2>
  <span fxFlex></span>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm" (ftAsyncSubmit)="submit()" [subscription]="loader" autocomplete="false">
  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div fxLayout="column" fxLayoutGap="6px" class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">
      <ng-container *ngFor="let user of staff.users; let i = index">
        <mat-form-field fxFlex class="md:col-span-6 col-span-12">
          <mat-label>{{ 'auth.staff.service' | translate }}</mat-label>
          <ft-select required [config]="serviceSelectConfig"
                     [(ngModel)]="staff.service" name="service">
          </ft-select>
        </mat-form-field>
        <mat-form-field fxFlex class="md:col-span-6 col-span-12 with-error-label">
          <mat-label>{{ 'auth.staff.login' | translate }}</mat-label>
          <input matInput required [name]="'username[' + i + ']'"
                 [(ngModel)]="user.username" ftCustomValidation="user_login.title_validation"
                 [query]="{id: user.id}" #login="ngModel"/>
          <mat-error *ngIf="login.errors?.exists"
                     [innerHTML]="'auth.staff.login_already_exist' | translate:user"></mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="md:col-span-6 col-span-12">
          <mat-label>{{ 'auth.staff.profile' | translate }}</mat-label>

          <ft-select required [config]="profileSelectConfig"
                     [(ngModel)]="user.profile" [name]="'profile[' + i + ']'">
          </ft-select>
        </mat-form-field>
        <mat-form-field fxFlex class="md:col-span-6 col-span-12">
          <mat-label>{{ 'auth.staff.password' | translate }}</mat-label>
          <input matInput [required]="!user.id"
                 [name]="'password[' + i + ']'" [(ngModel)]="user.password" type="password">
        </mat-form-field>
        <mat-form-field fxFlex class="md:col-span-6 col-span-12 with-error-label">
          <mat-label>{{ 'auth.staff.password' | translate }}</mat-label>
          <input matInput [required]="!user.id" [placeholder]="'auth.staff.password_confirm' | translate"
                 [name]="'password_confirm[' + i + ']'" [(ngModel)]="user.password_confirm"
                 [ftEqualValidator]="'password[' + i + ']'"
                 #passwordComfirm="ngModel" type="password">
          <mat-error *ngIf="passwordComfirm.invalid">{{ 'auth.account.password_confirm_invalid' | translate }}
          </mat-error>
        </mat-form-field>


      </ng-container>

    </div>
  </div>

  <div mat-dialog-actions>

    <span fxFlex></span>
    <button mat-raised-button type="submit" tabindex="2" color="primary"
            [disabled]="form.invalid || !staff?.users[0]?.password || !staff?.users[0]?.password !=!staff?.users[0]?.password_confirm ">
      <span translate="auth.save"></span>
    </button>
    <button mat-raised-button mat-dialog-close tabindex="-1" color="warn">
      <span translate="auth.cancel"></span>
    </button>
  </div>
</form>
