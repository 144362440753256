import {Component, OnInit} from '@angular/core';
import {PatientService} from "../../services/patient.service";
import {PhysicianService} from "../../../settings/services/physician.service";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {MatDialog} from "@angular/material/dialog";
import {AuthService, DefaultValuesService} from "@ft/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SettingsService} from "../../../settings/services/settings.service";
import {PatientBaseComponent} from "../patient-form/patient-base.component";
import {noop} from 'lodash';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
    selector: 'app-patient-mobile-form',
    templateUrl: './patient-mobile-form.component.html',
    styleUrls: ['./patient-mobile-form.component.scss']
})
export class PatientMobileFormComponent extends PatientBaseComponent {
    afterSave = this.handleAfterSave.bind(this);

    constructor(public patientService: PatientService,
                public physicianService: PhysicianService,
                public _adapter: MomentDateAdapter,
                public dialog: MatDialog,
                public _authService: AuthService,
                public snackBar: MatSnackBar,
                public translateService: TranslateService,
                public router: Router,
                public route: ActivatedRoute,
                public settingService: SettingsService,
                public breakpointObserver: BreakpointObserver,
                public defaultValuesService: DefaultValuesService) {
        super(patientService, physicianService, _adapter, dialog, _authService, snackBar, translateService, router, route, settingService, breakpointObserver, defaultValuesService);
    }

    handleAfterSave(data) {
        this.snackBar.open(this.translateService.instant('shared.save_success'), null, {
            horizontalPosition: 'left',
            duration: 500
        });

        this.router.navigate([`patient/mobile-form/${data['id']}`]).then(noop);
    }
}
