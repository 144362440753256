import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss'],
})
export class PrescriptionComponent implements OnInit {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  public breakpoints = Breakpoints;
  constructor(
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
    .observe(['(max-width: 640px)'])
    .subscribe((result) => {
      if (result.breakpoints['(max-width: 640px)']) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
}
}
