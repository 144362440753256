import { Component, Optional } from '@angular/core';
import { TFUModelType } from '../../../types/telephone-follow-up.type';
import { TfuModelListComponent } from '../tfu-model-list/tfu-model-list.component';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TfuModelService } from '../../../services/tfu-model.service';
import { ResponsiveBaseComponent } from '../../../../shared/components/responsive-base/responsive-base.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pr-tfu-model-list-base',
  templateUrl: './tfu-model-list-base.component.html',
  styleUrls: ['./tfu-model-list-base.component.scss'],
})
export class TfuModelListBaseComponent extends ResponsiveBaseComponent {
  public tfuModels: TFUModelType[] = [];
  public selectedTfuModel: TFUModelType = null;
  public isClassToggled: boolean = false;
  public selectedId: number | null = null;
  constructor(
    @Optional() public dialogRef: MatDialogRef<TfuModelListComponent>,
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    public tfuModelService: TfuModelService
  ) {
    super(breakpointObserver, titleService, translateService);
    this.title = "settings.tfu-models";
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subs.add(
      this.tfuModelService.fetchAll().subscribe({
        next: (tfuModels: TFUModelType[]): void => {
          this.tfuModels = tfuModels;
          
          
        },
      })
    );
  }
}
