import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {assignIn} from 'lodash';
import * as moment from 'moment';
import {AppConfigsService, AuthService} from '@ft/core';

@Component({
  selector: 'app-suspend',
  templateUrl: './suspend.component.html',
  styleUrls: ['./suspend.component.scss']
})
export class SuspendDialogComponent implements OnInit {
  subscription: Subscription;

  model = {
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    reason: null
  };

  constructor(public dialogRef: MatDialogRef<SuspendDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public appConfigService: AppConfigsService, public authService: AuthService) {
  }

  ngOnInit(): void {
  }

  handleSave() {
    this.dialogRef.close(assignIn(this.model, !this.authService.isRoot() ? {
      suspended_by: this.authService.getStaff(),
      status: 'suspended'
    } : {}));
  }
}
