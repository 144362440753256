import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {DebounceSearch} from '@ft/core';
import {FtViewService} from '@ft/table';
import {concat, find, get, head, noop, chain, isNil, assignIn, pick, isEmpty, assign} from 'lodash';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {SharedService} from '../../services/shared.service';
import {Subscription} from "rxjs";

@Component({
    selector: 'pr-cards-view',
    templateUrl: './cards-view.component.html',
    styleUrls: ['./cards-view.component.scss']
})
export class CardsViewComponent extends DebounceSearch implements OnInit, OnDestroy {
    public isMobile = false;
    public data = [];
    page = 0;
    dataLength = 0;
    limit = 50;
    view = null;
    pagesCount = 1;
    viewSubscription: Subscription;
    isFocused: boolean;
    viewSettings = null;
    public breakpoints = Breakpoints;

    customTemplateContent: TemplateRef<any>;
    searchKey = null;
    customFilterActivated = false;

    @Input('customTemplate')
    public set headerCustomTemplate(template: TemplateRef<any>) {
        this.customTemplateContent = template;
    }

    @Input('viewSettings') set handleViewDefinition(settings) {
        this.viewSettings = settings;
    }

    constructor(public ftViewService: FtViewService, public sharedService: SharedService, public breakpointObserver: BreakpointObserver) {
        super();
    }

    ngOnInit(): void {
        this.breakpointObserver
            .observe(['(max-width: 640px)'])
            .subscribe((result) => {
                if (result.breakpoints['(max-width: 640px)']) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
        this.initView();
        this.subscription = this.searchSubscription();

    }

    ngOnDestroy() {
        this.viewSubscription.unsubscribe();
        this.subscription.unsubscribe();
    }

    onScroll(): void {
        if (this.page < this.pagesCount + 1) {
            this.page++;
            this.handleData();
        }
    }

    handleData() {
        this.viewSubscription = this.sharedService.getCardList({
            'configKey': this.viewSettings?.viewSettings?.model,
            'namespace': this.viewSettings?.viewSettings?.defaultName,
            'viewId': this.view.id,
            'query': {
                'search': [],
                'search_all': !isEmpty(this.searchKey) ? {
                    search: this.searchKey,
                    columns: this.viewSettings?.viewSettings?.viewColumns
                } : {},
                'page': {
                    'sortBy': 'id',
                    'sortDirection': 'desc',
                    'page': this.page,
                    'limit': this.limit
                }
            }
        }).subscribe(data => {
            if (data) {
                if (!isEmpty(data.list)) {
                    this.data.push(...data.list);
                }
                if (!this.dataLength) {
                    this.dataLength = get(data, 'length', 0);
                    this.pagesCount = this.dataLength !== 0 ? Math.ceil(this.dataLength / this.limit) : 0;
                }
            }
        });
    }

    initView() {
        this.ftViewService.getConfig(this.viewSettings?.viewSettings?.model).subscribe(data => {
            if (data) {
                this.initTableConfig(data);
            } else {
                this.handleConfig();
            }
        });


    }

    initTableConfig(data) {
        this.handleViewChange(find(data.views, ['is_default', true]) || head(data.views));
    }

    handleViewChange(view) {
        let newestColumns = null;
        this.view = view;
        if (get(view, 'is_system', false)) {
            newestColumns = chain(this.viewSettings?.viewSettings?.viewColumns).reject((e) => {
                return !isNil(find(view.columns, {key: e.key})) || e.key === 'actions';
            }).map((e, idx) => {
                return assignIn(pick(e, ['key']), {
                    order_in: [{name: this.viewSettings?.viewNamespace, value: idx}],
                    show_in: [{name: this.viewSettings?.viewNamespace, allow: true}]
                });
            }).value();
            if (!isEmpty(newestColumns)) {
                this.ftViewService.updateView(get(view, 'id'), {columns: concat(view.columns, newestColumns)})
                    .subscribe(data => noop());
            }
        }
        this.handleData();
    }

    handleConfig() {
        let tableView;
        const default_page_size = 10;
        const default_page_options = [5, 10, 20, 50, 100];
        tableView = assignIn({}, {
            name: get(this.viewSettings?.viewSettings, 'defaultName'),
            is_default: true,
            is_system: true,
            pagination: [{
                name: 'default',
                page_size: default_page_size,
                page_options: default_page_options
            }],

            columns: chain(this.viewSettings?.viewSettings?.viewColumns).map((e, idx) => {
                return assign({
                    order_in: [{name: this.viewSettings?.viewNamespace, value: idx}],
                    show_in: [{name: this.viewSettings?.viewNamespace, allow: true}]
                }, new Object({key: e.key}));
            }).reject({key: 'actions'} as any).value()

        });

        this.ftViewService.handleConfig({
            views: [tableView],
            key: this.viewSettings?.viewSettings?.model
        }).subscribe(data => {
            if (data) {
                this.initTableConfig(data);
            }
        });
    }

    startSearch(key) {
        if (this.data) {
            this.page = 0;
            this.data = [];
        }
        this.searchKey = key;
        this.handleData();
    }

}
