export let lang = {
    level1: 'Niveau1',
    level2: 'Niveau2',
    device_type: 'Type d\'appareil',
    prescription_list: 'Liste des prescriptions',
    prescription_file: 'Fiche prescription',
    status: 'Status',
    external_files_import: 'Fichiers depuis Pratisoft',
    end_date: 'Fin de Traitement',
    refundable: 'Remboursable',
    priority: 'Priorité',
    LOW: 'MINEUR',
    NORMAL: 'STANDARD',
    HIGH: 'IMPORTANT',
    IMMEDIATE: 'URGENT',
    exam_forms: 'Formulaires d\'intervention',
    in_progress: 'En cours',
    expired: 'Expirée',
    non_conforme: 'Non conforme',
    suspended: 'Suspendue',
    new: 'Nouvelle prescription',
    cancellation: 'Résilier',
    cancellation_reason: 'Raison de résiliation',
    cancellation_title: 'Résiliater la prescription',
    reactivate: 'Réactiver',
    close: 'Clôturer',
    closure_reason: 'Commentaire relatif à la clôture',
    closure_title: 'Clôturer la prescription',
    renew: 'Renouveler',
    renew_title: 'Renouveler la prescription',
    renew_comment: 'Commentaire relatif au renouvellement de la prescription',
    renew_start_date: 'Début de Traitement(Dernier Renouvellement)',
    renew_end_date: 'Fin de Traitement (Dernier Renouvellement)',
    suspend: 'Suspendre',
    suspend_title: 'Suspendre',
    suspend_comment: 'Motif de suspension',

    force_close_title: 'Forcer la clôture de la prescription',
    force_close: 'Forcer la clôture',

    force_conform_title: 'Forcer la conformité de la prescription',
    force_conform: 'Forcer la conformité',
    force_conform_reason: 'Motif',
    close_not_allowed_warning: 'Impossible de clôturer cette prescription en raison du nombre insuffisant de rapports!',
    no_element_found: 'Aucune prescription trouvée',
    create_date: 'Date de création',
    choose_patient: 'Patient',
    prescription_details: 'Détails',
    choose_model: 'Choisissez le modèle souhaité',
    prescription_model: 'Modèles des prescriptions',
    confirm_choice: 'Confirmer le choix',
    non_conform_warning: 'Votre prescription n\'est pas conforme! Veuillez vérifier les champs obligatoires.',
    conform_ok: 'Votre prescription est conforme.',
    choose_medical_forms: 'Choisissez les formulaires à prescrire',
    medical_forms: 'Formulaires médicaux',
    satisfaction_forms: 'Formulaires de satisfaction',
    report: 'Rapport',
    report_count: 'Nombre de rapports',
    intervention_map_address:"Adresse d'intervention (Lien Google Map)",
    add_follow_up_plan: 'Ajouter un plan de suivi',
    tutorials:'Tutoriels',
general_infos:"Général"
};
