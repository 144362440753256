export let lang = {
  'items-per-page': 'Lignes par Page',
  'next-page': 'Page suivante',
  'previous-page': 'Page précédente',
  of: 'de',
  search: 'Rechercher',
  table_search: 'Rechercher      ex: abc...| 123... | AAAA-MM-JJ',
  mobile_table_search: 'Rechercher',
  no_element_to_show: 'Aucun élément trouvé',
  add: 'Ajouter',
  save: 'Enregistrer',
  next: 'Suivant',
  reset: 'Annuler',
  edit: 'Modifier',
  delete: 'Supprimer',
  title: 'Titre',
  temp_title: '',
  comment: 'Commentaire',
  prescription: 'Prescription',
  history: 'Historique',
  date: 'Date',
  note: 'Commentaire',
  print: 'Imprimer',
  file_manager: 'Pièces jointes',
  upload_file_error: "Erreur d'upload",
  upload_success: 'Upload terminé',
  patients: 'Patients',
  prescriptions: 'Prescriptions',
  settings: 'Paramètres',
  delete_error: 'Suppression Impossible',
  states: 'États',
  table_items_per_page_label: 'Lignes par Page',
  table_next_page_label: 'Page suivante',
  table_previous_page_label: 'Page précédente',
  table_first_page_label: 'Première page',
  table_last_page_label: 'Dernière page',
  range_page_label_1: '0 de {{length}}',
  range_page_label_2: '{{startIndex}} - {{endIndex}} de {{length}}',
  start_date: 'Début de traitment',
  city: 'Ville',
  country: 'Pays',
  age: 'Age',
  months: ' (mois)',
  clean: 'vider',
  address: 'Adresse',
  already_exist: 'Ce nom est déjà utilisé',
  canceled: 'Supprimée',
  waiting_list: 'à planifier',
  waiting: 'En attente',
  entered: "En cours d'execution",
  missed: 'Raté',
  force_completed: 'Terminé (forcé)',
  planned: 'Planifiée',
  unplanned: 'Sans RDV',
  draft: 'Sans RDV',
  closed: 'Clôturée',
  color: 'Couleur',
  new: 'Nouveau',
  save_success: 'Enregistré avec succès!',
  display: 'Afficher',
  write_comment: 'Écrire un commentaire',
  comments: 'Commentaires',
  type: 'Type',
  role: 'Profile',
  sms_msg_content: 'Contenu',
  send_sms_dialog_title: 'Nouveau SMS',
  send_sms: 'Envoyer',
  patient_fullname_shortcut: 'Patient.Nom complet',
  physician_fullname_shortcut: 'Médecin.Nom complet',
  patient: 'Patient',
  physician: 'Médecin',
  all: 'Tous',
  send_to: 'Envoyer au',
  name: 'Nom',
  description: 'Description',
  deactivate: 'Désactiver',
  sms_err_invalid_config: 'Erreur dans la configuration du serveur SMS',
  sms_err_sms_server_missed: 'Configuration du serveur SMS introuvable',
  sms_error_invalid_phone_number: 'Numéro de téléphone invalide',
  sms_err_send_request_issue: 'Votre requête a rencontrer un problème!',
  sms: 'SMS',
  sms_err_msg_1: 'Votre requête a rencontrer une ou plusieurs erreurs!',
  sms_err_msg_2: 'Afficher les erreurs',
  sms_err_authentication_failed: 'Authentification au service SMS non aboutie.',
  sms_err_invalid_recipient_1: 'Numéro de telephone incorrecte',
  sms_err_body_field_is_empty: 'Corps du message vide.',
  sms_err_invalid_params: 'Paramétres SMS erronés.',
  sms_err_invalid_phone_number: 'Numéro de téléphone invalide',
  send_sms_acl: 'Envoyer SMS',
  patient_sms_error_label: 'Patient: ',
  physician_sms_error_label: 'Médecin: ',
  sms_server_config: 'Configuration du serveur sms',
  save_error: 'Enregistrement Impossible',
  from_date: 'De',
  to_date: 'À',
  disconnect: 'Se déconnecter',
  general_infos: 'Informations générales',
  apply: 'Appliquer',
  choose_template: 'Choisir un template',
  confirm_password: 'Confirmer le mot de passe',
  select: 'Sélectionner',
  tickets: 'Tickets',
  yes: 'Oui',
  no: 'Non',
  cancel: 'Annuler',
  ok: 'Ok',
  reply: 'Répondre',
  write_reply: 'Ecrire une reponse',
  treated: 'Traité',
  not_treated: 'Non traité',
  appointments: 'Rendez-vous',
  link: "Lien (vers l'objet concerné)",
  the_comment_has_been_deleted: 'Le commentaire a eté supprimé',
  select_channels: 'Choisir un ou plusieurs canaux',
  to_app: 'Sur application',
  email: 'Email',
  app_notification_template: 'Template de notification dans application',
  users: 'Utilisateurs',
  select_recipient: 'Sélectionner le destinataire',
  profiles: 'Profils',
  recurrent: 'Récurrent',
  frequency_unit: "Unité de fréquence",
  select_frequency_unit : "Sélectionner l'unité de fréquence de rappel",
  enter_title : "Saisir un titre",
  enter_reminder_period :"Saisir la fréquence de rappel",
  select_recurrence_duration :"Sélectionner la durée de récurrence",
  enter_end_date :"Saisir la date de fin",
  add_intervals_before_event: "Ajouter des intervalles de notification avant l'événement",
  add_interval: "Ajouter un intervalle",
  interval: "Intervalle",
  interval_unit: "Unité de l'intervalle",
  select_interval_unit: "Sélectionner l'unité de l'intervalle",
  enter_interval_value: "Saisir la valeur de l'intervalle",
  please_select_frequency_unit: 'Veuillez sélectionner une unité de fréquence',
  confirm_delete: 'Confirmer la suppression',
  please_select_date: 'Veuillez sélectionner une date',
  please_enter_time: 'Veuillez saisir une heure',
  hour:"Heure",
  please_enter_reminder_period: 'Veuillez saisir la période de rappel',
  please_select_recurrence_duration: 'Veuillez sélectionner la durée de récurrence',
  please_select_until_date: 'Veuillez sélectionner la date de fin',
  please_select_interval_unit: 'Veuillez sélectionner l\'unité de l\'intervalle',
  please_enter_interval_value: 'Veuillez saisir la valeur de l\'intervalle',
  please_enter_title: 'Veuillez saisir un titre',
  changes_saved_successfully: 'Modifications enregistrées avec succès', //'Mis à jour avec succès',
  new_element_added_successfully: 'Nouvel élément ajouté avec succès',
  item_deleted_successfully: 'Élément deleted successfully',
  dismiss: 'Fermer',
  there_are_no_users_in_the_above_profiles: 'Il n\'y a aucun utilisateur dans les profils ci-dessus',
  save_any_way: 'Enregistrer quand même',
  controls: {years: '(ans)'},
  user_account_exist:'Compte utilisateur Actif'
};
