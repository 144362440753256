import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {FILES_COLUMNS} from '../../utils/files-columns';
import {PrescriptionService} from '../../services/prescription.service';
import {get} from 'lodash';
import {TableColumnDefinition} from '@ft/core';


@Component({
  selector: 'external-files-dialog',
  templateUrl: './external-files-dialog.component.html',
  styleUrls: ['./external-files-dialog.component.scss'],
})
export class ExternalFilesDialogComponent implements OnInit {
  subscription: Subscription;
  physicians = [];
  displayedColumns = new BehaviorSubject<TableColumnDefinition[]>(FILES_COLUMNS);
  dataSource: Observable<any>;
  patient = null;
  selectedItems = [];

  constructor(public dialogRef: MatDialogRef<ExternalFilesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private prescriptionService: PrescriptionService,
              private _adapter: MomentDateAdapter ) {
    this.patient = get(data, 'patient');
  }

  ngOnInit(): void {
    this.dataSource = this.prescriptionService.getPatientExternalFiles(this.patient);
  }

  handleSelect(ev) {
    this.dialogRef.close(this.selectedItems);
  }

}
