export let patientAppointmentEn: Record<string, any> = {
    appointment_list: 'List of appointments',
    action: {
        confirm: 'confirm',
        cancel: 'cancel',
        reschedule: 'reschedule',
    },
    confirm_appointment_question: 'Do you want to confirm this appointment?',
    request_rescheduling : 'Request rescheduling',
    request_cancellation : 'Request cancellation',
};
