import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@ft/core';
import { PatientRequestContainerComponent } from './components/patient-request-container/patient-request-container.component';
import { PatientRequestListComponent } from './components/patient-request-list/patient-request-list.component';


const routes: Routes = [
  {
    path: '',
    component: PatientRequestContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', component: PatientRequestListComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientRequestRoutingModule {}
