import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {has, get} from 'lodash';
import {FtWsService} from '@ft/core';
import {Observable, catchError, finalize, of} from 'rxjs';
import {UIService} from 'src/app/shared/services/ui.service';
import {
  PathParamsType,
  TicketType,
} from 'src/app/ticket/ticket.type';

type UpdateObjectType = {
  id?: number;
  dataAPIAction?: string;
  [key: string]: any;
};


@Injectable({
  providedIn: 'root',
})
export class DataApiService<T> {
  private _url = '/api/pl';

  constructor(
    public _httpClient: HttpClient,
    private _uiService: UIService
  ) {
  }

  public setRootUrl(urlApp?: string) {
    this._url = urlApp;
  }

  public getRootUrl(): string {
    return this._url;
  }

  /**
   * Creation of an entity of type T, in POST
   * @param object : the object to create
   */
  public create(object: T): Observable<any> {
    const url = this._getUrl();

    return this._httpClient
      .post(url, JSON.stringify(object), {headers: this._setHeadersJson()})
      .pipe(
        finalize(() => {
          //   this.clearCache();
          //   this.loadingSubject.next(false);
          this._uiService.openSnackBar('shared.new_element_added_successfully');
        }),
        catchError((error) => this._catchError(error))
      );
  }

  /**
   * Update of a T entity, in PUT
   * @param object The object to be modified
   * @param key Allows (optional) to specify the key priority of the object to modify, by default 'id'.
   */
  public update(object: UpdateObjectType, showSnakeBar: boolean = true): Observable<any> {
    // this.loadingSubject.next(true);

    const url = this._getUrl(object['id'], object['dataAPIAction']);

    return this._httpClient.put(url, object).pipe(
      finalize(() => {
        // this.clearCache();
        // this.loadingSubject.next(false);
        if (showSnakeBar) this._uiService.openSnackBar('shared.changes_saved_successfully');
      }),
      catchError((error) => this._catchError(error))
    );
  }

  /**
   * Gets an entity T
   * @param id : the required id
   */
  public fetch(pathParams: PathParamsType, url = ''): Observable<any> {
    const generatedUrl = url || this._getUrl(pathParams.id, pathParams.name);

    return this._httpClient
      .get(generatedUrl)
      .pipe(catchError((error) => this._catchError(error)));
  }

  public getImage(url: string): Observable<Blob | any> {
    return this._httpClient.get(url, {responseType: 'blob'}).pipe(
      catchError(catchError((error) => this._catchError(error)))
    );
  }

  /**
   * Gets the list of entities T
   */
  public fetchAll(url: string = null, query_params: string = ''): Observable<any> {
    return this._fetchAll(url, query_params);
  }

  /**
   * Deletion of an entity by its id
   * @param id id of the entity to delete
   */
  public deleteById(id: number) {
    return this._delete(id);
  }

  /***
   * PRIVATE
   ****/

  /**
   * Setting the type of data passed via http headers as application/json
   * for POST
   * @private
   */
  private _setHeadersJson(): HttpHeaders {
    const headers = new HttpHeaders();

    return headers.append('content-type', 'application/json');
  }


  /**
   * Gets the list of entities T
   */
  private _fetchAll(url: string, query_params: string): Observable<any> {
    const newUrl = url || this._getUrl();
    return this._httpClient.get(`${newUrl}?${query_params}`).pipe(
      // finalize(() => {
      //   // this.clearCache();
      //   // this.loadingSubject.next(false);
      //   this._uiService.showSnackBar(error.error.detail, undefined, 3000);
      // }),
      catchError((error) => this._catchError(error))
    );
  }

  /**
   * Deletion of an entity by its id
   * @param id
   * @private
   */
  private _delete(id: number): Observable<Object | string | boolean> {
    // this.loadingSubject.next(true);
    const url = this._getUrl(id);

    return this._httpClient.delete(url).pipe(
      finalize(() => {
        // this.loadingSubject.next(false);
        this._uiService.openSnackBar('shared.item_deleted_successfully');
      }),
      catchError((error) => this._catchError(error))
    );
  }

  /**
   * Determines the API URL to use, with or without id
   * @param id
   * @param name
   * @private
   */
  private _getUrl(id?: number, dataAPIAction?: string) {
    let url = this._url + '/';

    if (id) url = `${url}${id}/`;
    if (dataAPIAction) url = `${url}${dataAPIAction}/`;

    return url;
  }

  private _catchError(error: HttpErrorResponse) {
    let message = error.message;
    const errorMsg = error.error.detail || error.message;

    switch (error.status) {
      case 401:
        message = `Unauthorized access, please login (${errorMsg})`;
        break;
      case 403:
        message = `Access denied, you do not have sufficient rights (${errorMsg})`;
        break;
      case 404:
        // message = `Not Found (${errorMsg})`;
        console.error(error);
        throw error;
        break;
    }
    console.error(error);

    // this._uiService.showSnackBar(message, undefined, 3000);
    // this._uiService.openSnackBar(message);
    // throw 'error in source. Details: ' + error;
    return of(false);
  }
}
