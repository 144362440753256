import { Observable, of } from 'rxjs';
import { PatientRequestType } from '../../patient-request/patient-request.type';

export let patientRequestSample = (): Observable<PatientRequestType[]> => {
  return of([
    {
      id: 1,
      type: 'CANCEL_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: true,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 1,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'Planner PCI reboot Shoes Cheese',
            },
          ],
        }
      },
    },
    {
      id: 2,
      content: 'pour le 15 novembre à 15 heures',
      type: 'RESCHEDULE_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 2,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'hack Configurable magenta Facilitator Micronesia',
            },
          ],
        }
      },
    },
    {
      id: 3,
      content: 'email: test@mail.com',
      type: 'PATIENT_EDIT_INFO',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
    },
    {
      id: 4,
      content: 'pour le 15 novembre à 15 heures',
      type: 'RESCHEDULE_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 2,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'hack Configurable magenta Facilitator Micronesia',
            },
          ],
        }
      },
    },
    {
      id: 5,
      content: 'pour le 15 novembre à 15 heures',
      type: 'RESCHEDULE_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 2,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'hack Configurable magenta Facilitator Micronesia',
            },
          ],
        }
      },
    },
    {
      id: 6,
      content: 'pour le 15 novembre à 15 heures',
      type: 'RESCHEDULE_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 2,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'hack Configurable magenta Facilitator Micronesia',
            },
          ],
        }
      },
    },
    {
      id: 7,
      content: 'pour le 15 novembre à 15 heures',
      type: 'RESCHEDULE_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 2,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'hack Configurable magenta Facilitator Micronesia',
            },
          ],
        }
      },
    },
    {
      id: 8,
      content: 'pour le 15 novembre à 15 heures',
      type: 'RESCHEDULE_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 2,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'hack Configurable magenta Facilitator Micronesia',
            },
          ],
        }
      },
    },
    {
      id: 9,
      content: 'pour le 15 novembre à 15 heures',
      type: 'RESCHEDULE_REQUESTED',
      request_treated: {
        performer_id: 1,
        is_treated: false,
        created_at: '2023-11-17',
      },
      appointment: {
        id: 2,
        ticket: {
          ticket_content_histories: [
            {
              reason: 'hack Configurable magenta Facilitator Micronesia',
            },
          ],
        }
      },
    },
  ]);
};
