<div class="pr-content-container">
  <div class="pr-content-header">
    <div class="list-title text-h4 font-bold">
      {{ "settings.telephone-follow-up-plan-model" | translate }}
    </div>
    <button mat-icon-button [routerLink]="'/settings/tfu-model'">
      <mat-icon fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
    </button>
  </div>
  <div class="pr-content-body">
    <form
      class="settings flex flex-col gap-4"
      [formGroup]="reactiveForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="settings-section">
        <div class="settings-sub-section">
          <div class="form-field-label">
            {{ "settings.enter_title" | translate }}:
          </div>
          <mat-form-field appearance="outline">
            <!-- <input formControlName="title" matInput type="text" /> -->
            <input
              matInput
              formControlName="title"
              [placeholder]="'settings.title' | translate"
              type="text"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="settings-section-separator">
        * * * * * *
      </div>
      <div
        class="settings-section flex flex-col gap-4"
        [ngClass]="{ 'opacity-50': !isFormControlsValid() }"
      >
        <div class="form-field-label">
          {{ "settings.steps_follow_up" | translate }}:
        </div>
        <div
          class="notif-step-container flex flex-col gap-4"
          *ngIf="tfuObj?.items?.length"
        >
          <div
            class="notif-step"
            *ngFor="
              let tfuItem of tfuObj?.items;
              trackBy: trackByFunction;
              let i = index
            "
          >
            <div class="settings-sub-section">
              <div class="notif-step-header">
                <div class="flex-grow">
                  {{
                    tfuItem.title
                      ? tfuItem.title
                      : ("settings.step_follow_up" | translate) + " " + (i + 1)
                  }}
                </div>
                <div class="cursor-pointer" (click)="editTfuItem(tfuItem.oid)">
                  <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                </div>
                <div
                  class="cursor-pointer"
                  (click)="removeTfuItem(tfuItem.oid)"
                >
                  <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-delete-outline"
                  ></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="centred-add-btn"
          (click)="addTfuItem()"
          [disabled]="!isFormControlsValid()"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-plus-circle-outline"></mat-icon>
          <span>{{ "settings.add_step_follow_up" | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
