
export const TITLES = [
  {
    value: 'Mr',
    'gender': 'MALE'
  },
  {
    value: 'Mlle',
    gender: 'FEMALE'
  },
  {
    value: 'Mme',
    gender: 'FEMALE'
  },
  {
    value: 'Dr.',
    gender: 'UNDEFINED'
  },
  {
    value: 'Pr.',
    gender: 'UNDEFINED'
  },
  {
    value: 'Garçon',
    gender: 'MALE'
  },
  {
    value: 'Fille',
    gender: 'FEMALE'
  },
  {
    value: 'Bébé',
    gender: 'UNDEFINED'
  }];


export const EMPTY_TEMPLATE = {
  id: null,
  name: '',
  label: '',
  blocs: [],
  is_default:false
};

export const projectName = 'PratiLab';

export const TIME_UNITS: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];
