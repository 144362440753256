import {SYSTEM_COLUMNS} from '../../shared/utils/common-system-column';
import {PHYSICIAN_COLUMNS} from './physician-columns';
import {SPECIALITY_COLUMNS} from "./speciality-columns";

export const PHYSICIAN_VIEW = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'physicianList',
  query: [],
  viewSettings: {
    'model': 'staff.Physician',
    'viewColumns': PHYSICIAN_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des médecins'
  },
  actions: [],
  resource: 'physician'
};

export const SPECIALITY_VIEW = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'specialityList',
  query: [],
  viewSettings: {
    'model': 'staff.Speciality',
    'viewColumns': SPECIALITY_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des spécialités'
  },
  actions: [],
  resource: 'speciality'
};
