import { Injectable } from '@angular/core';
import { DataApiService } from '../../shared/services/data-api.service';
import { HttpClient } from '@angular/common/http';
import { ActionNotifierType } from '../types/action-notifier.type';
import { UIService } from '../../shared/services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class ActionNotifierService extends DataApiService<ActionNotifierType> {
  constructor(public httpClient: HttpClient, public uiService: UIService) {
    super(httpClient, uiService);
    this.setRootUrl('/api/pl/action-notifier');
  }
}
