import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Optional } from '@angular/core';
import { TfuModelService } from '../../../services/tfu-model.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TfuModelListBaseComponent } from '../tfu-model-list-base/tfu-model-list-base.component';
import { Router } from '@angular/router';
import { TFUModelType } from '../../../types/telephone-follow-up.type';
import { noop } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'pr-tfu-model-list',
  templateUrl: './tfu-model-list.component.html',
  styleUrls: ['./tfu-model-list.component.scss'],
})
export class TfuModelListComponent extends TfuModelListBaseComponent {
  constructor(
    @Optional() public dialogRef: MatDialogRef<TfuModelListComponent>,
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    public tfuModelService: TfuModelService,
    private _router: Router,
    public dialog: MatDialog
  ) {
    super(
      dialogRef,
      breakpointObserver,
      titleService,
      translateService,
      tfuModelService
    );
  }

  public addTfuModel(event) {
    this._router.navigate([`settings/tfu-model/`, 'new']).then(noop);
  }

  public removeTfuModel(event, tfuModelId: number) {
    event.stopPropagation();
    this.subs.add(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'settings.delete_follow_up_model_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) {
              this.subs.add(
                this.tfuModelService.deleteById(tfuModelId).subscribe({
                  next: (tfuModel: TFUModelType): void => {
                    this.tfuModels = this.tfuModels.filter(
                      (item) => item.id !== tfuModelId
                    );
                  },
                })
              );
            }
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }

  navigateToTfuModel(tfuModelId: string): void {
    // Navigate to the desired route
    this._router.navigate(['/settings/tfu-model/', tfuModelId]);
  }
}
