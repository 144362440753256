<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <button type="button" mat-icon-button tabindex="-2">
            <mat-icon fontSet="mdi" fontIcon="mdi-email-plus-outline"></mat-icon>
        </button>
    </div>
    <h2 translate="shared.send_sms_dialog_title"></h2>
    <span fxFlex></span>
    <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>

<div [ngBusy]="subscription"></div>
<form novalidate #form="ngForm">
    <div [ngBusy]="subscription"></div>
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div fxLayout="column" fxFlex fxLayoutGap="6px">
            <mat-form-field floatLabel="always" class="ft-radio-field">
                <input matInput placeholder="{{'shared.send_to'|translate}}" style="display: none">
                <mat-radio-group [(ngModel)]="send_to" name="send_to" fxLayout="row">
                    <mat-radio-button *ngFor="let b_type of ['patient','physician', 'all']" [value]="b_type">
                        {{'shared.' + b_type | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </mat-form-field>
            <div class="button-group">
                <button type="button" mat-flat-button color="primary"
                        (click)="updateContentPatient($event);$event.stopImmediatePropagation();">
                    {{'shared.patient_fullname_shortcut'|translate}}
                </button>
                <button type="button" mat-flat-button color="primary"
                        (click)="updateContentPhysician($event);$event.stopImmediatePropagation();">
                    {{'shared.physician_fullname_shortcut'|translate}}
                </button>
            </div>

            <mat-form-field
                    class="md:col-span-3 col-span-12"
            >
                <mat-label>{{'shared.choose_template' | translate}}</mat-label>
                <mat-select [(ngModel)]="template" name="template">
                    <mat-option *ngFor="let p of templates"
                                [value]="p">
                        {{p?.title}}
                    </mat-option>
                </mat-select>
                <button *ngIf="template"
                        matSuffix mat-icon-button type="button" color="primary"
                        [matTooltip]="'shared.apply' | translate "
                        (click)="$event.stopImmediatePropagation(); handleSmsTemplate(template)">
                    <mat-icon fontSet="mdi" fontIcon="mdi-text-box-check-outline"></mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFlex>
            <textarea matInput name="address" rows="7" #inp
                      placeholder="{{'shared.sms_msg_content'|translate}}"
                      [(ngModel)]="content"></textarea>
            </mat-form-field>
        </div>
        <div *ngIf="errorMessage">
            <p *ngFor="let m of errorMessage" class="ft-warn-color">{{m}}</p>
        </div>
    </div>
</form>


<div mat-dialog-actions>
    <span fxFlex></span>
    <button type="button" color="primary" mat-flat-button tabindex="2" (click)="handleSend($event)"
            [disabled]="!content">
        {{'shared.send_sms'|translate}}
    </button>
    <button type="button" mat-flat-button color="warn" mat-dialog-close>
        <span>{{'shared.reset'|translate}}</span>
    </button>
</div>
