import {Component, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from '../../services/settings.service';
import {get, head, cloneDeep, omit} from 'lodash';

import {trackByFn} from '../../../shared/utils/functions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppConfigsService, GeneralConfigsService} from '@ft/core';

@Component({
    selector: 'app-sms-setup',
    templateUrl: './sms-setup.component.html',
    styleUrls: ['./sms-setup.component.scss']
})
export class SmsSetupComponent implements OnInit {
    subscription: Subscription;

    multiActions = [];
    displayedColumns = [
        {
            'label': 'shared.date',
            'key': 'created_at',
            'sortBy': 'created_at',
            'type': 'date',
            'is_global_searchable': true
        },
        {
            'label': 'settings.status',
            'key': 'response.error',
            'type': 'icon',
            'callBack': (row) => {
                return 'mdi-circle';
            },
            'style': (row) => {
                return !get(row, 'response.error') ? {color: '#00FF00'} : {color: '#FF0000'};
            }
        },
        {
            'label': 'settings.phone_number',
            'key': 'to',
            'sortBy': 'to',
            'type': 'text',
            'is_global_searchable': true
        },
        {
            'label': 'settings.content',
            'key': 'content',
            'sortBy': 'content',
            'type': 'text',
            'is_global_searchable': true
        }
    ];
    dataSource: Observable<any[]> = of([]);
    smsServer = {
        identifier: null,
        user_name: null,
        password: null,
        confirmation_password: null,

    };
    showPassword = false;
    showConfirmPassword = false;
    currentItem = null;
    templates = [];
    public trackByFn = trackByFn('id');
    smsTemplate: any = {content: '', title: null};
    @ViewChild('inp') inp: any;
    @ViewChild('inpTitle') inpTitle: any;
    tabIdx = 0;

    constructor(private appConfigService: AppConfigsService, private generalConfigService: GeneralConfigsService, public settingsService: SettingsService, public translateService: TranslateService, protected snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.subscription = forkJoin([this.settingsService.getSmsHistory(), this.generalConfigService.getAppConfig('sms_server'),
            this.settingsService.getSmsTemplates()]).subscribe(data => {
            this.dataSource = of(data[0]);
            this.smsServer = (omit( data[1] , ['password', 'confirmation_password']) as any);
            this.templates = data[2];
        });
    }

    handlePassword(ev) {

    }

    handleConfirmPassword(ev) {

    }

    handleActivate() {
    }

    handleDeactivate() {
    }

    save() {
        this.generalConfigService.setAppConfig({'sms_server': this.smsServer}).subscribe(data => {
            this.smsServer = (omit(this.smsServer, ['password', 'confirmation_password']) as any);
            this.snackBar.open(this.translateService.instant('shared.save_success'));
        });
    }

    saveTemplate() {
        this.subscription = this.settingsService.saveSmsTemplate(this.smsTemplate).subscribe(data => {
            this.smsTemplate = data;
            this.snackBar.open(this.translateService.instant('shared.save_success'));
            this.reloadSmsTemplates();
        });
    }

    reloadSmsTemplates() {
        this.settingsService.getSmsTemplates().subscribe(data => {
            this.templates = data;
        });
    }

    updateContentPatient(ev) {
        this.smsTemplate.content = this.smsTemplate.content + `{{patient.full_name}} `;
        this.inp.nativeElement.focus();
    }

    updateContentPhysician(ev) {
        this.smsTemplate.content = this.smsTemplate.content + `{{physician.full_name}} `;
        this.inp.nativeElement.focus();
    }

    showTemplate(t) {
        this.smsTemplate = cloneDeep(t);
    }

    removeTemplate(t) {
        this.subscription = this.settingsService.deleteSmsTemplate(this.smsTemplate['id']).subscribe(data => {
            this.smsTemplate = {content: '', title: null};
            this.reloadSmsTemplates();
        });
    }

    onTabChange(ev) {
        this.tabIdx = ev.index;
    }

    addSmsTemplate() {
        this.smsTemplate = {content: '', title: null};
        this.inpTitle.nativeElement.focus();

    }

}
