import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pr-compose-dialog',
  templateUrl: './compose-dialog.component.html',
  styleUrls: ['./compose-dialog.component.scss']
})
export class ComposeDialogComponent implements OnInit {
  public commentText: string = '';
  constructor(
    public dialogRef: MatDialogRef<ComposeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
  public onClose(): void {
    this.dialogRef.close();
  }

  handleSave(pass: boolean = false) {
    this.dialogRef.close(pass ? {content: this.commentText, objId: this.data.objId} : {content: null});

}
}
