<div class="ft-parent-layout ft-content-margin " fxFlex fxLayout="column">

  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <mat-icon fontSet="mdi" fontIcon="mdi-wrench"></mat-icon>
    </div>
    <h2 translate="settings.base_settings"></h2>
  </mat-toolbar>
  <div fxFlex fxLayout="column" class="ft-content no-padding">

    <form #form="ngForm" fxLayout="column" class="ft-padding" [ngBusy]="subscription">
      <div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field fxFlex="20">
          <mat-label>{{'shared.country' | translate}}</mat-label>
          <mat-select [(ngModel)]="country" name="country"
                      [compareWith]="compareCondition"
                      ftDefaultValues="country"
                      [updateOnChange]="true"
          >
            <mat-option *ngFor="let t of countries" [value]="t">
              {{t.full_name}}
            </mat-option>
          </mat-select>
          <button *ngIf="country" matSuffix mat-icon-button type="button"
                  color="warn" [matTooltip]="'shared.clean' | translate "
                  (click)="$event.stopImmediatePropagation(); country=null;">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <mat-label>{{'shared.city' | translate}}</mat-label>
          <mat-select [(ngModel)]="city" name="city"
                      [compareWith]="compareCondition"
                      ftDefaultValues="city"
                      [updateOnChange]="true">
            <mat-option *ngFor="let t of cities" [value]="t">
              {{t.full_name}}
            </mat-option>
          </mat-select>
          <button *ngIf="city" matSuffix mat-icon-button type="button"
                  color="warn" [matTooltip]="'shared.clean' | translate "
                  (click)="$event.stopImmediatePropagation(); city=null;">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <div fxLayout="column">
          <h3 class="bold-text" translate="settings.prescription_states"></h3>
          <div class="table-container bordered">
            <mat-table [dataSource]="prescriptionStateColors" fxFlex="nogrow">
              <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef> {{'settings.state'|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let item"> {{'shared.' + item.name | translate}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef class="ft-icon-cell"
                                 style="min-width:200px">{{'shared.color'|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let item" class="ft-icon-cell" style="min-width:200px">
                  <button mat-icon-button type="button"
                          ftColorPicker name="prescriptionStateColors.{{item.name}}"
                          [(ngModel)]="item.color"
                          (ngModelChange)="handleChange()"

                  >
                    <mat-icon [style.color]="item.color" fontSet="mdi" fontIcon="mdi-circle">
                    </mat-icon>
                  </button>
                  <span style="flex: 1 1 100%">{{item.color}}</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="['state', 'color']">
              </mat-header-row>
              <mat-row
                *matRowDef="let row; columns: ['state', 'color']">
              </mat-row>

            </mat-table>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <div fxLayout="column">
          <h3 class="bold-text" translate="settings.prescription_templates_colors"></h3>
          <div class="table-container bordered">
            <mat-table [dataSource]="prescriptionTemplatesColors" fxFlex="nogrow">
              <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef> {{'shared.title'|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let item"> {{ item.name  }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef class="ft-icon-cell"
                                 style="min-width:200px">{{'shared.color'|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let item" class="ft-icon-cell" style="min-width:200px">
                  <button mat-icon-button type="button"
                          ftColorPicker name="prescriptionTemplatesColors.{{item.name}}"
                          [(ngModel)]="item.color"
                          (ngModelChange)="handleTemplateColorChange()"

                  >
                    <mat-icon [style.color]="item.color" fontSet="mdi" fontIcon="mdi-circle">
                    </mat-icon>
                  </button>
                  <span style="flex: 1 1 100%">{{item.color}}</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="['state', 'color']">
              </mat-header-row>
              <mat-row
                *matRowDef="let row; columns: ['state', 'color']">
              </mat-row>
            </mat-table>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <div fxLayout="column">
          <h3 class="bold-text" translate="settings.ticket_status_colors"></h3>
          <div class="table-container bordered">
            <mat-table [dataSource]="ticketColors" fxFlex="nogrow">
              <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef> {{'shared.title'|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let item"> {{('settings.'+ item.name) |translate }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="bgColor">
                <mat-header-cell *matHeaderCellDef class="ft-icon-cell"
                                 style="min-width:200px">bgColor</mat-header-cell>
                <mat-cell *matCellDef="let item" class="ft-icon-cell" style="min-width:200px">
                  <button mat-icon-button type="button"
                          ftColorPicker name="ticketColors.{{item.name}}"
                          [(ngModel)]="item.bgColor"
                          (ngModelChange)="handleTicketColorChange()"

                  >
                    <mat-icon [style.color]="item.bgColor" fontSet="mdi" fontIcon="mdi-circle">
                    </mat-icon>
                  </button>
                  <span style="flex: 1 1 100%">{{item.bgColor}}</span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="txtColor">
                <mat-header-cell *matHeaderCellDef class="ft-icon-cell"
                                 style="min-width:200px">txtColor</mat-header-cell>
                <mat-cell *matCellDef="let item" class="ft-icon-cell" style="min-width:200px">
                  <button mat-icon-button type="button"
                          ftColorPicker name="ticketColors.{{item.name}}"
                          [(ngModel)]="item.txtColor"
                          (ngModelChange)="handleTicketColorChange()"

                  >
                    <mat-icon [style.color]="item.txtColor" fontSet="mdi" fontIcon="mdi-circle">
                    </mat-icon>
                  </button>
                  <span style="flex: 1 1 100%">{{item.txtColor}}</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="['state', 'bgColor' ,'txtColor']">
              </mat-header-row>
              <mat-row
                *matRowDef="let row; columns: ['state', 'bgColor', 'txtColor']">
              </mat-row>
            </mat-table>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="6px">
        <div fxLayout="column">
          <h3 class="bold-text" translate="settings.appointment_status_colors"></h3>
          <div class="table-container bordered">
            <mat-table [dataSource]="appointmentColors" fxFlex="nogrow">
              <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef> {{'shared.title'|translate}}</mat-header-cell>
                <mat-cell *matCellDef="let item"> {{('settings.'+ item.name) |translate }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="bgColor">
                <mat-header-cell *matHeaderCellDef class="ft-icon-cell"
                                 style="min-width:200px">bgColor</mat-header-cell>
                <mat-cell *matCellDef="let item" class="ft-icon-cell" style="min-width:200px">
                  <button mat-icon-button type="button"
                          ftColorPicker name="appointmentColors.{{item.name}}"
                          [(ngModel)]="item.bgColor"
                          (ngModelChange)="handleAppointmentColorsChange()"

                  >
                    <mat-icon [style.color]="item.bgColor" fontSet="mdi" fontIcon="mdi-circle">
                    </mat-icon>
                  </button>
                  <span style="flex: 1 1 100%">{{item.bgColor}}</span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="txtColor">
                <mat-header-cell *matHeaderCellDef class="ft-icon-cell"
                                 style="min-width:200px">txtColor</mat-header-cell>
                <mat-cell *matCellDef="let item" class="ft-icon-cell" style="min-width:200px">
                  <button mat-icon-button type="button"
                          ftColorPicker name="appointmentColors.{{item.name}}"
                          [(ngModel)]="item.txtColor"
                          (ngModelChange)="handleAppointmentColorsChange()"

                  >
                    <mat-icon [style.color]="item.txtColor" fontSet="mdi" fontIcon="mdi-circle">
                    </mat-icon>
                  </button>
                  <span style="flex: 1 1 100%">{{item.txtColor}}</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="['state', 'bgColor' ,'txtColor']">
              </mat-header-row>
              <mat-row
                *matRowDef="let row; columns: ['state', 'bgColor', 'txtColor']">
              </mat-row>
            </mat-table>
          </div>
        </div>
      </div>


    </form>

  </div>
</div>
