<div class="pr-content-container">
  <div class="pr-content-header">
    <div class="list-title text-h4 font-bold">
      {{ "settings.a_step_follow_up" | translate }}
      <div
        *ngIf="!isModel"
        class="tag text-subtitle2 border-0"
        [ngClass]="tfuItem?.done ? 'tag-done' : 'tag-standard'"
      >
        {{
          (tfuItem?.done ? "settings.done" : "settings.in_progress") | translate
        }}
        <!-- {{ "settings.done" | translate }} -->
      </div>
    </div>
    <button mat-button color="accent" [mat-dialog-close]="false">
      {{ "shared.cancel" | translate }}
    </button>
    <button mat-button color="primary" (click)="saveTfuItem($event)">
      {{ "shared.save" | translate }}
    </button>
  </div>
  <div class="pr-content-body">
    <form
      class="settings flex flex-col gap-4"
      [formGroup]="reactiveForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="settings-section">
        <div class="settings-sub-section">
          <div class="form-field-label">
            {{ "settings.enter_title" | translate }}:
            <span class="text-red-500">*</span>
          </div>
          <mat-form-field appearance="outline">
            <!-- <input formControlName="title" matInput type="text" /> -->
            <input
              matInput
              formControlName="title"
              [placeholder]="'settings.title' | translate"
              type="text"
            />
            <mat-error *ngIf="reactiveForm.get('title').hasError('required')">
              {{ "settings.please_enter_title" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="settings-section-separator">* * * * * *</div>
      <div class="settings-section flex flex-col">
        <div class="form-field-label mb-4">
          {{ "settings.reminder" | translate }}:
        </div>
        <div class="notif-step-container flex flex-col" *ngIf="reminder">
          <div class="notif-step">
            <div class="settings-sub-section">
              <div class="notif-step-header">
                <div class="flex-grow">
                  {{
                    reminder?.title
                      ? reminder?.title
                      : ("settings.reminder" | translate)
                  }}
                </div>
                <div
                  class="cursor-pointer"
                  (click)="editReminder(reminder?.oid)"
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                </div>
                <div
                  class="cursor-pointer"
                  (click)="removeReminder(reminder?.oid)"
                >
                  <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-delete-outline"
                  ></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="centred-add-btn"
          (click)="addReminder()"
          *ngIf="!reminder"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-plus-circle-outline"></mat-icon>
          <span>{{ "settings.add_reminder" | translate }}</span>
        </button>
      </div>

      <div class="settings-section-separator">* * * * * *</div>
      <div class="settings-section">
        <div class="settings-section-title">
          {{ "settings.enter_comment" | translate }} :
        </div>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="comment"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
