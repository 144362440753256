import { Router } from '@angular/router';
import {get} from 'lodash';
import { DateTime, IANAZone } from 'luxon';
import { TIME_UNITS } from './consts';

export function trackByFn(key: string) {
  return (index: number, item: any) => get(item, key, index);
}

export function  convertToNumber(input: string): number | null {
  return /^\d+$/.test(input) ? Number(input) : null;
}

export function checkIsMobile() {
  let width;
  width = window.innerWidth;
  if (width <= 768) {
    return true;
  } else if (width > 768 && width <= 992) {
    return false;
  } else {
    return false;
  }
}

export function fromNow(dateVar: any) {
  const dateTime = parseStringToDateTime(dateVar);
  if (dateTime) {
    return timeAgo(dateTime);
  } else {
    return null;
  }
}

// A function that takes a string and returns a Luxon DateTime object or null if the string is not valid
export function parseStringToDateTime(str: string): DateTime | null {
  // Try to parse the string using Luxon's own methods
  let dateTimeFromISO = DateTime.fromISO(str);
  // let dateTimefromFormat = DateTime.fromFormat(str, "dd/MM/yyyy");
  // let dateTimefromFormat = DateTime.fromFormat(str, "yyyy-MM-dd h:mm:ss");
  let dateTimefromFormat = DateTime.fromFormat(str, "yyyy-MM-dd h:mm");

  // If the result is valid, return it
  if (dateTimeFromISO.isValid) {
    return dateTimeFromISO;
  } else if (dateTimefromFormat.isValid) {
    const offset = getOffset(dateTimefromFormat, dateTimefromFormat.zoneName);
    return dateTimefromFormat.plus({ minutes: offset });;
  } else {
    return null
  }
}


  /**
   * Converts a given DateTime to a human-readable relative time (e.g., "2 hours ago").
   *
   * @param dateTime - The DateTime to convert to relative time.
   * @returns A string representing the relative time.
   */
  export function timeAgo(dateTime: DateTime): string {
    // Define time units for relative time calculation
    let units = TIME_UNITS;

    // Get the current local DateTime
    const currentDateTime = DateTime.local();

    // Override the input DateTime with the current time zone
    const newDateTime = overrideDateTimeWithTimeZone(
      dateTime,
      currentDateTime.zoneName!
    );

    // Calculate the time difference between the input DateTime and the current DateTime
    const diff = newDateTime.diff(currentDateTime).shiftTo(...units);

    // Determine the appropriate time unit to display (e.g., "second", "minute", "hour", etc.)
    const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

    // Create an Intl.RelativeTimeFormat instance for generating relative time strings
    const relativeFormatter = new Intl.RelativeTimeFormat('fr', {
      numeric: 'auto',
    });

    // Format and return the relative time string
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
  }

  export function getOffset(dateTime: DateTime, timeZone: string) {
        // Create a Luxon IANAZone instance based on the provided time zone
        const zone = IANAZone.create(timeZone);

        // The valueOf() method of the DateTime
        // object returns the timestamp in milliseconds
        // since the Unix epoch. By converting the DateTime
        // object to a timestamp before passing it to the
        // offset() method, you ensure that the method
        // receives the expected type of data.
        const timestamp = dateTime.valueOf();
        // Get the current offset for the provided time zone at the given DateTime
        return zone.offset(timestamp);
  }


  export function overrideDateTimeWithTimeZone(dateTime: DateTime, timeZone: string): DateTime {
    const offset = getOffset(dateTime, timeZone);

    // Adjust the DateTime using the offset
    const newDateTime = dateTime.minus({ minutes: offset });

    // Override the DateTime with the desired offset
    return newDateTime.toUTC();
  }


/**
 * Redirects to the specified route without updating the browser's address bar.
 *
 * The purpose of this method is to perform a route redirection while keeping
 * the browser's address bar unchanged. It achieves this by briefly navigating
 * to the root route (without affecting the address bar) and then immediately
 * navigating to the desired route. This can be useful when you want to update
 * the content on the page without altering the URL displayed in the browser.
 *
 * @param {Router} router - The Angular Router service.
 * @param {string} intermediateRoute - The intermediate route used for the redirection (temporary).
 * @param {string} uri - The destination route to redirect to.
 */
export function redirectTo(router: Router, intermediateRoute: string, uri: string): void {
  /**
   * Use the Angular Router service to perform route navigation.
   * @see https://angular.io/api/router/Router
   */

  // Step 1: Navigate to the Intermediate Route route while skipping the addition of a history entry.
  router
    .navigateByUrl(intermediateRoute, { skipLocationChange: true }) // Navigates to the Intermediate Route route without history entry.
    .then(() => {
      /**
       * Step 2: After the initial navigation is complete, navigate to the desired route.
       * This step ensures that the browser's address bar remains unchanged.
       */
      router.navigate([uri]); // Navigate to the provided destination route.
    });
}


