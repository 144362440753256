import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ft-custom-busy',
  templateUrl: './custom-busy.component.html',
  styleUrls: ['./custom-busy.component.scss'],
})
export class CustomBusyComponent implements OnInit {
  ngOnInit(): void {}
}
