import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pr-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent implements OnInit {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  @Output() public next = new EventEmitter();
  @Output() public dayView = new EventEmitter();
  @Output() public weekView = new EventEmitter();
  @Output() public monthView = new EventEmitter();
  @Output() public fourDaysView = new EventEmitter();
  @Output() public listView = new EventEmitter();
  @Output() public mapView = new EventEmitter();
  @Output() public prev = new EventEmitter();
  @Output() public today = new EventEmitter();
  @Output() public goToDate = new EventEmitter();
  @Input() public config = {map: true, list: true, month: true, week: true, day: true};
  @Input() public headerTitle = '';
  @Input() public activeView: EventEmitter<string>;
  public calendarViewName = '';
  public selected: Date | null;
  constructor(public breakpointObserver: BreakpointObserver, private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeView.subscribe(data => {
      this.updateActiveButton(data);
    });
    this.breakpointObserver
    .observe(['(max-width: 820px)'])
    .subscribe((result) => {
      if (result.breakpoints['(max-width: 820px)']) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  public onNext() {
    this.next.emit();
  }
  public onDayView() {
    this.updateActiveButton('timeGridDay');
    this.dayView.emit();
  }
  public onWeekView() {
    this.updateActiveButton('timeGridWeek');
    this.weekView.emit();
  }
  public onMonthView() {
    this.updateActiveButton('dayGridMonth');
    this.monthView.emit();
  }
  public onFourDaysView() {
    this.updateActiveButton('timeGridFourDay');
    this.fourDaysView.emit();
  }
  public onListView() {
    this.updateActiveButton('listMonth');
    this.listView.emit();
  }
  public onMapView() {
    this.mapView.emit();
  }
  public onPrev() {
    this.prev.emit();
  }
  public onToday() {
    this.today.emit();
  }
  public onGoToDate($event) {
    this.goToDate.emit($event);
  }

  public updateActiveButton(view: string) {
    this.calendarViewName = view;
  }
}
