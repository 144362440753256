import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FtWsService} from '@ft/core';


@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private httpClient: HttpClient, private ws: FtWsService) {
  }

  getList(_cls, query = '') {
    return this.httpClient.get<any>(`${_cls}?${query}`);
  }

  sendSms(c) {
    return this.ws.call(`sms_message.send_sms`, c);
  }
}
