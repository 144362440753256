import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigsService, DefaultValuesService, FtWsService } from '@ft/core';
import { TicketService } from 'src/app/ticket/services/ticket.service';
import { BaseCalendarComponent } from 'src/app/shared/components/base-calendar/base-calendar.component';
import { DataApiService } from 'src/app/shared/services/data-api.service';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { TicketType } from '../../../ticket/ticket.type';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { BaseCalendarService } from '../../../shared/services/base-calendar.service';

@Component({
  selector: 'pr-patient-appointment-list',
  templateUrl: './patient-appointment-list.component.html',
  styleUrls: ['./patient-appointment-list.component.scss'],
})
export class PatientAppointmentListComponent
  extends BaseCalendarComponent
  implements OnInit, OnDestroy
{
  public calendarHeaderConfig = {
    map: false,
  };
  constructor(
    public sidenavService: SidenavService,
    public breakpointObserver: BreakpointObserver,
    public ticketService: TicketService,
    public patientAppointmentService: PatientAppointmentService,
    public router: Router,
    public defaultValuesService: DefaultValuesService,
    public app_configService: AppConfigsService,
    public ws: FtWsService,
    public route: ActivatedRoute,
    public baseCalendarService: BaseCalendarService
  ) {
    super(
      sidenavService,
      breakpointObserver,
      ticketService,
      patientAppointmentService,
      router,
      defaultValuesService,
      app_configService,
      ws,
      route,
      baseCalendarService
    );
    this.is_ticket = false;
    this.apiService = patientAppointmentService;
  }

  protected getEventClickNavigationLink(info){
    return `patient-appointment/item/${info.event.id}`
  }
}
