import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FtCoreModule, MaterialModule } from '@ft/core';
import { SharedModule } from '../shared/shared.module';
import { NgBusyModule } from 'ng-busy';
import { PrescriptionModule } from '../prescription/prescription.module';
import { ngfModule } from 'angular-file';
import { FtFormModule } from '@ft/form';
import { SettingsModule } from '../settings/settings.module';
import { PatientAppointmentContainerComponent } from './components/patient-appointment-container/patient-appointment-container.component';
import { PatientAppointmentRoutingModule } from './patient-appointment-routing.module';
import { PatientAppointmentListComponent } from './components/patient-appointment-list/patient-appointment-list.component';
import { PatientAppointmentItemComponent } from './components/patient-appointment-item/patient-appointment-item.component';

@NgModule({
  declarations: [
    PatientAppointmentContainerComponent,
    PatientAppointmentListComponent,
    PatientAppointmentItemComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ngfModule,
    PrescriptionModule,
    PatientAppointmentRoutingModule,
    FullCalendarModule,
    NgBusyModule,
    FtFormModule,
    SettingsModule,
    FtCoreModule,
  ],
})
export class PatientAppointmentModule {}
