import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prescription-main',
  template: `
    <div class="ft-parent-layout" fxFlex fxLayout="column">
        <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./prescription-main.component.scss'],
})
export class PrescriptionMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
