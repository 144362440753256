<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-cloud"></mat-icon>
    </button>
  </div>
  <h2 translate="prescription.external_files_import"></h2>
  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form #form="ngForm">
  <div  fxLayout="column" class="ft-busy-container" style="min-height: 230px;">
    <ft-generic-body-table fxFlex
                      [remote]="false"
                      [source]="dataSource"
                      [selectable]="true"
                      [hasPagination]="true"
                      [columns]="displayedColumns | async"
                      [(selected)]="selectedItems"
    >
    </ft-generic-body-table>

  </div>
</form>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button type="button"  mat-flat-button  color="warn" mat-dialog-close>
    <span>{{'shared.reset'|translate}}</span>
  </button>
  <button type="button" color="primary"  mat-flat-button  tabindex="2" (click)="handleSelect($event)"
          [disabled]="!selectedItems ||selectedItems.length==0">
    {{'shared.add'|translate}}
  </button>
</div>
