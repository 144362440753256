<mat-select [(value)]="value" (selectionChange)="valueChanged()" [compareWith]="compareFunc" (openedChange)="clearSearch()"
            [required]="required" [disabled]="disabled" [multiple]="multiple" [panelClass]="controlType" #select>
    <div  class="select-search-header" >
            <input matInput  #searchInput type="search"   [placeholder]="'shared.search'|translate" [(ngModel)]="searchKey" [ftAutoFocus]="true"
                   [autofocus]="true" (keydown)="$event.stopPropagation();">
    </div>
    <mat-option *ngIf="!items || items.length===0"></mat-option>
    <mat-option *ngFor="let item of items " [value]="valueKey ? item[valueKey] : item"
                [disabled]="handleDisabledOption(item)">
        <span class="ft-select-option-content" [innerHTML]="getContent(item)"></span>
    </mat-option>
</mat-select>
