<div class="pr-content-container">
  <div class="pr-content-header">
    <div class="list-title text-h4 font-bold">
      {{ "settings.reminder" | translate }}
    </div>
    <button mat-button color="accent" [mat-dialog-close]="false">{{"shared.cancel" | translate}}</button>
    <button mat-button color="primary" (click)="saveReminder($event)">{{"shared.save" | translate}}</button>
  </div>
  <div class="pr-content-body">
    <form
      class="settings flex flex-col gap-4"
      [formGroup]="reactiveForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="settings-section">
        <div class="settings-sub-section">
          <div class="form-field-label">{{ "shared.enter_title" | translate }}: <span class="text-red-500">*</span></div>
          <mat-form-field appearance="outline">
            <!-- <input formControlName="title" matInput type="text" /> -->
            <input
              matInput
              formControlName="title"
              [placeholder]="'shared.title' | translate"
              type="text"
            />
            <mat-error *ngIf="reactiveForm.get('title').hasError('required')">
              {{ "shared.please_enter_title" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="settings-sub-section">
          <div class="form-field-label">
            {{"shared.date" | translate}}: <span class="text-red-500">*</span>
          </div>
          <mat-form-field appearance="outline">
            <input
              matInput
              placeholder="Ex: 12/20/2023"
              [matDatepicker]="trigger_date"
              formControlName="trigger_date"
              name="trigger_date"
            />
            <mat-datepicker-toggle matSuffix [for]="trigger_date">
              <!-- matDatepickerToggleIcon -->
              <!-- <mat-icon
                fontSet="mdi"
                fontIcon="mdi-calendar-blank-outline"
              ></mat-icon> -->
            </mat-datepicker-toggle>
            <mat-datepicker #trigger_date></mat-datepicker>
            <mat-error *ngIf="reactiveForm.get('trigger_date').hasError('required')">
              {{ "shared.please_select_date" | translate }}
            </mat-error>
          </mat-form-field>
          <div class="form-field-label">
            {{"shared.hour" | translate}}: <span class="text-red-500">*</span>
          </div>
          <mat-form-field appearance="outline">
            <!-- <input formControlName="title" matInput type="text" /> -->
            <input
              matInput
              formControlName="trigger_time"
              placeholder="Ex: 10:00"
              type="text"
            />
            <mat-error *ngIf="reactiveForm.get('trigger_time').hasError('required')">
              {{ "shared.please_enter_time" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="settings-section-separator">* * * * * *</div>
      <div class="setting-section">
        <div class="flex flex-col gap-2 w-fit">
          <mat-slide-toggle class="free-toggle" formControlName="is_recurrent"
            >{{ "shared.recurrent" | translate }}</mat-slide-toggle
          >
        </div>
      </div>
      <div class="settings-section" formGroupName="recurrent" *ngIf="reactiveForm.get('is_recurrent')?.value">
        <div class="settings-sub-section">
          <div class="form-field-label">{{"shared.select_frequency_unit" | translate}}: <span class="text-red-500">*</span></div>
          <mat-form-field appearance="outline">
            <mat-select
              [placeholder]="'shared.frequency_unit' | translate"
              formControlName="frequency_unit"
            >
            <!-- [(value)]="reactiveForm.get('recurrent').value.frequency_unit" -->
              <mat-option
                *ngFor="let timeUnit of timeUnits"
                [value]="timeUnit.value"
                >{{ timeUnit.viewValue }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="reactiveForm.get('recurrent').get('frequency_unit').hasError('required')">
              {{ "shared.please_select_frequency_unit" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="settings-sub-section">
          <div class="form-field-label">{{ "shared.enter_reminder_period" | translate }}: <span class="text-red-500">*</span></div>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="frequency_value"
              placeholder="Ex: 15"
              type="number"
            />
            <mat-error *ngIf="reactiveForm.get('recurrent').get('frequency_value').hasError('required')">
              {{ "shared.please_enter_reminder_period" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="settings-sub-section">
          <div class="form-field-label">
            {{ "shared.select_recurrence_duration" | translate }}: <span class="text-red-500">*</span>
          </div>
          <mat-form-field appearance="outline">
            <mat-select
              #select
              [placeholder]="'shared.enter_end_date' | translate"
              formControlName="duration"
              required
            >
              <mat-option
                *ngFor="let recurrentDuration of recurrentDurations"
                [value]="recurrentDuration.value"
                >{{ recurrentDuration.viewValue }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="reactiveForm.get('recurrent').get('duration').hasError('required')">
              {{ "shared.please_select_recurrence_duration" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="select.value === 'UNTIL_DATE'"
          >
            <input
              matInput
              placeholder="Ex: 12/20/2023"
              [matDatepicker]="until_date"
              formControlName="until_date"
              name="until_date"
            />
            <mat-datepicker-toggle matSuffix [for]="until_date">
              <!-- matDatepickerToggleIcon -->
              <!-- <mat-icon
            fontSet="mdi"
            fontIcon="mdi-calendar-blank-outline"
          ></mat-icon> -->
            </mat-datepicker-toggle>
            <mat-datepicker #until_date></mat-datepicker>
            <mat-error *ngIf="reactiveForm.get('recurrent').get('until_date').hasError('required')">
              {{ "shared.please_select_until_date" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="select.value === 'SPECIFIC_NUMBER_OF_TIMES'"
          >
            <input
              matInput
              formControlName="specific_number_of_times"
              placeholder="Ex: 3"
              type="number"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="settings-section flex flex-col gap-4"
        formArrayName="intervals_before_event"
      >
        <div class="form-field-label">
          {{ "shared.add_intervals_before_event" | translate }}:
        </div>
        <div
          class="notif-step-container flex flex-col gap-4"
          *ngIf="getIntervals()?.value.length"
        >
          <div
            class="notif-step"
            [formGroupName]="i"
            *ngFor="
              let interval of getIntervals().value;
              trackBy: trackByFunction;
              let i = index
            "
          >
            <div class="settings-sub-section">
              <div class="notif-step-header">
                <div class="flex-grow">{{ "shared.interval" | translate }} {{ i + 1 }}</div>
                <div class="cursor-pointer" (click)="removeInterval(i)">
                  <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-delete-outline"
                  ></mat-icon>
                </div>
              </div>
            </div>
            <div class="settings-sub-section">
              <div class="form-field-label">
                {{ "shared.select_interval_unit" | translate }}: <span class="text-red-500">*</span>
              </div>
              <mat-form-field appearance="outline">
                <mat-select
                  [placeholder]="'shared.interval_unit' | translate"
                  formControlName="interval_unit"
                >
                  <mat-option
                    *ngFor="let timeUnit of timeUnits"
                    [value]="timeUnit.value"
                    >{{ timeUnit.viewValue }}</mat-option
                  >
                </mat-select>
                <mat-error *ngIf="reactiveForm.get('intervals_before_event')?.get(''+i)?.get('interval_unit')?.hasError('required')">
                  {{ "shared.please_select_interval_unit" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="settings-sub-section">
              <div class="form-field-label">
                {{ "shared.enter_interval_value" | translate }}: <span class="text-red-500">*</span>
              </div>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="interval_value"
                  placeholder="Ex: 15"
                  type="number"
                />
                <mat-error *ngIf="reactiveForm.get('intervals_before_event')?.get(''+i)?.get('interval_value')?.hasError('required')">
                  {{ "shared.please_enter_interval_value" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <button type="button" class="centred-add-btn" (click)="addInterval()">
          <mat-icon fontSet="mdi" fontIcon="mdi-plus-circle-outline"></mat-icon>
          <span>{{ "shared.add_interval" | translate }}</span>
        </button>
      </div>
      <div class="settings-section-separator">* * * * * *</div>
      <div class="settings-section">
        <button type="button" class="settings-add-btn" (click)="editNotificationByDialog()">
          <mat-icon fontSet="mdi" fontIcon="mdi-cog-outline"></mat-icon>
          <span>{{ "settings.edit_notification" | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
