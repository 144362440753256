import {Component, HostBinding, OnInit} from '@angular/core';
import {PatientBaseComponent} from './patient-base.component';
import {MatDialog} from '@angular/material/dialog';
import {PatientService} from '../../services/patient.service';
import {PhysicianService} from '../../../settings/services/physician.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {AuthService, DefaultValuesService, SelectConfig} from '@ft/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../../settings/services/settings.service';
import {noop} from 'lodash';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Title} from '@angular/platform-browser';
import {projectName} from '../../../shared/utils/consts';
import {delay, of} from "rxjs";

@Component({
    selector: 'app-patient-form',
    templateUrl: './patient-form.component.html',
    styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent extends PatientBaseComponent implements OnInit {
    public breakpoints = Breakpoints;
    afterSave = this.handleAfterSave.bind(this);
    @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
    public organizationSelectConfig: SelectConfig = {key: 'name', event: 'patient.get_external_insurances'};
    public staffSelectConfig: SelectConfig = {key: 'full_name', url: 'api/staff/staff-form/'};
    constructor(
        public breakpointObserver: BreakpointObserver,
        public patientService: PatientService,
        public physicianService: PhysicianService,
        public _adapter: MomentDateAdapter,
        public dialog: MatDialog,
        public _authService: AuthService,
        public snackBar: MatSnackBar,
        public translateService: TranslateService,
        public router: Router,
        public route: ActivatedRoute,
        public settingService: SettingsService,
        private _titleService: Title,
        public defaultValuesService: DefaultValuesService
    ) {

        super(
            patientService,
            physicianService,
            _adapter,
            dialog,
            _authService,
            snackBar,
            translateService,
            router,
            route,
            settingService,
            breakpointObserver,
          defaultValuesService
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.breakpointObserver
            .observe(['(max-width: 640px)'])
            .subscribe((result) => {
                if (result.breakpoints['(max-width: 640px)']) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
        // TODO fix title
        this._titleService.setTitle(projectName + ' - ' + this.translateService.instant('patient.new_patient'));
    }

    handleAfterSave(data) {
        this.snackBar.open(this.translateService.instant('shared.save_success'), null, {
            horizontalPosition: 'left',
            duration: 500
        });

        this.router.navigate([`patient/form/${data['id']}`]).then(noop);
    }

     asyncClick(ev) {
      this.subscription = of([]).pipe(delay(5000)).subscribe();
    }


}
