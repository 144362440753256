<div class="ft-parent-layout ft-content-margin mat-elevation-z1" fxFlex fxLayout="column">

    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-contacts"></mat-icon>
        </div>
        <h2 translate="settings.physician_list"></h2>
    </mat-toolbar>
    <div fxFlex fxLayout="column" class="ft-content">
        <!--   <ft-generic-table fxFlex
                             [remote]="true"
                             [source]="dataSource"
                             [selectable]="false"
                             [hasPagination]="true"
                             [columns]="displayedColumns | async"
                             [multiActions]="multiActions"
                             [cls]="'physicians'"
                             [reloadEvent]="reloadEvent"
              >
              </ft-generic-table>-->

        <app-pr-generic-view-table [namespace]="viewSettings?.viewNamespace"
                                   [actions]="viewSettings?.actions"
                                   [viewSettings]="viewSettings?.viewSettings"
                                   [hasPagination]="viewSettings?.hasPagination"
                                   [selectable]="viewSettings?.selectable"
                                   [query]="viewSettings?.query"
                                   [reloadEvent]="reloadEvent"
                                   [exportExcel]="true"
        >
        </app-pr-generic-view-table>
    </div>
</div>
