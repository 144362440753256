import { Injectable } from '@angular/core';
import { patientRequestSample } from '../../shared/samples/patient-request.sample';
import { map, Observable } from 'rxjs';
import { PatientRequestType } from '../patient-request.type';
import { DataApiService } from '../../shared/services/data-api.service';
import { HttpClient } from '@angular/common/http';
import { isEmpty } from 'lodash';
import { UIService } from '../../shared/services/ui.service';
@Injectable({
  providedIn: 'root',
})
export class PatientRequestService extends DataApiService<PatientRequestType> {
  constructor(public httpClient: HttpClient, public uiService: UIService) {
    super(httpClient, uiService);
    this.setRootUrl('/api/pl/patient-request');
  }

  public getPatientRequets(): any {
    return patientRequestSample();
  }

  public requestTreated(requestId: number): any {
    return patientRequestSample().pipe(
      map((requests: PatientRequestType[]) => {
        requests.find((request: PatientRequestType) => {
          if (request.id == requestId) {
            request.request_treated = {
              performer_id: 123,
              created_at: '2023-01-01',
              is_treated: true,
            };
          }
        });
        return requests;
      })
    );
  }

  public getPatientPaginatedRequests(page, searchAll): Observable<any> {
    let query = isEmpty(searchAll)
      ? `page_number=${page}`
      : `page_number=${page}&search_all=${searchAll}`;
    return this.httpClient.get(`/api/pl/patient-request/?${query}`);
  }
}
