import {SYSTEM_COLUMNS} from '../../shared/utils/common-system-column';
import {PRESCRIPTION_COLUMNS} from './prescription-columns';

export const PRESCRIPTION_VIEW = {
    trackByKey: 'id',
    hasSearch: true,
    selectable: false,
    hasPagination: true,
    viewNamespace: 'patientList',
    query: [],
    viewSettings: {
        'model': 'prescription.Prescription',
        'viewColumns': PRESCRIPTION_COLUMNS,
        'systemColumns': SYSTEM_COLUMNS,
        'defaultName': 'Liste des prescriptions'
    },
    actions: [],
    resource: 'prescription',
    formRoute: '/prescription/content'

};
