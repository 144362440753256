import {Component, EventEmitter, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {get, concat, assignIn, cloneDeep} from 'lodash';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpecialitySetupComponent} from '../../dialogs/speciality-setup/speciality-setup.component';
import {SettingsService} from '../../services/settings.service';
import {SPECIALITY_COLUMNS} from '../../utils/speciality-columns';
import {SPECIALITY_VIEW} from '../../utils/views-settings';
import {ActionItem, SMALL_DIALOG, TableColumnDefinition} from '@ft/core';

@Component({
    selector: 'app-speciality-grid',
    templateUrl: './speciality-grid.component.html',
    styleUrls: ['./speciality-grid.component.scss']
})
export class SpecialityGridComponent implements OnInit {
    subscription: Subscription;
    dataSource: Observable<any[]> = of([]);
    multiActions: ActionItem[] = [
        {
            class: 'mat-primary',
            icon: 'mdi-plus',
            method: (item, ev) => {
                return this.handleSpeciality();
            },
            tooltip: this.translateService.instant('shared.add'),
            acl: {resource: 'speciality', action: 'create', behavior: 'remove'}
        }
    ];
    singleActions = {
        label: '', key: 'actions', type: 'actions', actions: [
            {
                class: 'mat-primary',
                icon: 'mdi-pencil',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.handleSpeciality(item);
                },
                tooltip: this.translateService.instant('shared.edit'),
                acl: {resource: 'speciality', action: 'update', behavior: 'remove'}

            },
            {
                class: 'mat-warn',
                icon: 'mdi-delete',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.removeSpeciality(get(item, 'id'));
                },
                tooltip: this.translateService.instant('shared.delete'),
                acl: {resource: 'speciality', action: 'delete', behavior: 'remove'}

            }
        ]
    };
    displayedColumns = new BehaviorSubject<TableColumnDefinition[]>(concat(SPECIALITY_COLUMNS, this.singleActions as any));
    reloadEvent = new EventEmitter();
    viewSettings = SPECIALITY_VIEW;

    constructor(
        public translateService: TranslateService,
        public settingsService: SettingsService,
        private dialog: MatDialog,
        private router: Router,
        protected snackBar: MatSnackBar) {
        this.viewSettings.viewSettings.viewColumns = concat(this.viewSettings.viewSettings.viewColumns,
            this.singleActions as any);
        this.viewSettings.actions = this.multiActions;
    }

    ngOnInit(): void {

    }

    applyFilter(ev) {

    }

    handleSpeciality(model = null) {
        this.dialog.open(SpecialitySetupComponent, assignIn(SMALL_DIALOG,
            {
                data: {model: cloneDeep(model)},
                disableClose: true,
                autoFocus: false
            }))
            .afterClosed().subscribe((data) => {
            if (data) {
                this.reloadEvent.emit(true);
            }
        });
    }

    removeSpeciality(pk) {
        this.subscription = this.settingsService.deleteSpeciality(pk).subscribe(res => {
            this.reloadEvent.emit(true);
        });
    }
}
