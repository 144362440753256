import {Component, OnInit} from '@angular/core';
import {map} from 'lodash';
import {PatientService} from '../../../patient/services/patient.service';
import {SettingsService} from '../../services/settings.service';
import {forkJoin, Subscription} from 'rxjs';
import {DefaultValuesService} from '@ft/core';
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import { Title } from '@angular/platform-browser';
import { projectName } from '../../../shared/utils/consts';

@Component({
  selector: 'app-base-settings',
  templateUrl: './base-settings.component.html',
  styleUrls: ['./base-settings.component.scss']
})
export class BaseSettingsComponent implements OnInit {
  subscription: Subscription;
  prescriptionStateColors: any = this.defaultValuesService.getValue('prescriptionStateColors') || [
    {'name': 'canceled', 'color': 'rgba(245, 123, 0, 1)'},
    {'name': 'waiting_list', 'color': 'rgba(0, 92, 92, 1)'},
    {'name': 'entered', 'color': 'rgba(0, 214, 214, 1)'},
    {'name': 'missed', 'color': 'rgba(82, 0, 163, 1)'},
    {'name': 'waiting', 'color': 'rgba(214, 0, 214, 1)'},
    {'name': 'planned', 'color': 'rgba(0, 82, 163, 1)'},
    {'name': 'unplanned', 'color': 'rgba(0, 82, 100, 1)'},
    {'name': 'closed', 'color': 'rgba(0, 214, 24, 1)'},
    {'name': 'draft', 'color': 'red'}
  ];

  ticketColors: any = this.defaultValuesService.getValue('ticketStatusColors') || [
    {'name': 'CANCELLED', 'bgColor': '#ffc48c', 'txtColor': '#8b3e00'},
    {'name': 'COMPLETED', 'bgColor': '#fc418f', 'txtColor': '#fff'},
    {'name': 'ACCEPTED', 'bgColor': '#ddc3f0', 'txtColor': '#660099'},
    {'name': 'REJECTED', 'bgColor': '#f0d7e4', 'txtColor': '#7b0053'},
    {'name': 'IN_PROGRESS', 'bgColor': '#d7f0e5', 'txtColor': '#00704b'},
    {'name': 'ON_HOLD', 'bgColor': '#ffdf80', 'txtColor': '#8b5200'},
    {'name': 'MISSED', 'bgColor': '#FFD9D9', 'txtColor': '#8B0000'},
    {'name': 'SCHEDULED', 'bgColor': '#b3eeff', 'txtColor': '#003366'},
    {'name': 'RESCHEDULED', 'bgColor': '#fbfbfb', 'txtColor': '#d659e5'},
    {'name': 'FORCE_COMPLETED', 'bgColor': '#fbfbfb', 'txtColor': '#d659e5'},
  ]

  appointmentColors: any = this.defaultValuesService.getValue('appointmentStatusColors') || [
    { 'name': 'SCHEDULED', 'bgColor': '#b3eeff', 'txtColor': '#003366' },
    { 'name': 'CONFIRMED', 'bgColor': '#d7f0e5', 'txtColor': '#00704b' },
    {
      'name': 'CANCEL_REQUESTED',
      'bgColor': 'rgba(255, 230, 204, 1)',
      'txtColor': '#8b3e00',
    },
    { 'name': 'RESCHEDULE_REQUESTED', 'bgColor': '#ffdf80', 'txtColor': '#8b5200' },
  ]



  prescriptionTemplatesColors: any = this.defaultValuesService.getValue('prescriptionTemplatesColors') || map(
    this.settingsService.prescriptionContentTemplates, (e) => {
      return {name: e.name, color: `#${Math.floor(Math.random() * 1e7).toString(16)}`};
    });

  country: any = this.defaultValuesService.getValue('country');
  city: any = this.defaultValuesService.getValue('city');
  countries: any = [];
  cities: any = [];

  constructor(public patientService: PatientService, private settingsService: SettingsService,
              private defaultValuesService: DefaultValuesService,
              private _snackBar: MatSnackBar,
              private translateService: TranslateService,
              private _titleService: Title
  ) {
    this.subscription = forkJoin([
      this.patientService.getCountries(),
      this.patientService.getCities()
    ]).subscribe(data => {
      this.countries = data[0];
      this.cities = data[1];
    });
  }

  ngOnInit() {
    this._titleService.setTitle(projectName + ' - ' + this.translateService.instant('settings.settings'));
  }

  compareCondition(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  handleChange() {
    this.defaultValuesService.updateValue('prescriptionStateColors', this.prescriptionStateColors).subscribe(data => {
      // m = data;
      this._snackBar.open(this.translateService.instant('core.default_values.update_success'));
      this.defaultValuesService.load();
    });
  }

  handleTemplateColorChange() {
    this.defaultValuesService.updateValue('prescriptionTemplatesColors', this.prescriptionTemplatesColors).subscribe(data => {
      // m = data;
      this._snackBar.open(this.translateService.instant('core.default_values.update_success'));
      this.defaultValuesService.load();
    });
  }

  handleTicketColorChange() {
    this.defaultValuesService.updateValue('ticketStatusColors', this.ticketColors).subscribe(data => {
      // m = data;
      this._snackBar.open(this.translateService.instant('core.default_values.update_success'));
      this.defaultValuesService.load();
    });
  }


  handleAppointmentColorsChange() {
    this.defaultValuesService.updateValue('appointmentStatusColors', this.appointmentColors).subscribe(data => {
      // m = data;
      this._snackBar.open(this.translateService.instant('core.default_values.update_success'));
      this.defaultValuesService.load();
    });
  }

}
