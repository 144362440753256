<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <button type="button" mat-icon-button tabindex="-2">
            <mat-icon fontSet="mdi" fontIcon="mdi-reload"></mat-icon>
        </button>
    </div>
    <h2>{{ 'prescription.renew_title' | translate }}</h2>

    <span fxFlex></span>
    <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<form novalidate #form="ngForm">
    <div [ngBusy]="subscription"></div>
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div fxLayout="column" fxLayoutGap="6px">
            <mat-form-field fxFlex="nogrow" [ftAutoFocus]="true">
                <input matInput ftDate [matDatepicker]="start_date"
                       [placeholder]="'shared.start_date'|translate" required
                       [(ngModel)]="model.start_date" name="start_date">
                <mat-datepicker-toggle matSuffix [for]="start_date">
                    <mat-icon matDatepickerToggleIcon fontSet="mdi"
                              fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #start_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="nogrow" [ftAutoFocus]="true">
                <input matInput ftDate [matDatepicker]="end_date"
                       [placeholder]="'prescription.end_date'|translate" required
                       [(ngModel)]="model.end_date" name="end_date">
                <mat-datepicker-toggle matSuffix [for]="end_date">
                    <mat-icon matDatepickerToggleIcon fontSet="mdi"
                              fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #end_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex>
                <textarea matInput name="reason" rows="7" required
                          placeholder="{{'prescription.renew_comment'|translate}}"
                          [(ngModel)]="model.comment"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions>
        <span fxFlex></span>
        <button type="button" mat-flat-button  color="primary" (click)="handleSave()" [disabled]="form.invalid">
            <span>{{'shared.save'|translate}}</span>
        </button>
        <button type="button" mat-flat-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
            <span>{{'shared.reset'|translate}}</span>
        </button>
    </div>
</form>
