import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@ft/core';
import { PatientPrescriptionContainerComponent } from './components/patient-prescription-container/patient-prescription-container.component';
import { PatientPrescriptionListComponent } from './components/patient-prescription-list/patient-prescription-list.component';
import { PatientPrescriptionItemComponent } from './components/patient-prescription-item/patient-prescription-item.component';


const routes: Routes = [
  {
    path: '',
    component: PatientPrescriptionContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', component: PatientPrescriptionListComponent },
      { path: 'item/:id', component: PatientPrescriptionItemComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientPrescriptionRoutingModule {}
