import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {get} from 'lodash';
import * as moment from "moment";

@Component({
    selector: 'app-patient-death',
    templateUrl: './patient-death.component.html',
    styleUrls: ['./patient-death.component.scss']
})
export class PatientDeathDialogComponent implements OnInit {
    subscription: Subscription;
    model = null;
    death_date = null;
    death_reason = null;

    constructor(public dialogRef: MatDialogRef<PatientDeathDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.model = data;
        this.death_date = get(data, 'death_date') ? get(data, 'death_date') : moment().format('YYYY-MM-DD');
        this.death_reason = get(data, 'death_reason');
    }

    ngOnInit(): void {
    }

    handleSave(reset: boolean = false) {
        this.dialogRef.close(reset ? {death_date: null, death_reason: null} : this.model);

    }
}
