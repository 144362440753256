import { Observable, of } from 'rxjs';
import { AppointmentType } from '../../patient-appointment/patient-appointment.type';

export let appointmentSample = (): Observable<AppointmentType[]> => {
  return of([
    {
      id: 1,
      status_histories: [
        { created_at: '2023-01-01', status: 'SCHEDULED' },
        { created_at: '2023-01-02', status: 'CONFIRMED' },
      ],
      created_at: '2023-11-25',
      ticket: {
        _id: 354,
        prescription: {
          template_name: 'VNI',
        },
        id: 354,
        created_at: '2023-11-17T16:50:14.887Z',
        ticket_read: {
          performer_id: 1,
          is_read: true,
          created_at: '2023-11-17',
        },
        status_histories: [
          {
            status: 'MISSED',
          },
        ],
        ticket_content_histories: [
          {
            reason: 'Planner PCI reboot Shoes Cheese',
            location: {
              lat: '33.576698',
              lng: '-7.626266',
            },
          },
        ],
        ticket_schedule_histories: [
          {
            all_day: false,
            execution_date: '2023-11-21',
            start_time: '12:15:00',
            end_time: '15:15:00',
          },
        ],
      },
      appointment_confirmation_histories: [
        { performer_id: 123, created_at: '2023-01-01', is_confirmed: true },
      ],
      appointment_receipt: {
        performer_id: 123,
        created_at: '2023-01-01',
        is_received: false,
      },
      appointment_read: {
        performer_id: 123,
        created_at: '2023-01-01',
        is_read: true,
      },
    },
    {
      id: 2,
      status_histories: [
        { created_at: '2023-01-01', status: 'SCHEDULED' },
      ],
      created_at: '2023-11-25',
      ticket: {
        _id: 354,
        prescription: {
          template_name: 'VNI',
        },
        id: 354,
        created_at: '2023-11-17T16:50:14.887Z',
        ticket_read: {
          performer_id: 1,
          is_read: true,
          created_at: '2023-11-17',
        },
        status_histories: [
          {
            status: 'MISSED',
          },
        ],
        ticket_content_histories: [
          {
            reason: 'Planner PCI reboot Shoes Cheese',
            location: {
              lat: '33.576698',
              lng: '-7.626266',
            },
          },
        ],
        ticket_schedule_histories: [
          {
            all_day: false,
            execution_date: '2023-11-21',
            start_time: '12:15:00',
            end_time: '15:15:00',
          },
        ],
      },
      appointment_confirmation_histories: [
        
      ],
      appointment_receipt: {
        performer_id: 123,
        created_at: '2023-01-01',
        is_received: false,
      },
      appointment_read: {
        performer_id: 123,
        created_at: '2023-01-01',
        is_read: true,
      },
    },
  ]);
};
