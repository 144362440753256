<div [ngBusy]="viewSubscription"></div>

<div class="cards-container" fxFlex fxLayout="column">
    <div class="second-header-container">
        <div class="search-bar-container" [ngClass]="{'search-bar-container-for-tablet': !isMobile}">
            <div class="search-bar" [class.focused]="isFocused">
                <div class="search-bar-icon search-bar-item">
                    <mat-icon
                            fontSet="mdi"
                            fontIcon="mdi-magnify"
                            [class.mat-primary]="isFocused"
                            class="magnify-icon"
                    ></mat-icon>
                </div>
                <div class="search-bar-input search-bar-item">
                    <input
                            type="text"
                            fxFlex
                            [value]="key"
                            [ftAutoFocus]="false"
                            [placeholder]="'table.search' | translate"
                            (focus)="isFocused = true"
                            (blur)="isFocused = false"
                            (input)="onSearch($event.target.value)"
                    />
                </div>
                <button *ngIf="customFilterActivated" mat-icon-button color="primary" class="pr-filter">
                    <mat-icon svgIcon="filter-options"></mat-icon>
                </button>

            </div>
            <ng-template [ftAcl]="{resource: viewSettings?.resource, action: 'create', behavior: 'remove'}"
                         [ignoreEmpty]="true">
                <button
                        mat-mini-fab
                        color="primary"
                        class="add-btn"
                        [routerLink]="viewSettings?.formRoute"
                        *ngIf="!isMobile"
                >
                    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
                </button>
            </ng-template>
        </div>
    </div>
    <div class="infinite-scroll-container flex flex-col"
         infiniteScroll
         [infiniteScrollDistance]="5"
         [infiniteScrollThrottle]="100"
         (scrolled)="onScroll()"
         [fromRoot]="false"
         [scrollWindow]="false"
    >
        <ul fxFlex class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
            [ngClass]="{'card-grid-mobile': isMobile, 'card-grid-md': !isMobile}">
            <li *ngFor="let item of data">
                <ng-container *ngTemplateOutlet="customTemplateContent; context: {item: item}">
                </ng-container>
            </li>
        </ul>
        <!-- <div class="h-6"></div> -->
    </div>
</div>