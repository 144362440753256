<div class="form-box" fxLayout="column" [ngBusy]="subscription" [@transformAnimation]="state">
    <div class="logo" fxFlex="noshrink" fxLayout="row" fxLayoutAlign="center center" *ngIf="logo">
        <img [src]="logo" alt="" fxFlex="nogrow">
    </div>

    <form name="login-form" #loginForm="ngForm" fxLayout="column" class="ft-padding" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline">
            <mat-label>{{'auth.login.username' | translate}}</mat-label>
            <input [ftAutoFocus]="state == 'enter'" matInput required [placeholder]="'auth.login.username' | translate" name="username"
                   [(ngModel)]="model.username" autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'auth.login.password' | translate}}</mat-label>
            <input matInput [type]="showPassword ? 'text' : 'password'" required [placeholder]="'auth.login.password' | translate" name="password"
                   [(ngModel)]="model.password">
            <button matSuffix mat-icon-button type="button" (click)="handlePassword($event)" tabindex="-1">
                <mat-icon fontSet="mdi" [fontIcon]="showPassword ? 'mdi-eye' : 'mdi-eye-off'"></mat-icon>
            </button>
        </mat-form-field>
        <div class="action" fxLayout="row" fxLayoutAlign="center center">
            <button type="submit" mat-raised-button [disabled]="loginForm.invalid" color="primary">
                {{'auth.login.connect' | translate}}
            </button>
        </div>
    </form>
</div>

