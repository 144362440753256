import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get} from 'lodash';
import {Subscription} from 'rxjs';
import {SettingsService} from '../../services/settings.service';

@Component({
    selector: 'app-speciality-setup',
    templateUrl: './speciality-setup.component.html',
    styleUrls: ['./speciality-setup.component.scss']
})
export class SpecialitySetupComponent implements OnInit {
    model: any = {name: null};
    subscription: Subscription;

    constructor(public dialogRef: MatDialogRef<SpecialitySetupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public settingsService: SettingsService) {
        if (get(data, 'model.id')) {
            this.subscription = this.settingsService.getSpeciality(get(data, 'model.id')).subscribe(res => {
                this.model = res;
            });
        }

    }

    ngOnInit(): void {
    }

    handleSave() {
        this.subscription = this.settingsService.saveSpeciality(get(this.model, 'id'), this.model).subscribe(data => {
            this.dialogRef.close(data);
        });
    }

    compareCondition(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
    }

}
