import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { projectName } from '../../../shared/utils/consts';

@Component({
  selector: 'pr-patient-request-container',
  templateUrl: './patient-request-container.component.html',
  styleUrls: ['./patient-request-container.component.scss']
})
export class PatientRequestContainerComponent implements OnInit {
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  constructor(
    public translateService: TranslateService,
    private _titleService: Title,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    // TODO fix title
    // this._titleService.setTitle(
    //   projectName +
    //     ' - ' +
    //     this.translateService.instant('patient_request.request_list')
    // );
    this.breakpointObserver
    .observe(['(max-width: 640px)'])
    .subscribe((result) => {
      this.isMobile = result.breakpoints['(max-width: 640px)'] ? true : false;
    });
  }

}
