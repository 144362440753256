<div fxLayout="column">
    <div *ngIf="model.label" fxFlex="nogrow" fxLayoutAlign="center center" class="block-header">
        <h1>{{ model.label }}</h1>
        <span fxFlex=""></span>
        <button
                mat-icon-button
                color="primary"
                *ngIf="hasFormulas()"
                (click)="calculateBlocFormula()"
                [matTooltip]="'form.recalculate_block_fields' | translate"
        >
            <mat-icon fontIcon=" mdi-sync" fontSet="mdi"></mat-icon>
        </button>
    </div>
    <form #blockForm="ngForm"
          fxLayout="column"
          fxFlex
    >
        <div fxFlex class="block-content gap-y-6" fxLayoutGap="6px"
             [ngClass]="model?.config?.classes">
            <ng-container *ngFor="let f of model['fields']; trackBy: trackByFn;let fieldIdx = index"
                          [ngSwitch]="f.type">
                <div class="form-field-wrapper" fxLayout="column" [ngClass]="f?.config?.classes" [ngStyle]="handleFieldStyle(f)">
                    <ng-template [ngSwitchCase]="'checkbox'">
                        <div fxLayout="column" *ngIf="handleDisplayRules(f)">
                            <span class="field-label">{{ f['label'] }}</span>
                            <div fxFlex fxLayout="row wrap" fxLayoutGap="8px">
                                <ng-container
                                        *ngFor="let o of f['options']; trackBy: trackByFn; let idx = index"
                                >
                                    <mat-checkbox
                                            color="primary"
                                            [(ngModel)]="f.value[o]"
                                            (ngModelChange)="emitBlockChange()"
                                            [required]="f.required"
                                            name="{{   model.name + f.name }}"
                                    >
                                        <span>{{ o }}</span>
                                    </mat-checkbox>
                                </ng-container>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template [ngSwitchCase]="'radio-button'">
                        <div *ngIf="handleDisplayRules(f)" fxFlex fxLayout="column" fxLayoutGap="8px"
                             class="ft-radio-field"
                             [appearance]="'standard'">
                            <!--                            <mat-label>{{f.label}}</mat-label>-->
                            <label>{{f.label}}</label>
                            <mat-radio-group class="radio-group"
                                             [(ngModel)]="f.value"
                                             (ngModelChange)="emitBlockChange()"
                                             [fxLayout]="isMobile? 'column':'row'"
                                             name="{{   model.name + f.name }}"
                            >
                                <mat-radio-button  *ngFor="let o of f.options" [value]="o">
                                    <span> {{ o }}</span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'text'">
                        <div *ngIf="handleDisplayRules(f)" fxLayout="row">
                            <mat-form-field fxFlex [appearance]="!isMobile ? 'outline' : 'standard'">
                                <mat-label>{{f?.label}}</mat-label>
                                <input
                                        matInput
                                        type="text"
                                        [placeholder]="f.label"
                                        [(ngModel)]="f.value"
                                        [required]="f.required"
                                        (ngModelChange)="emitBlockChange()"
                                        name="{{   model.name + f.name }}"
                                />
                            </mat-form-field>
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'long-text'">
                        <div *ngIf="handleDisplayRules(f)" fxLayout="row" fxFlex>
                            <mat-form-field fxFlex [appearance]="!isMobile ? 'outline' : 'standard'">
                                <mat-label>{{f?.label}}</mat-label>
                                <textarea
                                        matInput
                                        [(ngModel)]="f.value"
                                        [required]="f.required"
                                        [rows]="f.rows || 5"
                                        (ngModelChange)="emitBlockChange()"
                                        name="{{   model.name + f.name }}"
                                ></textarea>
                            </mat-form-field>
                        </div>
                    </ng-template>
                    <div fxLayout="row" *ngIf="f.type === 'number' && !f.options['suffix']" fxFlex>
                        <mat-form-field fxFlex [appearance]="!isMobile ? 'outline' : 'standard'">
                            <mat-label>{{f?.label}}</mat-label>
                            <input
                                    matInput
                                    [(ngModel)]="f.value"
                                    [required]="f.required"
                                    type="number"
                                    (ngModelChange)="emitBlockChange()"
                                    name="{{   model.name + f.name }}"
                            />
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlex *ngIf="f.type === 'number' && f.options['suffix']">
                        <mat-form-field fxFlex [appearance]="!isMobile ? 'outline' : 'standard'">
                            <mat-label>{{f?.label}}</mat-label>

                            <input
                                    matInput
                                    [(ngModel)]="f.value"
                                    [required]="f.required"
                                    type="number"
                                    (ngModelChange)="emitBlockChange()"
                                    name="{{   model.name + f.name }}"
                            />
                            <span matSuffix>{{ f.options['suffix'] | translate }}</span>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlex *ngSwitchCase="'formula'">
                        <mat-form-field *ngIf="handleDisplayRules(f)" fxFlex
                                        [appearance]="!isMobile ? 'outline' : 'standard'">
                            <mat-label>{{f?.label}}</mat-label>

                            <input
                                    matInput
                                    [(ngModel)]="f.value"
                                    [required]="f.required"
                                    type="number"
                                    (ngModelChange)="emitBlockChange()"
                                    name="{{   model.name + f.name }}"
                            />
                            <button
                                    matSuffix
                                    mat-icon-button
                                    type="button"
                                    color="primary"
                                    (click)="calculateFormula(f)"
                                    [matTooltip]="'form.calculate' | translate"
                            >
                                <mat-icon fontIcon="mdi-equal" fontSet="mdi"></mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <ng-template [ngSwitchCase]="'date'">
                        <div *ngIf="handleDisplayRules(f)" fxLayout="row" fxFlex>
                            <mat-form-field class="mn-datepicker-container"
                                            [appearance]="!isMobile ? 'outline' : 'standard'">
                                <mat-label>{{f?.label}}</mat-label>

                                <input
                                        matInput
                                        [matDatepicker]="f_date"
                                        [(ngModel)]="f.value"
                                        [required]="f.required"
                                        (ngModelChange)="emitBlockChange()"
                                        name="{{   model.name + f.name }}"
                                />
                                <mat-datepicker-toggle matSuffix [for]="f_date">
                                    <mat-icon
                                            matDatepickerToggleIcon
                                            fontSet="mdi"
                                            fontIcon="mdi-calendar-today"
                                    ></mat-icon>
                                </mat-datepicker-toggle>
                                <mat-datepicker #f_date></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'list'">
                        <mat-form-field *ngIf="handleDisplayRules(f)" [appearance]="!isMobile ? 'outline' : 'standard'">
                            <mat-label>{{ f.label }}</mat-label>
                            <mat-select
                                    [(ngModel)]="f.value"
                                    [required]="f.required"
                                    (ngModelChange)="emitBlockChange()"
                                    name="{{   model.name + f.name }}"
                            >
                                <mat-option *ngFor="let o of f.options" [value]="o">
                                    {{ o }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'title'">
                        <h5 *ngIf="handleDisplayRules(f)">{{ f.label }}</h5>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'table-layout'">
                        <div *ngIf="handleDisplayRules(f)" fxFlex fxLayout="column">
                            <table mat-table [dataSource]="f.value" fxFlex>
                                <ng-container
                                        [matColumnDef]="column"
                                        *ngFor="let column of f.options['columns']; let cIdx = index"
                                >
                                    <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                                    <td
                                            mat-cell
                                            *matCellDef="let element; let eIdx = index"
                                            [ngSwitch]="getElementType(f, cIdx, eIdx)"
                                    >
                                        <ng-template [ngSwitchCase]="'radio-button'">
                                            <mat-checkbox
                                                    color="primary"
                                                    [(ngModel)]="element[column]"
                                                    [disableRipple]="true"
                                                    (ngModelChange)="emitBlockChange()"
                                                    name="{{   model.name + f.name }}"
                                            >
                                            </mat-checkbox>
                                        </ng-template>
                                        <p *ngSwitchCase="'paragraph'">
                                            {{ getElementLabel(f, cIdx, eIdx) }}
                                        </p>
                                        <ng-template [ngSwitchCase]="'text'">
                                            <div fxLayout="row">
                                                <input
                                                        matInput
                                                        type="text"
                                                        [placeholder]="getElementLabel(f, cIdx, eIdx)"
                                                        placeholder="...................................................................................."
                                                        [(ngModel)]="element[column]"
                                                        (ngModelChange)="emitBlockChange()"
                                                        name="{{   model.name + f.name }}"
                                                />
                                                <span matSuffix>{{ getElementSuffix(f, cIdx, eIdx) }}</span>
                                            </div>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'number'">
                                            <input
                                                    matInput
                                                    type="number"
                                                    [placeholder]="getElementLabel(f, cIdx, eIdx)"
                                                    placeholder="......................................................................................"
                                                    [(ngModel)]="element[column]"
                                                    (ngModelChange)="emitBlockChange()"
                                                    name="{{   model.name + f.name }}"
                                            />
                                        </ng-template>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="f.options['columns']"></tr>
                                <tr mat-row *matRowDef="let row; columns: f.options['columns']"></tr>
                            </table>
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'paragraph'">
                        <div *ngIf="handleDisplayRules(f)" fxFlex>
                            <p>{{ f['label'] }}</p>
                        </div>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'image'">
                        <div *ngIf="handleDisplayRules(f)" fxFlex fxLayout="row" fxLayoutAlign="center">
                            <img [ngStyle]="f?.config?.picture_style" [src]="f.picture" alt=""/>
                        </div>
                    </ng-template>
                    <div *ngIf="handleDisplayRules(f)">
                        <img
                                *ngIf="f.type !== 'image' && f.picture"
                                [ngStyle]="f.config['picture_style']"
                                [src]="f.picture"
                        />
                    </div>
                    <ng-template [ngSwitchCase]="'file'">
                        <div *ngIf="handleDisplayRules(f)" class="block-file-field">
                            <div class="file-item">
                                <div class="file-item-icon">
                                    <mat-icon
                                            color="accent"
                                            fontSet="mdi"
                                            fontIcon="mdi-file"
                                    ></mat-icon>
                                </div>
                                <div (click)="previewFile(f,model?.name)"
                                     class="file-item-name">{{f?.value?.file_name}}</div>
                                <button mat-icon-button>
                                    <mat-icon
                                            color="primary"
                                            fontSet="mdi"
                                            fontIcon="mdi-cloud-upload"
                                            ngfSelect multiple="1" accept="image/*" [(files)]="uploadedFiles"
                                            (filesChange)="uploadFiles($event,f,model?.name,fieldIdx)"
                                    ></mat-icon>
                                </button>
                                <button mat-icon-button>
                                    <mat-icon
                                            color="warn"
                                            fontSet="mdi"
                                            fontIcon="mdi-delete"
                                            (click)="deleteFieldFile(f, model?.name, fieldIdx)"
                                    ></mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </div>
    </form>
</div>
