import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ViewValueType } from '../../../settings/types/shared.type';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { NotificationComponent } from '../notification/notification.component';
import { DOCUMENT } from '@angular/common';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'pr-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
})
export class ReminderComponent implements OnInit, OnDestroy {
  public timeUnits: ViewValueType[] = [
    { value: 'MINUTE', viewValue: 'Minute' },
    { value: 'HOUR', viewValue: 'Heure' },
    { value: 'DAY', viewValue: 'Jour' },
    { value: 'WEEK', viewValue: 'Semaine' },
    { value: 'MONTH', viewValue: 'Mois' },
    { value: 'YEAR', viewValue: 'Année' },
  ];
  public recurrentDurations: ViewValueType[] = [
    { value: 'FOREVER', viewValue: 'Toujours' },
    { value: 'UNTIL_DATE', viewValue: 'Saisir la date de fin' },
    {
      value: 'SPECIFIC_NUMBER_OF_TIMES',
      viewValue: 'Saisir le nombre de fois',
    },
  ];
  // public reminder = { date: new Date() };
  public reactiveForm!: FormGroup;
  private _tempSub = new Subscription();
  private _subs = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReminderComponent>,
    private _fb: FormBuilder,
    private _dialog: MatDialog
  ) {
    this.reactiveForm = this._fb.group({
      oid: [''],
      title: ['', Validators.required],
      trigger_date: ['', Validators.required],
      trigger_time: ['', Validators.required],
      is_recurrent: [false],
      recurrent: this._fb.group({
        frequency_unit: [''],
        frequency_value: [''],
        duration: [''],
        specific_number_of_times: [1],
        until_date: [''],
      }),
      // intervals: this._fb.array([this._fb.group({
      //       // id: [''],
      //       interval_unit: [''],
      //       interval_value: [''],
      //     })])
      intervals_before_event: this._fb.array([]),
      notification_config: []
    });
  }
  ngOnDestroy(): void {
    this._tempSub?.unsubscribe();
  }

  ngOnInit(): void {
    
    
    
    this.reactiveForm.get('is_recurrent').valueChanges.subscribe(val => {
      if (val) {
        this.reactiveForm.controls['recurrent'].get('frequency_unit').setValidators([Validators.required]);
        this.reactiveForm.controls['recurrent'].get('frequency_value').setValidators([Validators.required]);
        this.reactiveForm.controls['recurrent'].get('duration').setValidators([Validators.required]);
      } else {
        this.reactiveForm.controls['recurrent'].get('frequency_unit').clearValidators();
        this.reactiveForm.controls['recurrent'].get('frequency_value').clearValidators();
        this.reactiveForm.controls['recurrent'].get('duration').clearValidators();
      }
      this.reactiveForm.controls['recurrent'].get('frequency_unit').updateValueAndValidity();
      this.reactiveForm.controls['recurrent'].get('frequency_value').updateValueAndValidity();
      this.reactiveForm.controls['recurrent'].get('duration').updateValueAndValidity();
    });

    const intervalsArray = this.reactiveForm.get('intervals_before_event') as FormArray;
    intervalsArray.valueChanges.subscribe(val => {
      if (val.length > 0) {
        intervalsArray.controls.forEach((control) => {
          control.get('interval_unit').setValidators([Validators.required]);
          control.get('interval_value').setValidators([Validators.required]);
        });
      } else {
        intervalsArray.controls.forEach((control) => {
          control.get('interval_unit').clearValidators();
          control.get('interval_value').clearValidators();
        });
      }
      // intervalsArray.controls.forEach((control) => {
      //   control.get('interval_unit').updateValueAndValidity();
      // });
    });

    const recurrentGroup = this.reactiveForm.controls['recurrent'];

    recurrentGroup.get('duration').valueChanges.subscribe(val => {
      const untilDateControl = recurrentGroup.get('until_date');
      const specificTimesControl = recurrentGroup.get('specific_number_of_times');
    
      if (val === 'UNTIL_DATE') {
        untilDateControl.setValidators([Validators.required]);
        specificTimesControl.clearValidators();
      } else if (val === 'SPECIFIC_NUMBER_OF_TIMES') {
        untilDateControl.clearValidators();
        specificTimesControl.setValidators([Validators.required]);
      } else {
        untilDateControl.clearValidators();
        specificTimesControl.clearValidators();
      }
    
      untilDateControl.updateValueAndValidity();
      specificTimesControl.updateValueAndValidity();
    });
    // let intervalsControl: FormArray = this._fb.array([]);
    let intervalsControl = this.reactiveForm.get('intervals_before_event') as FormArray;

    this.reactiveForm.get('oid')?.setValue(this.data.reminder?.oid ?? '');
    this.reactiveForm.get('title')?.setValue(this.data.reminder?.title ?? '');
    this.reactiveForm
      .get('trigger_date')
      ?.setValue(this.data.reminder?.trigger_date ?? '');
    this.reactiveForm
      .get('trigger_time')
      ?.setValue(this.data.reminder?.trigger_time ?? '');
    this.reactiveForm
      .get('is_recurrent')
      ?.setValue(this.data.reminder?.is_recurrent ?? false);
    if (this.data.reminder?.recurrent) {
      let clonedReminder = Object.assign({}, this.data.reminder.recurrent, {
        until_date: this.data.reminder.recurrent.until_date || '',
      });
      // this.reactiveForm
      //   .get('recurrent')
      //   ?.setValue(this.data.reminder?.recurrent);
      
      
      
      
      
      this.reactiveForm
        .get('recurrent')
        ?.setValue(clonedReminder);

        // this.reactiveForm.get('recurrent')?.patchValue({
        //   frequency_unit: this.data.reminder?.frequency_unit,
        //   frequency_value: this.data.reminder?.frequency_value,
        //   duration: this.data.reminder?.duration,
        //   specific_number_of_times: this.data.reminder?.specific_number_of_times,
        //   until_date: this.data.reminder?.until_date || '',
        // });
    } else {
      // this.reactiveForm.removeControl('recurrent');
    }

    this.reactiveForm.patchValue({
      notification_config: this.data.reminder?.notification_config,
    });

    const intervals = this.data.reminder?.intervals_before_event;

    if (intervals) {
      intervals.forEach((item, index) => {
        intervalsControl.push(
          this._fb.group({
            id: [index],
            interval_unit: [item.interval_unit],
            interval_value: [item.interval_value],
          })
        );
      });
      this.reactiveForm.addControl('intervals_before_event', intervalsControl);
    }
  }

  public trackByFunction(index: number, item: any): any {
    return item.id; // Assuming items have a unique identifier property like 'id'
  }

  public onSubmit() {}

  public isDate(date: Date): boolean {
    return typeof date.getMonth === 'function';
  }

  public saveReminder(event) {
    if (this.reactiveForm.valid) {
      if (!this.reactiveForm.get('recurrent').get('until_date').value) {
        (this.reactiveForm.get('recurrent') as FormGroup)?.removeControl('until_date');
      }
      if (!this.reactiveForm.get('is_recurrent').value) {
        this.reactiveForm.removeControl('recurrent');
      }
      this.dialogRef.close(this.reactiveForm.value);
    } else {
      event.preventDefault();
      this.reactiveForm.markAllAsTouched();
    }
  }

  public addInterval() {
    let intervalsControl = this.reactiveForm.get(
      'intervals_before_event'
    ) as FormArray;
    let formElementFieldGroup = this._fb.group({
      id: [intervalsControl.value.length],
      interval_unit: [''],
      interval_value: [''],
    });
    intervalsControl.push(formElementFieldGroup);
    let i = 0
    
    
    
  }

  public removeInterval(index: number) {
    this._subs.add(
      this._dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'shared.confirm_delete',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) {
              let intervalsControl = this.reactiveForm.get(
                'intervals_before_event'
              ) as FormArray;
              intervalsControl.removeAt(index);
            }
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );

  }

  public getIntervals() {
    return this.reactiveForm.get('intervals_before_event') as FormArray;
  }

  public editNotificationByDialog() {
    // return
    // 
    // 
    
    let dialogRef = this._dialog.open(NotificationComponent, {
      panelClass: 'big-dialog',
      data: this.data.reminder?.notification_config || null,
    });

    this._tempSub?.unsubscribe();
    this._tempSub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        
        
        this.reactiveForm.patchValue({
          notification_config: data,
        });
        // 
        // 
        // this._tempSub?.unsubscribe();
        // this._tempSub = this._actionNotifier
        //   .update({
        //     id: this.reactiveForm.get('id').value,
        //     notification_config: data,
        //   })
        //   .subscribe({
        //     next: (actionNotifierItem: ActionNotifierType) => {
        //       this.actionNotifierItem = actionNotifierItem;
        //     },
        //   });
      }
    });
  }
}
