<div class="pr-content-container">
  <div class="pr-content-header">
    <div class="list-title text-h4 font-bold">
      {{ "settings.acl.telephone-follow-up-plan" | translate }}
      <div *ngIf="tfuObj?.status" class="tag text-subtitle2 border-0" [ngClass]="tfuObj?.status === 'DONE' ? 'tag-done' : 'tag-standard'">
        {{ "settings." + tfuObj?.status | translate }}
      </div>
    </div>
  </div>
  <div class="pr-content-body">
    <form
      class="settings flex flex-col gap-4"
      [formGroup]="reactiveForm"
      (ngSubmit)="onSubmit()"
    >
      <!-- <div class="settings-section">
        <div class="settings-sub-section">
          <div class="form-field-label">
            {{ "settings.enter_title" | translate }}:
          </div>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="title"
              [placeholder]="'settings.title' | translate"
              type="text"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="settings-section-separator">* * * * * *</div> -->
      <div class="settings-section flex flex-col gap-4">
        <div class="form-field-label">
          {{ "settings.steps_follow_up" | translate }}:
        </div>
        <div
          class="notif-step-container flex flex-col gap-4"
          *ngIf="tfuObj?.items?.length"
        >
          <div
            class="notif-step"
            *ngFor="
              let tfuItem of tfuObj?.items;
              trackBy: trackByFunction;
              let i = index
            "
          >
            <div class="settings-sub-section">
              <div class="notif-step-header">
                <div class="flex-grow">
                  {{ tfuItem.title ? tfuItem.title : ("settings.step_follow_up" | translate) + " " + (i + 1)}}
                </div>
                <!-- <div class="tag tag-standard">En cours</div> -->
                <div
                  class="tag"
                  [ngClass]="tfuItem.done ? 'tag-done' : 'tag-standard'"
                >
                {{ (tfuItem.done ? "settings.done" : "settings.in_progress") | translate }}
                  <!-- {{ "settings.done" | translate }} -->
                </div>
                <div
                  class="cursor-pointer"
                  (click)="checkTfuItem(tfuItem)"
                  *ngIf="!tfuItem.done"
                  [matTooltip]="'settings.done' | translate"
                >
                  <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </div>
                <div class="cursor-pointer" [matMenuTriggerFor]="menu" *ngIf="!tfuItem.done">
                  <mat-icon
                    fontSet="mdi"
                    fontIcon="mdi-dots-vertical"
                  ></mat-icon>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editTfuItem(tfuItem.oid)">
                      <span>{{ "settings.edit" | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="removeTfuItem(tfuItem.oid)">
                      <span>{{ "settings.delete" | translate }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="centred-add-btn" (click)="addTfuItem()">
          <mat-icon fontSet="mdi" fontIcon="mdi-plus-circle-outline"></mat-icon>
          <span>{{ "settings.add_step_follow_up" | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
