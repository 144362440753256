import {ChangeDetectorRef, Component, ElementRef, HostBinding, ViewChild} from '@angular/core';
import {PatientRequestType} from '../../patient-request.type';
import {PatientRequestService} from '../../services/patient-request.service';
import {Subscription} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import {debounceTime} from "rxjs/operators";
import {concat, get, isEmpty} from "lodash";
import { ResponsiveBaseComponent } from '../../../shared/components/responsive-base/responsive-base.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pr-patient-request-list',
  templateUrl: './patient-request-list.component.html',
  styleUrls: ['./patient-request-list.component.scss'],
})
export class PatientRequestListComponent extends ResponsiveBaseComponent {
  @HostBinding('class.has-scroll') public hasScroll: boolean;
  @ViewChild('innerTable', {static: true}) innerTable: ElementRef;
  public requests: PatientRequestType[] = [];
  private _subs = new Subscription();

  page = 0;
  dataLength = 0;
  limit = 50;
  pagesCount = 1;
  searchAll = null;


  constructor(
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    private _patientRequestService: PatientRequestService,
    private _dialog: MatDialog, private ref: ChangeDetectorRef
  ) {
    super(breakpointObserver, titleService, translateService);
    this.title = "patient_request.request_list";
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.handleData();
    // this._subs.add(
    //   this._patientRequestService.fetchAll().subscribe({
    //     next: (items: PatientRequestType[]): void => {
    //       this.requests = items;
    //     },
    //   })
    // );
  }

  public isTreatedRequestAction(requestId: number) {
    this._subs.add(
      this._dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'patient_request.is_treated_request_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isTreated: boolean) => {
            if (isTreated) this.requestTreated(requestId);
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }

  public requestTreated(requestId: number) {
    this._subs.add(
      this._patientRequestService
        .update({id: requestId, dataAPIAction: 'treat-request'})
        .subscribe({
          next: (request: PatientRequestType) => {

            // Find the index of the object with id request.id
            const index = this.requests.findIndex((obj) => obj.id === request.id);

            // Replace the object at the found index with request
            if (index !== -1) {
              this.requests[index] = request;
            }
          },
        })
    );
  }


  public onScroll(): void {
    if (this.page < this.pagesCount + 1) {
      this.page++;
      this.handleData();
    }
  }


  handleSearch(ev) {
    this.searchAll = ev;
    this.page = 0;
    this.requests = [];
    this.getScrollStatus();
    this.handleData();
  }

  getScrollStatus() {
    setTimeout(() => {
      this.hasScroll = this.innerTable.nativeElement.scrollHeight > this.innerTable.nativeElement.clientHeight;
      this.ref.markForCheck();
    });
  }

  handleData() {
    this._patientRequestService.getPatientPaginatedRequests(this.page, this.searchAll).pipe(debounceTime(150)).subscribe(data => {
      if (data) {
        if (!isEmpty(data.list)) {
          // this.requests = concat(this.requests, data.list);
        }
        if (!this.dataLength) {
          this.dataLength = get(data, 'length', 0);
          this.pagesCount = this.dataLength !== 0 ? Math.ceil(this.dataLength / this.limit) : 0;
        }
      }
    })
  }

}
