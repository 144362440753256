import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {PhysicianGridComponent} from './components/physician-grid/physician-grid.component';
import {BaseSettingsComponent} from './components/base-settings/base-settings.component';
import {SmsSetupComponent} from './components/sms-setup/sms-setup.component';
import {SpecialityGridComponent} from './components/speciality-grid/speciality-grid.component';
import {
    AuthGuard,
    PrintingSetupComponent,
    ProfileDetailComponent,
    ProfileSetupComponent,
    StaffSetupComponent, StaffTypes
} from '@ft/core';
import {CommonStaffDialogComponent} from './dialogs/common-staff-dialog/common-staff-dialog.component';
import {PrescriptionFormsComponent} from './components/prescription-forms/prescription-forms.component';
import { ActionNotifierItemComponent } from './components/action-notifier/action-notifier-item/action-notifier-item.component';
import { TfuModelComponent } from './components/tfu/tfu-model/tfu-model.component';
import { TfuModelListComponent } from './components/tfu/tfu-model-list/tfu-model-list.component';
import { ActionNotifierListComponent } from './components/action-notifier/action-notifier-list/action-notifier-list.component';

const defaultLinks = [
    {label: 'shared.patients', link: '/patient/list'},
    {label: 'shared.prescriptions', link: '/prescription/list'},
    {label: 'shared.tickets', link: '/ticket/list'},
    {label: 'shared.appointments', link: '/patient-appointment/list'},

];

const STAFF_TYPES: StaffTypes[] = [
    {
        label: 'settings.physicians',
        url: '/api/staff/staff-form/',
        dialog: CommonStaffDialogComponent,
    }
];


const routes: Routes = [

    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '', pathMatch: 'full', redirectTo: 'base-settings'},
            {path: 'physician-list', component: PhysicianGridComponent},
            {path: 'base-settings', component: BaseSettingsComponent},
            {
                path: 'printing-setup',
                component: PrintingSetupComponent,
                data: {componentPath: '/settings/printing-setup'}
            },
            {
                path: 'profile-setup',
                component: ProfileSetupComponent,
                data: {profileDetail: '/settings/profile-update', defaultLinks: defaultLinks}
            },
            {path: 'profile-update', component: ProfileDetailComponent, data: {profileList: '/settings/profile-setup'}},
            {path: 'staff-setup', component: StaffSetupComponent, data: {staffs: STAFF_TYPES}},
            {path: 'sms-setup', component: SmsSetupComponent},
            {path: 'speciality-list', component: SpecialityGridComponent},
            {path: 'prescription-forms', component: PrescriptionFormsComponent},
            {path: 'action-notifier', component: ActionNotifierListComponent},
            {path: 'action-notifier/:id', component: ActionNotifierItemComponent},
            {path: 'tfu-model', component: TfuModelListComponent},
            {path: 'tfu-model/:id', component: TfuModelComponent},
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SettingsRoutingModule {
}
