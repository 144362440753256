import {TableColumnDefinition} from '@ft/core';
import {get} from 'lodash';

export const PRESCRIPTION_COLUMNS: TableColumnDefinition[] = [
    {
        'label': 'shared.date',
        'key': 'date',
        'sortBy': 'date',
        'type': 'date',
        'is_global_searchable': true,
        'required': true
    },
    {
        'label': 'patient.last_name',
        'key': 'patient.last_name',
        'sortBy': 'patient.last_name',
        'type': 'text',
        'is_global_searchable': true,
        'required': true,
        'is_ref': true,
        'model': 'patient.Patient'
    },
    {
        'label': 'patient.first_name',
        'key': 'patient.first_name',
        'sortBy': 'patient.first_name',
        'type': 'text',
        'is_global_searchable': true,
        'required': true,
        'is_ref': true,
        'model': 'patient.Patient'
    },
    {
        'label': 'shared.city',
        'key': 'patient.city.short_name',
        'sortBy': 'patient.city.short_name',
        'type': 'text',
        'is_global_searchable': true,
        'required': false,
        'callBack': (item) => {
            return get(item, 'patient.city.short_name');
        }
    },


    {
        'label': 'shared.start_date',
        'key': 'start_date',
        'sortBy': 'start_date',
        'type': 'date',
        'is_global_searchable': true,
        'required': true
    },
    {
        'label': 'prescription.end_date',
        'key': 'end_date',
        'sortBy': 'end_date',
        'type': 'date',
        'is_global_searchable': true,
        'required': true
    },
    // {
    //   'label': 'patient.national_id',
    //   'key': 'patient.national_id',
    //   'sortBy': 'national_id',
    //   'type': 'text',
    //   'is_global_searchable': true
    // }

];
