import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, Subscription} from 'rxjs';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {PHYSICIAN_VIEW} from '../../../settings/utils/views-settings';


@Component({
    selector: 'app-search-dialog',
    templateUrl: './search-dialog.component.html',
    styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent implements OnInit {
    subscription: Subscription;
    physicians = [];
    dataSource: Observable<any[]> = of([]);
    viewSettings = PHYSICIAN_VIEW;

    constructor(public dialogRef: MatDialogRef<SearchDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _adapter: MomentDateAdapter ) {
    }

    ngOnInit(): void {
    }

    selectItem(ev) {
        this.dialogRef.close(ev);
    }

}
