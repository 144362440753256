import { Component, OnInit, Optional } from '@angular/core';
import { TfuModelListBaseComponent } from '../tfu-model-list-base/tfu-model-list-base.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TfuModelListComponent } from '../tfu-model-list/tfu-model-list.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TfuModelService } from '../../../services/tfu-model.service';
import { TFUModelType } from '../../../types/telephone-follow-up.type';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pr-tfu-model-list-for-prescription',
  templateUrl: './tfu-model-list-for-prescription.component.html',
  styleUrls: ['./tfu-model-list-for-prescription.component.scss']
})
export class TfuModelListForPrescriptionComponent extends TfuModelListBaseComponent {

  private oldTitle = '';
  constructor(
    @Optional() public dialogRef: MatDialogRef<TfuModelListComponent>,
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    public tfuModelService: TfuModelService
  ) {
    super(dialogRef, breakpointObserver, titleService, translateService, tfuModelService)
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.titleService.setTitle(this.oldTitle);
  }

  ngOnInit(): void {
    this.oldTitle = this.titleService.getTitle();
    

    super.ngOnInit();
  }
  
  public selectTfuModel(event) {
    // this.selectedTfuModel = this.tfuModels.find((item) => item.id === profileId);
      if(this.selectedTfuModel){
        this.dialogRef.close(this.selectedTfuModel);
      } else {
        event.preventDefault();
      }
    
  }

  public selectNewTfuModel(event, tfuModel: TFUModelType) {
    this.selectedId = tfuModel.id;
    this.selectedTfuModel = tfuModel;
  }

}