import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'pr-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public key: string = '';
  public isFocused: boolean = false;
  @Output() public searchChange = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  public onSearch(event: any): void {
    this.searchChange.emit(event);
  }
}
