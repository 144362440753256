import {Component, EventEmitter, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {PhysicianService} from '../../services/physician.service';
import {PHYSICIAN_COLUMNS} from '../../utils/physician-columns';
import {assignIn, cloneDeep, concat, get} from 'lodash';
import {PhysicianSetupComponent} from '../../dialogs/physician-setup/physician-setup.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PHYSICIAN_VIEW} from '../../utils/views-settings';
import {ActionItem, MEDIUM_DIALOG, TableColumnDefinition} from '@ft/core';

@Component({
    selector: 'app-physician-grid',
    templateUrl: './physician-grid.component.html',
    styleUrls: ['./physician-grid.component.scss']
})
export class PhysicianGridComponent implements OnInit {
    subscription: Subscription;
    dataSource: Observable<any[]> = of([]);
    multiActions: ActionItem[] = [
        {
            class: 'mat-primary',
            icon: 'mdi-account-plus',
            method: (item, ev) => {
                return this.handlePhysician();
            },
            tooltip: this.translateService.instant('shared.add'),
            acl: {resource: 'physician', action: 'create', behavior: 'remove'}
        }
    ];
    singleActions = {
        label: '', key: 'actions', type: 'actions', actions: [
            {
                class: 'mat-primary',
                icon: 'mdi-account-edit',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.handlePhysician(item);
                },
                tooltip: this.translateService.instant('shared.edit'),
                acl: {resource: 'physician', action: 'update', behavior: 'remove'}

            },
            {
                class: 'mat-warn',
                icon: 'mdi-account-remove',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.removePhysician(get(item, 'id'));
                },
                tooltip: this.translateService.instant('shared.delete'),
                acl: {resource: 'physician', action: 'delete', behavior: 'remove'}

            }
        ]
    };
    displayedColumns = new BehaviorSubject<TableColumnDefinition[]>(concat(PHYSICIAN_COLUMNS, (this.singleActions as any)));
    reloadEvent = new EventEmitter();
    viewSettings = PHYSICIAN_VIEW;

    constructor(public physicianService: PhysicianService,
                public translateService: TranslateService,
                private dialog: MatDialog,
                private router: Router,
                protected snackBar: MatSnackBar) {
        this.viewSettings.viewSettings.viewColumns = concat(this.viewSettings.viewSettings.viewColumns,
            (this.singleActions as any));
        this.viewSettings.actions = this.multiActions;
    }

    ngOnInit(): void {
    }

    applyFilter(ev) {

    }

    handlePhysician(model = null) {
        this.dialog.open(PhysicianSetupComponent, assignIn(MEDIUM_DIALOG,
            {
                data: {model: cloneDeep(model)},
                disableClose: true,
                autoFocus: false
            }))
            .afterClosed().subscribe((data) => {
            if (data) {
                this.reloadEvent.emit(true);
            }
        });
    }

    removePhysician(pk) {
        this.subscription = this.physicianService.allowPhysicianDelete(pk).subscribe(data => {
            if (data) {
                this.subscription = this.physicianService.deletePhysician(pk).subscribe(res => {
                    this.reloadEvent.emit(true);
                });
            } else {
                this.snackBar.open(this.translateService.instant('shared.delete_error'));
            }
        });
    }
}
