export let patientRequestFr: Record<string, any> = {
    request_list: 'Liste des demandes',
    requests: 'Les demandes',
    treated: 'Traité',
    not_treated: 'Non traité',
    appointment: 'Rendez-vous',
    request: 'Demande',
    is_treated_request_question: 'La demande est-elle traitée ?', 
    title: 'Titre',
    content: 'Contenu',
    status: 'Statut',
    type: 'Type',

};
