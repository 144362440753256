import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BasicGenericViewTableComponent} from '@ft/table';
import {get, isEmpty} from 'lodash';


@Component({
    selector: 'app-pr-basic-generic-view-table',
    templateUrl: './pr-basic-generic-view-table.component.html',
    styleUrls: ['./pr-basic-generic-view-table.component.scss']
})
export class PrBasicGenericViewTableComponent extends BasicGenericViewTableComponent {
    activeFilter = null;
    customFilters = [];
    @Output() public queryChange: EventEmitter<any> = new EventEmitter<any>();

    @Input('customFilters') set handleModelName(m: any) {
        this.customFilters = m;
    }

    constructor(
        _sanitizer: DomSanitizer,
        _ref: ChangeDetectorRef
    ) {
        super(_sanitizer, _ref);
    }

    handleCustomFilter(f) {
        this.activeFilter = this.activeFilter === f ? null : f;
        this.queryChange.emit(get(this.activeFilter, 'query', []));
    }

    handleIconValue(element: any, column: any, styleRules: any) {

        return this.handleValue(element, column);

    }

}
