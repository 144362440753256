import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from '../../../../shared/components/responsive-base/responsive-base.component';
import { ActionNotifierService } from '../../../services/action-notifier.service';
import { ActionNotifierType } from '../../../types/action-notifier.type';
import { Router } from '@angular/router';
import { noop } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RecipientList } from '../../../utils/recipient.data';
import { ConfirmDialogComponent } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'pr-action-notifier-list',
  templateUrl: './action-notifier-list.component.html',
  styleUrls: ['./action-notifier-list.component.scss'],
})
export class ActionNotifierListComponent extends ResponsiveBaseComponent {
  public actionNotifiers: ActionNotifierType[] = [];
  constructor(
    public breakpointObserver: BreakpointObserver,
    public titleService: Title,
    public translateService: TranslateService,
    private _actionNotifierService: ActionNotifierService,
    private _router: Router,
    public dialog: MatDialog
  ) {
    super(breakpointObserver, titleService, translateService);
    this.title = 'settings.the_actions_notifiers';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subs.add(
      this._actionNotifierService.fetchAll().subscribe({
        next: (actionNotifiers: ActionNotifierType[]): void => {
          this.actionNotifiers = actionNotifiers;
        },
      })
    );
  }

  public getRecipientViewValue(recipient: string): string {
    const recipientItem = RecipientList.find(
      (item) => item.value === recipient
    );
    return recipientItem ? recipientItem.viewValue : recipient;
  }

  public addActionNotifier(event) {
    this._router.navigate([`settings/action-notifier/`, 'new']).then(noop);
  }

  public remove(event, actionNotifierId: number) {
    event.stopPropagation();
    this.subs.add(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'settings.delete_action_notifier_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) {
              this.subs.add(
                this._actionNotifierService
                  .deleteById(actionNotifierId)
                  .subscribe({
                    next: (actionNotifier: ActionNotifierType): void => {
                      this.actionNotifiers = this.actionNotifiers.filter(
                        (item) => item.id !== actionNotifierId
                      );
                    },
                  })
              );
            }
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }
}
