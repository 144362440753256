<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-card-account-details"></mat-icon>
    </button>
  </div>
  <h2>{{ 'patient.patient_file' | translate }}</h2>

</mat-toolbar>
<div fxFlex fxFill fxLayout="column" class="ft-content ft-padding">
  <div [ngBusy]="subscription"></div>
  <form #form="ngForm" fxFlex>
    <div fxLayout="column" class="ft-busy-container" style="min-height: 230px;">
      <div fxLayout="column" fxLayoutGap="6px">
        <mat-form-field fxFlex>
          <mat-label>{{'shared.title' | translate}}</mat-label>
          <mat-select [(ngModel)]="patient.title" name="title" [compareWith]="compareCondition"
                      (ngModelChange)="handleTitleChange($event)">
            <mat-option *ngFor="let t of titles" [value]="t">
              {{t.value}}
            </mat-option>
          </mat-select>
          <button *ngIf="patient.title" matSuffix mat-icon-button type="button" color="warn"
                  [matTooltip]="'shared.clean' | translate "
                  (click)="$event.stopImmediatePropagation(); patient.title=null;">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="text" required name="last_name" placeholder="{{'patient.last_name'|translate}}"
                 [ftAutoFocus]="true"
                 [(ngModel)]="patient.last_name"/>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="text" required name="first_name" placeholder="{{'patient.first_name'|translate}}"
                 [(ngModel)]="patient.first_name"/>
        </mat-form-field>
        <mat-form-field fxFlex="nogrow">
          <input matInput ftDate [matDatepicker]="b_date" [placeholder]="'patient.birth_date'|translate" required
                 #dateModel="ngModel"
                 [(ngModel)]="patient.birth_date" name="b_date">
          <mat-datepicker-toggle matSuffix [for]="b_date">
            <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #b_date></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput [placeholder]="'shared.age'|translate"
                 [(ngModel)]="patient.age" name="age"
                 ftNumber="float" [ftAgeHandler]="dateModel" [fix]="0"
          />
        </mat-form-field>
        <mat-form-field fxFlex floatLabel="always" class="ft-radio-field">
          <input matInput placeholder="{{'patient.gender'|translate}}" style="display: none">
          <mat-radio-group [(ngModel)]="patient.gender" name="b_type" fxLayout="row">
            <mat-radio-button *ngFor="let b_type of ['MALE','FEMALE']" [value]="b_type">
              {{'patient.' + b_type | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </mat-form-field>
        <mat-form-field fxFlex class="with-error-label">
          <input matInput type="text" name="national_id" placeholder="{{'patient.national_id'|translate}}"
                 [(ngModel)]="patient.national_id"
                 name="national_id"
          />
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{'shared.country' | translate}}</mat-label>
          <mat-select [(ngModel)]="patient.country" name="country" [compareWith]="compareCondition"
                      ftDefaultValues="defaultParams.country">
            <mat-option *ngFor="let t of countries" [value]="t">
              {{t.full_name}}
            </mat-option>
          </mat-select>
          <button *ngIf="patient.country" matSuffix mat-icon-button type="button" color="warn"
                  [matTooltip]="'shared.clean' | translate "
                  (click)="$event.stopImmediatePropagation(); patient.country=null;">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-label>{{'shared.city' | translate}}</mat-label>
          <mat-select [(ngModel)]="patient.city" name="city" [compareWith]="compareCondition"
                      ftDefaultValues="defaultParams.city">
            <mat-option *ngFor="let t of cities" [value]="t">
              {{t.full_name}}
            </mat-option>
          </mat-select>
          <button *ngIf="patient.city" matSuffix mat-icon-button type="button" color="warn"
                  [matTooltip]="'shared.clean' | translate "
                  (click)="$event.stopImmediatePropagation(); patient.city=null;">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="tel" name="phone_numbers" placeholder="{{'patient.phone_number'|translate}}"
                 [(ngModel)]="patient.phone_number" pattern="^[0-9]{5,12}$"/>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="tel" name="emergency_phone_numbers"
                 placeholder="{{'patient.emergency_phone_number'|translate}}"
                 [(ngModel)]="patient.emergency_phone_number" pattern="^[0-9]{5,12}$"/>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="email" name="email" placeholder="{{'patient.email'|translate}}"
                 [(ngModel)]="patient.email" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"/>
        </mat-form-field>

        <mat-form-field *ngIf="!isDoctor" fxFlex>
          <input readonly matInput [placeholder]="'settings.physician'|translate" name="physician"
                 [(ngModel)]="physician"/>
          <button matSuffix mat-icon-button type="button" color="primary"
                  [matTooltip]="'shared.search' | translate "
                  (click)="$event.stopImmediatePropagation(); searchPhysician();">
            <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
          </button>
          <button matSuffix mat-icon-button type="button" color="primary"
                  [matTooltip]="'shared.add' | translate "
                  (click)="$event.stopImmediatePropagation(); newPhysician();">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
          </button>
          <button *ngIf="patient.physician" matSuffix mat-icon-button type="button" color="warn"
                  [matTooltip]="'shared.clean' | translate "
                  (click)="$event.stopImmediatePropagation(); clearPhysician();">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field fxFlex>
            <textarea matInput [(ngModel)]="patient.address" name="address"
                      [placeholder]="'shared.address'|translate"></textarea>
        </mat-form-field>
        <mat-form-field fxFlex>
            <textarea matInput [(ngModel)]="patient.comment" name="comment"
                      [placeholder]="'shared.comment'|translate"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="ft-actions">
  <span fxFlex></span>
  <button type="button" mat-raised-button color="warn" (click)="exit()">
    <span>{{'shared.reset'|translate}}</span>
  </button>
  <button type="button" mat-raised-button color="primary" (click)="handleSave()" [disabled]="form.invalid">
    <span>{{'shared.save'|translate}}</span>
  </button>
</div>
