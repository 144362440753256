import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewValueType } from '../../../types/shared.type';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionNotifierService } from '../../../services/action-notifier.service';
import { ActionService } from '../../../services/action.service';
import {
  ActionNotifierType,
  ActionType,
  NotificationConfigType,
  ReminderType,
} from '../../../types/action-notifier.type';
import { MatDialog } from '@angular/material/dialog';
import { ReminderComponent } from '../../../../shared/dialogs/reminder/reminder.component';
import { Subscription } from 'rxjs';
import { NotificationComponent } from '../../../../shared/dialogs/notification/notification.component';
import { TranslateService } from '@ngx-translate/core';
import { RecipientList } from '../../../utils/recipient.data';
import { Title } from '@angular/platform-browser';
import { projectName } from '../../../../shared/utils/consts';
import { convertToNumber } from '../../../../shared/utils/functions';
import { ConfirmDialogComponent } from '../../../../shared/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'pr-action-notifier-item',
  templateUrl: './action-notifier-item.component.html',
  styleUrls: ['./action-notifier-item.component.scss'],
})
export class ActionNotifierItemComponent implements OnInit, OnDestroy {
  public reactiveForm!: FormGroup;
  public actionNotifierItem: ActionNotifierType | null = null;
  public actions: any = [];
  public docId: number | null = null;
  public recipients: string[] = [];
  public action: any = null;
  public notification_config: NotificationConfigType | null = null;
  // TODO filter recipientList by resource
  public recipientList: ViewValueType[] = RecipientList;
  private _subs = new Subscription();
  private _tempSub = new Subscription();

  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _actionNotifier: ActionNotifierService,
    private _action: ActionService,
    private _dialog: MatDialog,
    private _translateService: TranslateService,
    private _titleService: Title
  ) {
    this.reactiveForm = this._fb.group({
      id: [''],
      recipients: [[], Validators.required],
      // recipients: this._fb.array(['']),
      action: ['', Validators.required],
      // reminders: this._fb.array(['']),
    });
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this._tempSub.unsubscribe();
  }

  ngOnInit(): void {
    this._titleService.setTitle(
      projectName +
        ' - ' +
        this._translateService.instant('settings.action_notifier')
    );
    this._subs.add(
      this._route.params.subscribe({
        next: (params) => {
          this.docId = convertToNumber(params['id']);
          this._subs.add(
            this._action.fetchAll().subscribe({
              next: (actions: ActionType[]): void => {
                this.actions = actions;   
              },
            })
          );
          if (this.docId) {
            this._subs.add(
              this._actionNotifier.fetch({ id: this.docId }).subscribe({
                next: (actionNotifierItem: ActionNotifierType): void => {
                  this.actionNotifierItem = actionNotifierItem;
                  this.init(actionNotifierItem);
                },
                error: (error) => {
                  this.docId = null;
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
  }

  public init(actionNotifierItem: ActionNotifierType) {
    // this.reactiveForm
    //   .get('recipients')
    //   .setValue(this.recipientList);
    // })
    this.updateActionAndRecipientsControls(actionNotifierItem);
  }

  public addIfNone(fb: Function) {
    if (!this.docId && this.reactiveForm.valid) {
      this._subs.add(
        this._actionNotifier.create(this.reactiveForm.value).subscribe({
          next: (actionNotifier: ActionNotifierType): void => {
            this.docId = actionNotifier.id;
            this._router.navigate(['/settings/action-notifier/', this.docId], {
              replaceUrl: true,
            });
            this.init(actionNotifier);
            this.recipients = [];
            this.action = null;
            if (fb) {
              fb();
            }
          },
        })
      );
    } else if (this.reactiveForm.valid) {
      fb();
    } else {
      this.reactiveForm.markAllAsTouched();
    }
  }

  private _updateFields() {
    if (this.recipients?.length > 0) {
      this._tempSub?.unsubscribe();
      this._tempSub = this._actionNotifier
        .update({
          id: this.reactiveForm.get('id').value,
          recipients: this.recipients,
        })
        .subscribe({
          next: (actionNotifierItem: ActionNotifierType) => {
            this.actionNotifierItem = actionNotifierItem;
            this.recipients = [];
          },
        });
    }
    if (this.action) {
      this._tempSub?.unsubscribe();
      this._tempSub = this._actionNotifier
        .update({
          id: this.reactiveForm.get('id').value,
          action: this.action,
        })
        .subscribe({
          next: (actionNotifierItem: ActionNotifierType) => {
            this.actionNotifierItem = actionNotifierItem;
            this.action = null;
          },
        });
    }
  }

  public isFormControlsValid() {
    return this.reactiveForm.valid;
  }

  public updateRecipients(eventValue: string[]) {
    this.recipients = eventValue;
    if (this.reactiveForm.valid) {
      this.addIfNone(this._updateFields.bind(this));
    }
  }

  public updateAction(eventValue: string) {
    this.action = eventValue;
    if (this.reactiveForm.valid) {
      this.addIfNone(this._updateFields.bind(this));
    }
  }

  public getFirstSelectedRecipient(desiredValue: string) {
    const desiredRecipient = this.recipientList.find(
      (item) => item.value === desiredValue
    );

    if (desiredRecipient) {
      return this._translateService.instant(desiredRecipient.viewValue);
    } else {
      return '';
    }
  }

  public trackByFunction(index: number, item: any): any {
    return item.oid;
  }

  public onSubmit() {}

  public updateActionAndRecipientsControls(
    actionNotifierItem: ActionNotifierType
  ) {
    this.reactiveForm.patchValue({
      id: actionNotifierItem.id,
      recipients: actionNotifierItem.recipients,
      action: actionNotifierItem.action,
      // action: {label: "settings.acl.action-notifier", resource_name: "action-notifier", name: "core.acl.create"},
    });
  }

  public objectComparisonFunction = function (option, value): boolean {
    return option.label === value.label && option.name === value.name;
  };

  public addReminder() {
    this.addReminderByDialog('');
  }

  public editReminder(reminderOid: string) {
    this.addReminderByDialog(reminderOid);
  }

  public addReminderByDialog(reminderOid: string) {
    let dialogRef: any;
    if (reminderOid) {
      dialogRef = this._dialog.open(ReminderComponent, {
        panelClass: 'big-dialog',
        data: {
          reminder: this.actionNotifierItem.reminders?.find(
            (reminder) => reminder.oid === reminderOid
          ),
        },
      });
    } else {
      dialogRef = this._dialog.open(ReminderComponent, {
        panelClass: 'big-dialog',
        data: { reminder: null },
      });
    }

    this._tempSub?.unsubscribe();
    this._tempSub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (!data.oid) {
          delete data.oid;
        }
        let newReminders: ReminderType[] = [];

        // // for new action notifier
        // if(this.reminders.length > 0){
        //   newReminders = [...this.reminders];
        // }

        // for existing action notifier
        if (this.actionNotifierItem?.reminders?.length > 0) {
          newReminders = [...this.actionNotifierItem?.reminders];
        }

        if (data.oid) {
          let foundReminder = newReminders.find(
            (reminder: ReminderType) => reminder.oid === data.oid
          );
          if (foundReminder) {
            newReminders = newReminders.map((obj) =>
              data.oid === obj.oid ? data : obj
            );
          }
        } else {
          newReminders.push(data);
        }
        this.updateReminderBackend(newReminders);
      }
    });
  }

  public editNotificationByDialog() {
    let dialogRef = this._dialog.open(NotificationComponent, {
      panelClass: 'big-dialog',
      data: this.actionNotifierItem.notification_config || null,
    });

    this._tempSub?.unsubscribe();
    this._tempSub = dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.notification_config = data;
        this.addIfNone(() => {
          this._tempSub?.unsubscribe();
          this._tempSub = this._actionNotifier
            .update({
              id: this.reactiveForm.get('id').value,
              notification_config: data,
            })
            .subscribe({
              next: (actionNotifierItem: ActionNotifierType) => {
                this.actionNotifierItem = actionNotifierItem;
              },
            });
        });
      }
    });
  }

  public updateReminderBackend(newReminders) {
    this.addIfNone(() => {
      this._tempSub?.unsubscribe();
      this._tempSub = this._actionNotifier
        .update({
          id: this.reactiveForm.get('id').value,
          reminders: newReminders,
        })
        .subscribe({
          next: (actionNotifierItem: ActionNotifierType) => {
            this.actionNotifierItem = actionNotifierItem;
            this.updateActionAndRecipientsControls(actionNotifierItem);
          },
        });
    });
  }

  public removeReminder(reminderOid: string) {
    this._subs.add(
      this._dialog
        .open(ConfirmDialogComponent, {
          data: {
            question: 'settings.delete_reminder_question',
          },
        })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (isConfirmed) {
              let newReminders = this.actionNotifierItem.reminders.filter(
                (obj) => obj.oid !== reminderOid
              );
              this.updateReminderBackend(newReminders);
            }
          },
          error: (error: Error) => {
            console.error(error);
          },
        })
    );
  }
}
