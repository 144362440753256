import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {PatientPrescriptionType} from '../patient-prescription.type';
import {patientPrescriptionSample} from '../../shared/samples/patient-prescription.sample';
import {DataApiService} from '../../shared/services/data-api.service';
import {HttpClient} from '@angular/common/http';
import {isEmpty} from "lodash";
import { UIService } from '../../shared/services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class PatientPrescriptionService extends DataApiService<PatientPrescriptionType> {
  constructor(
    public httpClient: HttpClient,
    public uiService: UIService
  ) {
    super(httpClient, uiService);
    this.setRootUrl('/api/pl/patient-prescription');
  }

  public getPatientPrescriptions(): Observable<PatientPrescriptionType[]> {
    return patientPrescriptionSample();
  }

  public getPatientPrescriptionItem(pk): Observable<any> {
    return this.httpClient.get(`/api/pl/prescription/${pk}/`);
  }

  public getPatientPaginatedPrescriptions(page, searchAll): Observable<any> {
    let query = isEmpty(searchAll) ? `page_number=${page}` : `page_number=${page}&search_all=${searchAll}`;
    return this.httpClient.get(`/api/pl/patient-prescription/?${query}`);
  }
  public getPatientPrescriptionSatisfactionForms(pk): Observable<any> {
    return this.httpClient.get(`/api/pl/patient-prescription/satisfaction-form/?prescription=${pk}`);
  }


}
