<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <mat-icon fontSet="mdi" fontIcon="mdi-account-settings"></mat-icon>
    </div>
    <h2 mat-dialog-title translate="auth.account.label"></h2>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<form novalidate #form="ngForm" (ftAsyncSubmit)="submit()" [subscription]="loader" autocomplete="false">
    <div mat-dialog-content fxLayout="column" class="ft-busy-container">
        <div fxLayout="row" fxLayoutGap="6px">
<!--            <mat-form-field fxFlex="150px">-->
<!--                <ft-select [placeholder]="'auth.staff.title' | translate" [config]="titleSelectConfig"-->
<!--                           [(ngModel)]="staff.title" name="title">-->
<!--                </ft-select>-->
<!--            </mat-form-field>-->
            <mat-form-field fxFlex>
                <input matInput required [ftAutoFocus]="true" [placeholder]="'auth.staff.last_name' | translate"
                       name="last_name" [(ngModel)]="staff.last_name">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput required [placeholder]="'auth.staff.first_name' | translate" name="first_name"
                       [(ngModel)]="staff.first_name">
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex="150px">
                <input matInput ftDate  [matDatepicker]="dp" [placeholder]="'auth.staff.birth_date' | translate"
                       [(ngModel)]="staff.birth_date" name="birth_date">
                <mat-datepicker-toggle matSuffix [for]="dp">
                    <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex>
                <ft-select required [placeholder]="'auth.staff.service' | translate" [config]="serviceSelectConfig"
                           [(ngModel)]="staff.service" name="service">
                </ft-select>
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput [placeholder]="'settings.shared_code' | translate" name="shared_code"
                       [(ngModel)]="staff['shared_code']">
            </mat-form-field>
        </div>

        <hr class="ft-sep">

        <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex>
                <input matInput [placeholder]="'auth.staff.phone_number' | translate" name="number"
                       [(ngModel)]="staff.contact_info.phone_numbers[0]">
            </mat-form-field>
            <mat-form-field fxFlex>
                <input matInput [placeholder]="'auth.staff.email' | translate" name="email"
                       [(ngModel)]="staff.contact_info.email" type="email">
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <textarea matInput [placeholder]="'auth.staff.address' | translate" name="address"
                          [(ngModel)]="staff.address.content"></textarea>
            </mat-form-field>
        </div>

        <ng-container *ngFor="let user of staff.users; let i = index">
            <hr class="ft-sep">

            <div fxLayout="row" fxLayoutGap="6px">
                <mat-form-field fxFlex class="with-error-label">
                    <input matInput required [placeholder]="'auth.staff.login' | translate" [name]="'username[' + i + ']'"
                           [(ngModel)]="user.username" ftCustomValidation="user_login.title_validation"
                           [query]="{id: user.id}" #login="ngModel" />
                    <mat-error *ngIf="login.errors?.exists"
                               [innerHTML]="'auth.staff.login_already_exist' | translate:user"></mat-error>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <ft-select required [placeholder]="'auth.staff.profile' | translate" [config]="profileSelectConfig"
                               [(ngModel)]="user.profile" [name]="'profile[' + i + ']'">
                    </ft-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="6px">
                <mat-form-field fxFlex>
                    <input matInput [required]="!user.id" [placeholder]="'auth.staff.password' | translate"
                           [name]="'password[' + i + ']'" [(ngModel)]="user.password" type="password">
                </mat-form-field>
                <mat-form-field fxFlex class="with-error-label">
                    <input matInput [required]="!user.id" [placeholder]="'auth.staff.password_confirm' | translate"
                           [name]="'password_confirm[' + i + ']'" [(ngModel)]="user.password_confirm"  [ftEqualValidator]="'password[' + i + ']'"
                           #passwordComfirm="ngModel" type="password">
                    <mat-error *ngIf="passwordComfirm.invalid">{{'auth.account.password_confirm_invalid' | translate}}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row">
                <mat-slide-toggle [(ngModel)]="user.is_special" [name]="'is_special[' + i + ']'" style="height: 30px;">
                    <span fxFlex>{{'auth.staff.is_special' | translate}}</span>
                </mat-slide-toggle>
            </div>
        </ng-container>

    </div>
    <div mat-dialog-actions>
        <mat-slide-toggle [(ngModel)]="staff.is_active" name="is_active" color="primary">
            <span translate="auth.staff.account_activated"></span>
        </mat-slide-toggle>
        <mat-slide-toggle [(ngModel)]="staff.is_doctor" name="is_doctor" color="primary">
            <span translate="settings.is_doctor"></span>
        </mat-slide-toggle>
        <span fxFlex></span>
        <button mat-raised-button class="has-icon" type="button" tabindex="-1" color="primary" [disabled]="form.invalid" (click)="addUser()">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span translate="auth.staff.add_user"></span>
        </button>
        <button mat-raised-button type="submit" tabindex="2" color="primary" [disabled]="form.invalid">
            <span translate="auth.save"></span>
        </button>
        <button mat-raised-button mat-dialog-close tabindex="-1" color="warn">
            <span translate="auth.cancel"></span>
        </button>
    </div>
</form>

