import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, of, Observable } from 'rxjs';
import { TicketType } from '../ticket.type';
import { DataApiService } from 'src/app/shared/services/data-api.service';
import { HttpClient } from '@angular/common/http';
import { get, has } from 'lodash';
import { UIService } from '../../shared/services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class TicketService extends DataApiService<TicketType> {
  constructor(public httpClient: HttpClient, public uiService: UIService) {
    super(httpClient, uiService);
    this.setRootUrl('/api/pl/ticket');
  }

  public ChangeStatusToReceived(items: TicketType[]) {
    const ticketIds: number[] = items
      .filter((ticketData) => {
        return !ticketData['ticket_receipt'];
      })
      .map((ticketData: TicketType) => {
        return ticketData.id;
      });

    if (ticketIds.length > 0)
      return this.update({
        ticket_ids: ticketIds,
        dataAPIAction: 'receipt_bulk',
      }, false);
    else {
      return of(false);
    }
  }

  public getTicketSatisfactionForm(ticket_id: number) {
    return this.httpClient.get<any>(
      `/api/pl/ticket-satisfaction-form/?ticket=${ticket_id}`
    );
  }

  public saveTicketSatisfactionForm(model) {
    return this.httpClient.post(
      `api/pl/ticket-satisfaction-form/${
        has(model, 'id') ? get(model, 'id') + '/' : ''
      }`,
      model
    );
  }
  public getTicketSatisfactionFormResult(pk): Observable<any> {
    return this.httpClient.get(
      `/api/pl/ticket/satisfaction-form/?ticket=${pk}`
    );
  }
  public getSatisfactionForm(pk): Observable<any> {
    return this.httpClient.get(`/api/pl/ticket-satisfaction-form/${pk}/`);
  }
}
