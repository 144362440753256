<div *ngIf="breakpointObserver.isMatched('(min-width: 1280px)')"
     fxFlex
     fxLayout="column"
     class="ft-content"
>
    <app-pr-generic-view-table
            [namespace]="viewSettings?.viewNamespace"
            [actions]="viewSettings?.actions"
            [viewSettings]="viewSettings?.viewSettings"
            [hasPagination]="viewSettings?.hasPagination"
            [selectable]="viewSettings?.selectable"
            [query]="viewSettings?.query"
            [reloadEvent]="reloadEvent"
            [exportExcel]="true"
            [customFilters]="customFilters"
    >
    </app-pr-generic-view-table>
</div>

<pr-cards-view fxFlex fxLayout="column" *ngIf="breakpointObserver.isMatched('(max-width: 1279.98px)')"
               [viewSettings]="viewSettings"
               [customTemplate]="cardsContentTemplate"></pr-cards-view>

<ng-template let-item="item" #cardsContentTemplate>
    <div class="attributes-header">
        <img [src]="item.picture? item.picture :'/assets/icons/default-avatar.svg'" alt="avatar"/>
        <div class="item-prescription-name"><span
                (click)="handlePatient(item)">{{ item.first_name + " " + item.last_name }}</span></div>
        <div class="item-prescription-count" *ngIf="item?.prescriptions_count" [ngStyle]="handleStatusColor(item)">
            <span>{{item?.prescriptions_count}}</span></div>
        <!-- <div class="item-prescription-count" *ngIf="1" [ngStyle]="handleStatusColor(item)"><span>5</span></div> -->
    </div>
    <div class="attributes-container">
        <div class="attribute-item">
            <div class="attribute-icon">
                <mat-icon fontSet="mdi" fontIcon="mdi-gender-male-female"></mat-icon>
            </div>
            <div>
                <div class="attribute-key">{{'patient.gender'| translate}}</div>
                <div class="attribute-value">{{item.gender != null ? (("patient." + item.gender)|translate) : '' }}</div>
            </div>
        </div>
        <div class="attribute-item">
            <div class="attribute-icon">
                <mat-icon fontSet="mdi" fontIcon="mdi-calendar-range"></mat-icon>
            </div>
            <div>
                <div class="attribute-key">{{'patient.birth_date'|translate}}</div>
                <div class="attribute-value">{{ item.birth_date |ftDate }}</div>
            </div>
        </div>

        <div class="attribute-item">
            <div class="attribute-icon">
                <mat-icon fontSet="mdi" fontIcon="mdi-phone"></mat-icon>
            </div>
            <div>
                <div class="attribute-key">{{'settings.phone_number'|translate}}</div>
                <div class="attribute-value">{{ item.phone_number || '###' }}</div>
            </div>
        </div>
        <div class="attribute-item">
            <div class="attribute-icon">
                <mat-icon fontSet="mdi" fontIcon="mdi-account"></mat-icon>
            </div>
            <div>
                <div class="attribute-key">{{'settings.physician'|translate}}</div>
                <div class="attribute-value">
                    {{                    item.physician ? ('Dr.' + item?.physician?.first_name + ' ' + item?.physician?.last_name) : '###'}}
                </div>
            </div>
        </div>
        <!--        <div class="attribute-item">-->
        <!--            <div class="attribute-icon">-->
        <!--                <mat-icon fontSet="mdi" fontIcon="mdi-map-marker"></mat-icon>-->
        <!--            </div>-->
        <!--            <div>-->
        <!--                <div class="attribute-key">{{'shared.address'| translate}}</div>-->
        <!--                <div class="attribute-value">-->
        <!--                    {{   item.address || '###' }}-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

    </div>
</ng-template>
<button
        mat-mini-fab
        class="action-btn"
        color="primary"
        routerLink="/patient/form-edit"
        *ngIf="isMobile">
    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
</button>
