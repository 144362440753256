import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import KeenSlider, {KeenSliderInstance} from 'keen-slider';
import {SidenavService} from 'src/app/shared/services/sidenav.service';
import {duration} from 'src/app/shared/animations/animations';
import {PatientBaseComponent} from '../patient-form/patient-base.component';
import {PatientService} from '../../services/patient.service';
import {PhysicianService} from '../../../settings/services/physician.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatDialog} from '@angular/material/dialog';
import {AuthService, DefaultValuesService} from '@ft/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from '../../../settings/services/settings.service';
import {projectName} from '../../../shared/utils/consts';
import {noop, get} from 'lodash';
import {PrescriptionService} from '../../../prescription/services/prescription.service';

@Component({
  selector: 'pr-patient-display',
  templateUrl: './patient-display.component.html',
  styleUrls: ['./patient-display.component.scss'],
})
export class PatientDisplayComponent extends PatientBaseComponent implements OnInit, OnDestroy {
  @HostBinding('class.host-class-mobile') public isMobile = false;
  @ViewChild('desktopSliderRef') desktopSliderRef: ElementRef<HTMLElement>;
  slider: KeenSliderInstance = null;
  public breakpoints = Breakpoints;
  public isSideNavOpen = false;
  public isDesktop = false;
  public isNavigable = true;
  public isCarouselEmpty = false;
  public showSliders = false;
  public prescriptions = [];

  constructor(
    public breakpointObserver: BreakpointObserver,
    public patientService: PatientService,
    public physicianService: PhysicianService,
    public _adapter: MomentDateAdapter,
    public dialog: MatDialog,
    public _authService: AuthService,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public settingService: SettingsService,
    private _titleService: Title,
    private _sidenavService: SidenavService,
    private prescriptionService: PrescriptionService,
    public defaultValuesService: DefaultValuesService
  ) {
    super(
      patientService,
      physicianService,
      _adapter,
      dialog,
      _authService,
      snackBar,
      translateService,
      router,
      route,
      settingService,
      breakpointObserver,
      defaultValuesService
    );
  }

  ngOnInit() {
    // TODO fix title
    this._titleService.setTitle(projectName + ' - ' + this.translateService.instant('patient.patient_file'));
    this.breakpointObserver
      .observe(['(min-width: 1040px)', '(max-width: 640px)'])
      .subscribe((result) => {
        if (result.breakpoints['(min-width: 1040px)']) {
          this.isDesktop = true;

          setTimeout(() => {
            this.newKeenSlider(1, this.desktopSliderRef);
          }, 0);
        } else {
          this.isDesktop = false;
          // if (this.slider) this.slider.destroy();
          // setTimeout(() => {
          //     this.newKeenSlider(1, this.mobileSliderRef);
          // }, 0);
        }
        if (result.breakpoints['(max-width: 640px)']) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });

    this._sidenavService.sideNavState$.subscribe((isSideNavOpen) => {
      this.isSideNavOpen = isSideNavOpen;
      setTimeout(() => {
        if (this.slider) this.slider.update();
      }, parseInt(duration));
    });
    super.ngOnInit();
    this.route.params.subscribe(params => {
      if (get(params, 'id')) {
        this.handlePrescriptions(get(params, 'id'));
      }
    });


  }

  handlePrescriptions(pk) {
    this.subscription = this.prescriptionService.getPrescriptions(parseInt(pk, 10)).subscribe(data => {
      if (data) {
        this.showSliders = false;
        this.prescriptions = data;
        if (this.slider) {
          setTimeout(() => {
            this.showSliders = true;
            this.slider.update();
          }, 100);
        }
      }
    });
  }

  newKeenSlider(slideColumnNum = 1, sliderRef) {
    if (sliderRef && sliderRef.nativeElement) {
      this.slider = new KeenSlider(sliderRef.nativeElement, {
        slides: {
          perView: () => {
            return this.compareSliderNum(slideColumnNum);
          },
        },
        breakpoints: {
          '(min-width: 640px)': {
            slides: {
              perView: () => {
                if (this.isSideNavOpen) {
                  return this.compareSliderNum(1);
                } else {
                  return this.compareSliderNum(2);
                }
              },
            },
          },
          '(min-width: 1400px)': {
            slides: {
              perView: () => {
                if (this.isSideNavOpen) {
                  return this.compareSliderNum(2);
                } else {
                  return this.compareSliderNum(3);
                }
              },
            },
          },
        },
      });
    }
  }

  compareSliderNum(preferredSlidersNum: number): number {
    let slidesNum = -1;
    if (this.slider) {
      slidesNum = this.slider.slides.length;
    }
    if (preferredSlidersNum >= slidesNum) {
      this.isNavigable = false;
      if (slidesNum === -1 || slidesNum === 0) {
        this.isCarouselEmpty = true;
      }
      return slidesNum;
    } else {
      this.isNavigable = true;
      this.isCarouselEmpty = false;
      return preferredSlidersNum;
    }
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
    this.subscription.unsubscribe();
  }

  handlePrescription() {
    this.router
      .navigate(['prescription/content/'], {
        queryParams: {patient: this.patient.id},
      })
      .then(noop);
  }

  handleEdit() {
    this.router.navigate([`patient/form-edit/${this.patient.id}`]).then(noop);
  }

}
