import {Component, Input} from '@angular/core';
import {FtViewService, GenericViewTableComponent} from '@ft/table';
// import {   GenericViewTableComponent } from "./../../../../../node_modules/@ft/table/public_api";
// import {  FtViewService} from "./../../../../../node_modules/@ft/table/public_api";
// import {  FtViewService} from "C:/Users/user/Documents/Projects/fullstack/ft-table-front/projects/ft-table/src/public_api";
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-pr-generic-view-table',
    templateUrl: './pr-generic-view-table.component.html',
    styleUrls: ['./pr-generic-view-table.component.scss']
})
export class PrGenericViewTableComponent extends GenericViewTableComponent {
    customFilters = [];

    @Input('customFilters') set handleModelName(m: any) {
        this.customFilters = m;
    }

    constructor(ftViewService: FtViewService, translateService: TranslateService) {
        super(ftViewService, translateService);
    }

    // constructor(translateService: TranslateService) {
    //   super(null, translateService);
    // }

}
