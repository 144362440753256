import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {has, get} from 'lodash';
import {FtWsService} from '@ft/core';
import {UIService} from 'src/app/shared/services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(
    private _httpClient: HttpClient,
    private ws: FtWsService,
    private _uiService: UIService
  ) {}

  getList(_cls, query = '') {
    // Set the Authorization header with the token
    // const headers = new HttpHeaders({'Content-Type': 'application/json','Authorization': 'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJvb3QiLCJpYXQiOjE2ODg1NjY2OTEsImV4cCI6MTY4OTE3MTQ5MSwianRpIjoiOTg0NGFiMjEtYmZmMS00OWM2LWE4ZDgtZmQwMTBkODQ2NjdmIiwidXNlcl9pZCI6MSwidXNlcl9wcm9maWxlX2lkIjpbMV0sIm9yaWdfaWF0IjoxNjg4NTY2NjkxfQ.iv6eUdMrRwTC5LDW2pkE4TumJD1A7SBn5KQNC_dSLiE'});
    // return this.httpClient.get<any>(`${_cls}?${query}`, {headers});
    return this._httpClient.get<any>(`${_cls}?${query}`);
  }

  saveDocumentComment(c) {
    if (has(c, 'id')) {
      return this._httpClient.put(`api/pl/comment/${get(c, 'id')}/`, c);
    } else {
      return this._httpClient.post(`api/pl/comment/`, c);
    }
  }

  getDocumentComments(cls, docId) {
    return this._httpClient.get<any>(
      `api/pl/comment/?doc_model=${cls}&doc_id=${docId}`
    );
  }

  deleteDocumentComment(pk) {
    return this._httpClient.delete(`api/pl/comment/${pk}/`);
  }

  getCardList(query) {
    return this.ws.call(`table.list`, query);
  }

  getNotifications(page_num) {
    return this._httpClient.get(`api/pl/notification/?page_num=${page_num}` );
  }

  getChangeNotificationStatus(ids) {
    return this._httpClient.put(`api/pl/notification/receipt_bulk/`, {ids: ids});
  }

  handleReadNotification(pk) {
    return this._httpClient.put(`api/pl/notification/read/`, {pk: pk});
  }
}
