import {Component, EventEmitter, HostBinding, OnInit, Output} from '@angular/core';
import {FormBlockComponent} from '@ft/form';
import {FileManagerService} from '@ft/file-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {filter, get, isEmpty, has} from 'lodash';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
    selector: 'pr-prescription-block',
    templateUrl: './prescription-block.component.html',
    styleUrls: ['./prescription-block.component.scss'],
})
export class PrescriptionBlockComponent extends FormBlockComponent implements OnInit {
    @Output() public fieldFileChange = new EventEmitter();
    @Output() public fieldFileDelete = new EventEmitter();
    @Output() public fieldFilePreview = new EventEmitter();
    uploadedFiles = [];
    @HostBinding('class.host-class-mobile') public isMobile = false;

    constructor(private _managerService: FileManagerService,
                private _snackBar: MatSnackBar,
                private _translate: TranslateService,
                public breakpointObserver: BreakpointObserver,) {
        super();
    }
    ngOnInit() {
        super.ngOnInit();
        this.breakpointObserver
            .observe(['(max-width: 640px)'])
            .subscribe((result) => {
                if (result.breakpoints['(max-width: 640px)']) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
    }

    uploadFiles(files: File[], field, block_name, fieldIdx) {
        const filteredFiles = filter(files, i => i instanceof File);
        if (filteredFiles.length > 0) {
            this.fieldFileChange.emit({filteredFiles, field, block_name, fieldIdx});

            this.uploadedFiles = [];
        }
    }

    deleteFieldFile(field, block_name, fieldIdx) {
        this.fieldFileDelete.emit({fieldIdx, fileId: field?.value?.file_id});
    }

    previewFile(field, blockName) {
        this.fieldFilePreview.emit({field: field?.name, fileId: field?.value?.file_id, blockName});
    }
    public handleFieldStyle(f:any):any{
        return has(f.config,'cssStyle') && !isEmpty(f.config.cssStyle) ? JSON.parse(`${f.config.cssStyle}`) :{};
      }

}
