import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get} from 'lodash';
import {TITLES} from '../../../shared/utils/consts';
import {Subscription} from 'rxjs';
import {PhysicianService} from "../../../settings/services/physician.service";

@Component({
    selector: 'app-patient-account-setup',
    templateUrl: './patient-account-setup.component.html',
    styleUrls: ['./patient-account-setup.component.scss']
})
export class PatientAccountSetupComponent implements OnInit {
    model: any = { };
    subscription: Subscription;
    staffs: any = [];

    constructor(public dialogRef: MatDialogRef<PatientAccountSetupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public physicianService: PhysicianService ) {

    }

    ngOnInit(): void {

        this.physicianService.getStaffList().subscribe(data => {
            this.staffs = data;
        });


    }

    handleSave() {
    }

    compareCondition(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
    }
}
