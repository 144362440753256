import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ReplaySubject, Subject, of } from 'rxjs';
import { TicketType } from '../../ticket/ticket.type';
import { DataApiService } from './data-api.service';

@Injectable({
  providedIn: 'root',
})
export class BaseCalendarService {
  private _appointmentChange$: ReplaySubject<TicketType[]> = new ReplaySubject<
    TicketType[]
  >(1);
  private _mapResizeChange$: Subject<void> = new Subject<void>();
  private _appointmentNotificationChange$: Subject<void> = new Subject<void>();
  
  public setAppointmentChange$(appointmentsData: TicketType[]) {
    this._appointmentChange$.next(appointmentsData);
  }

  public getAppointmentChange$(): Subject<TicketType[]> {
    return this._appointmentChange$;
  }

  public resetAppointmentChange$() {
    // Clear old values by creating a new instance
    this._appointmentChange$ = new ReplaySubject(1);
  }

  public setMapResizeChange$() {
    this._mapResizeChange$.next();
  }

  public getMapResizeChange$(): Subject<void> {
    return this._mapResizeChange$;
  }

  public setAppointmentNotificationChange$() {
    this._appointmentNotificationChange$.next();
  }

  public getAppointmentNotificationChange$(): Subject<void> {
    return this._appointmentNotificationChange$;
  }
}
