export let lang = {
  gender: 'Sexe',
  birth_date: 'Date de naissance',
  first_name: 'Prénom',
  last_name: 'Nom',
  national_id: 'CIN',
  phone_number: 'Téléphone',
  email: 'Email',
  patient_file: 'Fiche patient',
  patient_list: 'Liste des patients',
  MALE: 'M',
  FEMALE: 'F',
  emergency_phone_number: 'N°.Tel de contact d\'urgence',
  emergency_contact_name: 'Nom de contact d\'urgence',
  declare_death: 'Déclarer le décès',
  death_date: 'Date de décès',
  death_reason: 'Motif de décès',
  reset_death_info: 'Annuler le décès',
  intervention_address: 'Adresse d\'intervention',
  insured: 'Assuré',
  insurance: 'Assurance',
  prescriptions_count: 'Nbr de prescriptions',
  with_prescription: 'Avec prescription',
  without_prescription: 'Sans prescription',
  dead: 'Décédé',
  new: 'Nouveau',
  new_patient: 'Nouveau patient',
  maritalStatus: 'État civil',
  profession: 'Profession',
  contact_infos: 'Informations de contact',
  province: 'Préfecture',
  create_prescription: 'Créer une prescription',
  actif: 'ACTIF',
  idle: 'INACTIF',
  prescribing_physician: 'Prescripteur',
  new_prescription: 'Nouvelle prescription',
  add_user_account: 'Veuillez choisir le compte utlisateur à assigner à ce patient',
  patient_already_exist: 'Patient déjà existe',
  use_this_instance: 'Utilisé ce patient',
  phone_invalid:'(le numéro saisie est invalide)'
};
