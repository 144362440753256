<mat-toolbar color="primary">
    <div class="ft-module-icon">
        <button type="button" mat-icon-button tabindex="-2">
            <mat-icon fontSet="mdi" fontIcon="mdi-contacts"></mat-icon>
        </button>
    </div>
    <h2 translate="settings.physician_list"></h2>

    <span fxFlex></span>
    <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    </button>
</mat-toolbar>
<form #form="ngForm">

    <div mat-dialog-content fxLayout="column" class="ft-busy-container ft-dialog-table" style="min-height: 230px;">

        <ft-generic-view-table [namespace]="viewSettings?.viewNamespace"
                               [actions]="viewSettings?.actions"
                               [viewSettings]="viewSettings?.viewSettings"
                               [hasPagination]="viewSettings?.hasPagination"
                               [selectable]="false"
                               [query]="viewSettings?.query"
                               (lineClick)="selectItem($event)"
        >
        </ft-generic-view-table>
    </div>

</form>
