import { of } from "rxjs"

export let commentSample = () => {
    return of(
        [
            {
                "id": 61,
                "title": "comment_note 02/10/2023 12:00",
                "content": "Je tiens à laisser une note concernant le service que j'ai reçu ici. J'ai rencontré un problème avec la coordination des rendez-vous, où les horaires n'étaient pas toujours respectés. J'apprécierais une meilleure gestion de l'emploi du temps pour une expérience plus fluide à l'avenir.",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 62,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnb",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 63,
                "title": "comment_note 02/10/2023 12:00",
                "content": "vbnvb",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 64,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 65,
                "reply_to": 61,
                "title": "comment_note 23/11/2023 17:16",
                "content": "new comment",
                "comment_date": "2023-11-30",
                // TODO: Pass the correct date format 'yyyy-MM-dd h:mm:ss' as a parameter to the fromNow function
                "comment_time": "14:53",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 90,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 91,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 92,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 93,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 94,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 95,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 96,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 97,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 98,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 99,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 100,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
            {
                "id": 101,
                "title": "comment_note 02/10/2023 12:00",
                "content": "bvnvbn",
                "comment_date": "2023-10-02",
                "comment_time": "12:00",
                "doc_model": "prescription",
                "doc_id": 1664,
                "is_private": false,
                "access_level": "mine"
            },
        ]
    )
}