<div class="pr-content-container">
  <div class="pr-content-header" *ngIf="!isMobile">
    <div class="list-title text-h4 font-bold">
      {{ "patient_appointment.appointment" | translate }}
    </div>
  </div>
  <div class="pr-content-body">
    <div class="appointment-details-outer-container">
      <div class="appointment-action-details-container">
        <div class="appointment-details-container appointment-card lg:flex-row">
          <div class="psad-date-container lg:border-b-0 lg:border-r">
            <div class="date">
              <div
                class="day-week"
                [matTooltip]="
                  appointment.ticket?.ticket_schedule_histories.at(-1)
                    .execution_date | date : 'MMMM d, y'
                "
              >
                <div class="day text-h5 font-bold">
                  {{
                  appointment.ticket?.ticket_schedule_histories.at(-1)
                    .execution_date | date : "dd"
                  }}
                </div>
                <div class="week text-primary uppercase">
                  {{
                  appointment.ticket?.ticket_schedule_histories.at(-1)
                    .execution_date
                    | date : "EEE"
                    | slice : 0 : -1
                  }}
                </div>
              </div>
            </div>
            <div class="psad-details">
              <div class="psad-info">
                <div class="psad-img text-h5 font-bold">A</div>
              </div>
              <div class="meeting-info">
                <div class="participant-name">
                  <div class="full-name text-subtitle2">
                    <span class="capitalize">{{ (appointment.ticket?.assignee?.first_name || '---') + ' ' }}</span>
                    <span class="capitalize">{{ appointment.ticket?.assignee?.last_name }}</span>
                  </div>
                </div>
                <div class="meeting-time text-primary font-bold">
                  <div class="start-time" *ngIf="appointment.ticket?.ticket_schedule_histories.at(-1).start_time">
                    {{
                    appointment.ticket?.ticket_schedule_histories.at(-1).start_time | ftTime : "hh:mm"
                    }}
                  </div>
                  <div class="divider">-</div>
                  <div class="end-time" *ngIf="appointment.ticket?.ticket_schedule_histories.at(-1).end_time">
                    {{
                    appointment.ticket?.ticket_schedule_histories.at(-1).end_time | ftTime : "hh:mm"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="additional-details">
            <div class="details-container">
              <div class="title">
                <div class="label">
                  <div>{{ "patient_appointment.reason" | translate }}</div>
                </div>
                <div class="tag-container">
                  <div
                    class="tag ml-4"
                    *ngIf="appointmentStatus()"
                    [ngStyle]="{
                      'background-color': appointmentStatus()?.bgColor,
                      color: appointmentStatus()?.txtColor
                    }"
                  >
                    {{ "settings." + appointmentStatus()?.name | translate }}
                  </div>
                </div>
              </div>
              <div class="description">
                <div class="text">
                  {{
                  appointment.ticket?.ticket_content_histories.at(-1).reason
                  }}
                </div>
              </div>
            </div>
            <div class="details-container">
              <div class="title">
                <div class="label">
                  <div>{{ "patient_appointment.address" | translate }}</div>
                </div>
              </div>
              <div class="description">
                <!-- <div class="text">42 Rue des Fleurs, 20160 Casablanca</div> -->
                <div class="text">                  {{
                  appointment.ticket?.prescription?.patient?.address || '---'
                  }}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="actions-container appointment-card"
          *ngIf="isAppointmentActionStatus()"
        >
          <div class="action" (click)="confirmAction()">
            <div class="icon">
              <mat-icon fontIcon="mdi-check" fontSet="mdi"></mat-icon>
            </div>
            <div class="name">
              {{ "patient_appointment.action.confirm" | translate }}
            </div>
          </div>
          <div class="action" (click)="requestRescheduling()">
            <div class="icon">
              <mat-icon
                fontIcon="mdi-calendar-account"
                fontSet="mdi"
              ></mat-icon>
            </div>
            <div class="name">
              {{ "patient_appointment.action.reschedule" | translate }}
            </div>
          </div>
          <div class="action" (click)="requestCancellation()">
            <div class="icon">
              <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
            </div>
            <div class="name">
              {{ "patient_appointment.action.cancel" | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="section-header">{{ "patient_appointment.comments" | translate }}</div>
      <div class="comments-container appointment-card">
        <div [id]="'anchor'+comment.id" class="details" *ngFor="let comment of comments">
          <ng-container *ngIf="comment.reply_to">
            <!-- <a [href]="'#anchor' + comment.reply_to" [fragment]="'anchor' + comment.reply_to" class="reply-to"> -->
            <a [routerLink]="[]" [fragment]="'anchor' + comment.reply_to" class="reply-to">
              <!-- <a [skipLocationChange]="true" [href]="'#anchor' + comment.reply_to" class="reply-to"> -->
              <div class="commenter">
                <div class="text-secondary font-medium">{{ comment.owner_full_name || 'Root' }}</div>
              </div>

              <div class="content">
                <div class="text-secondary" *ngIf="getCommentById(comment.reply_to)?.content">
                  {{ getCommentById(comment.reply_to)?.content}}
                </div>
                <div class="text-secondary text-orange-600" *ngIf="!getCommentById(comment.reply_to)?.content">
                  {{ "shared.the_comment_has_been_deleted" | translate }}
                </div>
              </div>
            </a>
          </ng-container>

          <div class="commenter">
            <div class="img-container">
              <div class="img text-h6 font-bold">{{ getFirstLetter(comment.owner_full_name) }}</div>
            </div>
            <div class="name-date">
              <div class="name">
                <div class="text-subtitle3 font-medium">{{ comment.owner_full_name || 'Root' }}</div>
              </div>
              <div class="date text-secondary">
                <div
                  [matTooltip]="
                    comment.comment_date + ' ' + comment.comment_time
                  "
                  class="w-fit"
                >
                  {{
                  fromNow(comment.comment_date + " " + comment.comment_time)
                  }}
                </div>
              </div>
            </div>
            <div class="more-container icon">
              <mat-icon
                fontSet="mdi"
                fontIcon="mdi-dots-vertical"
                [matMenuTriggerFor]="menu"
              ></mat-icon>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="replyToComment(comment.id)">
                <span>{{ "shared.reply" | translate }}</span>
              </button>
              <button mat-menu-item (click)="deleteComment(comment.id)">
                <span>{{ "shared.delete" | translate }}</span>
              </button>
            </mat-menu>
          </div>
          <div class="content">
            <div>
              {{ comment.content }}
            </div>
          </div>
        </div>
        <div class="comment-input-container">
          <mat-form-field appearance="outline">
            <textarea
              matInput
              [(ngModel)]="commentContent"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="10"
            ></textarea>
          </mat-form-field>
          <button class="send-comment" (click)="addNewComment($event)">
            <mat-icon fontSet="mdi" fontIcon="mdi-send"></mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
