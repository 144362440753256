export let patientPrescriptionFr: Record<string, any> = {
    patient_prescription_list: 'Liste des prestations',
    benefits: 'Prestations',
    benefit: 'Prestation',
    date: 'Date',
    type: 'Type',
    starting_treatment: 'Début du traitement',
    ending_treatment: 'Fin du traitement',
    attending_physician: 'Médecin traitant',
    status: 'Statut',
    details: 'Details',
    estimates: 'Devis',
    invoice: 'Facture',
    satisfaction_form: 'Formulaire de satisfaction',
    tutorials: 'Tutoriels',
};
