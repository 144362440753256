<!-- <app-toolbar (window:resize)="onResize($event)"></app-toolbar> -->
<app-toolbar></app-toolbar>
<!-- <router-outlet></router-outlet> -->
<mat-sidenav-container *ngIf="!isMobile" style="flex-grow: 1">
  <mat-sidenav
    class="side-nav"
    #sidenav
    mode="side"
    opened
    [@onSideNavChange]="onSideNavChange ? 'open' : 'close'"
  >
    <button mat-icon-button (click)="onSidenavToggle()" class="toggle-btn">
      <mat-icon fontSet="mdi" fontIcon="mdi-menu-open"></mat-icon>
    </button>
    <nav class="nav-list" [ngClass]="onSideNavChange ? 'not-short' : 'short'">
      <ul>
        <li *ngFor="let link of links">
          <ng-template [ftAcl]="link.acl">
            <a
              class="nav-btn "
              [routerLink]="link.path"
              href="..."
              routerLinkActive="active-link"
            >
            <span class="nav-list-icon">
              <mat-icon [matTooltip]="link.label | translate" fontSet="mdi" [fontIcon]="link.icon"></mat-icon>
            </span>
              <span
                class="nav-list-link"
                [@animateText]="onSideNavChange ? 'show' : 'hide'"
              >
              {{ link.label }}
            </span>
            </a>
          </ng-template>
        </li>
        <li>
          <a class="nav-btn aide">
            <span class="nav-list-icon">
              <mat-icon fontSet="mdi" fontIcon="mdi-help-circle"></mat-icon>
            </span>
            <span
              class="nav-list-link"
              [@animateText]="onSideNavChange ? 'show' : 'hide'"
            >
              Besoin d'aide?
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content
    [@onMainContentChange]="onSideNavChange ? 'open' : 'close'"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<router-outlet *ngIf="isMobile"></router-outlet>

<div class="mobile-nav" *ngIf="isMobile">
  <div class="flex-item"></div>
  <div class="flex-item-middle">
    <div>
      <!-- <button mat-flat-button (click)="onSidenavToggle()" class="add-btn" color="primary">
        <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
      </button> -->
      <svg
        width="390"
        height="69"
        viewBox="0 0 390 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 69C0 69 243.553 69 390 69V0H281.25H257.952C245.552 0 234.644 8.12191 225.986 16.9984C219.047 24.1136 208.644 30.7618 194 30.7618C179.818 30.7618 169.816 24.5261 163.106 17.6697C154.213 8.58348 143.125 0 130.411 0H0V69Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
  <div class="flex-item"></div>
  <div class="mobile-nav-content">
    <ng-template
      [ftAcl]="{ resource: 'patient', action: 'get' }"
    >
      <div class="flex-item">
        <button mat-icon-button class="mobile-nav-btn" routerLink="/patient" routerLinkActive="active-mobile-link">
          <mat-icon fontSet="mdi" fontIcon="mdi-account-multiple"></mat-icon>
        </button>
      </div>
    </ng-template>
    <ng-template
      [ftAcl]="{ resource: 'prescription', action: 'get' }"
    >
      <div class="flex-item">
        <button mat-icon-button class="mobile-nav-btn" routerLink="/prescription" routerLinkActive="active-mobile-link">
          <mat-icon fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
        </button>
      </div>
    </ng-template>
<!--    <div class="flex-item-middle" *ngIf="isMobileNavBarHasPlusButton"></div>-->
    <ng-template
      [ftAcl]="{ resource: 'ticket', action: 'get' }"
    >
      <div class="flex-item">
        <button mat-icon-button class="mobile-nav-btn" routerLink="/ticket" routerLinkActive="active-mobile-link">
          <mat-icon fontSet="mdi" fontIcon="mdi-ticket-outline"></mat-icon>
        </button>
      </div>
    </ng-template>
    <ng-template
      [ftAcl]="{ resource: 'appointment', action: 'get' }"
    >
      <div class="flex-item">
        <button mat-icon-button class="mobile-nav-btn" routerLink="/patient-appointment" routerLinkActive="active-mobile-link">
          <mat-icon fontSet="mdi" fontIcon="mdi-ticket-account"></mat-icon>
        </button>
      </div>
    </ng-template>
    <ng-template
      [ftAcl]="{ resource: 'patient-prescription', action: 'get' }"
    >
      <div class="flex-item">
        <button mat-icon-button class="mobile-nav-btn" routerLink="/patient-prescription" routerLinkActive="active-mobile-link">
          <mat-icon fontSet="mdi" fontIcon="mdi-file-document-outline"></mat-icon>
        </button>
      </div>
    </ng-template>
    <ng-template
      [ftAcl]="{ resource: 'patient-request', action: 'get' }"
    >
      <div class="flex-item">
        <button mat-icon-button class="mobile-nav-btn" routerLink="/patient-request" routerLinkActive="active-mobile-link">
          <mat-icon fontSet="mdi" fontIcon="mdi-hand-extended-outline"></mat-icon>
        </button>
      </div>
    </ng-template>
<!--   todo return after implement setting for mobile <div class="flex-item">-->
<!--      <button mat-icon-button class="mobile-nav-btn" routerLink="/settings" routerLinkActive="active-mobile-link"-->
<!--              disabled="true">-->
<!--        <mat-icon fontSet="mdi" fontIcon="mdi-cog"></mat-icon>-->
<!--      </button>-->
<!--    </div>-->
  </div>
</div>
