import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { Subscription } from 'rxjs';
import { TicketService } from 'src/app/ticket/services/ticket.service';
import { TicketType } from '../../ticket.type';
import { BaseCalendarService } from '../../../shared/services/base-calendar.service';

@Component({
  selector: 'pr-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  private _subs = new Subscription();
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;

  public appointmentsData: TicketType[] = [];
  public focusedTicket: TicketType;
  private _map: any;
  constructor(
    public breakpointObserver: BreakpointObserver,
    private _ticketService: TicketService,
    private _baseCalendarService: BaseCalendarService,
  ) {}

  ngOnInit(): void {
    L.Icon.Default.imagePath = 'assets/leaflet/';
    this._subs.add(
      this._baseCalendarService.getAppointmentChange$().subscribe({
        next: (appointmentsData) => {
          this.appointmentsData = appointmentsData;
          this._findTicketWithMapLocation();
          this._initMap();
        },
        error: (error) => {
          console.error(error);
        },
      })
    );

    this._subs.add(
      this._baseCalendarService.getMapResizeChange$().subscribe({
        next: () => {
          if (this.appointmentsData.length) {
            setTimeout(() => {
              this._map?.invalidateSize();
              this._setMarker();
            }, 0);
          }
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
    this.breakpointObserver
      .observe(['(max-width: 640px)'])
      .subscribe((result) => {
        this.isMobile = result.breakpoints['(max-width: 640px)'] ? true : false;
      });
  }

  private _findTicketWithMapLocation(): void {
    for (const appointmentData of this.appointmentsData) {
      if (appointmentData.ticket_content_histories?.length > 0) {
        for (const ticketContentHistory of appointmentData.ticket_content_histories) {
          if (ticketContentHistory.location) {
            this.focusedTicket = appointmentData;
            return;
          }
        }
      }
    }
  }

  private _initMap(): void {
    // Destroy leaflet map
    this._map?.off();
    this._map?.remove();
    this._map = null;

    if (this.appointmentsData.length && this.focusedTicket) {
      setTimeout(() => {
        if (!this._map) {
          if (this.focusedTicket.ticket_content_histories.at(-1).location) {
            this._map = L.map('map').setView(
              [
                this.focusedTicket.ticket_content_histories.at(-1).location.lat,
                this.focusedTicket.ticket_content_histories.at(-1).location.lng,
              ],
              20
            );
            // this._map.on('load', ()=>{
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(this._map);
            // })
          }
        }
      }, 0);
    }
  }

  private _setMarker() {
    // remove all the markers if exist
    if (this._map) {
      this._map.eachLayer((layer) => {
        if (layer['_latlng'] != undefined) layer.remove();
      });

      // create markers
      for (const appointment of this.appointmentsData) {
        if (appointment.ticket_content_histories.at(-1).location)
          L.marker([
            appointment.ticket_content_histories.at(-1).location.lat,
            appointment.ticket_content_histories.at(-1).location.lng,
          ])
            .addTo(this._map)
            // .bindPopup(appointment.reason)
            .bindPopup(
              '<a href="https://maps.google.com/maps?q=' +
                appointment.ticket_content_histories.at(-1).location.lat +
                ',' +
                appointment.ticket_content_histories.at(-1).location.lng +
                '">' +
                appointment.ticket_content_histories.at(-1).reason +
                '</a>'
            )
            .openPopup();
      }
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
