import {TableColumnDefinition} from '@ft/core';

export const PHYSICIAN_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'patient.last_name',
    'key': 'last_name',
    'sortBy': 'last_name',
    'type': 'text',
    'is_global_searchable': true
  },
  {
    'label': 'patient.first_name',
    'key': 'first_name',
    'sortBy': 'first_name',
    'type': 'text',
    'is_global_searchable': true
  }
];
