import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Calendar,
  CalendarOptions,
  DatesSetArg,
  sliceEvents,
} from '@fullcalendar/core';
// import { appointments } from 'src/app/shared/samples/appointment.sample';
import * as moment from 'moment';
import { CALENDAR_PRESETS } from 'src/app/ticket/calendar-presets';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { duration } from 'src/app/shared/animations/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDrawer } from '@angular/material/sidenav';
import { Subject, noop, delay, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { parseStringToDateTime } from 'src/app/shared/utils/functions';
import { DateTime } from 'luxon';
import { DataApiService } from 'src/app/shared/services/data-api.service';
import { TicketType } from 'src/app/ticket/ticket.type';
import { AppConfigsService, DefaultValuesService, FtWsService } from '@ft/core';
import { get, filter, find } from 'lodash';
import { TicketService } from 'src/app/ticket/services/ticket.service';
import { BaseCalendarService } from '../../services/base-calendar.service';
import { AppointmentType } from '../../../patient-appointment/patient-appointment.type';
import { PatientAppointmentService } from '../../../patient-appointment/services/patient-appointment.service';

moment.locale('fr');
moment.updateLocale('fr', { weekdaysMin: 'D_L_M_M_J_V_S'.split('_') });

@Component({
  selector: 'pr-base-calendar',
  templateUrl: './base-calendar.component.html',
  styleUrls: ['./base-calendar.component.scss'],
})
export abstract class BaseCalendarComponent implements OnInit, OnDestroy {
  private _calendar: Calendar = {} as Calendar;
  @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
  public xl = false;
  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('calendar', { static: true }) public calendarRef: ElementRef =
    {} as ElementRef;
  public is_ticket = true;
  public tickets: TicketType[] = [];
  public calendarHeaderTitle: string = '';
  public emitCalendarView = new EventEmitter<string>();
  public dateFormat: any;
  public fromDate: any;
  public toDate: any;
  public calendarView = '';
  public calendarStart = '';
  public calendarEnd = '';
  public isInsideCalendar = false;
  public subscription: Subscription;
  public queryParamMapSubscription: Subscription;
  private _subs = new Subscription();
  public apiService: any = {};

  constructor(
    public sidenavService: SidenavService,
    public breakpointObserver: BreakpointObserver,
    public ticketService: TicketService,
    public appointmentService: PatientAppointmentService,
    public router: Router,
    public defaultValuesService: DefaultValuesService,
    public app_configService: AppConfigsService,
    public ws: FtWsService,
    public route: ActivatedRoute,
    public baseCalendarService: BaseCalendarService
  ) {
    this.dateFormat = app_configService.dateFormat;
  }

  ngOnInit(): void {
    this._subs.add(
      this.breakpointObserver
        .observe(['(max-width: 640px)', '(min-width: 1280px)'])
        .subscribe((result) => {
          this.isMobile = !!result.breakpoints['(max-width: 640px)'];
          this.xl = !!result.breakpoints['(min-width: 1280px)'];
          this.calendarView = this.route.snapshot.queryParams['view'];
          this.calendarStart = this.route.snapshot.queryParams['start'];
          this.calendarEnd = this.route.snapshot.queryParams['end'];
          this._initCalendar();
        })
    );

    this._subs.add(
      this.ws.observe('ticket.handle_change_notification').subscribe((data) => {
        this.tickets = [];
        this._calendar.refetchEvents();
      })
    );
  }

  public dayView() {
    this._changeView('timeGridDay');
  }

  public weekView() {
    this._changeView('timeGridWeek');
  }

  public monthView() {
    this._changeView('dayGridMonth');
  }

  public fourDaysView() {
    this._changeView('timeGridFourDay');
  }

  public listView() {
    this._changeView('listMonth');
  }

  public mapView() {
    // TODO map view
    // this._calendar.changeView('map');
    // alert('wow!')
    this.drawer.open();
    this.baseCalendarService.setMapResizeChange$();
  }

  public next() {
    this.isInsideCalendar = true;
    this._calendar.next();
  }

  public prev() {
    this.isInsideCalendar = true;
    this._calendar.prev();
  }

  public today() {
    this.isInsideCalendar = true;
    this._calendar.today();
  }

  public createEvent() {
    alert('createEvent!');
  }

  public goToDate($event: any) {
    this.isInsideCalendar = true;
    const date = moment($event.value).format('YYYY-MM-DD');
    this._calendar.gotoDate(date);
  }

  protected abstract getEventClickNavigationLink(info): string;

  ngOnDestroy(): void {
    this.baseCalendarService.resetAppointmentChange$();
    this.subscription?.unsubscribe();
    this.queryParamMapSubscription?.unsubscribe();
    this._subs.unsubscribe();
  }

  private get _calendarOptions(): CalendarOptions {
    return {
      // when view change
      datesSet: (arg: DatesSetArg) => this._formatCalendarTitle(arg),
      views: {
        dayGrid: {
          dayHeaderContent: (args) => {
            if (this.isMobile) {
              return true;
            } else if (this.xl) {
              return moment(args.date).format('dddd');
            } else {
              return moment(args.date).format('ddd');
            }
          },
          eventContent: function (arg) {
            return arg.event.title;
          },
        },
        day: {
          // options apply to dayGridDay and timeGridDay views
          dayHeaderFormat: { weekday: 'long' },
        },
      },
      dayHeaderClassNames: function (arg) {
        return 'weekend-header';
      },
      eventClassNames: 'event-style',

      initialView: ((): string => {
        let newInitialView = '';

        if (
          ['listMonth', 'dayGridMonth', 'timeGridDay'].includes(
            this.calendarView
          )
        ) {
          newInitialView = this.calendarView;
        } else {
          newInitialView = this.isMobile ? 'listMonth' : 'dayGridMonth';
        }

        // this._router.navigate([], {
        //   relativeTo: this._route,
        //   queryParams: { view: newInitialView },
        // });
        return newInitialView;
      })(),
      eventClick: (info) => {
        this.router
          .navigate([this.getEventClickNavigationLink(info)])
          .then(noop);
      },
    };
  }

  private _initCalendar() {
    let overrides = Object.assign({}, CALENDAR_PRESETS, this._calendarOptions, {
      events: (fetchInfo, successCallback, failureCallback) => {
        setTimeout(() => {
          if (
            this.tickets.length === 0 ||
            this.fromDate != this._calendar.view.activeStart ||
            this.toDate != this._calendar.view.activeEnd
          ) {
            if (
              !this.isInsideCalendar &&
              this.calendarView &&
              this.calendarStart &&
              this.calendarEnd
            ) {
              this.isInsideCalendar = true;
              this.fromDate = this.calendarStart;
              this.toDate = this.calendarEnd;

              let startDate = new Date(this.fromDate);
              let endDate = new Date(this.toDate);
              this._calendar.changeView(
                this.calendarView,
                this._getMiddleDate(startDate, endDate)
              );
            } else {
              this.fromDate = this._calendar.view.activeStart;
              this.toDate = this._calendar.view.activeEnd;
              let newInitialView = this._calendar.getOption('initialView');
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  // if the url has the query param 'view' if not get the initial view
                  view: this.calendarView || newInitialView,
                  start: this.fromDate,
                  end: this.toDate,
                },
              });
            }

            this.subscription?.unsubscribe();
            this.subscription = this.apiService
              .fetchAll(
                null,
                `from=${moment(this.fromDate).format(
                  this.dateFormat
                )}&to=${moment(this.toDate).format(this.dateFormat)}`
              )
              .subscribe({
                next: (items:  any): void => {
                  if (items) {
                    this.tickets = [...items];
                    if (this.is_ticket) {
                      this._subs.add(
                        this.ticketService
                          .ChangeStatusToReceived(this.tickets)
                          .subscribe({
                            next: (done: boolean) => {},
                          })
                      );
                    } else {
                      this._subs.add(
                        this.appointmentService
                          .ChangeStatusToReceived(items)
                          .subscribe({
                            next: (done: boolean) => {},
                          })
                      );
                    }

                    let events = this._prepareEvents();

                    successCallback(events);
                    // setTimeout(() => {
                    //   // Update calendar header button state (emit event to calendar header component)
                    //   this.emitCalendarView.emit(this._calendar.view.type);
                    //   this._calendar.render();
                    // }, 0);

                    this._subs.add(
                      this.sidenavService.sideNavState$.subscribe(
                        (isSideNavOpen) => {
                          setTimeout(() => {
                            this._calendar.render();
                          }, parseInt(duration));
                        }
                      )
                    );
                  }
                },
              });
          }
        }, 0);
      },
    });

    this._calendar = new Calendar(this.calendarRef.nativeElement, overrides);
  }

  private _prepareEvents() {
    if (this.is_ticket) {
      return this.tickets.map((appointment: TicketType) => {
        
        let bgColor = this.statusColor(
          appointment.status_histories.at(-1).status
        ).bgColor;

        let txtColor = this.statusColor(
          appointment.status_histories.at(-1).status
        ).txtColor;
        let titlePrefix = "";
        if (appointment.external_id){
          titlePrefix +=`#${appointment.external_id} -`;
        }
        if (appointment?.prescription){
          titlePrefix +=appointment.prescription.template_name + ' : ';
        }
        return {
          id: appointment.id,
          title: `${titlePrefix}${
            appointment.ticket_content_histories.at(-1).reason
          }`,
          allDay: appointment.ticket_schedule_histories.at(-1).all_day,
          start: moment(
            appointment.ticket_schedule_histories.at(-1).execution_date +
              ' ' +
              appointment.ticket_schedule_histories.at(-1).start_time,
            'YYYY-MM-DD hh:mm:ss'
          ).toDate(),
          end: moment(
            appointment.ticket_schedule_histories.at(-1).execution_date +
              ' ' +
              appointment.ticket_schedule_histories.at(-1).end_time,
            'YYYY-MM-DD hh:mm:ss'
          ).toDate(),
          display: 'block',
          backgroundColor: bgColor,
          borderColor: bgColor,
          textColor: txtColor,
        };
      });
    } else {
      return this.tickets.map((appointment: AppointmentType) => {
        let bgColor = this.statusColor(
          appointment.status_histories.at(-1).status
        )?.bgColor;
        let txtColor = this.statusColor(
          appointment.status_histories.at(-1).status
        )?.txtColor;
        let titlePrefix = appointment?.ticket.prescription
          ? appointment.ticket.prescription.template_name + ' : '
          : '';


        return {
          id: appointment.id,
          title: `${titlePrefix}${
            appointment.ticket.ticket_content_histories.at(-1).reason
          }`,
          allDay: appointment.ticket.ticket_schedule_histories.at(-1).all_day,
          start: moment(
            appointment.ticket.ticket_schedule_histories.at(-1).execution_date +
              ' ' +
              appointment.ticket.ticket_schedule_histories.at(-1).start_time,
            'YYYY-MM-DD hh:mm:ss'
          ).toDate(),
          end: moment(
            appointment.ticket.ticket_schedule_histories.at(-1).execution_date +
              ' ' +
              appointment.ticket.ticket_schedule_histories.at(-1).end_time,
            'YYYY-MM-DD hh:mm:ss'
          ).toDate(),
          display: 'block',
          backgroundColor: bgColor,
          borderColor: bgColor,
          textColor: txtColor,
        };
      });
    }
  }

  private _changeView(viewStr: string) {
    this.calendarView = viewStr;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { view: viewStr },
    });
    this._calendar.changeView(viewStr);
    this._calendar.refetchEvents();
  }

  private _getMiddleDate(date1, date2) {
    // Calculate the average timestamp of the two dates
    const timestamp1 = date1.getTime();
    const timestamp2 = date2.getTime();
    const averageTimestamp = (timestamp1 + timestamp2) / 2;

    // Create a new Date object using the average timestamp
    const middleDate = new Date(averageTimestamp);

    return middleDate;
  }

  private _formatCalendarTitle(arg: DatesSetArg) {
    let formattedDate!: string;
    let startDay = arg.start.getDate().toString();
    let endDay = arg.end.getDate().toString();

    let startMonth = moment(arg.start, 'MM').format('MMMM');
    let endMonth = moment(arg.end, 'MM').format('MMMM');
    let monthForDayGridMonth = moment(arg.end.getMonth() + 1, 'MM')
      .subtract(1, 'months')
      .format('MMMM');
    let startYear = arg.start.getFullYear().toString();
    let endYear = arg.end.getFullYear().toString();
    let viewTypes = ['dayGridMonth', 'listMonth'];
    // TODO timeGridWeek
    if (arg.view.type === 'timeGridWeek' && this.isMobile) {
      formattedDate =
        this._getLetrDiv(monthForDayGridMonth) + this._getNumDiv(endYear);
    } else if (viewTypes.includes(arg.view.type)) {
      formattedDate =
        this._getLetrDiv(monthForDayGridMonth) + this._getNumDiv(endYear);
    } else if (arg.view.type === 'timeGridDay') {
      formattedDate =
        this._getNumDiv(startDay) +
        this._getLetrDiv(startMonth) +
        this._getNumDiv(endYear);
    } else if (startMonth !== endMonth && startYear !== endYear) {
      formattedDate =
        this._getLetrDiv(startMonth) +
        this._getNumDiv(startDay + ',') +
        this._getNumDiv(startYear) +
        this._getNumDiv('-') +
        this._getLetrDiv(endMonth) +
        this._getNumDiv(endDay + ',') +
        this._getNumDiv(endYear);
    } else if (startMonth !== endMonth) {
      formattedDate =
        this._getLetrDiv(startMonth) +
        this._getNumDiv(startDay) +
        this._getNumDiv('-') +
        this._getLetrDiv(endMonth) +
        this._getNumDiv(endDay + ',') +
        this._getNumDiv(endYear);
    } else {
      formattedDate =
        this._getLetrDiv(startMonth) +
        this._getFormattedDay(startDay, endDay, arg.view.type) +
        this._getNumDiv(endYear);
    }
    // Update calendar title
    this.calendarHeaderTitle = `
                     <div class='fc-title'>
                        ${formattedDate}
                     </div>
                     <span class="arrow"></span>
                     `;
    this.emitCalendarView.emit(this._calendar.view.type);
    let allEvents = this._calendar.getEvents();

    let currentViewEvents = allEvents.filter((event) => {
      return (
        this._calendar.view.activeStart <= event.start &&
        event.start <= this._calendar.view.activeEnd
      );
    });
    let currentViewAppointmentsIdsArr = currentViewEvents.map(function (i) {
      return i.id;
    });

    let currentViewAppointments = this.tickets.filter(function (item) {
      // Check if the item id is in the currentViewAppointments ids
      return currentViewAppointmentsIdsArr.indexOf(item.id.toString()) !== -1;
    });
    this.baseCalendarService.setAppointmentChange$(currentViewAppointments);
  }

  private _getFormattedDay(
    startDay: string,
    endDay: string,
    viewType: string
  ): string {
    if (viewType !== 'dayGridMonth') {
      if (startDay !== endDay) {
        return (
          this._getNumDiv(startDay) +
          this._getNumDiv('-') +
          this._getNumDiv(endDay + ',')
        );
      } else {
        return this._getNumDiv(startDay);
      }
    } else {
      return '';
    }
  }

  private _getLetrDiv(letr: string) {
    return `<div class='letr'>${letr!}</div>`;
  }

  private _getNumDiv(num: string) {
    return `<div class='num'>${num!}</div>`;
  }

  public statusColor(status) {
    if (this.is_ticket) {
      return find(this.defaultValuesService.getValue('ticketStatusColors'), {
        name: status,
      });
    } else {
      return find(this.defaultValuesService.getValue('appointmentStatusColors'), {
        name: status,
      });
    }

  }
}
