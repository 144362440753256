<div [ngBusy]="subscription"></div>

<div class="second-header-container">
  <div class="flex-item-1 magnify-container">
    <div
      fxLayout="row"
      class="ft-table-search-bar"
      [class.focused]="isFocused"
      *ngIf="hasSearch"
    >
      <mat-icon
        fontSet="mdi"
        fontIcon="mdi-magnify"
        [class.mat-primary]="isFocused"
        class="magnify-icon"
      ></mat-icon>

      <input
        type="text"
        fxFlex
        [value]="key"
        [ftAutoFocus]="true"
        [placeholder]="'table.search' | translate"
        (focus)="isFocused = true"
        (blur)="isFocused = false"
        (input)="onSearch($event.target.value)"
      />

      <button
        type="button"
        mat-icon-button
        (click)="cancelSearch()"
        color="warn"
        *ngIf="key.length > 0"
        [@scaleAnimation]
      >
        <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
      </button>
    </div>
  </div>

    <div class="flex-item-2">
        <button *ngFor="let p of customFilters" mat-button class="filter-btn" (click)="handleCustomFilter(p)"
                [ngClass]="{'active-filter': this.activeFilter===p}">{{ p.label|translate}}</button>
    </div>
    <div class="flex-item-3">
        <ng-container *ngFor="let action of actions">
            <ng-template [ftAcl]="action.acl" [ignoreEmpty]="true">
                <button
                        mat-flat-button
                        color="primary"
                        class="add-btn-for-table"
                        *ngIf="!handleActionHidden(selection.selected, action)"
                        (click)="handleMultiActionClick(action, $event)"
                        [disabled]="
            handleActionBehaviour(selection.selected, action) ||
            (!selection.hasValue() && action.isMultipleSelection)
          ">
                    <mat-icon [fontIcon]="action.icon" fontSet="mdi"></mat-icon>
                    {{ action?.tooltip  | translate }}
                </button>
            </ng-template>
        </ng-container>
    </div>
</div>

<div class="inner-table-container" #innerTable>
  <mat-table
    [dataSource]="dataSource"
    [trackBy]="trackByFnHandler()"
    matSort
    [matSortActive]="sortByKey"
    [class.empty-table]="!hasItems()"
  >
    <ng-container matColumnDef="ft-multi-selection" *ngIf="selectable">
      <mat-header-cell *matHeaderCellDef class="ft-multi-selection">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
        </mat-checkbox>
      </mat-header-cell>
      <!--    *****   A REVOIR   *****   -->
      <mat-cell *matCellDef="let element" class="ft-multi-selection">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? handleRowClick(element) : null"
          [checked]="selection.isSelected(element)"
          [disableRipple]="true"
        >
        </mat-checkbox>
      </mat-cell>
      <!--    *****   A REVOIR   *****   -->
      <mat-footer-cell
        *matFooterCellDef="let element"
        class="ft-multi-selection"
      ></mat-footer-cell>
    </ng-container>

    <ng-container
      *ngFor="let column of displayedColumns; trackBy: columnTrackByFn"
      [matColumnDef]="column.key"
    >
      <mat-header-cell
        [ngClass]="handleHeaderClass(column)"
        *matHeaderCellDef
        [mat-sort-header]="column?.sortBy"
        [disabled]="!column?.sortBy"
        [title]="column.label | translate"
      >
        {{ column.label | translate }}
      </mat-header-cell>

      <mat-cell
        *matCellDef="let element; let i = index"
        [ngClass]="handleCellClass(element, column)"
        [ngStyle]="
          !['badge'].includes(getColumnType(column, element))
            ? getStyleRulesColumn(element, column)
            : {}
        "
        [ngSwitch]="getColumnType(column, element)"
      >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'text'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{ handleValue(element, column) }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'translate'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{ handleValue(element, column) | translate }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'number'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{
            handleValue(element, column) | ftNumber : column?.numberConfig
          }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'date'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{ handleValue(element, column) | ftDate }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'time'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{
            handleValue(element, column) | ftTime : column?.timeFormats
          }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'datetime'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{ handleValue(element, column) | ftDatetime }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'file-size'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{ handleValue(element, column) | ftFileSize }}</span
        >
        <span [matTooltip]="handleColumnTooltip(element, column) | translate"
          class="ft-table-value-container ft-badge"
          *ngSwitchCase="'badge'"
          [ngStyle]="handleCustomStyle(element, column)"
          ><span class="global-badge">{{ handleValue(element, column) | translate }}</span>
        </span>

        <span
          class="ft-table-value-container"
          *ngSwitchCase="'color'"
          [ngStyle]="handleColumnStyle(element, column)"
        >
          <mat-icon
            fontSet="mdi"
            fontIcon="mdi-checkbox-blank-circle"
            [ngStyle]="handleColumnStyle(element, column)"
            [style.color]="handleValue(element, column)"
          ></mat-icon>
          {{ handleValue(element, column) }}
        </span>

        <span
          class="ft-table-value-container"
          *ngSwitchCase="'progress'"
          [ngStyle]="handleColumnStyle(element, column)"
        >
          <span>{{
            handleValue(element, column) | ftNumber : "percentage"
          }}</span>
          <mat-progress-bar
            mode="determinate"
            [value]="handleValue(element, column) * 100"
          ></mat-progress-bar>
        </span>

        <span
          class="ft-table-value-container"
          *ngSwitchCase="'html'"
          [ngStyle]="handleColumnStyle(element, column)"
          [innerHTML]="handleHtmlValue(element, column)"
        ></span>

        <mat-icon
          *ngSwitchCase="'icon'"
          fontSet="mdi"
          [fontIcon]="handleIconValue(element, column, styleRules)"
          [ngStyle]="handleCustomStyle(element, column)"
          [matTooltip]="handleColumnTooltip(element, column) | translate"
        ></mat-icon>

        <mat-icon
          *ngSwitchCase="'boolean'"
          fontSet="mdi"
          fontIcon="mdi-checkbox-blank-circle"
          [ngStyle]="handleCustomStyle(element, column)"
          [matTooltip]="handleColumnTooltip(element, column) | translate"
          [ngClass]="{
            'ft-success': handleValue(element, column),
            'mat-warn': !handleValue(element, column)
          }"
        >
        </mat-icon>

        <ng-template [ngSwitchCase]="'actions'">
          <ng-container *ngFor="let action of column?.actions">
            <ng-template [ftAcl]="action.acl" [ignoreEmpty]="true">
              <button
                type="button"
                mat-icon-button
                *ngIf="!handleActionHidden(element, action)"
                [disabled]="handleActionBehaviour(element, action)"
                (click)="handleActionClick(element, action, i, $event)"
                [ngClass]="action.class"
                [matTooltip]="action?.tooltip | translate"
              >
                <mat-icon [fontIcon]="action.icon" fontSet="mdi"></mat-icon>
              </button>
            </ng-template>
          </ng-container>
        </ng-template>
      </mat-cell>
      <mat-footer-cell
        *matFooterCellDef="let element; let i = index"
        [ngClass]="handleCellClass(element, column)"
        [ngSwitch]="column.type"
      >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'text'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{ handleFooterValue(element, column) }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'translate'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{ handleFooterValue(element, column) | translate }}</span
        >
        <span
          class="ft-table-value-container"
          *ngSwitchCase="'number'"
          [ngStyle]="handleColumnStyle(element, column)"
          >{{
            handleFooterValue(element, column) | ftNumber : column?.numberConfig
          }}</span
        >
      </mat-footer-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="columnsItems; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columnsItems"
      [ngClass]="handleRowClass(row)"
      matRipple
      matRippleColor="rgba(0,0,0,.1)"
      [matRippleDisabled]="!selectable && !hasClickItem()"
      (click)="handleRowClick(row)"
      [ngStyle]="getStyleRulesRow(row)"
    >
    </mat-row>
    <mat-footer-row
      *matFooterRowDef="columnsItems; sticky: true"
      [ngClass]="{ hidden: !hasFooter || !hasItems() }"
    ></mat-footer-row>
  </mat-table>
</div>
<div class="empty-table-cell mat-cell" *ngIf="!hasItems()">
  {{ "table.no_element_to_show" | translate }}
</div>

<mat-paginator
  [hidden]="!hasPagination"
  [pageSize]="pageSize"
  [length]="dataLength"
  [pageSizeOptions]="pageSizeOptions"
  (page)="handlePaginate($event)"
  showFirstLastButtons
>
</mat-paginator>
