import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {get, has} from 'lodash';
import {FtWsService} from '@ft/core';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private httpClient: HttpClient, private ws: FtWsService) {
  }

  getPatient(pk) {
    return this.httpClient.get<any>(`api/pl/patient/${pk}/`);
  }

  savePatient(model) {
    return this.httpClient.post(`api/pl/patient/${has(model, 'id') ? get(model, 'id') + '/' : ''}`, model);
  }

  updatePatient(pk, data) {
    return this.httpClient.put(`api/pl/patient/${pk}/`, data);
  }

  deletePatient(pk) {
    return this.httpClient.delete(`api/pl/patient/${pk}/`);
  }

  checkExistence(query) {
    return this.ws.call(`patient.check_exist`, query);
  }

  allowPatientDelete(pk) {
    return this.ws.call(`patient.allow_delete`, {pk});
  }

  getTitles() {
    return this.ws.call(`patient.get_external_titles`);
  }

  getCountries() {
    return this.ws.call(`patient.get_external_countries`);
  }

  getCities() {
    return this.ws.call(`patient.get_external_cities`);
  }

  getInsurances() {
    return this.ws.call(`patient.get_external_insurances`);
  }


  getMaritalStatus() {
    return this.ws.call(`patient.get_marital_status`);
  }


  getProfessions() {
    return this.ws.call(`patient.get_profession`);
  }

  getProvince() {
    return this.ws.call(`patient.get_province`);
  }


}
