<!-- <h2 translate="shared.comments"></h2> -->
<h2 mat-dialog-title>{{data.question | translate}}</h2>

<!-- <button mat-raised-button (click)="onClose()">Open dialog without animation</button> -->
<!-- <button mat-button [mat-dialog-close]="false" (click)="handleSave(false)">No</button> -->
<div>
    <mat-form-field class="w-full" appearance="outline">
      <!-- <mat-label>{{
        "appointment.passage_proof.comment" | translate
      }}</mat-label> -->
      <textarea
        matInput
        #requestContent
        name="commentText"
        [(ngModel)]="commentText"
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="10"
        [disabled]="false"
      ></textarea>
      <!-- [(ngModel)]="commentText" -->
      <!-- (keyup)="saveComment($event)" -->
    </mat-form-field>
  </div>
<div mat-dialog-actions>
    <button mat-button (click)="handleSave(false)">{{"shared.cancel" | translate}}</button>
    <button mat-button (click)="handleSave(true)" cdkFocusInitial>{{"shared.ok" | translate}}</button>
  </div>