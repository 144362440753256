import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from 'src/app/settings/services/settings.service';
import {projectName} from 'src/app/shared/utils/consts';
import {TicketService} from '../../services/ticket.service';
import {isNull} from 'lodash';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UIService} from 'src/app/shared/services/ui.service';

@Component({
  selector: 'pr-satisfaction-form',
  templateUrl: './satisfaction-form.component.html',
  styleUrls: ['./satisfaction-form.component.scss'],
})
export class SatisfactionFormComponent implements OnInit {
  public subscription: Subscription;

  public satisfactionForm : any;
  public ticket_id: string;
  private _subs = new Subscription();
  public showExistWarning=false;
  public showSaveSuccess=false;
  public showMissedTicketWarning=false;
  public readOnly=false;
  public readFormId=null;
  constructor(
    private _titleService: Title,
    public translateService: TranslateService,
    public settingsService: SettingsService,
    private _ticketService: TicketService,
    private _activatedRoute: ActivatedRoute,
    private _uiService: UIService
  ) {}

stars: boolean[] = Array(5).fill(false);
  rating: number = 0;
  hoverState: number = 0;

  rate(rating: number): void {
    this.rating = rating;
    this.satisfactionForm.rating=this.rating * 20;
  }

  hover(rating: number): void {
    this.hoverState = rating;
  }

  resetHover(): void {
    this.hoverState = 0;
  }

  get ratingMessage(): string {
    return `Vous avez noté ce service ${this.rating * 20}/100.`;
  }

  ngOnInit(): void {
    // TODO fix title
    this._titleService.setTitle(
      projectName +
        ' - ' +
        this.translateService.instant('appointment.satisfaction_form')
    );
    this.readOnly=this._activatedRoute.snapshot.queryParams['read'];

    if(this.readOnly){
      this.readFormId=this._activatedRoute.snapshot.queryParams['form'];
      if( this.readFormId){
           this._ticketService.getSatisfactionForm(this.readFormId).subscribe(data=>{
             this.satisfactionForm =data;
           })
      }

    }else{
         this._activatedRoute.params.subscribe({
        next: (params) => {
          this.ticket_id = params['id'];
          if (params['id']) {
                // todo checkek first ticket existance

                this.subscription= this._ticketService
                .getTicketSatisfactionForm(params['id'])
                .subscribe((satisfactionForm) => {
                  if (isNull(satisfactionForm)) this.showMissedTicketWarning=true;
                  else if (satisfactionForm.length > 0) this.showExistWarning=true;
                  else {
                    this.satisfactionForm = {
                      template:
                        this.settingsService
                          .prescriptionSatisfactionFormTemplate,
                    };
                  }
                })
          }
        },
        error: (error) => {
          this._uiService.log(error);
        },
      })
    }

  }

  public save(event) {
    this.satisfactionForm['ticket'] = parseInt(this.ticket_id);
    this.subscription=this._ticketService
      .saveTicketSatisfactionForm(this.satisfactionForm)
      .subscribe({
        next: (res) => {this.showSaveSuccess=true},
      });
  }

}
