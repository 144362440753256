import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, map, of } from 'rxjs';
import { TicketType } from '../../ticket/ticket.type';
import { DataApiService } from '../../shared/services/data-api.service';
import { HttpClient } from '@angular/common/http';
import { get, has } from 'lodash';
import { appointmentSample } from '../../shared/samples/patient-appointment.sample';
import { AppointmentType } from '../patient-appointment.type';
import { UIService } from '../../shared/services/ui.service';

@Injectable({
  providedIn: 'root',
})
export class PatientAppointmentService extends DataApiService<
  TicketType | any
> {
  constructor(public httpClient: HttpClient, public uiService: UIService) {
    super(httpClient, uiService);
    this.setRootUrl('/api/pl/appointment');
  }

  public getPatientAppointments(patientId: number) {}

  public getPatientAppointmentItem(idObj: any): Observable<AppointmentType> {
    return appointmentSample().pipe(
      map((appointments: AppointmentType[]) => {
        return appointments.find((item: AppointmentType) => {
          return item.id == idObj.id;
        });
      })
    );
  }

  public confirmAppointment(id: number) {
    return appointmentSample().pipe(
      map((appointments: AppointmentType[]) => {
        return appointments.find((appointment: AppointmentType) => {
          if (appointment.id == id) {
            appointment.appointment_confirmation_histories.push({
              performer_id: 123,
              created_at: '2023-01-01',
              is_confirmed: true,
            });
            appointment.status_histories.push({
              created_at: '2023-01-01',
              status: 'CONFIRMED',
            });
            return appointment;
          }
        });
      })
    );
  }

  public requestRescheduling(id: number) {
    return appointmentSample().pipe(
      map((appointments: AppointmentType[]) => {
        return appointments.find((appointment: AppointmentType) => {
          if (appointment.id == id) {
            appointment.status_histories.push({
              created_at: '2023-01-01',
              status: 'RESCHEDULE_REQUESTED',
            });
            return appointment;
          }
        });
      })
    );
  }

  public requestCancellation(id: number) {
    return appointmentSample().pipe(
      map((appointments: AppointmentType[]) => {
        return appointments.find((appointment: AppointmentType) => {
          if (appointment.id == id) {
            appointment.status_histories.push({
              created_at: '2023-01-01',
              status: 'CANCEL_REQUESTED',
            });
            return appointment;
          }
        });
      })
    );
  }

  public ChangeStatusToReceived(items: AppointmentType[]) {
    const appointmentIds: number[] = items
      .filter((appointmentData) => {
        return !appointmentData['appointment_receipt'];
      })
      .map((appointmentData: AppointmentType) => {
        return appointmentData.id;
      });
    if (appointmentIds.length > 0)
      return this.update({
        appointment_ids: appointmentIds,
        dataAPIAction: 'receipt_bulk',
      });
    else {
      return of(false);
    }
  }

  
}
