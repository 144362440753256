<div class="ft-parent-layout ft-content-margin mat-elevation-z1" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-phone-message"></mat-icon>
        </div>
        <h2 translate="settings.sms_setup"></h2>
        <span fxFlex=""></span>
        <button type="button" mat-button class="with-icon" (click)="addSmsTemplate($event)"
                *ngIf="tabIdx==1 && this.smsTemplate['id']">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span>{{ 'shared.add' | translate }}</span>
        </button>
    </mat-toolbar>
    <div fxFlex fxLayout="column" class="ft-content no-padding">
        <mat-tab-group fxFlex (selectedTabChange)="onTabChange($event)">
            <mat-tab fxFlex label="{{'settings.sms_history'|translate}}">
                <ft-generic-body-table fxFlex
                                       [remote]="false"
                                       [source]="dataSource"
                                       [selectable]="false"
                                       [hasPagination]="true"
                                       [columns]="displayedColumns"
                                       [multiActions]="multiActions"
                >
                </ft-generic-body-table>
            </mat-tab>
            <mat-tab fxFlex label="{{'settings.sms_templates'|translate}}">

                <mat-drawer-container class="ft-content-container" fxFlex>
                    <mat-drawer mode="side" [opened]="true">
                        <mat-toolbar color="primary">
                            <div class="ft-module-icon">
                                <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
                            </div>
                            <h2 translate="settings.sms_template_list"></h2>
                        </mat-toolbar>
                        <mat-action-list role="list" class="ft-content">
                            <div *ngIf="!templates || templates.length == 0" class="ft-empty-content">
                                <mat-icon fontSet="mdi" fontIcon="mdi-alert-circle-outline"></mat-icon>
                                <span translate="shared.no_element_to_show"></span>
                            </div>
                            <mat-list-item role="listitem" *ngFor="let t of templates">
                                <button type="button" mat-line (click)="showTemplate(t)" [disabled]="t == smsTemplate">
                                    <mat-icon fxFlex="noshrink" fontSet="mdi" fontIcon="mdi-comment-text"
                                              color="accent"></mat-icon>
                                    <span fxFlex>{{t.title}}</span>
                                    <mat-icon fxFlex="noshrink"
                                              class="ft-success" *ngIf="smsTemplate['id']==t['id']" fontSet="mdi"
                                              fontIcon="mdi-pencil-box-outline"></mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-action-list>
                    </mat-drawer>
                    <mat-drawer-content fxLayout="column" class="tab-drawer-container">
                        <div [ngBusy]="subscription"></div>
                        <form #templateForm="ngForm" fxLayout="column" fxFlex class="ft-padding" fxLayoutGap="6px">
                            <div fxLayout="row">
                                <mat-form-field fxFlex="50">
                                    <input matInput type="text" required name="title" #inpTitle
                                           placeholder="{{'shared.title'|translate}}"
                                           [ftAutoFocus]="true"
                                           [(ngModel)]="smsTemplate.title"/>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" class="sms-template-content" fxLayoutGap="6px">
                                <div class="button-group" fxLayoutGap="6px">
                                    <button type="button" mat-raised-button
                                            (click)="updateContentPatient($event);$event.stopImmediatePropagation();">
                                        {{'shared.patient_fullname_shortcut'|translate}}
                                    </button>
                                    <button type="button" mat-raised-button
                                            (click)="updateContentPhysician($event);$event.stopImmediatePropagation();">
                                        {{'shared.physician_fullname_shortcut'|translate}}
                                    </button>
                                </div>
                                <mat-form-field fxFlex>
                            <textarea matInput name="content" rows="10" #inp required
                                      placeholder="{{'shared.sms_msg_content'|translate}}"
                                      [(ngModel)]="smsTemplate.content"></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                        <div class="ft-actions">
                            <button type="button" *ngIf="smsTemplate['id']" (click)="removeTemplate($event)"
                                    mat-mini-fab
                                    color="warn" [matTooltip]="'shared.delete'|translate">
                                <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
                            </button>
                            <span fxFlex></span>
                            <button type="button" [disabled]="templateForm.invalid" mat-raised-button color="primary"
                                    (click)="saveTemplate()">
                                <span>{{'shared.save'|translate}}</span>
                            </button>
                        </div>

                    </mat-drawer-content>
                </mat-drawer-container>
            </mat-tab>
            <mat-tab fxFlex label="{{'settings.sms_server'|translate}}">
                <div fxLayout="column" fxFlex="" class="ft-busy-container" [ngBusy]="subscription">
                    <form #form="ngForm" fxFlex fxLayout="column" class="ft-padding">
                        <div fxLayout="row" fxLayoutGap="6px">
                            <mat-form-field fxFlex="30">
                                <input matInput type="text" required name="identifier"
                                       placeholder="{{'settings.sms_identifier'|translate}}"
                                       [ftAutoFocus]="true"
                                       [(ngModel)]="smsServer.identifier"/>
                            </mat-form-field>
                            <mat-form-field fxFlex="30">
                                <input matInput type="text" required name="user_name"
                                       placeholder="{{'auth.user_name'|translate}}"
                                       [(ngModel)]="smsServer.user_name"/>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="6px">
                            <mat-form-field fxFlex="30">
                                <input matInput [type]="showPassword ? 'text' : 'password'" required
                                       [placeholder]="'login.auth.password' | translate" name="password"
                                       [(ngModel)]="smsServer.password">
                            </mat-form-field>
                            <mat-form-field fxFlex="30">
                                <input matInput [type]="showConfirmPassword ? 'text' : 'password'" required
                                       [placeholder]="'shared.confirm_password' | translate" name="password"
                                       [(ngModel)]="smsServer.confirmation_password">
                            </mat-form-field>
                        </div>
                    </form>
                    <ng-template [ftAcl]="{resource: 'sms-message', action: 'sms_server_config'}">
                        <div class="ft-actions"
                             fxFlex="noshrink">
                            <span fxFlex></span>
                            <button mat-raised-button color="primary" (click)="save()" [disabled]="form.invalid">
                                <span>{{'shared.save' | translate}}</span>
                            </button>
                        </div>
                    </ng-template>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
